import { useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'

import { faqTexts } from './faqTexts'
import { useSelector } from 'react-redux'
import { GlobalState } from '../../data/store'

import './styles.css'

export function FaqPage(): JSX.Element {
    const [expanded, setExpanded] = useState('')

    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const isExpanded = (panel: string) => expanded === panel

    const handleToggleExpanded = (panel: string) => {
        if (expanded === panel) {
            setExpanded('')
        } else {
            setExpanded(panel)
        }
    }

    const faqPairs = faqTexts.map((translatePair) => {
        // @ts-ignore
        const translateQ = translatePair[0][lang]
        // @ts-ignore
        const translateA = translatePair[1][lang]
        return [translateQ, translateA]
    })

    const title = {
        bg: 'Често задавани въпроси',
        en: 'Frequently Asked Questions',
    }
	// @ts-ignore
    const translatedTitle = title[lang]

    return (
        <div className="faq-container">
            <Typography variant="h4" align="center" className="faq-header">
                {translatedTitle}
            </Typography>
            <div className="faq-accordions">
                {faqPairs.map((keyValueArray: string[], i: number) => {
                    const question = keyValueArray[0]
                    const answer = keyValueArray[1]
                    return (
                        <Accordion
                            key={i}
                            expanded={isExpanded(`panel${i}`)}
                            onChange={() => handleToggleExpanded(`panel${i}`)}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <strong>{question}</strong>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>
        </div>
    )
}
