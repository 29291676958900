export const i18nEntryModal = {
    title: {
        bg: 'ВАЖНО СЪОБЩЕНИЕ',
        en: 'IMPORTANT MESSAGE'
    },
    message1: {
        bg: 'Тъй като постоянно се стремим да подобряваме услугата се местим на нов адрес в Италия.',
        en: 'As we are constatly trying to improve our service we are moving to a new address in Italy.'
    },
    message2: {
        bg: 'ВАЖНО - Изпращайте всяка следваща пратка на новия ни адрес:',
        en: 'IMPORTANT - Send any new package/s on our new address:'
    },
    confirmButton: {
        bg: 'РАЗБРАХ',
        en: 'UNDERSTOOD'
    }
}
