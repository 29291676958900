import { Device, useDevice } from "./useDevice"

export interface DeviceStrings {
	mobile: string
    tablet: string | null
    smallDesktop: string
    mediumDesktop: string | null
    bigDesktop: string | null
}

export function useResponsiveString(strings: DeviceStrings) {
	const device = useDevice()

	return switchComponent(device, strings)
}

function switchComponent(device: Device, strings: DeviceStrings): string {
    if (device === 'm') return strings.mobile
    if (device === 't') return strings.tablet || strings.mobile
    if (device === 'sd') return strings.smallDesktop
    if (device === 'md') return strings.mediumDesktop || strings.smallDesktop
    if (device === 'bd') return strings.bigDesktop || strings.mediumDesktop || strings.smallDesktop

    return strings.mobile
}
