export const i18nRegBanner = {
	question: {
		bg: 'Нямаш профил в системата?',
		en: "Don't have a profile yet?"
	},
	action: {
		bg: 'Регистрирай се сега!',
		en: 'Create your profile now!'
	},
	buttonLabel: {
		bg: 'Регистрация',
		en: 'Register'
	}
}
