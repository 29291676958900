import { AnyAction, createReducer } from '@reduxjs/toolkit'

import { ActionTypes } from '../actionTypes'

export type NotificationType = 'success' | 'error' | 'warning'

export interface NotificationState {
    visible: boolean
    message: string
    type: NotificationType
}

const initialState: NotificationState = {
    visible: false,
    message: '',
    type: 'success',
}

export const notificationReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(ActionTypes.notification.show.typeName, (state: NotificationState, action: AnyAction) => {
			state.visible = true
			state.type = action.payload.type
			state.message = action.payload.message
		})
		.addCase(ActionTypes.notification.hide.typeName, (state: NotificationState) => {
			state.visible = false
		})
})
