export const i18nProfileCO = {
	noCompletedOrders: {
		bg: 'Все още нямате завършени пратки',
		en: 'There are no completed orders yet'
	},
	dateCreated: {
		bg: 'Създадена',
		en: 'Created'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	weight: {
		bg: 'Тегло',
		en: 'Weight'
	},
	count: {
		bg: 'Брой',
		en: 'Count'
	},
	origin: {
		bg: 'Произход',
		en: 'Origin'
	},
	price: {
		bg: 'Цена продукт',
		en: 'Product price'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	}
}
