import { Formik } from 'formik'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { Typography } from '@mui/material'

import { i18nRestorePass } from '../i18n'
import { useTranslate } from '../../../hooks/translate'
import { restorePassword } from '../../../data/actions/usersActions'
import { RestorePasswordFormDesktop } from './RestorePasswordFormDesktop'
import { validateEmail, validatePassword } from '../../../utils/formik/validations'

import './styles.css'

function RestorePasswordPageDesktopComponent(props: any): JSX.Element {
    const { token } = useParams()

    const t = useTranslate(i18nRestorePass)

    return (
        <div className="restore-password-container">
            <div className="restore-password-form-wrapper">
                <Typography variant="h3" className="rp-header">
                    {t.title}
                </Typography>
                <Typography variant="body1" className="rp-hint">
                    {t.subTitle}
                </Typography>
                <Formik
                    initialValues={{
                        email: '',
                        newPassword: '',
                    }}
                    validate={(values) => {
                        return {
                            ...validateEmail(values, ['email']),
                            ...validatePassword(values, ['newPassword']),
                        }
                    }}
                    onSubmit={(values: any, { setSubmitting }) => {
                        setSubmitting(true)
                        props.restorePassword({ ...values, token })
                    }}
                >
                    {(props) => <RestorePasswordFormDesktop {...props} />}
                </Formik>
            </div>
        </div>
    )
}

const mapDispatchToProps = { restorePassword }

export const RestorePasswordPageDesktop = connect(null, mapDispatchToProps)(RestorePasswordPageDesktopComponent)
