import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

import { i18nMainNavBar } from '../i18n'
import { useTranslate } from '../../../../hooks/translate'
import MainLogo from '../../../../assets/vectors/TUTTO-POST_MAIN-LOGO.svg'

import './styles.css'

export function NavBarDesktop() {
    const t = useTranslate(i18nMainNavBar)

    return (
        <div className="nav-bar-desktop">
            <Link to="/" className="main-logo-link">
                <img src={MainLogo} alt="main-logo" className="main-logo-img" />
            </Link>
            <div className="right">
                <Link to="/how-it-works">
                    <Button size="large" color="info" variant="text" className="nav-button">
                        {t.howItWorks}
                    </Button>
                </Link>
                <Link to="/schedules">
                    <Button size="large" color="info" variant="text" className="nav-button">
                        {t.schedule}
                    </Button>
                </Link>
                <Link to="/pricing">
                    <Button size="large" color="info" variant="text" className="nav-button">
                        {t.prices}
                    </Button>
                </Link>
                {/* <Link to="/stores">
                    <Button size="large" color="info" variant="text" className="nav-button">
                        Магазини
                    </Button>
                </Link> */}
                <Link to="/contacts">
                    <Button size="large" color="info" variant="text" className="nav-button">
                        {t.contacts}
                    </Button>
                </Link>
                <Link to="/register-package">
                    <Button size="large" color="secondary" variant="contained" className="nav-button">
                        {t.registerOrder}
                    </Button>
                </Link>
            </div>
        </div>
    )
}
