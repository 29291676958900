export const i18nAdminCombineOrders = {
    title: {
        bg: 'Потвърждение за комбиниране на пратки',
        en: 'Combine orders confirmation'
    },
    sureText: {
        bg: 'Сигурни ли сте, че искате да комбинирате описаните пратки?',
        en: 'Are you sure you want to combine the packages, listed below?'
    },
    confirm: {
        bg: 'Комбинирай',
        en: 'Combine'
    },
    close: {
        bg: 'Затвори',
        en: 'Close'
    },
    kg: {
        bg: 'кг',
        en: 'kg'
    },
    cm: {
        bg: 'см',
        en: 'cm'
    }
}
