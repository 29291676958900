//@ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from "react-redux"

import { GlobalState } from "../../../../data/store"
import { Order } from "../../../../data/reducers/ordersReducer"

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function OrdersDestinationOvertimeGraphic() {
    const { orders } = useSelector((state: GlobalState) => state.orders)

    const ordersData = mapOrders(orders)
    const sofiaDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.sofiaOrders.length
    }))
    const plovdivDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.plovdivOrders.length
    }))
    const varnaDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.varnaOrders.length
    }))
    const burgasDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.burgasOrders.length
    }))
    const ruseDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.ruseOrders.length
    }))
    const otherDataPoints = ordersData.map(ordersByMonth => ({
        x: ordersByMonth.date,
        y: ordersByMonth.otherOrders.length
    }))

    const options = {
        theme: "light2",
        animationEnabled: true,
        title: {
            text: "Orders destination"
        },
        data: [
            {
                type: "spline",
                name: "Sofia",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Sofia #,##0",
                dataPoints: sofiaDataPoints
            },
            {
                type: "spline",
                name: "Plovdiv",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Plovdiv #,##0",
                dataPoints: plovdivDataPoints
            },
            {
                type: "spline",
                name: "Varna",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Varna #,##0",
                dataPoints: varnaDataPoints
            },
            {
                type: "spline",
                name: "Burgas",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Burgas #,##0",
                dataPoints: burgasDataPoints
            },
            {
                type: "spline",
                name: "Ruse",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Ruse #,##0",
                dataPoints: ruseDataPoints
            },
            {
                type: "spline",
                name: "Other",
                showInLegend: true,
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "Other #,##0",
                dataPoints: otherDataPoints
            }
        ]
    }

    return <CanvasJSChart options={options} />
}

function mapOrders(orders: Order[]) {
    const yearAgo = new Date()
    yearAgo.setFullYear(yearAgo.getFullYear() - 1)
    yearAgo.setHours(0, 0, 0, 0)

    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - 1)

    const registeredOrdersByMonth = []
    while (
        yearAgo.getTime() <= currentDate.getTime()
    ) {
        yearAgo.setMonth(yearAgo.getMonth() + 1)
        const fromDateEpoch = yearAgo.getTime()

        const toDate = new Date(yearAgo)
        toDate.setMonth(toDate.getMonth() + 1)
        const toDateEpoch = toDate.getTime()

        const ordersForCurrentMonth = orders.filter(order => (
            order.registerDateEpoch >= fromDateEpoch &&
            order.registerDateEpoch <= toDateEpoch
        ))

        const destinations = ['sofia', 'софия', 'plovdiv', 'пловдив', 'varna', 'варна', 'burgas', 'бургас', 'ruse', 'русе']

        const isSofia = destinationPredicate(destinations[0], destinations[1])
        const isPlovdiv = destinationPredicate(destinations[2], destinations[3])
        const isVarna = destinationPredicate(destinations[4], destinations[5])
        const isBurgas = destinationPredicate(destinations[6], destinations[7])
        const isRuse = destinationPredicate(destinations[8], destinations[9])

        const sofiaOrders = ordersForCurrentMonth.filter(isSofia)
        const plovdivOrders = ordersForCurrentMonth.filter(isPlovdiv)
        const varnaOrders = ordersForCurrentMonth.filter(isVarna)
        const burgasOrders = ordersForCurrentMonth.filter(isBurgas)
        const ruseOrders = ordersForCurrentMonth.filter(isRuse)
        const otherOrders = ordersForCurrentMonth.filter(order => {
            return destinations.every(destination => !order.deliveryAddress.toLowerCase().includes(destination))
        })

        registeredOrdersByMonth.push({
            date: new Date(yearAgo),
            month: yearAgo.getMonth(),
            sofiaOrders,
            plovdivOrders,
            varnaOrders,
            burgasOrders,
            ruseOrders,
            otherOrders
        })
    }

    registeredOrdersByMonth.pop()

    return registeredOrdersByMonth
}

function destinationPredicate(cityNameEn: string, cityNameBg: string) {
    return (order: Order) => (
        order.deliveryAddress.toLowerCase().includes(cityNameEn) ||
        order.deliveryAddress.toLowerCase().includes(cityNameBg)
    )
}