import { IconButton, Tooltip } from "@mui/material"
import { User } from "../../../data/reducers/usersReducer"
import { DeleteForeverOutlined } from "@mui/icons-material"
import { getFormattedDate } from "../../../utils/functions"

export const clientTableStruct = (heads: string[]) => [
	{
		head: heads[0],
		getData: (obj: User) => obj.uniqueId,
		sortable: true,
		name: 'uniqueId'
	},
	{
		head: heads[1],
		getData: (obj: User) => obj.email,
		sortable: false
	},
	{
		head: heads[2],
		getData: (obj: User) => obj.phoneNumber,
		sortable: false
	},
	{
		head: heads[3],
		getData: (obj: User) => obj.country,
		sortable: true,
		name: 'country'
	},
	{
		head: heads[4],
		getData: (obj: User) => obj.city,
		sortable: true,
		name: 'city'
	},
	{
		head: heads[5],
		getData: (obj: User) => {
			if (obj.firstName && obj.lastName) {
				return `${obj.firstName} ${obj.lastName}`
			}

			return 'x'
		},
		sortable: true,
		name: 'firstName'
	},
	{
		head: heads[6],
		getData: (obj: User) => obj.registerDate,
		sortable: true,
		name: 'registerDateEpoch'
	},
]

export const companyTableStruct = (heads: string[]) => [
	{
		head: heads[0],
		getData: (obj: User) => obj.uniqueId,
		sortable: true,
		name: 'uniqueId'
	},
	{
		head: heads[1],
		getData: (obj: User) => obj.email,
		sortable: false
	},
	{
		head: heads[2],
		getData: (obj: User) => obj.phoneNumber,
		sortable: false
	},
	{
		head: heads[3],
		getData: (obj: User) => obj.companyId,
		sortable: false
	},
	{
		head: heads[4],
		getData: (obj: User) => obj.companyName,
		sortable: true,
		name: 'companyName'
	},
	{
		head: heads[5],
		getData: (obj: User) => obj.companyOwner || 'x',
		sortable: true,
		name: 'companyOwner'
	},
	{
		head: heads[6],
		getData: (obj: User) => obj.registerDate,
		sortable: true,
		name: 'registerDateEpoch'
	},
]

export const clientDataGridStruct = (t: any, handleOpenDeleteModal: (ev: any, user: User) => void) => [
	{
		field: 'uniqueId',
		headerName: t.id,
		type: 'number',
		width: 100,
		editable: false,
	},
	{
		field: 'email',
		headerName: t.email,
		type: 'string',
		width: 250,
		editable: false,
	},
	{
		field: 'phoneNumber',
		headerName: t.phoneNumber,
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'country',
		headerName: t.country,
		type: 'string',
		width: 120,
		editable: false,
	},
	{
		field: 'city',
		headerName: t.city,
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'firstName',
		headerName: t.name,
		type: 'string',
		width: 250,
		editable: false,
		renderCell: (params: any) => {
			const { firstName, lastName } = params.row
			if (firstName && lastName) {
				return `${params.row.firstName} ${params.row.lastName}`
			}

			return 'x'
		}
	},
	{
		field: 'registerDateEpoch',
		headerName: t.registerDate,
		type: 'number',
		width: 150,
		renderCell: (params: any) => getFormattedDate(true, true, true, true, params.value)
	},
	{
		field: 'actions',
		headerName: t.actions,
		type: 'string',
		width: 100,
		renderCell: (params: any) => {
			return (
				<Tooltip title={t.deleteOrder}>
					<IconButton color="secondary" onClick={(ev: any) => handleOpenDeleteModal(ev, params.row)}>
						<DeleteForeverOutlined />
					</IconButton>
				</Tooltip>
			)
		}
	},
]

export const companyDataGridStruct = (t: any, handleOpenDeleteModal: (ev: any, user: User) => void) => [
	{
		field: 'uniqueId',
		headerName: t.idCompany,
		type: 'number',
		width: 100,
		editable: false,
	},
	{
		field: 'email',
		headerName: t.emailCompany,
		type: 'string',
		width: 250,
		editable: false,
	},
	{
		field: 'phoneNumber',
		headerName: t.companyPhoneNumber,
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'companyId',
		headerName: t.companyId,
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'companyName',
		headerName: t.companyName,
		type: 'string',
		width: 200,
		editable: false,
	},
	{
		field: 'companyOwner',
		headerName: t.companyOwner,
		type: 'string',
		width: 250,
		editable: false,
		renderCell: (params: any) => {
			const { firstName, lastName } = params.row
			if (firstName && lastName) {
				return `${params.row.firstName} ${params.row.lastName}`
			}

			return 'x'
		}
	},
	{
		field: 'registerDateEpoch',
		headerName: t.registerDate,
		type: 'number',
		width: 150,
		renderCell: (params: any) => getFormattedDate(true, true, true, true, params.value)
	},
	{
		field: 'actions',
		headerName: t.actions,
		type: 'string',
		width: 100,
		renderCell: (params: any) => {
			return (
				<Tooltip title={t.deleteOrder}>
					<IconButton color="secondary" onClick={(ev: any) => handleOpenDeleteModal(ev, params.row)}>
						<DeleteForeverOutlined />
					</IconButton>
				</Tooltip>
			)
		}
	},
]
