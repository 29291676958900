import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Box } from '@mui/system'
import {
    Menu,
    Call,
    Login,
    Logout,
    AttachMoney,
    QuestionMark,
    LocalShipping,
    FollowTheSigns,
    AccountCircleOutlined,
} from '@mui/icons-material'
import {
    List,
    Button,
    Drawer,
    Divider,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    ListItemButton,
} from '@mui/material'

import { i18nMainNavBar } from '../i18n'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import MainLogo from '../../../../assets/vectors/TUTTO-POST_MAIN-LOGO.svg'

import './styles.css'

export function NavBarMobile(): JSX.Element {
    const [open, setOpen] = useState(false)

    const openDrawer = () => setOpen(true)
    const closeDrawer = () => setOpen(false)

    const user = useSelector((state: GlobalState) => state.users.loggedIn)

    const t = useTranslate(i18nMainNavBar)

    return (
        <div className="nav-bar-mobile">
            <div className="mobile-nav-elements">
                <Link to="/" className="main-logo-link">
                    <img src={MainLogo} alt="main-logo" className="main-logo-img" />
                </Link>
                <Link to="/register-package">
                    <Button size="small" variant="contained" color="secondary" className="register-order-nav-button">
                        {t.registerOrder}
                    </Button>
                </Link>
                <IconButton color="info" onClick={openDrawer}>
                    <Menu />
                </IconButton>
            </div>
            <Drawer anchor="right" open={open} onClose={closeDrawer}>
                <Box sx={{ width: 300 }} role="presentation" onClick={closeDrawer} onKeyDown={closeDrawer}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <QuestionMark />
                                </ListItemIcon>
                                <Link to="/how-it-works">
                                    <ListItemText primary={t.howItWorks} />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LocalShipping />
                                </ListItemIcon>
                                <Link to="/schedules">
                                    <ListItemText primary={t.schedule} />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AttachMoney />
                                </ListItemIcon>
                                <Link to="/pricing">
                                    <ListItemText primary={t.prices} />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Call />
                                </ListItemIcon>
                                <Link to="/contacts">
                                    <ListItemText primary={t.contacts} />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <Link to="/register-package">
                                    <Button variant="contained" color="secondary">
                                        {t.registerOrder}
                                    </Button>
                                </Link>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        {user.isLogged ? (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <AccountCircleOutlined />
                                        </ListItemIcon>
                                        <Link to="/profile">
                                            <ListItemText primary={t.profile} />
                                        </Link>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <Logout />
                                        </ListItemIcon>
                                        <Link to="/logout">
                                            <ListItemText primary={t.logout} />
                                        </Link>
                                    </ListItemButton>
                                </ListItem>
                            </>
                        ) : (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <Login />
                                        </ListItemIcon>
                                        <Link to="/login">
                                            <ListItemText primary={t.login} />
                                        </Link>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <FollowTheSigns />
                                        </ListItemIcon>
                                        <Link to="/register">
                                            <ListItemText primary={t.register} />
                                        </Link>
                                    </ListItemButton>
                                </ListItem>
                            </>
                        )}
                    </List>
                </Box>
            </Drawer>
        </div>
    )
}
