import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { Box } from '@mui/system'
import {
    Chip,
    Table,
    Paper,
    TableRow,
    Accordion,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    IconButton,
    TableContainer,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material'
import { ExpandMore, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material'

import { i18nProfileIS } from '../i18n'
import { tableStruct } from '../tableUtils'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import { getFormattedDate } from '../../../../utils/functions'
import { getPricesSettings } from '../../../../data/actions/settingsActions'
import { ORDER_STATUS, Order } from '../../../../data/reducers/ordersReducer'
import { getInternalScheduleStatusChip } from '../../../../components/Shared'
import { showNotification } from '../../../../data/actions/notificationActions'
import { InternalSchedule } from '../../../../data/reducers/internalSchedulesReducer'
import { updateOrder, getSpecificOrders } from '../../../../data/actions/ordersActions'
import { changeDeliveriesOrder, getActiveInternalSchedules } from '../../../../data/actions/internalSchedulesActions'

import './styles.css'

function InternalSchedulesDesktopComponent(props: any): JSX.Element {
    const {
        getActiveInternalSchedules,
        changeDeliveriesOrder,
        getPricesSettings,
        getSpecificOrders,
        showNotification,
        updateOrder,
    } = props

    const [expanded, setExpanded] = useState({} as InternalSchedule)

    const prices = useSelector((state: GlobalState) => state.settings.prices)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const { schedules } = useSelector((state: GlobalState) => state.internalSchedules)
    const { orders } = useSelector((state: GlobalState) => state.orders)
    const { loggedIn } = useSelector((state: GlobalState) => state.users)

    const scheduleOrders = expanded?.orders?.map(orderId => {
        return orders?.find(x => x._id === orderId) ?? undefined
    }) as any

    const t = useTranslate(i18nProfileIS)
    const heads = Object.values(t).slice(2)

    useEffect(() => {
        const schedule = schedules?.find(x => x._id === expanded._id)
        if (schedule) {
            setExpanded(schedule)
        }
    }, [schedules, expanded._id])

    useEffect(() => {
        if (expanded._id && expanded.orders?.length) {
            getSpecificOrders(expanded.orders)
        }
    }, [getSpecificOrders, expanded])

    useEffect(() => {
        getPricesSettings()
        getActiveInternalSchedules()
    }, [getActiveInternalSchedules, getPricesSettings])

    if (!schedules?.length) {
        return <Typography variant='h4'>{t.noInternalSchedules}</Typography>
    }

    const handleClick = (panel: InternalSchedule) => {
        if (expanded._id === panel._id) {
            setExpanded({} as InternalSchedule)
        } else {
            setExpanded(panel)
        }
    }

    const handleStatusClicked = (ev: any, data: any) => {
        ev.stopPropagation()

        let newStatus = ORDER_STATUS.COMPLETED
        if (data.status === ORDER_STATUS.COMPLETED) {
            newStatus = ORDER_STATUS.ARRIVED_BG
        }

        updateOrder({ userId: loggedIn._id, orderId: data._id, status: newStatus })
    }

    const tableStructure = tableStruct(heads, lang)

    function getFromTo(schedule: InternalSchedule): string {
        const from = getFormattedDate(true, true, true, false, schedule.startDate)
        const to = getFormattedDate(true, true, true, false, schedule.endDate)
        return `${from} - ${to}`
    }

    function moveUp(ev: any, row: Order): void {
        ev.stopPropagation()

        const index = scheduleOrders.indexOf(row)
        if (index === 0) {
            return
        }

        const reorderedCopy = scheduleOrders.slice()
        const prev = reorderedCopy[index - 1]
        reorderedCopy[index - 1] = row
        reorderedCopy[index] = prev

        changeDeliveriesOrder({ scheduleId: expanded._id, deliveries: reorderedCopy })
    }

    function moveDown(ev: any, row: Order): void {
        ev.stopPropagation()

        const index = scheduleOrders.indexOf(row)
        if (index === scheduleOrders.length - 1) {
            return
        }

        const reorderedCopy = scheduleOrders.slice()
        const next = reorderedCopy[index + 1]
        reorderedCopy[index + 1] = row
        reorderedCopy[index] = next

        changeDeliveriesOrder({ scheduleId: expanded._id, deliveries: reorderedCopy })
    }

    const copyToClipboard = (ev: any, address: string) => {
        ev.stopPropagation()
        const copiedText = { en: 'Copied to clipboard', bg: 'Адресът е копиран' }
        navigator.clipboard.writeText(address)
        showNotification(copiedText, 'success')
    }

    return (
        <div>
            {schedules?.map((schedule: InternalSchedule, i: number) => {
                return (
                    <Accordion key={i} expanded={expanded._id === schedule._id} onClick={() => handleClick(schedule)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Box sx={{ width: '33%', flexShrink: 0 }}>
                                {getInternalScheduleStatusChip(schedule.status, lang)}
                                <Chip
                                    size="small"
                                    color='success'
                                    variant="filled"
                                    style={{ marginLeft: '5px' }}
                                    label={schedule.deliveryCompany.toUpperCase()}
                                />
                            </Box>
                            <Typography sx={{ color: 'text.secondary' }}>{getFromTo(schedule)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {scheduleOrders && scheduleOrders.length ? (
                                <TableContainer component={Paper} className="orders-container">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {tableStructure.map((x: any, ii: number) => {
                                                    return (
                                                        <TableCell key={ii} align="center">
                                                            <Typography variant="h6">{x.head}</Typography>
                                                        </TableCell>
                                                    )
                                                })}
                                                <TableCell align="center">
                                                    <Typography variant="h6">{heads[6]}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {scheduleOrders.length && scheduleOrders?.map((row: Order, iii: number) => (
                                                <>
                                                    <TableRow
                                                        key={iii}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        {
                                                            tableStructure.map((x: any, iiii: number) => {
                                                                if (!row) {
                                                                    return <></>
                                                                }
                                                                const data = x.getData(row, prices)
                                                                if (x.head === t.address) {
                                                                    return (
                                                                        <TableCell key={iiii} align="center" onClick={(ev) => copyToClipboard(ev, data)}>
                                                                            {data}
                                                                        </TableCell>
                                                                    )
                                                                } else if (x.head === t.status) {
                                                                    return (
                                                                        <TableCell key={iiii} align="center">
                                                                            <span className='update-order-status-wrapper' onClick={(ev: any) => handleStatusClicked(ev, row)}>
                                                                                {data}
                                                                            </span>
                                                                        </TableCell>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <TableCell key={iiii} align="center">
                                                                            {data}
                                                                        </TableCell>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        <TableCell align="center">
                                                            <IconButton onClick={(ev: any) => moveUp(ev, row)}>
                                                                <KeyboardDoubleArrowUp />
                                                            </IconButton>
                                                            <IconButton onClick={(ev: any) => moveDown(ev, row)}>
                                                                <KeyboardDoubleArrowDown />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography>{t.noOrdersMsg}</Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}

const mapDispatchToProps = {
    updateOrder,
    showNotification,
    getPricesSettings,
    getSpecificOrders,
    changeDeliveriesOrder,
    getActiveInternalSchedules,
}

export const InternalSchedulesDesktop = connect(null, mapDispatchToProps)(InternalSchedulesDesktopComponent)
