import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { AccountCircleOutlined, AdminPanelSettings, Logout } from '@mui/icons-material'

import { i18nTopNavBar } from '../i18n'
import { ROLES } from '../../../../utils/roles'
import { GlobalState } from '../../../../data/store'
import { texts } from '../../../../utils/i18n/texts'
import { useTranslate } from '../../../../hooks/translate'
import { changeLanguage } from '../../../../data/actions/languageActions'

import ukFlag from '../../../../assets/uk-flag.png'
import itFlag from '../../../../assets/italy-flag.png'
import bgFlag from '../../../../assets/bulgaria-flag.png'

import './styles.css'

export function TopNavBarDesktopComponent(props: any): JSX.Element {
    const { loggedIn } = useSelector((state: GlobalState) => state.users)

    const t = useTranslate(i18nTopNavBar)

    const bgLang = () => props.changeLanguage('bg')
    const enLang = () => props.changeLanguage('en')

    const isAdmin = loggedIn.role === ROLES.ADMIN || loggedIn.role === ROLES.MODERATOR

    return (
        <Box sx={{ backgroundColor: 'info.main' }} className="top-nav-bar">
            <div className="left">
                <a href={`tel:${texts.phoneBG}`} className="phone-link">
                    <img src={bgFlag} alt="italy-flag" className="image-flag" />
                    {texts.phoneBG}
                </a>
                <a href={`tel:${texts.phoneIT}`} className="phone-link">
                    <img src={itFlag} alt="italy-flag" className="image-flag" />
                    {texts.phoneIT}
                </a>
            </div>
            <div className="center">
                {loggedIn.isLogged ? (
                    <span className="center-links">
                        <Link to="/profile" className="top-nav-link">
                            <AccountCircleOutlined color="primary" className="top-nav-link-icon" />
                            {t.profile}
                        </Link>
                        {isAdmin && (
                            <Link to="/admin" className="top-nav-link">
                                <AdminPanelSettings color="primary" className="top-nav-link-icon" />
                                {t.admin}
                            </Link>
                        )}
                        <Link to="/logout" className="top-nav-link">
                            <Logout color="primary" className="top-nav-link-icon" />
                            {t.logout}
                        </Link>
                    </span>
                ) : (
                    <span className="center-links">
                        <Link to="/login" className="top-nav-link">
                            {t.login}
                        </Link>
                        {' /'}
                        <Link to="/register" className="top-nav-link">
                            {t.register}
                        </Link>
                    </span>
                )}
            </div>
            <div className="right">
                <img src={bgFlag} alt="bg-flag" className="image-flag" onClick={bgLang} />
                <img src={ukFlag} alt="uk-flag" className="image-flag" onClick={enLang} />
            </div>
        </Box>
    )
}

const mapDispatchToProps = { changeLanguage }

export const TopNavBarDesktop = connect(null, mapDispatchToProps)(TopNavBarDesktopComponent)
