import { HomePerks } from './sections/perks'
import { HomeLanding } from './sections/landing'
import { HomeSteps } from './sections/stepsBanner'
// import { HomeBrands } from './sections/promotedBrands'
import { HomeRegisterUser } from './sections/registrationBanner'

import { RegisterOrderBanner } from '../../components/RegisterOrderBanner'
import { PriceCalculator } from '../../components/PriceCalculator/PriceCalculator'

export function HomePage() {
    return (
        <div>
            <HomeLanding />
            <PriceCalculator />
            {/* <HomeBrands /> */}
            <HomePerks />
            <HomeRegisterUser />
            <HomeSteps />
            <RegisterOrderBanner />
        </div>
    )
}
