import { useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import { Button } from '@mui/material'

import { i18nAdminOrders } from './i18n'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import { Order } from '../../../../data/reducers/ordersReducer'
import { getActiveOrdersDataGridColumns } from './dataGridUtils'
import { EditOrderModal } from './EditOrderModal/EditOrderModal'
import { DeviceStrings, useResponsiveString } from '../../../../hooks/useResponsiveString'
import {
    updateOrder,
    deleteOrder,
    combineOrders,
    createUnregisteredOrder,
} from '../../../../data/actions/ordersActions'
import { CombineOrdersModal } from './CombineOrdersModal/CombineOrdersModal'
import { IDefaultEcontDeliveryAddress } from '../../../../data/reducers/usersReducer'
import { ConfirmActionModal } from '../../../../components/ConfirmActionModal/ConfirmActionModal'
import { AddUnregisteredOrderModal } from './AddUnregisteredOrderModal/AddUnregisteredOrderModal'
import { CreateLabelFromOrderModal } from './CreateLabelFromOrderModal/CreateLabelFromOrderModal'

import './styles.css'
import { createEcontLabel } from '../../../../data/actions/econtActions'
import { calculateTransportationPrice } from '../../../../utils/functions'
import { texts } from '../../../../utils/i18n/texts'

function ActiveOrdersListComponent(props: any) {
    const dataGridRef = useRef()

    const [openEditModal, setOpenEditModal] = useState(false)
    const [editModalData, setEditModalData] = useState(null)

    const [openCreateLabelModal, setOpenCreateLabelModal] = useState(false)
    const [createLabelModalData, setCreateLabelModalData] = useState<Order | null>(null)

    const [openCombineModal, setOpenCombineModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalId, setDeleteModalId] = useState('')

    const [openAddUnregModal, setOpenAddUnregModal] = useState(false)
    const [selectedOrders, setSelectedOrders] = useState<Order[]>([])

    const { orders, users, lang, settings } = useSelector((state: GlobalState) => state)

    const { _id, role } = users.loggedIn
    const rows = orders.orders?.map((order: Order, i: number) => ({ ...order, id: i })) ?? []

    const handleOpenEditModal = (rowData: any) => {
        setEditModalData(rowData.row)
        setOpenEditModal(true)
    }
    const handleCloseEditModal = () => {
        setOpenEditModal(false)
        setEditModalData(null)
    }
    const handleEditOrder = (orderId: string, values: any) => {
        handleCloseEditModal()
        props.updateOrder({ userId: _id, orderId, ...values })
    }

    const handleOpenDeleteModal = (ev: any, order: Order) => {
        ev.stopPropagation()
        setOpenDeleteModal(true)
        setDeleteModalId(order._id)
    }
    const handleConfirmDeleteOrder = () => {
        const deleteOrderData = {
            orderId: deleteModalId,
            userId: _id,
        }
        props.deleteOrder(deleteOrderData)
    }
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
        setDeleteModalId('')
    }

    const handleOpenCreateLabelModal = (ev: any, order: Order) => {
        ev.stopPropagation()
        setOpenCreateLabelModal(true)
        setCreateLabelModalData(order)
    }
    const handleCloseCreateLabelModal = () => {
        setOpenCreateLabelModal(false)
        setCreateLabelModalData(null)
    }
    const handleConfirmCreateLabel = (senderAddress: IDefaultEcontDeliveryAddress, order: Order) => {
        const price = calculateTransportationPrice(order.packages, settings.prices, false, order.unregistered)
        const data = {
            orderId: order._id,
            lastUpdaterId: _id,
            senderAddress,
            senderPhone: texts.phoneBG,
            senderName: texts.companyName.bg,
            receiverName: order.receiver,
            receiverPhone: order.receiverPhone,
            receiverAddress: order.econtOfficeDeliveryAddress,
            requiredPayment: price,
            packages: order.packages,
            description: order.description,
            // @ts-ignore
            sizeUnder60cm: order.sizeUnder60cm
        }
        props.createEcontLabel(data)
    }

    const handleOpenAddUnregModal = () => setOpenAddUnregModal(true)
    const handleCloseAddUnregModal = () => setOpenAddUnregModal(false)
    const handleSaveAddUnregModalData = (data: any) => {
        const orderData = { userId: _id, userRole: role, registerDateEpoch: Date.now(), ...data }
        props.createUnregisteredOrder(orderData)
        setOpenAddUnregModal(false)
    }

    const handleOpenCombineModal = () => {
        setOpenCombineModal(true)
    }
    const handleCloseCombineModal = () => {
        setOpenCombineModal(false)
    }
    const handleCheckClicked = (ev: any, dataGrid: any) => {
        const selectedRows = dataGrid.api.getSelectedRows()
        const arr = []
        for (const kvp of selectedRows) {
            const value = kvp[1]
            arr.push(value)
        }
        setSelectedOrders(arr)
    }
    const handleCombineOrders = () => {
        const noDataGridIdsOrders = selectedOrders.slice().map(order => {
            // @ts-ignore
            const { id, ...rest } = order
            return rest
        })
        props.combineOrders({ orders: noDataGridIdsOrders, userId: _id })
        // @ts-ignore
        const selectedRows = dataGridRef.current?.getSelectedRows()
        for (const kvp of selectedRows) {
            const rowId = kvp[0]
            // @ts-ignore
            dataGridRef.current?.selectRow(rowId, false)
        }
        setSelectedOrders([])
    }

    const t = useTranslate(i18nAdminOrders)
    const columns = getActiveOrdersDataGridColumns(t, lang.currentSelection, handleOpenDeleteModal, handleOpenCreateLabelModal)

    const classNames = {
        mobile: 'add-unregistered-order-mobile',
        smallDesktop: 'add-unregistered-order'
    } as DeviceStrings

    const className = useResponsiveString(classNames)

    const strings = {
        mobile: 'mobile',
        smallDesktop: '',
    } as DeviceStrings
    const isMobile = useResponsiveString(strings)

    return (
        <div>
            <Button
                color="secondary"
                variant="contained"
                className={className}
                onClick={handleOpenAddUnregModal}
                size={isMobile ? 'small' : "large"}
                style={{ marginBottom: `${isMobile ? '20px' : '10px'}` }}
            >
                {t.addUnregistered}
            </Button>
            {
                selectedOrders.length > 1 ?
                    <Button
                        color="primary"
                        variant="contained"
                        className={className}
                        onClick={handleOpenCombineModal}
                        size={isMobile ? 'small' : "large"}
                        style={{ marginBottom: `${isMobile ? '20px' : '10px'}`, marginLeft: '10px' }}
                    >
                        {t.combineOrders}
                    </Button> : <></>
            }
            <DataGrid
                rows={rows}
                // @ts-ignore
                columns={columns}
                checkboxSelection
                // @ts-ignore
                apiRef={dataGridRef}
                disableRowSelectionOnClick
                onRowClick={handleOpenEditModal}
                onRowSelectionModelChange={handleCheckClicked}
                getRowClassName={(params) => `row-delivery-company row-delivery-company-${params.row.deliveryCompany}`}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'registerDateEpoch', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                pageSizeOptions={[25, 50, 100]}
            />
            {openEditModal && (
                <EditOrderModal handleSaveChanges={handleEditOrder} handleClose={handleCloseEditModal} data={editModalData} />
            )}
            <CombineOrdersModal
                orders={selectedOrders}
                isOpen={openCombineModal}
                handleConfirm={handleCombineOrders}
                handleClose={handleCloseCombineModal}
            />
            <ConfirmActionModal
                type="delete"
                text={t.confirmDelete}
                isOpen={openDeleteModal}
                handleClose={handleCloseDeleteModal}
                confirmCallback={handleConfirmDeleteOrder}
            />
            {
                openCreateLabelModal &&
                <CreateLabelFromOrderModal
                    order={createLabelModalData}
                    handleConfirm={handleConfirmCreateLabel}
                    handleClose={handleCloseCreateLabelModal}
                    handleOpenEditOrderModal={handleOpenEditModal}
                />
            }
            {openAddUnregModal && (
                <AddUnregisteredOrderModal
                    handleClose={handleCloseAddUnregModal}
                    handleSave={handleSaveAddUnregModalData}
                />
            )}
        </div>
    )
}

const mapDispatchToProps = {
    deleteOrder,
    updateOrder,
    combineOrders,
    createEcontLabel,
    createUnregisteredOrder,
}

export const ActiveOrdersList = connect(null, mapDispatchToProps)(ActiveOrdersListComponent)