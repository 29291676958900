import { AnyAction, createReducer } from "@reduxjs/toolkit"

import { ActionTypes } from "../actionTypes"

export type InternalScheduleStatus = 'EXPECT_TO_PICKUP' | 'PICKED_UP' | 'DELIVERED'

export const INTERNAL_SCHEDULE_STATUS = {
	EXPECT_TO_PICKUP: 'EXPECT_TO_PICKUP' as InternalScheduleStatus,
	PICKED_UP: 'PICKED_UP' as InternalScheduleStatus,
	DELIVERED: 'DELIVERED' as InternalScheduleStatus
}

export interface InternalSchedule {
	_id: string
	status: InternalScheduleStatus
	startDate: number
	endDate: number
	creationDate: number
	createdBy: string
	lastUpdated?: number
	lastUpdaterId?: number
	orders: string[]
	deliveryCompany: string
}

export interface InternalSchedulesState {
	schedules: InternalSchedule[]
}

const initialState = {
	schedules: [],
} as InternalSchedulesState

export const internalSchedulesReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(ActionTypes.internalSchedules.allActive.fulfilled(), (state: InternalSchedulesState, action: AnyAction) => {
			state.schedules = action.payload ?? []
		})
		.addCase(ActionTypes.internalSchedules.allCompleted.fulfilled(), (state: InternalSchedulesState, action: AnyAction) => {
			state.schedules = action.payload ?? []
		})
		.addCase(ActionTypes.internalSchedules.create.fulfilled(), (state: InternalSchedulesState, action: AnyAction) => {
			if (!action.payload) {
				return
			}
			
			state.schedules = [
				...state.schedules,
				action.payload
			]
		})
		.addCase(ActionTypes.internalSchedules.update.fulfilled(), (state: InternalSchedulesState, action: AnyAction) => {
			const schedule = state.schedules.find(x => x._id === action.payload._id)
			if (schedule) {
				const index = state.schedules.indexOf(schedule)
				state.schedules.splice(index, 1, {
					...schedule,
					...action.payload
				})
			}
		})
		.addCase(ActionTypes.internalSchedules.delete.fulfilled(), (state: InternalSchedulesState, action: AnyAction) => {
			const index = state.schedules.findIndex(x => x._id === action.payload._id)
			state.schedules.splice(index, 1)
		})
		.addCase(ActionTypes.internalSchedules.updateOrder.fulfilled(), (state: InternalSchedulesState, action: AnyAction) => {
			const schedule = state.schedules.find(x => x._id === action.payload.scheduleId)
			if (schedule) {
				const ordersIds = action.payload.deliveries.map((x: any) => x._id)
				schedule.orders = ordersIds
				const index = state.schedules.indexOf(schedule)
				state.schedules.splice(index, 1, schedule)
			}
		})
		.addCase(ActionTypes.internalSchedules.complete.fulfilled(), (state: InternalSchedulesState, action: AnyAction) => {
			const schedule = state.schedules.find(x => x._id === action.payload.scheduleId)
			if (!schedule) {
				return
			}

			schedule.status = INTERNAL_SCHEDULE_STATUS.DELIVERED
			const index = state.schedules.indexOf(schedule)
			state.schedules.splice(index, 1, schedule)
		})
})