import { texts } from "../../../utils/i18n/texts"

export const i18nTopmostMessage = {
    messageLabel: {
        bg: 'ВАЖНО СЪОБЩЕНИЕ - смяна на адреса в Италия: ',
        en: 'IMPORTANT MESSAGE - change of our address in Italy: '
    },
    messageValue: {
        bg: texts.itAddress,
        en: texts.itAddress
    }
}