export const i18nDeliveryTime = {
	title: {
		bg: 'Време за доставка',
		en: 'Delivery time'
	},
	text1: {
		bg: 'Времето за получаване на пратка от сайт или магазин в склад Италия е ',
		en: 'Time to receive an order from Italian retailer in our storage in Italy is '
	},
	text1Bold: {
		bg: 'от 2 до 5 работни дни.',
		en: 'from 3 to 5 working days.'
	},
	text2: {
		bg: 'След получаване на пратка в склад Италия, ще бъде натоварена и препратена към България с първия възможен курс, ',
		en: 'After we receive it in Italy it will be placed and trasported to Bulgaria with first possible schedule, '
	},
	text2Bold: {
		bg: 'от 1 до 7 дни.',
		en: 'from 1 to 7 days.'
	},
	text3: {
		bg: 'Пътя от склад Италия до склад България е ',
		en: 'Road time from our Italian storage to our Bulgarian storage is '
	},
	text3Bold: {
		bg: 'от 3 до 5 дни.',
		en: 'from 3 to 5 days.'
	},
	text4: {
		bg: 'След пристигане на пратка в България следва разтоварване и обработка ',
		en: 'After arriving in Bulgaria we need to process all orders '
	},
	text4Bold: {
		bg: 'от 1 до 2 дни.',
		en: 'from 1 to 2 days.'
	}
}
