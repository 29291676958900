import { Device, useDevice } from './useDevice'

export interface DeviceComponents {
    mobile: JSX.Element
    tablet: JSX.Element | null
    smallDesktop: JSX.Element
    mediumDesktop: JSX.Element | null
    bigDesktop: JSX.Element | null
}

export function useResponsive(components: DeviceComponents) {
    const device = useDevice()

    return switchComponent(device, components)
}

function switchComponent(device: Device, components: DeviceComponents): JSX.Element {
    if (device === 'm') return components.mobile
    if (device === 't') return components.tablet || components.mobile
    if (device === 'sd') return components.smallDesktop
    if (device === 'md') return components.mediumDesktop || components.smallDesktop
    if (device === 'bd') return components.bigDesktop || components.mediumDesktop || components.smallDesktop

    return components.mobile
}
