import { useSelector } from "react-redux"
import {
    Radio,
    Select,
    MenuItem,
    TextField,
    FormLabel,
    InputLabel,
    RadioGroup,
    FormControl,
    Autocomplete,
    FormControlLabel,
} from "@mui/material"

import { GlobalState } from "../../data/store"
import { i18nDeliveryInformation } from "./i18n"
import { useTranslate } from "../../hooks/translate"
import { FormikError } from "../../utils/formik/FormikError"
import { useEcontOffices } from "../../hooks/useEcontOffices"
import { __defaultEcontDeliveryAddress } from "../../data/reducers/usersReducer"
import { DeliveryCompany, deliveryCompanies } from "../../utils/deliveryCompanies"

interface IProps {
    values: any
    errors: any
    touched: any
    fieldClassName: string
    handleBlur: () => void
    handleChange: (event: any, args: any) => void
    setFieldValue: (name: string, value: string) => void
}

export function DeliveryInformation(props: IProps) {
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        fieldClassName,
    } = props

    const t = useTranslate(i18nDeliveryInformation)

    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const econtOffices = useEcontOffices()

    const handleChangeDeliveryCompany = (event: any, arg: any) => {
        handleChange(event, arg)

        if (event.target.value === 'tuttopost') {
            setFieldValue('deliverTo', 'address')
        } else {
            setFieldValue('deliverTo', 'office')
        }
    }

    const handleChangeEcontOfficeDeliveryAddress = (e: any, args: any) => {
        setFieldValue('econtOfficeDeliveryAddress', args?.names || __defaultEcontDeliveryAddress)
    }

    return (
        <>
            <div className={fieldClassName}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="delivery-company-name">{t.deliveryCompany}</InputLabel>
                    <Select
                        onChange={handleChangeDeliveryCompany}
                        labelId="delivery-company-name"
                        value={values.deliveryCompany}
                        label={t.deliveryCompany}
                        name="deliveryCompany"
                        onBlur={handleBlur}
                        variant="outlined"
                    >
                        {deliveryCompanies.map((x: DeliveryCompany, i: number) => {
                            return (
                                <MenuItem key={i} value={x.value}>
                                    {x.displayName}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormikError name="deliveryCompany" errors={errors} touched={touched} />
            </div>
            <div className={fieldClassName}>
                <FormControl>
                    <FormLabel id="company-delivery-type">{t.deliveryCompanyAddressType}</FormLabel>
                    <RadioGroup
                        row
                        name="deliverTo"
                        onChange={handleChange}
                        value={values.deliverTo}
                        aria-labelledby="company-delivery-type"
                    >
                        <FormControlLabel
                            value="office"
                            control={<Radio />}
                            label={t.deliveryCompanyAddressTypeOffice}
                            disabled={values.deliveryCompany === 'tuttopost'}
                        />
                        <FormControlLabel
                            value="address"
                            control={<Radio />}
                            label={t.deliveryCompanyAddressTypeAddress}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            {
                values.deliverTo === 'office'
                    ?
                    <div className={fieldClassName}>
                        <Autocomplete
                            fullWidth
                            size='small'
                            autoHighlight
                            options={econtOffices}
                            onChange={handleChangeEcontOfficeDeliveryAddress}
                            value={values.econtOfficeDeliveryAddress?.[lang] || ''}
                            renderInput={(params) => <TextField label={t.address} {...params} />}
                        />
                        <FormikError name="econtOfficeDeliveryAddress" errors={errors} touched={touched} />
                    </div>
                    :
                    <div className={fieldClassName}>
                        <TextField
                            fullWidth
                            size="small"
                            label={t.address}
                            variant="outlined"
                            onBlur={handleBlur}
                            name="deliveryAddress"
                            // @ts-ignore
                            onChange={handleChange}
                            value={values.deliveryAddress}
                        />
                        <FormikError name="deliveryAddress" errors={errors} touched={touched} />
                    </div>
            }
        </>
    )
}
