import { DeleteForeverOutlined, LocalShippingOutlined } from '@mui/icons-material'
import { Badge, Chip, IconButton, Tooltip, Typography } from '@mui/material'

import { getFormattedDate } from '../../../../utils/functions'
import { getOrderStatusChip } from '../../../../components/Shared'
import { ORDER_STATUS, Order } from '../../../../data/reducers/ordersReducer'

export const getActiveOrdersDataGridColumns = (
    t: any,
    lang: string,
    handleOpenDeleteModal: (ev: any, order: Order) => void,
    handleOpenCreateLabelModal: (ev: any, order: Order) => void,
) => {
    return [
        {
            field: 'status',
            headerName: t.status,
            type: 'string',
            width: 200,
            renderCell: (params: any) => getOrderStatusChip(params.value, lang)
        },
        {
            field: 'receiver',
            headerName: t.receiver,
            type: 'string',
            width: 250,
            renderCell: (params: any) => {
                if (params.row.type === 'MASTER' && params.row.linkedOrdersIds?.length) {
                    return (
                        <>
                            <Typography component='span'>{params.value}</Typography>
                            <Chip size="small" variant="filled" color="secondary" label={params.row.linkedOrdersIds?.length} style={{ marginLeft: '10px' }} />
                        </>
                    )
                }

                return <Typography component='span'>{params.value}</Typography>
            }
        },
        {
            field: 'receiverPhone',
            headerName: t.phone,
            type: 'string',
            width: 130,
        },
        {
            field: 'deliveryAddress',
            headerName: t.address,
            type: 'string',
            width: 300,
        },
        {
            field: 'packages',
            headerName: t.count,
            width: 80,
            align: 'center',
            sortable: false,
            valueGetter: (value: any, row: Order) => `${row.packages.length ?? 0}`,
        },
        {
            field: 'registerDateEpoch',
            headerName: t.registerDate,
            type: 'number',
            width: 150,
            renderCell: (params: any) => getFormattedDate(true, true, true, true, params.value)
        },
        {
            field: 'orderId',
            headerName: t.orderId,
            type: 'number',
            width: 110,
        },
        {
            field: 'deliveryCompany',
            headerName: t.deliveryCompany,
            type: 'string',
            width: 100,
            editable: false,
        },
        {
            field: 'actions',
            headerName: t.actions,
            type: 'string',
            width: 100,
            renderCell: (params: any) => {
                const shouldHaveCreateLabelOption = (
                    params.row.deliveryCompany === 'econt' &&
                    params.row.deliverTo === 'office' &&
                    !!params.row.econtOfficeDeliveryAddress
                )

                let hasCreateLabelIssues = false
                if (shouldHaveCreateLabelOption) {
                    hasCreateLabelIssues = params.row.status !== ORDER_STATUS.ARRIVED_BG || params.row.packages.some((x: any) => !x.processed)
                }

                let isInvisible = !shouldHaveCreateLabelOption || !hasCreateLabelIssues

                const badgeVariant = !!params.row.econtLabelCreated ? 'standard' : 'dot'
                const badgeContent = !!params.row.econtLabelCreated ? '1' : ''

                if (params.row.econtLabelCreated) {
                    isInvisible = false
                }

                const shouldEnableOpenCreateLabelModal = !params.row.econtLabelCreated && shouldHaveCreateLabelOption

                return (
                    <>
                        <Tooltip title={t.createLabel}>
                            <IconButton
                                color={shouldEnableOpenCreateLabelModal ? "primary" : "warning"}
                                onClick={(ev: any) => {
                                    ev.stopPropagation()

                                    if (!shouldEnableOpenCreateLabelModal || params.row.econtLabelCreated) {
                                        return
                                    }

                                    handleOpenCreateLabelModal(ev, params.row) // TODO TOGGLE: uncomment to turn on/off ECONT API integration
                                }}>
                                <Badge
                                    color='secondary'
                                    variant={badgeVariant}
                                    invisible={isInvisible}
                                    badgeContent={badgeContent}
                                >
                                    <LocalShippingOutlined />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t.deleteOrder}>
                            <IconButton color="secondary" onClick={(ev: any) => handleOpenDeleteModal(ev, params.row)}>
                                <DeleteForeverOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
    ]
}

