import { store } from '../data/store'
import { ApiResponse } from '../utils/apiResponse'

// const baseApiUrl = 'http://localhost:1203'
const baseApiUrl = 'https://api.tuttopost.com'

const constructUrl = (route: string) => `${baseApiUrl}/${route}`

function getHeaders(): HeadersInit {
	const token = localStorage.getItem('authToken')
	const lang = store.getState().lang.currentSelection
	return {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
		lang
	}
}

export const apiService = {
	async get(route: string): Promise<ApiResponse> {
		const url = constructUrl(route)
		const response = await fetch(url, {
			headers: getHeaders(),
		})
		return response.json()
	},
	async post(route: string, data: Object): Promise<ApiResponse> {
		const url = constructUrl(route)
		const response = await fetch(url, {
			method: 'POST',
			headers: getHeaders(),
			body: JSON.stringify(data),
		})

		return response.json()
	},
	// async patch(route: string, data: Object): Promise<ApiResponse> {
	// 	const url = constructUrl(route)
	// 	const response = await fetch(url, {
	// 		method: 'PATCH',
	// 		headers: getHeaders(),
	// 		body: JSON.stringify(data),
	// 	})

	// 	return response.json()
	// },
	async delete(route: string, data: Object): Promise<ApiResponse> {
		const url = constructUrl(route)
		const response = await fetch(url, {
			method: 'DELETE',
			headers: getHeaders(),
			body: JSON.stringify(data),
		})

		return response.json()
	},
}
