import { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { Box } from "@mui/system"
import { Tab, Tabs, Typography } from "@mui/material"

import { TABS } from "../TABS"
import { i18nProfileIS } from '../i18n'
import { GlobalState } from "../../../../data/store"
import { useTranslate } from '../../../../hooks/translate'
import { InternalScheduleCards } from "./InternalSchedulesCards"
import { TabPanel } from "../../../../components/TabPanel/TabPanel"
import { getSpecificOrders } from '../../../../data/actions/ordersActions'
import { getPricesSettings } from '../../../../data/actions/settingsActions'
import { Order, ORDER_STATUS } from '../../../../data/reducers/ordersReducer'
import { showNotification } from '../../../../data/actions/notificationActions'
import { InternalSchedule } from "../../../../data/reducers/internalSchedulesReducer"
import { getActiveInternalSchedules } from '../../../../data/actions/internalSchedulesActions'
import { updateInternalScheduleOrderStatus } from '../../../../data/actions/internalSchedulesActions'

import './styles.css'

function InternalSchedulesMobileComponent(props: any) {
    const {
        showNotification,
        getPricesSettings,
        getSpecificOrders,
        getActiveInternalSchedules,
        updateInternalScheduleOrderStatus,
    } = props

    const [tab, setTab] = useState(TABS.tuttopost)
    const [expanded, setExpanded] = useState({} as InternalSchedule)

    const { _id } = useSelector((state: GlobalState) => state.users.loggedIn)
    const { schedules } = useSelector((state: GlobalState) => state.internalSchedules)
    const { orders } = useSelector((state: GlobalState) => state.orders)

    useEffect(() => {
        if (expanded._id && expanded.orders?.length) {
            getSpecificOrders(expanded.orders)
        }
    }, [getSpecificOrders, expanded])

    useEffect(() => {
        getActiveInternalSchedules()
    }, [getActiveInternalSchedules])

    useEffect(() => {
        getPricesSettings()
    }, [getPricesSettings])

    const t = useTranslate(i18nProfileIS)

    if (!schedules?.length) {
        return (
            <div className='profile-no-internal-schedules'>
                <Typography variant='h4' align='center'>{t.noInternalSchedules}</Typography>
            </div>
        )
    }

    if (!orders?.length) {
        return (
            <div className='profile-no-internal-schedules'>
                <Typography variant='h4' align='center'>{t.noOrdersMsg}</Typography>
            </div>
        )
    }

    const tuttopostSchedule = schedules?.filter(x => x.deliveryCompany === 'tuttopost')[0]
    const econtSchedule = schedules?.filter(x => x.deliveryCompany === 'econt')[0]

    const tuttoOrders = tuttopostSchedule?.orders?.map(orderId => {
        return orders.find((x: Order) => x?._id === orderId)
    }) as any

    const econtOrders = econtSchedule?.orders?.map(orderId => {
        return orders.find((x: Order) => x?._id === orderId)
    }) as any

    const handleChange = (ev: any, value: any) => setTab(value)
    const handleClick = (schedule: InternalSchedule) => setExpanded(schedule)

    const handleChangeStatus = (ev: any, order: Order) => {
        ev.stopPropagation()

        const newStatus = order.status === ORDER_STATUS.ARRIVED_BG ? ORDER_STATUS.COMPLETED : ORDER_STATUS.ARRIVED_BG
        updateInternalScheduleOrderStatus({ scheduleId: expanded._id, userId: _id, orderId: order._id, newStatus })
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label={t.tuttopostSchedules} id="profile-tab-0" onClick={() => handleClick(tuttopostSchedule)} />
                    <Tab label={t.econtSchedules} id="profile-tab-1" onClick={() => handleClick(econtSchedule)} />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <InternalScheduleCards
                    orders={tuttoOrders}
                    toggleStatus={handleChangeStatus}
                    showNotification={showNotification}
                />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <InternalScheduleCards
                    orders={econtOrders}
                    toggleStatus={handleChangeStatus}
                    showNotification={showNotification}
                />
            </TabPanel>
        </>
    )
}

const mapDispatchToProps = {
    showNotification,
    getPricesSettings,
    getSpecificOrders,
    getActiveInternalSchedules,
    updateInternalScheduleOrderStatus,
}

export const InternalSchedulesMobile = connect(null, mapDispatchToProps)(InternalSchedulesMobileComponent)
