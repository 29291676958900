import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { i18nRegBanner } from '../i18n'
import { useTranslate } from '../../../../../hooks/translate'

import './styles.css'

export function HomeRegisterUserMobile(): JSX.Element {
    const t = useTranslate(i18nRegBanner)

    return (
        <section className="home-register-user-mobile">
            <div className="text-content">
                <Typography variant="h6" className="register-question-label">
                    {t.question}
                </Typography>
                <Typography variant="h5" className="register-now-label">
                    {t.action}
                </Typography>
                <Link to="/register">
                    <Button className="register-now-button" size="large" variant="contained" color="secondary">
                        {t.buttonLabel}
                    </Button>
                </Link>
            </div>
        </section>
    )
}
