import { Typography } from "@mui/material"

import { i18nTopmostMessage } from "./i18n"
import { useTranslate } from "../../../hooks/translate"

import './styles.css'

export function TopmostMessage() {
    const t = useTranslate(i18nTopmostMessage)

    return (
        <div className='topmost-message-container'>
            <Typography color='secondary' variant='h5' align='center'>{t.messageLabel}<strong>{t.messageValue}</strong></Typography>
        </div>
    )
}
