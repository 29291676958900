export const endpoints = {
	users: {
		login: 'users/login',
		register: 'users/register',
		registerSpecific: 'users/register-specific',
		updateRole: 'users/update-role',
		getAll: 'users/get-all',
		updateProfile: 'users/update-profile',
		forgetPasswordRequest: 'users/forget-password',
		resendVerificationCode: 'users/resend-verification-code',
		sendVerificationSupportRequest: 'users/send-verification-support-request',
		restorePassword: 'users/restore-password',
		verifyRegistration: 'users/verify-registration',
		delete: 'users',
		emailRequest: 'users/send-email-request',
	},
	orders: {
		userActiveOrders: (userId: string) => `orders/active/${userId}`,
		userCompletedOrders: (userId: string) => `orders/completed/${userId}`,
		getSpecific: 'orders/get-specific',
		allActiveOrders: 'orders/all-active',
		combine: 'orders/combine',
		uncombine: 'orders/uncombine',
		allCompletedOrders: 'orders/all-completed',
		registerOrder: 'orders/register',
		createUnregisteredOrder: 'orders/create-unregistered',
		delete: 'orders',
		updateOrder: (userId: string) => `orders/update/${userId}`,
	},
	internalSchedules: {
		getAllActive: 'internal-schedules/active',
		getAllCompleted: 'internal-schedules/completed',
		create: 'internal-schedules',
		update: 'internal-schedules/update',
		updateOrder: 'internal-schedules/update-order',
		delete: (id: string) => `internal-schedules/${id}`,
		completeSchedule: 'internal-schedules/complete',
		notifySofiaOrdersDelivery: 'internal-schedules/notify-sofia-delivery'
	},
	crossBorderSchedules: {
		getAllActive: 'cross-border-schedules/active',
		getAllCompleted: 'cross-border-schedules/completed',
		create: 'cross-border-schedules',
		update: 'cross-border-schedules/update',
		delete: (id: string) => `cross-border-schedules/${id}`,
		completeSchedule: 'cross-border-schedules/complete',
		getAll: 'cross-border-schedules/all',
		notifyOrdersPickedUp: 'cross-border-schedules/notify-picked-up-orders',
	},
	settings: {
		prices: 'settings/prices',
		defaults: 'settings/defaults'
	},
	econt: {
		getOffices: 'econt/get-offices',
		validateLabel: 'econt/validate-label',
		createLabel: 'econt/create-label',
		getOfficesByCityId: (id: number) => `econt/get-offices/${id}`
	}
}
