import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Tab, Tabs } from '@mui/material'

import { i18nAdminOrdersTabs } from './i18n'
import { useTranslate } from '../../../hooks/translate'
import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { ActiveOrdersList } from './ActiveOrdersList/ActiveOrdersList'
import { getAllUsersActiveOrders } from '../../../data/actions/ordersActions'
import { ActiveOrdersStatistics } from './ActiveOrdersStatistics/ActiveOrdersStatistics'
import { getDefaultsSettings, getPricesSettings } from '../../../data/actions/settingsActions'

const ActiveOrdersTabs = {
    LIST: 0,
    STATISTICS: 1
}

export function ActiveOrdersSectorComponent(props: any): JSX.Element {
    useEffect(() => {
        props.getAllUsersActiveOrders()
        props.getPricesSettings()
        props.getDefaultsSettings()
    }, [props])

    const [tab, setTab] = useState(ActiveOrdersTabs.LIST)

    const handleChange = (ev: any, value: any) => setTab(value)

    const t = useTranslate(i18nAdminOrdersTabs)

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label={t.list} id="admin-tab-0" />
                    <Tab label={t.statistics} id="admin-tab-1" />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={ActiveOrdersTabs.LIST}>
                <ActiveOrdersList />
            </TabPanel>
            <TabPanel value={tab} index={ActiveOrdersTabs.STATISTICS}>
                <ActiveOrdersStatistics />
            </TabPanel>
        </>
    )
}

const mapDispatchToProps = {
    getPricesSettings,
    getDefaultsSettings,
    getAllUsersActiveOrders,
}

export const ActiveOrdersSector = connect(null, mapDispatchToProps)(ActiveOrdersSectorComponent)
