import { CrossBorderSchedule } from '../../../data/reducers/crossBorderSchedulesReducer'
import { getFormattedDate } from '../../../utils/functions'

export const tableStruct = (heads: string[]) => [
	{
		head: heads[0],
		getData: (obj: CrossBorderSchedule) => `${obj.fromCity}, ${obj.fromCountry}`,
	},
	{
		head: heads[1],
		getData: (obj: CrossBorderSchedule) => `${obj.toCity}, ${obj.toCountry}`,
	},
	{
		head: heads[2],
		getData: (obj: CrossBorderSchedule) => getFormattedDate(true, true, true, false, obj.pickupDate),
	},
	{
		head: heads[3],
		getData: (obj: CrossBorderSchedule) => getFormattedDate(true, true, true, false, obj.deliveryDate),
	},
	{
		head: heads[4],
		getData: (obj: CrossBorderSchedule) => getFormattedDate(true, true, true, false, obj.creationDate),
	},
	{
		head: heads[5],
		getData: (obj: CrossBorderSchedule) => obj.orders ? obj.orders.length : 0,
	},
]
