import { connect, useSelector } from "react-redux"

import { i18nProfileAO } from "../i18n"
import { Typography } from "@mui/material"
import { GlobalState } from "../../../../data/store"
import { useTranslate } from "../../../../hooks/translate"
import { Order } from "../../../../data/reducers/ordersReducer"
import { ActiveOrdersMobileCard } from "./ActiveOrdersMobileCard"
import { getUserActiveOrders } from "../../../../data/actions/ordersActions"

import './styles.css'

export function ActiveOrdersMobileListComponent() {
    const { orders } = useSelector((state: GlobalState) => state.orders)

    const t = useTranslate(i18nProfileAO)

    return (
        <>
            {

                !orders?.length ?
                    <div className='profile-mobile-no-active-orders'>
                        <Typography align="center" variant='h4'>{t.noActiveOrders}</Typography>
                    </div>
                    :
                    orders?.map((order: Order, i: number) => {
                        return <ActiveOrdersMobileCard key={i} order={order} />
                    })
            }
        </>
    )
}

const mapDispatchToProps = { getUserActiveOrders }

export const ActiveOrdersMobileList = connect(null, mapDispatchToProps)(ActiveOrdersMobileListComponent)
