export const i18nAdminCBScreateUpdateModal = {
	createTitle: {
		bg: 'Създаване на международен график',
		en: 'Create cross border schedule'
	},
	updateTitle: {
		bg: 'Промяна на международен график',
		en: 'Update cross border schedule'
	},
	pickupDate: {
		bg: 'Дата тръгва',
		en: 'Pickup date'
	},
	pickupCountry: {
		bg: 'Тръгва от държава',
		en: 'Pickup country'
	},
	pickupRegion: {
		bg: 'Тръгва от район',
		en: 'Pickup region'
	},
	arriveDate: {
		bg: 'Дата пристига',
		en: 'Arrive date'
	},
	arriveCountry: {
		bg: 'Пристига в държава',
		en: 'Arrive in country'
	},
	arriveRegion: {
		bg: 'Пристига в район',
		en: 'Arrive in region'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	buttonLabel1: {
		bg: 'Създай',
		en: 'Create'
	},
	buttonLabel2: {
		bg: 'Обнови',
		en: 'Update'
	},
	buttonLabel3: {
		bg: 'Затвори',
		en: 'Close'
	}
}
