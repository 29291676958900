import { CrossBorderScheduleStatus, CROSS_BORDER_SCHEDULE_STATUS } from '../data/reducers/crossBorderSchedulesReducer'
import { InternalScheduleStatus, INTERNAL_SCHEDULE_STATUS } from '../data/reducers/internalSchedulesReducer'
import { OrderStatus, ORDER_STATUS } from '../data/reducers/ordersReducer'
import { PricesSettings } from '../data/reducers/settingsReducer'
import { TranslatePairs } from './i18n/TranslateObject'
import { i18nFunctions } from './i18n/functions'
import { ApiResponse } from './apiResponse'

export function logFailApiRequest(response: ApiResponse): void {
	console.error(`${response.status} - ${response.message}`)
}

export function getFormattedDate(yy = true, mm = false, dd = false, time = false, epoch: number = Date.now()): string {
	const now = new Date(epoch)

	const date = now.getDate()
	const month = now.getMonth() + 1
	const year = now.getFullYear()
	const hours = now.getHours()
	const minutes = now.getMinutes()

	const formatDigits = (value: number) => value.toString().length === 1 ? `0${value}` : value

	const dateFormat = formatDigits(date)
	const monthFormat = formatDigits(month)
	const hoursFormat = formatDigits(hours)
	const minsFormat = formatDigits(minutes)

	return `${dd ? dateFormat : ''}${dd ? '.' : ''}${mm ? monthFormat : ''}${mm ? '.' : ''}${yy ? year : ''} ${time ? `${hoursFormat}:${minsFormat}` : ''}`
}

export function calculateTransportationPrice(
	packages: Array<any>,
	priceSettings: PricesSettings,
	isTuttoPostSofiaDelivery: boolean = false,
	isUnregistered: boolean = false
): number {
	const weightKgs = []
	const sizeKgs = []

	for (const packageInfo of packages) {
		const { width, weight, height, depth } = packageInfo

		let safeWeight = weight
		if (isNaN(weight)) {
			safeWeight = 1
			console.log('TP-ERR: package with weight:NaN => ', packageInfo)
		}

		if (width > 59 || height > 59 || depth > 59) {
			const sizeToKg = (width * height * depth) / 6000
			const biggerPrice = sizeToKg > safeWeight ? sizeToKg : safeWeight
			
			sizeKgs.push(biggerPrice)
		} else {
			weightKgs.push(+safeWeight)
		}
	}

	let totalPrice = 0
	totalPrice += (priceSettings.processPricePerPackage * packages.length)

	if (sizeKgs.length) {
		const sumWeightKgs = sizeKgs.reduce((a, b) => +a + +b, 0)
		totalPrice += +(Math.ceil(sumWeightKgs) * priceSettings.pricePerKg).toFixed(2)
	}

	if (weightKgs.length) {
		const sumWeightKgs = weightKgs.reduce((a, b) => +a + +b, 0)
		totalPrice += +(Math.ceil(sumWeightKgs) * priceSettings.pricePerKg).toFixed(2)
	}

	if (isTuttoPostSofiaDelivery) {
		const sumSizeKgs = sizeKgs.reduce((a, b) => +a + +b, 0)
		const sumWeightKgs = weightKgs.reduce((a, b) => +a + +b, 0)
		const kilograms = sumSizeKgs + sumWeightKgs
		const sofiaDeliveryPrice = Math.ceil(kilograms / 5) * priceSettings.priceDeliverySofia
		totalPrice += sofiaDeliveryPrice
	}

	if (isUnregistered) {
		totalPrice += priceSettings.unregisteredOrdersPenaltyFee
	}

	return +totalPrice.toFixed(2)
}

export function mapPackagesStringSizesToPackagesObjectsSizes(packages: Array<any>) {
	const objects = []
	for (const pckg of packages) {
		const [w, h, d] = pckg.sizes.split('x')
		const obj = {
			width: w,
			height: h,
			depth: d,
			weight: pckg.weight
		}
		objects.push(obj)
	}
	return objects
}

export function orderPredicate(orderBy: string, ascending: boolean) {
	return (a: any, b: any) => {
		if (orderBy) {
			if (typeof a[orderBy] === 'string') {
				if (ascending) {
					if (a[orderBy] === '') {
						return 1
					}
					return a[orderBy].localeCompare(b[orderBy])
				} else {
					if (b[orderBy] === '') {
						return 1
					}
					return b[orderBy].localeCompare(a[orderBy])
				}
			} else {
				if (ascending) {
					return a[orderBy] - b[orderBy]
				} else {
					return b[orderBy] - a[orderBy]
				}
			}
		} else {
			return 0
		}
	}
}

export function mapToFriendlyInternalSchedulesStatus(status: InternalScheduleStatus, lang: string = 'bg'): string {
	switch (status) {
		case INTERNAL_SCHEDULE_STATUS.EXPECT_TO_PICKUP: return i18nFunctions.expectsPickup[lang as keyof TranslatePairs]
		case INTERNAL_SCHEDULE_STATUS.PICKED_UP: return i18nFunctions.pickedUp[lang as keyof TranslatePairs]
		case INTERNAL_SCHEDULE_STATUS.DELIVERED: return i18nFunctions.delivered[lang as keyof TranslatePairs]
		default: return i18nFunctions.wrongStatus[lang as keyof TranslatePairs]
	}
}

export function mapToFriendlyCrossBorderScheduleStatus(status: CrossBorderScheduleStatus, lang: string = 'bg'): string {
	switch (status) {
		case CROSS_BORDER_SCHEDULE_STATUS.EXPECT_TO_PICKUP: return i18nFunctions.expectsPickup[lang as keyof TranslatePairs]
		case CROSS_BORDER_SCHEDULE_STATUS.PICKED_UP: return i18nFunctions.pickedUp[lang as keyof TranslatePairs]
		case CROSS_BORDER_SCHEDULE_STATUS.DELIVERED: return i18nFunctions.deliveredCrossSchedule[lang as keyof TranslatePairs]
		case CROSS_BORDER_SCHEDULE_STATUS.COMPLETED: return i18nFunctions.completed[lang as keyof TranslatePairs]
		default: return i18nFunctions.wrongStatus[lang as keyof TranslatePairs]
	}
}

export function mapToFriendlyOrderStatus(status: OrderStatus, lang: string = 'bg'): string {
	switch (status) {
		case ORDER_STATUS.REGISTERED: return i18nFunctions.registered[lang as keyof TranslatePairs]
		case ORDER_STATUS.ARRIVED_ABROAD: return i18nFunctions.arrivedIt[lang as keyof TranslatePairs]
		case ORDER_STATUS.TRAVELING: return i18nFunctions.traveling[lang as keyof TranslatePairs]
		case ORDER_STATUS.ARRIVED_BG: return i18nFunctions.arrivedBg[lang as keyof TranslatePairs]
		case ORDER_STATUS.COMPLETED: return i18nFunctions.delivered[lang as keyof TranslatePairs]
		case ORDER_STATUS.CANCELLED: return i18nFunctions.cancelled[lang as keyof TranslatePairs]
		default: return i18nFunctions.wrongStatus[lang as keyof TranslatePairs]
	}
}

export function calculatePackagesTotalWeight(packages: { weight: number }[]): number {
	let totalWeight = 0
	for (const pckg of packages) {
		const weightToAdd = isNaN(pckg.weight) ? 1 : +pckg.weight
		totalWeight += weightToAdd
	}

	return totalWeight
}