import { Typography } from '@mui/material'

import { i18nPerks } from '../i18n'
import { useTranslate } from '../../../../../hooks/translate'

import NewsImage from '../../../../../assets/vectors/home-page_news-icon-image.svg'
import StoresImage from '../../../../../assets/vectors/home-page_stores-icon-image.svg'
import PromotionsImage from '../../../../../assets/vectors/home-page_promotions-icon-image.svg'

import './styles.css'

export function HomePerksMobile(): JSX.Element {
    const t = useTranslate(i18nPerks)

    return (
        <section className="perks-mobile">
            <div className="perks-mobile-subsections">
                <div className="subsection">
                    <img src={StoresImage} alt="stores" className="subsection-image" />
                    <Typography variant="h6">{t.stores}</Typography>
                    <Typography variant="body1">{t.storesSub}</Typography>
                </div>
                <div className="subsection">
                    <img src={NewsImage} alt="news" className="subsection-image" />
                    <Typography variant="h6">{t.newsAndSchedule}</Typography>
                    <Typography variant="body1">{t.newsAndScheduleSub}</Typography>
                </div>
                <div className="subsection">
                    <img src={PromotionsImage} alt="promotions" className="subsection-image" />
                    <Typography variant="h6">{t.promotions}</Typography>
                    <Typography variant="body1">{t.promotionsSub}</Typography>
                </div>
            </div>
        </section>
    )
}
