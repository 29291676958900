import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"

import { i18nAdminCombineOrders } from "./i18n"
import { useTranslate } from "../../../../../hooks/translate"
import { Order } from "../../../../../data/reducers/ordersReducer"

interface IProps {
    orders: Order[]
    isOpen: boolean
    handleClose: () => void
    handleConfirm: () => void
}

export function CombineOrdersModal(props: IProps) {
    const { orders, isOpen, handleClose, handleConfirm } = props

    const t = useTranslate(i18nAdminCombineOrders)

    const handleCombine = () => {
        handleClose()
        handleConfirm()
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t.sureText}</DialogContentText>
                {
                    orders.map((order, i) => {
                        return (
                            <>
                                <hr />
                                <Typography variant="body2">{order.receiver}</Typography>
                                <Typography variant="h6">{order.registerDate} - {order.orderId}</Typography>
                                {
                                    order.packages.map(pckg => (
                                        <Typography variant='body1'>
                                            <strong>{(pckg as any).weight}</strong> {t.kg} / <strong>{(pckg as any).sizes}</strong> {t.cm}
                                        </Typography>
                                    ))
                                }
                                {
                                    i === orders.length - 1 ? <hr /> : <></>
                                }
                            </>
                        )
                    })
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCombine} variant="contained" color='primary'>
                    {t.confirm}
                </Button>
                <Button onClick={handleClose} autoFocus variant="contained" color="info">
                    {t.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
