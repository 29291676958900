import { useState } from "react"
import { useSelector } from "react-redux"
import { InfoOutlined } from '@mui/icons-material'
import { IconButton, Paper, Typography } from "@mui/material"

import { GlobalState } from "../../../../data/store"
import { ORDER_STATUS, Order } from "../../../../data/reducers/ordersReducer"
import { InternalScheduleCardDetailsModal } from "./InternalScheduleCardDetailsModal/InternalScheduleCardDetailsModal"
import { calculatePackagesTotalWeight, calculateTransportationPrice, mapPackagesStringSizesToPackagesObjectsSizes } from "../../../../utils/functions"

export function InternalScheduleDeliveryCard(props: any) {
    const { showNotification, toggleStatus } = props
    const order = props.order ?? {} as Order

    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = (ev: any) => {
        stopPropagation(ev)
        setIsOpen(false)
    }

    const priceSettings = useSelector((state: GlobalState) => state.settings.prices)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const isCompleted = order?.status === ORDER_STATUS.COMPLETED

    const kg = lang === 'en' ? 'kg' : 'кг'
    const count = lang === 'en' ? 'pcs' : 'бр'
    const currency = lang === 'en' ? 'BGN' : 'лв'

    const copyToClipboard = (ev: any, address: string) => {
        stopPropagation(ev)
        const copiedText = { en: 'Copied to clipboard', bg: 'Адресът е копиран' }
        navigator.clipboard.writeText(address)
        showNotification(copiedText, 'success')
    }

    const handleShowDetails = (ev: any) => {
        stopPropagation(ev)
        openModal()
    }

    const stopPropagation = (ev: any) => ev.stopPropagation()

    const handleToggleStatus = (ev: any) => {
        if (isOpen) {
            return
        }

        toggleStatus(ev, order)
    }

    const totalWeight = calculatePackagesTotalWeight(order?.packages || [])
    const isSofiaDelivery = order?.deliveryCompany === 'tuttopost'
    const pckgs = order ? mapPackagesStringSizesToPackagesObjectsSizes(order.packages ?? []) : []
    const price = calculateTransportationPrice(pckgs, priceSettings, isSofiaDelivery, order?.unregistered)

    return (
        <Paper className={`internal-schedule-delivery-card ${isCompleted ? 'completed' : ''}`} elevation={3} onClick={handleToggleStatus}>
            <div className='name-info-wrapper'>
                <Typography variant='h5'>{order?.receiver}</Typography>
                <IconButton size='large' onClick={handleShowDetails}>
                    <InfoOutlined fontSize='large' />
                </IconButton>
            </div>
            <Typography variant='h6' onClick={stopPropagation}>
                <a href={`tel:${order?.receiverPhone}`}>
                    {order?.receiverPhone}
                </a>
            </Typography>
            <Typography className='clipboard-copy-address' onClick={(ev) => copyToClipboard(ev, order?.deliveryAddress)}>
                {order?.deliveryAddress}
            </Typography>
            <Typography>{order?.comment}</Typography>
            <Typography variant='h6'>{`${totalWeight} ${kg}. | ${order?.packages?.length} ${count}.`}</Typography>
            <Typography variant='h5'>{`${price} ${currency}.`}</Typography>
            {
                isOpen &&
                <InternalScheduleCardDetailsModal handleClose={closeModal} order={order} />
            }
        </Paper>
    )
}
