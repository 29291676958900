import { useSelector } from 'react-redux'

import { GlobalState } from '../data/store'
import { isEqualOrHigherPermissions, ROLE } from '../utils/roles'
import { UnauthorizedView } from '../components/views/UnauthorizedView'

export function authorize(minimumRole: ROLE, component: JSX.Element): () => JSX.Element {
    function NewComponent(): JSX.Element {
        const { loggedIn } = useSelector((state: GlobalState) => state.users)
        const isAuthorized = isEqualOrHigherPermissions(loggedIn.role, minimumRole)

        return isAuthorized && loggedIn._id && loggedIn.isLogged ? component : <UnauthorizedView />
    }

    return NewComponent
}
