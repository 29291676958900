export const i18nRelocationCalc = {
    title: {
        bg: 'Изчисляване цена преместване',
        en: 'Calculate relocation price',
    },
    sendRequest: {
        bg: 'Изпрати запитване',
        en: 'Send request'
    }
}
