//@ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from "react-redux"

import { GlobalState } from "../../../../data/store"
import { Order } from "../../../../data/reducers/ordersReducer"

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function OrdersDestinationTotalGraphic() {
    const { orders } = useSelector((state: GlobalState) => state.orders)

    const ordersData = mapOrders(orders)

	const dataPoints = getDataPoints(ordersData)

    const options = {
        theme: "light2",
        animationEnabled: true,
        exportFileName: "Orders Destination",
        exportEnabled: true,
        title: {
            text: "Orders by destination"
        },
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: `<strong>{label}:</strong>
			<br> Packages count: <strong>{y}</strong> 
			<br> Percentage <strong>{percentage}%</strong>
			`,
            indexLabel: "{percentage}%",
            indexLabelPlacement: "inside",
            dataPoints
        }]
    }

    return <CanvasJSChart options={options} />
}

function getDataPoints(ordersData: any) {
	const cities = ['Sofia', 'Plovdiv', 'Varna', 'Burgas', 'Ruse', 'Other']

	const totalCount = (() => {
		const keys = Object.keys(ordersData)

		return keys
			.map(key => (ordersData as any)[key].length)
			.reduce((a, b) => a + b, 0)
	})()

	const dataPoints = cities.map(city => {
		const key = `${city.toLowerCase()}Orders`
		const ordersCount = ordersData[key].length
		const percentage = (100 * ordersCount / totalCount).toFixed(2)

		return {
			y: ordersCount,
			percentage,
			label: city
		}
	})

	return dataPoints
}

function mapOrders(orders: Order[]) {
    const destinations = ['sofia', 'софия', 'plovdiv', 'пловдив', 'varna', 'варна', 'burgas', 'бургас', 'ruse', 'русе']

    const isSofia = destinationPredicate(destinations[0], destinations[1])
    const isPlovdiv = destinationPredicate(destinations[2], destinations[3])
    const isVarna = destinationPredicate(destinations[4], destinations[5])
    const isBurgas = destinationPredicate(destinations[6], destinations[7])
    const isRuse = destinationPredicate(destinations[8], destinations[9])

    const sofiaOrders = orders.filter(isSofia)
    const plovdivOrders = orders.filter(isPlovdiv)
    const varnaOrders = orders.filter(isVarna)
    const burgasOrders = orders.filter(isBurgas)
    const ruseOrders = orders.filter(isRuse)
    const otherOrders = orders.filter(order => {
        return destinations.every(destination => !order.deliveryAddress.toLowerCase().includes(destination))
    })

    return {
        sofiaOrders,
        plovdivOrders,
        varnaOrders,
        burgasOrders,
        ruseOrders,
        otherOrders
    }
}

function destinationPredicate(cityNameEn: string, cityNameBg: string) {
    return (order: Order) => (
        order.deliveryAddress.toLowerCase().includes(cityNameEn) ||
        order.deliveryAddress.toLowerCase().includes(cityNameBg)
    )
}
