import { Formik, FormikProps } from "formik"
import { useSelector } from "react-redux"
import { Paper, Typography } from "@mui/material"

import { i18nPackageCalc } from "./i18n"
import { GlobalState } from "../../../data/store"
import { useTranslateSingle } from "../../../hooks/translate"
import { calculateTransportationPrice } from "../../../utils/functions"
import { CalcPackagesMobileForm } from "./mobile/CalcPackagesMobileForm"
import { CalcPackagesDesktopForm } from "./desktop/CalcPackagesDesktopForm"
import { DeviceComponents, useResponsive } from "../../../hooks/useResponsive"
import { DeviceStrings, useResponsiveString } from "../../../hooks/useResponsiveString"

interface IPackage {
    weight: number
    width: number
    height: number
    depth: number
}

export interface IPackageCalcFormValues {
    packages: IPackage[]
    fromCountry: string
    toCountry: string
    fromRegion: string
    toRegion: string
}

function FormComponent(props: FormikProps<IPackageCalcFormValues>): JSX.Element {
    const components = (props: FormikProps<IPackageCalcFormValues>) => ({
        mobile: <CalcPackagesMobileForm {...props} />,
        smallDesktop: <CalcPackagesDesktopForm {...props} />,
    }) as DeviceComponents

    const formComponent = components(props)

    return useResponsive(formComponent)
}

export function CalcPackageForm(): JSX.Element {
    const prices = useSelector((state: GlobalState) => state.settings.prices)

    const title = useTranslateSingle(i18nPackageCalc.title)

    const classNames = {
        mobile: 'price-calc-packages-container-mobile',
        smallDesktop: 'price-calc-packages-container',
    } as DeviceStrings

    const className = useResponsiveString(classNames)

    return (
        <div className={className}>
            <Paper className="price-calc-form-paper" elevation={3}>
                <Typography variant="h5" className="price-calc-header">
                    {title}
                </Typography>
                <Formik
                    initialValues={{
                        packages: [
                            {
                                weight: 0,
                                width: 0,
                                height: 0,
                                depth: 0,
                            },
                        ],
                        showRequestButton: false,
                        fromCountry: 'Italy',
                        fromRegion: 'Rome',
                        toCountry: 'Bulgaria',
                        toRegion: 'Sofia',
                    } as IPackageCalcFormValues}
                    onSubmit={(values: any, { setSubmitting, setFieldValue }) => {
                        setSubmitting(true)

                        const { fromRegion, toRegion } = values
                        const noAutoPriceFromRegions = fromRegion !== 'Sofia' && fromRegion !== 'Rome'
                        const noAutoPriceToRegions = toRegion !== 'Sofia' && toRegion !== 'Rome'
                        if (noAutoPriceFromRegions || noAutoPriceToRegions) {
                            setFieldValue('price', 0)
                            return
                        }

                        const totalPrice = calculateTransportationPrice(values.packages, prices)
                        setFieldValue('price', totalPrice)
                    }}
                >
                    {(props) => <FormComponent {...props} />}
                </Formik>
            </Paper>
        </div>
    )
}