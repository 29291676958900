import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { hideLoader, showLoader } from "./loaderActions"
import { showNotification } from "./notificationActions"

const INCREMENT = 'counter/increment'
const DECREMENT = 'counter/decrement'
const INCREMENT_BY_AMOUNT = 'counter/incrementByAmount'

export const increment = createAction(INCREMENT)
export const decrement = createAction(DECREMENT)
export const incrementByAmount = createAction(INCREMENT_BY_AMOUNT)

export const incrementAsync = createAsyncThunk(
	INCREMENT,
	async (prop: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		dispatch(showLoader())
		const promise = new Promise(resolve => {
			setTimeout(() => {
				resolve('')
			}, 1000)
		})
		dispatch(showNotification('INCREMENTED', 'success'))
		const res = await promise
		dispatch(hideLoader())
		return res
	}
)