export const i18nRestorePass = {
	title: {
		bg: 'Възстановяване на парола',
		en: 'Reset password'
	},
	subTitle: {
		bg: 'Въведете имейла си и новата парола, която искате да използвате',
		en: 'Enter your email and the new password you want to use'
	},
	email: {
		bg: 'Имейл',
		en: 'Email'
	},
	newPassword: {
		bg: 'Нова парола',
		en: 'New password'
	},
	buttonLabel: {
		bg: 'Възстановяване',
		en: 'Reset password'
	}
}
