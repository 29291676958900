import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { GlobalState } from '../../../data/store'
import { CompletedOrdersTable } from './table/CompletedOrdersTable'
import { getUserCompletedOrders } from '../../../data/actions/ordersActions'
import { DeviceComponents, useResponsive } from '../../../hooks/useResponsive'
import { CompletedOrdersMobileList } from './mobileList/CompletedOrdersMobileList'

export function CompletedOrdersComponent(props: any) {
    const { _id } = useSelector((state: GlobalState) => state.users.loggedIn)

    useEffect(() => {
        const { getUserCompletedOrders } = props
        getUserCompletedOrders({ userId: _id })
    }, [props, _id])

    const components = {
        smallDesktop: <CompletedOrdersTable />,
        mobile: <CompletedOrdersMobileList />
    } as DeviceComponents

    return useResponsive(components)
}

const mapDispatchToProps = { getUserCompletedOrders }

export const CompletedOrders = connect(null, mapDispatchToProps)(CompletedOrdersComponent)
