export const i18nAdminCreateLabelFromOrder = {
    title: {
        bg: 'Потвърждение за създаване на ЕКОНТ товарителница',
        en: 'Create ECONT label confirmation'
    },
    sureText: {
        bg: 'Сигурни ли сте, че искате да създадете товарителница за описаната пратка?',
        en: 'Are you sure you want to create label for the package listed below?'
    },
    issueStatusText: {
        bg: "Статусът на пратката трябва да е 'пристигнала в България'",
        en: "The status of the package should be 'arrived in Bulgaria'"
    },
    issuePackagesText: {
        bg: 'Налични са необработени пратки за тази поръчка',
        en: 'There is unprocessed packages in this order'
    },
    confirm: {
        bg: 'Създай',
        en: 'Create'
    },
    close: {
        bg: 'Затвори',
        en: 'Close'
    },
    openEditModal: {
        bg: 'Промени',
        en: 'Edit'
    },
    packageLabel: {
        bg: 'Пакет',
        en: 'Item'
    },
    processedLabel: {
        bg: 'Обработена',
        en: 'Processed'
    },
    measuredWeight: {
        bg: 'Измерено тегло кг.',
        en: 'Measured weight kg.'
    },
    measuredSizes: {
        bg: 'Измерени размери см.',
        en: 'Measured sizes cm.'
    },
    addPackage: {
        bg: 'Добави пакет',
        en: 'Add item'
    },
    deliveryPrice: {
        bg: 'Цена за доставка',
        en: 'Delivery price'
    },
    senderEcontOfficeAddress: {
        bg: 'Еконт офис за изпращане',
        en: 'Econt office sender address'
    },
    receiver: {
        bg: 'Получател',
        en: 'Receiver'
    },
    receiverPhone: {
        bg: 'Телефон получател',
        en: 'Receiver phone'
    },
    receiverEcontOfficeAddress: {
        bg: 'Еконт офис адрес',
        en: 'Econt office address'
    },
    sizesLabel: {
        bg: 'Размерите на пратката са:',
        en: 'The sizes of the package are:'
    },
    requiredPayment: {
        bg: 'Наложен платеж',
        en: 'Required payment'
    },
    lessThan60: {
        bg: 'по-малки от 60 см.',
        en: 'less than 60 cm.'
    },
    moreThan60: {
        bg: 'по-големи от 60 см.',
        en: 'more than 60 cm.'
    }
}
