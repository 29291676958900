export const i18nForgetPass = {
	title: {
		bg: 'Забравена парола',
		en: 'Forgotten password'
	},
	subTitle: {
		bg: 'Посочете имейла, с който сте регистриран и ще ви изпратим линк за възстановяване на паролата',
		en: 'Provide the email of your registration, we will send a reset password link there'
	},
	email: {
		bg: 'Имейл',
		en: 'Email'
	},
	buttonLabel: {
		bg: 'Изпрати заявка',
		en: 'Send request'
	}
}
