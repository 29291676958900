export const i18nAdminUsers = {
	types: {
		bg: 'Тип',
		en: 'Type'
	},
	type1: {
		bg: 'Физически лица',
		en: 'Individual'
	},
	type2: {
		bg: 'Фирми',
		en: 'Companies'
	},
	confirmDeleteUser: {
		bg: 'Сигурни ли сте, че искате да изтриете този потребител?',
		en: 'Are you sure you want to delete selected user profile?'
	},
	actions: {
		bg: 'Действия',
		en: 'Actions'
	},
	search: {
		bg: 'Търсене',
		en: 'Search'
	},
	typeTerm: {
		bg: 'Напиши част от',
		en: 'Type term'
	},
	searchBy: {
		bg: 'Търсене по',
		en: 'Search by'
	},
	itemsPerPage: {
		bg: 'Брой на страница',
		en: 'Users per page'
	},
	// BEGIN client table heads
	id: {
		bg: 'ИД',
		en: 'ID'
	},
	email: {
		bg: 'Имейл',
		en: 'Email'
	},
	phoneNumber: {
		bg: 'Телефон',
		en: 'Phone'
	},
	country: {
		bg: 'Държава',
		en: 'Country'
	},
	city: {
		bg: 'Град / Село',
		en: 'City'
	},
	name: {
		bg: 'Имена',
		en: 'Names'
	},
	registerDate: {
		bg: 'Дата на регистрация',
		en: 'Register date'
	},
	// END client table heads

	// BEGIN company table heads
	idCompany: {
		bg: 'ИД',
		en: 'ID'
	},
	emailCompany: {
		bg: 'Имейл',
		en: 'Email'
	},
	companyPhoneNumber: {
		bg: 'Телефон',
		en: 'Phone'
	},
	companyId: {
		bg: 'ЕИК / Булстат',
		en: 'Company ID'
	},
	companyName: {
		bg: 'Име на фирма',
		en: 'Company name'
	},
	companyOwner: {
		bg: 'МОЛ',
		en: 'Owner'
	},
	registerDateCompany: {
		bg: 'Дата на регистрация',
		en: 'Register date'
	}
	// END company table heads
}
