export const i18nRegOrderBanner = {
	question: {
		bg: 'Направил си нова онлайн поръчка?',
		en: 'Have you placed a new online order?'
	},
	action: {
		bg: 'Регистрирай я сега!',
		en: 'Register it now!'
	},
	buttonLabel: {
		bg: 'Регистрирай пратка',
		en: 'Register package'
	}
}
