import { Button, TextField, Typography } from "@mui/material"
import { FormikError } from "../../utils/formik/FormikError"
import { useTranslate } from "../../hooks/translate"
import { i18nVerifyRegistration } from "./i18nVerifyRegistration"

export function VerifyRegistrationForm(props: any): JSX.Element {
    const { handleSubmit, setFieldValue, handleChange, values, errors, touched, handleBlur, requestNewCode, requestSupportCheck } = props

    const t = useTranslate(i18nVerifyRegistration)

    const showSupportCondition = values.newCodeRequestedTimes > 2

    const requestAgainLabel = showSupportCondition ? t.didntReceiveCodeX2Label : t.didntReceiveCodeLabel
    const requestAgainButtonLabel = showSupportCondition ? t.requestSupportCheck : t.requestNewCode

    function handleRequestNewCode(): void {
        requestNewCode(values, setFieldValue)
    }

    function handleRequestSupportCheck(): void {
        requestSupportCheck(setFieldValue)
    }

    const requestHandler = showSupportCondition ? handleRequestSupportCheck : handleRequestNewCode

    return (
        <form onSubmit={handleSubmit} className="verify-registration-input-fields">
            <div className="verify-registration-input-field">
                <TextField
                    fullWidth
                    name="code"
                    label={t.code}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.code}
                    onChange={handleChange}
                />
                <FormikError name="code" errors={errors} touched={touched} />
            </div>
            <div className="verify-registration-input-field">
                <TextField
                    fullWidth
                    name="firstName"
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.firstName}
                    onChange={handleChange}
                    value={values.firstName}
                />
                <FormikError name="firstName" errors={errors} touched={touched} />
            </div>
            <div className="verify-registration-input-field">
                <TextField
                    fullWidth
                    name="lastName"
                    variant="outlined"
                    label={t.lastName}
                    onBlur={handleBlur}
                    value={values.lastName}
                    onChange={handleChange}
                />
                <FormikError name="lastName" errors={errors} touched={touched} />
            </div>
            <div className="verify-registration-input-field">
                <TextField
                    fullWidth
                    name="phoneNumber"
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.phoneNumber}
                    onChange={handleChange}
                    value={values.phoneNumber}
                />
                <FormikError name="phoneNumber" errors={errors} touched={touched} />
            </div>
            {
                !values.supportCheckRequested &&
                <div className='error-labels'>
                    <Typography variant='body1'>{requestAgainLabel}</Typography>
                </div>
            }
            <div className={`action-buttons ${values.supportCheckRequested ? 'single-button' : ''}`}>
                {
                    !values.supportCheckRequested &&
                    <Button size="large" color="secondary" variant="outlined" onClick={requestHandler}>
                        {requestAgainButtonLabel}
                    </Button>
                }
                <Button size="large" type="submit" color="secondary" variant="contained" className='submit-button'>
                    {t.confirm}
                </Button>
            </div>
        </form>
    )
}