export const i18nFreightCalc = {
    title: {
        bg: 'Изчисляване цена камиони',
        en: 'Calculate freight price',
    },
    sendRequest: {
        bg: 'Изпрати запитване',
        en: 'Send request'
    }
}
