import { ApiResponse } from '../../utils/apiResponse'
import { logFailApiRequest } from '../../utils/functions'
import { messages } from '../../utils/i18n/messages'
import { showNotification } from './notificationActions'
import { getTranslation } from './translation'

export function isSessionActive(response: ApiResponse): boolean {
    return response.code !== 'token_expired' && response.status !== 203
}

export function isAuthRequest(response: ApiResponse, dispatch: any): boolean {
	const translatedMsgs = getTranslation(messages)
	if (!response.isSuccess) {
		if (!isSessionActive(response)) {
			dispatch(showNotification(translatedMsgs.sessionExpired, 'warning'))
		} else {
			dispatch(showNotification(response.message, 'error'))
			logFailApiRequest(response)
		}
		return false
	}

	return true
}
