export const i18nPalletCalc = {
    title: {
        bg: 'Изчисляване цена палета',
        en: 'Calculate pallets price',
    },
    sendRequest: {
        bg: 'Изпрати запитване',
        en: 'Send request'
    }
}
