import { HomeRegisterUserMobile } from './mobile/HomeRegisterUserMobile'
import { HomeRegisterUserDesktop } from './desktop/HomeRegisterUserDesktop'
import { DeviceComponents, useResponsive } from '../../../../hooks/useResponsive'

export function HomeRegisterUser(): JSX.Element {
    const components = {
        mobile: <HomeRegisterUserMobile />,
        smallDesktop: <HomeRegisterUserDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}
