import { Order } from '../../../data/reducers/ordersReducer'
import { getOrderStatusChip } from '../../../components/Shared'
import { calculateTransportationPrice, mapPackagesStringSizesToPackagesObjectsSizes } from '../../../utils/functions'

export const tableStruct = (heads: string[], lang: string) => [
    {
        head: heads[0],
        getData: (obj: Order) => (
            <span className='schedule-order-status-chip'>
                {getOrderStatusChip(obj.status, lang)}
            </span>
        ),
    },
    {
        head: heads[1],
        getData: (obj: Order) => obj.receiver,
    },
    {
        head: heads[2],
        getData: (obj: Order) => obj.deliveryAddress,
    },
    {
        head: heads[3],
        getData: (obj: Order) => {
            let totalWeight = 0
            const kg = lang === 'bg' ? 'кг.' : 'kg.'
            obj?.packages.forEach((pckg: any) => (totalWeight += +pckg.weight))
            return `${Number(totalWeight).toFixed(2)} ${kg}`
        },
    },
    {
        head: heads[4],
        getData: (obj: Order) => obj?.packages?.length,
    },
    {
        head: heads[5],
        getData: (obj: Order, priceSettings: any) => {
            if (!obj) {
                return '0'
            }
            
            const isTuttoDelivery = obj.deliveryCompany === 'tuttopost'
            const packagesInfo = mapPackagesStringSizesToPackagesObjectsSizes(obj.packages)
            const denomination = lang === 'bg' ? 'лв' : 'BGN'
            const price = calculateTransportationPrice(packagesInfo, priceSettings, isTuttoDelivery, !!obj.unregistered)
            return `${price} ${denomination}`
        },
    },
]
