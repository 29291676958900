import { useState } from 'react'
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material'
import { InventoryOutlined, InfoOutlined, Inventory, AirportShuttle } from '@mui/icons-material'

import { TABS } from '../TABS'
import { i18nProfile } from '../i18n'
import { useTranslate } from '../../../hooks/translate'
import { ActiveOrders } from '../activeOrders/ActiveOrders'
import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { CompletedOrders } from '../completedOrders/CompletedOrders'
import { ProfileInformation } from '../information/ProfileInformation'
import { InternalSchedules } from '../internalSchedules/InternalSchedules'

import './styles.css'

export function ProfilePageMobile(): JSX.Element {
    const [tab, setTab] = useState(TABS.information)

    const handleChange = (ev: any, value: any) => setTab(value)

    const t = useTranslate(i18nProfile)

    return (
        <Box className='profile-mobile-tab-box'>
            <TabPanel value={tab} index={0}>
                <ProfileInformation />
            </TabPanel>
            <TabPanel value={tab} index={1} boxClassName='active-orders-tab'>
                <ActiveOrders />
            </TabPanel>
            <TabPanel value={tab} index={2} boxClassName='mobile-internal-schedules-tab'>
                <InternalSchedules />
            </TabPanel>
            <TabPanel value={tab} index={3} boxClassName='completed-orders-tab'>
                <CompletedOrders />
            </TabPanel>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className='profile-mobile-navigation'>
                <BottomNavigation
                    // showLabels
                    value={tab}
                    onChange={handleChange}
                >
                    <BottomNavigationAction label={t.info} icon={<InfoOutlined />} />
                    <BottomNavigationAction label={t.activeOrders} icon={<Inventory />} />
                    <BottomNavigationAction label={t.internalSchedules} icon={<AirportShuttle />} />
                    <BottomNavigationAction label={t.ordersHistory} icon={<InventoryOutlined />} />
                </BottomNavigation>
            </Paper>
        </Box>
    )
}
