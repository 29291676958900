export const i18nWeightAndSizes = {
	title: {
		bg: 'Тагло и размери',
		en: 'Weight and sizes'
	},
	text1: {
		bg: 'За нестандартни пратки изпрати запитване за цена на имейла ни: ',
		en: 'For unusual weighted or sized packages send a price request to our email'
	},
	text2: {
		bg: 'Всички пратки се таксуват според теглото им. Теглото на пратки, които са с размери по-големи от 60см. се смята по следната формула:',
		en: 'The price of all packages is calculated depending on their weight. The weight of the packages which sizes exceeds 60cm. is being calculated with the following formula:'
	},
	text3: {
		bg: '(см.) Дължина * Ширина * Височина / 6000 = обемно тегло в кг.',
		en: '(cm.) Width * Height * Depth / 6000 = volume weight in kg.'
	},
	text4: {
		bg: 'Когато размера на пратката е по-голям от 60см. за таксуване на теглото се взима по-голямото от двете - физическо тегло или обемно тегло.',
		en: 'When one of the the sizes of the package is bigger than 60cm. we apply volume weight formula and we take the bigger weight of both (physical and volume weight).'
	},
	text5Em: {
		bg: 'Пример: ',
		en: 'Example: '
	},
	text5: {
		bg: 'Пратката тежи 15кг., размерите й са (см.) 60х60х30. По формулата за обемно тегло - (60 * 60 * 30) / 6000 = 18кг. обемно тегло. За ценообразуването се взима по-голямото тегло от двете - обемното 18кг.',
		en: "The package's weight is 15kg., its sizes are (cm.) 60x60x30. Using the volume weight formule - (60 * 60 * 30) / 6000 = 18kg. volume weight. For price calculation it is always considered the biggest weight of both, in this case, the volume weight - 18kg."
	},
	text6: {
		bg: 'Цена за доставяне на всеки започнат килограм: ',
		en: 'Delivery price for each started kilogram: '
	},
	text7: {
		bg: 'Такса обработка за всяка отделна пратка: ',
		en: 'Process free for each separate package: '
	},
	text8: {
		bg: 'Цена доставка на пратки с наш куриер (само за гр. София и пратки до 5кг.): ',
		en: 'Our internal delivery price (only for Sofia and weight up to 5kg.): '
	},
	text9: {
		bg: 'Глоба за нерегистрирани пратки:',
		en: 'Unregistered packages fee: '
	},
	text10: {
		bg: 'Всички посочени цени са с включен ДДС',
		en: 'All prices are with included Bulgarian VAT'
	}
}
