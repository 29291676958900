import { useState } from 'react'
import { useSelector } from 'react-redux'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'

import { GlobalState } from '../../../../data/store'
import { i18nAdminCBScreateUpdateModal } from './i18n'
import { useTranslate } from '../../../../hooks/translate'
import { mapToFriendlyCrossBorderScheduleStatus } from '../../../../utils/functions'
import { bgRegions, itRegions, transportCountries } from '../../../../utils/regionsAndCities'
import { CROSS_BORDER_SCHEDULE_STATUS } from '../../../../data/reducers/crossBorderSchedulesReducer'

import './styles.css'

export function CreateUpdateScheduleModal(props: any): JSX.Element {
    const { handleClose, data, handleSaveChanges } = props

    const initialPickupDate = data.pickupDate ? new Date(data.pickupDate) : new Date()
    const initialDeliveryDate = data.deliveryDate ? new Date(data.deliveryDate) : new Date()

    const [status, setStatus] = useState(data.status || CROSS_BORDER_SCHEDULE_STATUS.EXPECT_TO_PICKUP)
    const [fromCountry, setFromCountry] = useState(data.fromCountry || 'Italy')
    const [toCountry, setToCountry] = useState(data.toCountry || 'Bulgaria')
    const [fromCity, setFromCity] = useState(data.fromCity || 'Rome')
    const [toCity, setToCity] = useState(data.toCity || 'Sofia')
    const [fromRegions, setFromRegions] = useState(itRegions)
    const [toRegions, setToRegions] = useState(bgRegions)
    const [pickupDate, setPickupDate] = useState(initialPickupDate)
    const [deliveryDate, setDeliveryDate] = useState(initialDeliveryDate)

    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const t = useTranslate(i18nAdminCBScreateUpdateModal)

    const handleSave = () => {
        const fixedPickupDate = typeof pickupDate === 'number' ? pickupDate : pickupDate.getTime()
        const fixedDeliveryDate = typeof deliveryDate === 'number' ? deliveryDate : deliveryDate.getTime()
        const saveData = {
            _id: data._id,
            fromCountry,
            toCountry,
            fromCity,
            toCity,
            status,
            orders: [],
            pickupDate: fixedPickupDate,
            deliveryDate: fixedDeliveryDate,
        }
        handleSaveChanges(saveData)
    }

    const handleChangeStatus = (ev: any) => setStatus(ev.target.value)
    const handleChangeFromCountry = (ev: any) => {
        if (ev.target.value === 'Italy') {
            setToCountry('Bulgaria')
            setToRegions(bgRegions)
            setToCity('Sofia')
            setFromRegions(itRegions)
            setFromCity('Rome')
        } else {
            setToCountry('Italy')
            setToRegions(itRegions)
            setToCity('Rome')
            setFromRegions(bgRegions)
            setFromCity('Sofia')
        }

        setFromCountry(ev.target.value)
    }
    const handleChangeToCountry = (ev: any) => {
        if (ev.target.value === 'Italy') {
            setFromCountry('Bulgaria')
            setFromRegions(bgRegions)
            setFromCity('Sofia')
            setToRegions(itRegions)
            setToCity('Rome')
        } else {
            setFromCountry('Italy')
            setFromRegions(itRegions)
            setFromCity('Rome')
            setToRegions(bgRegions)
            setToCity('Sofia')
        }

        setToCountry(ev.target.value)
    }
    const handleChangeFromCity = (ev: any) => setFromCity(ev.target.value)
    const handleChangeToCity = (ev: any) => setToCity(ev.target.value)
    const handleChangePickupDate = (ev: any) => {
        const epoch = ev._d.getTime()
        if (deliveryDate && epoch > deliveryDate) {
            const _2daysAfter = new Date(epoch)
            _2daysAfter.setDate(_2daysAfter.getDate() + 2)
            const deliveryEpoch = _2daysAfter.getTime() as any
            setDeliveryDate(deliveryEpoch)
        }

        setPickupDate(epoch)
    }
    const handleChangeDeliveryDate = (ev: any) => {
        const epoch = ev._d.getTime()
        if (pickupDate && epoch < pickupDate) {
            const _2daysBefore = new Date(epoch)
            _2daysBefore.setDate(_2daysBefore.getDate() - 2)
            const pickupEpoch = _2daysBefore.getTime() as any
            setPickupDate(pickupEpoch)
        }

        setDeliveryDate(epoch)
    }

    const statuses = Object.values(CROSS_BORDER_SCHEDULE_STATUS)
    const friendlyStatuses = statuses.map((status) => ({
        value: status,
        friendly: mapToFriendlyCrossBorderScheduleStatus(status, lang),
    }))

    return (
        <Modal open={true} onClose={handleClose}>
            <div className="cross-border-schedules-modal-container">
                <Typography className="modal-header" variant="h4" align="center">
                    {data._id ? t.updateTitle : t.createTitle}
                </Typography>
                <div className="direction-inputs-wrapper">
                    <DesktopDatePicker
                        value={pickupDate}
                        label={t.pickupDate}
                        inputFormat="DD.MM.YYYY"
                        onChange={handleChangePickupDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <div className="destination-inputs-wrapper">
                        {/* <FormControl margin="dense" fullWidth size="small" disabled={!!data._id}> */}
                        <FormControl margin="dense" fullWidth size="small" disabled>
                            <InputLabel id="from-country-select-label">{t.pickupCountry}</InputLabel>
                            <Select
                                labelId="from-country-select-label"
                                onChange={handleChangeFromCountry}
                                label={t.pickupCountry}
                                defaultValue={'Italy'}
                                value={fromCountry}
                            >
                                {transportCountries.map((country, i) => {
                                    return (
                                        <MenuItem key={i} value={country.name}>
                                            {lang === 'bg' ? country.bgName : country.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl margin="dense" fullWidth size="small" disabled={!!data._id}>
                            <InputLabel id="from-city-select-label">{t.pickupRegion}</InputLabel>
                            <Select
                                labelId="from-city-select-label"
                                onChange={handleChangeFromCity}
                                label={t.pickupRegion}
                                defaultValue={'Rome'}
                                value={fromCity}
                            >
                                {fromRegions.map((region, i) => {
                                    return (
                                        <MenuItem key={i} value={region.name}>
                                            {lang === 'bg' ? region.bgName : region.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="direction-inputs-wrapper">
                    <DesktopDatePicker
                        value={deliveryDate}
                        label={t.arriveDate}
                        inputFormat="DD.MM.YYYY"
                        onChange={handleChangeDeliveryDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <div className="destination-inputs-wrapper">
                        {/* <FormControl margin="dense" fullWidth size="small" disabled={!!data._id}> */}
                        <FormControl margin="dense" fullWidth size="small" disabled>
                            <InputLabel id="to-country-select-label">{t.arriveCountry}</InputLabel>
                            <Select
                                labelId="to-country-select-label"
                                onChange={handleChangeToCountry}
                                defaultValue={'Bulgaria'}
                                label={t.arriveCountry}
                                value={toCountry}
                            >
                                {transportCountries.map((country, i) => {
                                    return (
                                        <MenuItem key={i} value={country.name}>
                                            {lang === 'bg' ? country.bgName : country.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl margin="dense" fullWidth size="small" disabled={!!data._id}>
                            <InputLabel id="to-city-select-label">{t.arriveRegion}</InputLabel>
                            <Select
                                labelId="to-city-select-label"
                                onChange={handleChangeToCity}
                                label={t.arriveRegion}
                                defaultValue={'Sofia'}
                                value={toCity}
                            >
                                {toRegions.map((region, i) => {
                                    return (
                                        <MenuItem key={i} value={region.name}>
                                            {lang === 'bg' ? region.bgName : region.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <FormControl margin="dense" fullWidth size="small">
                    <InputLabel id="status-select-label">{t.status}</InputLabel>
                    <Select
                        defaultValue={CROSS_BORDER_SCHEDULE_STATUS.EXPECT_TO_PICKUP}
                        labelId="status-select-label"
                        onChange={handleChangeStatus}
                        label={t.status}
                        value={status}
                    >
                        {friendlyStatuses.map((orderStatus, i) => {
                            return (
                                <MenuItem key={i} value={orderStatus.value}>
                                    {orderStatus.friendly}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div className="action-buttons">
                    <Button onClick={handleSave} variant="contained" color="secondary">
                        {data._id ? t.buttonLabel2 : t.buttonLabel1}
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="info">
                        {t.buttonLabel3}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
