import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { Fade } from 'react-awesome-reveal'
import { Typography } from '@mui/material'
import { Formik } from 'formik'

import { RegisterForm } from './RegisterForm'
import { register } from '../../data/actions/usersActions'
import {
    validateEmail,
    matchPasswords,
    validatePassword,
    validateTwoNames,
    validateNonEmpty,
    validateCompanyId,
} from '../../utils/formik/validations'
import { i18nRegister } from './i18nRegister'
import { easeTimes } from '../../utils/easeTimes'
import { useTranslateSingle } from '../../hooks/translate'
import { DeviceStrings, useResponsiveString } from '../../hooks/useResponsiveString'

import './styles.css'

const initialValues = {
    email: '',
    password: '',
    companyId: '',
    companyName: '',
    companyOwner: '',
    isCompany: false,
    companyAddress: '',
    agreeWithTC: false,
    confirmPassword: '',
    subscribeNewsletter: true,
}

export function RegisterPageComponent(props: any): JSX.Element {
    const navigate = useNavigate()

    const classNames = {
        mobile: 'register-container-mobile',
        smallDesktop: 'register-container',
    } as DeviceStrings

    const containerClassName = useResponsiveString(classNames)

    const title = useTranslateSingle(i18nRegister.title)

    return (
        <div className={containerClassName}>
            <div className="register-form">
                <Fade cascade triggerOnce damping={0.3} direction="up" duration={easeTimes.slow}>
                    <Typography variant="h4" className="header">
                        {title}
                    </Typography>
                    <Formik
                        initialValues={initialValues}
                        validate={(values: any) => {
                            const companyValidations = values.isCompany
                                ? {
                                      ...validateCompanyId(values, ['companyId']),
                                      ...validateTwoNames(values, ['companyOwner']),
                                      ...validateNonEmpty(values, ['companyName']),
                                  }
                                : {}
                            return {
                                ...validateEmail(values, ['email']),
                                ...matchPasswords(values, 'password', 'confirmPassword'),
                                ...validatePassword(values, ['password', 'confirmPassword']),
                                ...companyValidations,
                            }
                        }}
                        onSubmit={async (values: any, { setSubmitting, resetForm }) => {
                            setSubmitting(true)
                            const response = await props.register(values)
                            resetForm()

                            if (response.payload.email) {
                                navigate('/verify-registration')
                            }
                        }}
                    >
                        {(props: any) => <RegisterForm {...props} />}
                    </Formik>
                </Fade>
            </div>
        </div>
    )
}

const mapDispatchToProps = { register }

export const RegisterPage = connect(null, mapDispatchToProps)(RegisterPageComponent)
