import { apiService } from './apiService'
import { endpoints } from './endpoints'

const domain = endpoints.orders

export const ordersService = {
	getUserActiveOrders: (userId: string) => apiService.get(domain.userActiveOrders(userId)),
	getUserCompletedOrders: (userId: string) => apiService.get(domain.userCompletedOrders(userId)),
	getSpecific: (data: Object) => apiService.post(domain.getSpecific, data),
	getAllActiveOrders: () => apiService.get(domain.allActiveOrders),
	getAllCompletedOrders: () => apiService.get(domain.allCompletedOrders),
	registerOrder: (orderData: Object) => apiService.post(domain.registerOrder, orderData),
	updateOrder: (userId: string, orderData: Object) =>
		apiService.post(domain.updateOrder(userId), orderData),
	deleteOrder: (orderData: Object) => apiService.delete(domain.delete, orderData),
	createUnregisteredOrder: (orderData: Object) => apiService.post(domain.createUnregisteredOrder, orderData),
	combineOrders: (ordersData: Object) => apiService.post(domain.combine, ordersData),
    uncombineOrders: (orderData: Object) => apiService.post(domain.uncombine, orderData)
}
