// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from 'react-redux'
import { GlobalState } from '../../../../../data/store'
import { ORDER_STATUS, Order, OrderStatus } from '../../../../../data/reducers/ordersReducer'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function CompanyOrdersGraphic() {
    const { orders } = useSelector((state: GlobalState) => state.orders)

    // @ts-ignore
    const byStatus = Object.groupBy(orders, ({ status }) => status)

    const registeredEcont = getTotalWeightsArray(byStatus[ORDER_STATUS.REGISTERED], 'econt')
    const arrivedItEcont = getTotalWeightsArray(byStatus[ORDER_STATUS.ARRIVED_ABROAD], 'econt')
    const travelingEcont = getTotalWeightsArray(byStatus[ORDER_STATUS.TRAVELING], 'econt')
    const arrivedBgEcont = getTotalWeightsArray(byStatus[ORDER_STATUS.ARRIVED_BG], 'econt')

    const registeredWeightEcont = registeredEcont ? registeredEcont.reduce((a: number, b: number) => +a + +b, 0) : 0
    const arrivedItWeightEcont = arrivedItEcont ? arrivedItEcont.reduce((a: number, b: number) => +a + +b, 0) : 0
    const travelingWeightEcont = travelingEcont ? travelingEcont.reduce((a: number, b: number) => +a + +b, 0) : 0
    const arrivedBgWeightEcont = arrivedBgEcont ? arrivedBgEcont.reduce((a: number, b: number) => +a + +b, 0) : 0

    const registeredTuttopost = getTotalWeightsArray(byStatus[ORDER_STATUS.REGISTERED], 'tuttopost')
    const arrivedItTuttopost = getTotalWeightsArray(byStatus[ORDER_STATUS.ARRIVED_ABROAD], 'tuttopost')
    const travelingTuttopost = getTotalWeightsArray(byStatus[ORDER_STATUS.TRAVELING], 'tuttopost')
    const arrivedBgTuttopost = getTotalWeightsArray(byStatus[ORDER_STATUS.ARRIVED_BG], 'tuttopost')

    const registeredWeightTuttopost = registeredTuttopost ? registeredTuttopost.reduce((a: number, b: number) => +a + +b, 0) : 0
    const arrivedItWeightTuttopost = arrivedItTuttopost ? arrivedItTuttopost.reduce((a: number, b: number) => +a + +b, 0) : 0
    const travelingWeightTuttopost = travelingTuttopost ? travelingTuttopost.reduce((a: number, b: number) => +a + +b, 0) : 0
    const arrivedBgWeightTuttopost = arrivedBgTuttopost ? arrivedBgTuttopost.reduce((a: number, b: number) => +a + +b, 0) : 0

    const eRegistered = getShorthandName(ORDER_STATUS.REGISTERED, 'econt')
    const eArrivedIt = getShorthandName(ORDER_STATUS.ARRIVED_ABROAD, 'econt')
    const eTraveing = getShorthandName(ORDER_STATUS.TRAVELING, 'econt')
    const eArrivedBg = getShorthandName(ORDER_STATUS.ARRIVED_BG, 'econt')

    const tRegistered = getShorthandName(ORDER_STATUS.REGISTERED, 'tuttopost')
    const tArrivedIt = getShorthandName(ORDER_STATUS.ARRIVED_ABROAD, 'tuttopost')
    const tTraveing = getShorthandName(ORDER_STATUS.TRAVELING, 'tuttopost')
    const tArrivedBg = getShorthandName(ORDER_STATUS.ARRIVED_BG, 'tuttopost')

    const options = {
        animationEnabled: true,
        title: {
            text: 'Active orders stats by delivery company',
            fontFamily: "verdana"
        },
        axisY: {
            includeZero: true,
        },
        toolTip: {
            shared: true,
            reversed: true
        },
        legend: {
            verticalAlign: "center",
            horizontalAlign: "right",
            reversed: true
        },
        data: [
            {
                type: "stackedColumn",
                name: "Weight",
                showInLegend: true,
                yValueFormatString: "#,### kg",
                dataPoints: [
                    { label: eRegistered, y: registeredWeightEcont },
                    { label: eArrivedIt, y: arrivedItWeightEcont },
                    { label: eTraveing, y: travelingWeightEcont },
                    { label: eArrivedBg, y: arrivedBgWeightEcont },
                    { label: '<econt || tutto>', y: 0 },
                    { label: tRegistered, y: registeredWeightTuttopost },
                    { label: tArrivedIt, y: arrivedItWeightTuttopost },
                    { label: tTraveing, y: travelingWeightTuttopost },
                    { label: tArrivedBg, y: arrivedBgWeightTuttopost },
                ]
            },
            {
                type: "stackedColumn",
                name: "Count",
                showInLegend: true,
                yValueFormatString: "#,###",
                dataPoints: [
                    { label: eRegistered, y: registeredEcont?.length ?? 0 },
                    { label: eArrivedIt, y: arrivedItEcont?.length ?? 0 },
                    { label: eTraveing, y: travelingEcont?.length ?? 0 },
                    { label: eArrivedBg, y: arrivedBgEcont?.length ?? 0 },
                    { label: '<econt || tutto>', y: 0 },
                    { label: tRegistered, y: registeredTuttopost?.length ?? 0 },
                    { label: tArrivedIt, y: arrivedItTuttopost?.length ?? 0 },
                    { label: tTraveing, y: travelingTuttopost?.length ?? 0 },
                    { label: tArrivedBg, y: arrivedBgTuttopost?.length ?? 0 },
                ]
            }]
    }

    return <CanvasJSChart options={options} />
}

function getShorthandName(status: OrderStatus, deliveryCompany: string): string {
    const prefix = deliveryCompany === 'econt' ? 'e_' : 't_'
    switch (status) {
        case ORDER_STATUS.REGISTERED: return `${prefix}registered`
        case ORDER_STATUS.ARRIVED_ABROAD: return `${prefix}in_IT`
        case ORDER_STATUS.TRAVELING: return `${prefix}traveling`
        case ORDER_STATUS.ARRIVED_BG: return `${prefix}in_BG`
        default: return ''
    }
}

function getTotalWeightsArray(orders: Order[], deliveryCompany: string): number[] {
    return orders
        ?.filter((x: any) => x.deliveryCompany === deliveryCompany)
        .map((x: any) => x.totalWeight ?? 0)
}
