import { Formik } from 'formik'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { Typography } from '@mui/material'

import { i18nRestorePass } from '../i18n'
import { useTranslate } from '../../../hooks/translate'
import { restorePassword } from '../../../data/actions/usersActions'
import { RestorePasswordFormMobile } from './RestorePasswordFormMobile'

import './styles.css'

export function RestorePasswordPageMobileComponent(props: any): JSX.Element {
    const { token } = useParams()

    const t = useTranslate(i18nRestorePass)

    return (
        <div className="restore-password-container-mobile">
            <div className="restore-password-form-wrapper-mobile">
                <Typography variant="h3" className="fp-header">
                    {t.title}
                </Typography>
                <Typography variant="body1" className="fp-hint">
                    {t.subTitle}
                </Typography>
                <Formik
                    initialValues={{
                        email: '',
                        newPassword: '',
                    }}
                    onSubmit={(values: any, { setSubmitting }) => {
                        setSubmitting(true)
                        props.restorePassword({ ...values, token })
                    }}
                >
                    {(props) => <RestorePasswordFormMobile {...props} />}
                </Formik>
            </div>
        </div>
    )
}

const mapDispatchToProps = { restorePassword }

export const RestorePasswordPageMobile = connect(null, mapDispatchToProps)(RestorePasswordPageMobileComponent)
