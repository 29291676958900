import { InternalSchedule } from '../../../data/reducers/internalSchedulesReducer'
import { getFormattedDate } from '../../../utils/functions'

export const tableStruct = (heads: string[]) => [
	{
		head: heads[0],
		getData: (obj: InternalSchedule) => getFormattedDate(true, true, true, false, obj.startDate),
	},
	{
		head: heads[1],
		getData: (obj: InternalSchedule) => getFormattedDate(true, true, true, false, obj.endDate),
	},
	{
		head: heads[2],
		getData: (obj: InternalSchedule) => getFormattedDate(true, true, true, false, obj.creationDate),
	},
	{
		head: heads[3],
		getData: (obj: InternalSchedule) => obj.orders ? obj.orders.length : 0,
	},
	{
		head: heads[4],
		getData: (obj: InternalSchedule) => obj.deliveryCompany
	},
]
