import { HomeLandingMobile } from './mobile/HomeLandingMobile'
import { HomeLandingDesktop } from './desktop/HomeLandingDesktop'
import { DeviceComponents, useResponsive } from '../../../../hooks/useResponsive'

export function HomeLanding(): JSX.Element {
    const components = {
        mobile: <HomeLandingMobile />,
        smallDesktop: <HomeLandingDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}
