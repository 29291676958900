import { store } from "../store"
import { TranslateObject, TranslatePairs, TranslatedObject } from "../../utils/i18n/TranslateObject"

export function getTranslation(translateObject: TranslateObject): TranslatedObject {
	const lang = store.getState().lang.currentSelection

	const keys = Object.keys(translateObject)
	const translatedObject = {} as TranslatedObject
	for (const key of keys) {
		const pairs = translateObject[key as keyof Object] as unknown as TranslatePairs
		const translated = pairs[lang as keyof TranslatePairs]
		// @ts-ignore
		translatedObject[key as keyof TranslatedObject] = translated
	}
	return translatedObject
}

export function getSingleTranslation(translatePair: TranslatePairs): string {
	const lang = store.getState().lang.currentSelection
	return translatePair[lang as keyof TranslatePairs]
}
