import { v4 as uuid } from 'uuid'
import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { Box } from '@mui/system'
import {
    Table,
    Paper,
    TableRow,
    Accordion,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    TableContainer,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import { i18nProfileCBS } from './i18n'
import { tableStruct } from './tableUtils'
import { GlobalState } from '../../../data/store'
import { useTranslate } from '../../../hooks/translate'
import { getFormattedDate } from '../../../utils/functions'
import { getPricesSettings } from '../../../data/actions/settingsActions'
import { getCrossBorderScheduleStatusChip } from '../../../components/Shared'
import { getAllUsersActiveOrders } from '../../../data/actions/ordersActions'
import { CrossBorderSchedule } from '../../../data/reducers/crossBorderSchedulesReducer'

import './styles.css'

export function CrossBorderSchedulesComponent(props: any): JSX.Element {
    const { getAllUsersActiveOrders, getPricesSettings } = props

    const [expanded, setExpanded] = useState({} as CrossBorderSchedule)

    const { loggedIn } = useSelector((state: GlobalState) => state.users)
    const prices = useSelector((state: GlobalState) => state.settings.prices)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const { schedules } = useSelector((state: GlobalState) => state.crossBorderSchedules)
    const { orders } = useSelector((state: GlobalState) => state.orders)

    const t = useTranslate(i18nProfileCBS)
    const heads = Object.values(t).slice(1)

    useEffect(() => {
        getPricesSettings()
        getAllUsersActiveOrders()
    }, [getPricesSettings, getAllUsersActiveOrders])

    if (!schedules?.length) {
        return <></>
    }

    const handleClick = (panel: CrossBorderSchedule) => {
        if (expanded._id === panel._id) {
            setExpanded({} as CrossBorderSchedule)
        } else {
            setExpanded(panel)
        }
    }

    const tableStructure = tableStruct(heads, lang)

    function getFromTo(schedule: CrossBorderSchedule): string {
        const fromLocation = `${schedule.fromCountry}, ${schedule.fromCity}`
        const toLocation = `${schedule.toCountry}, ${schedule.toCity}`
        const fromDate = getFormattedDate(true, true, true, false, schedule.pickupDate)
        const toDate = getFormattedDate(true, true, true, false, schedule.deliveryDate)
        return `${fromLocation} (${fromDate}) => ${toLocation} (${toDate})`
    }

    const scheduleOrders = orders.filter(order => expanded.orders?.some(orderId => orderId === order._id) ?? false)

    return (
        <div>
            {schedules?.map((schedule: CrossBorderSchedule, i: number) => {
                return (
                    <Accordion key={i} expanded={expanded._id === schedule._id} onClick={() => handleClick(schedule)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Box sx={{ width: '33%', flexShrink: 0 }}>
                                {getCrossBorderScheduleStatusChip(schedule.status, lang)}
                            </Box>
                            <Typography sx={{ color: 'text.secondary' }}>{getFromTo(schedule)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {scheduleOrders && scheduleOrders.length ? (
                                <TableContainer component={Paper} className="orders-container">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {tableStructure.map((x: any) => {
                                                    return x.display(loggedIn.role) ? (
                                                        <TableCell key={uuid()} align="center">
                                                            <Typography variant="h6">{x.head}</Typography>
                                                        </TableCell>
                                                    ) : (
                                                        <></>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {scheduleOrders.map((row) => (
                                                <>
                                                    <TableRow
                                                        key={uuid()}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        {tableStructure.map((x: any) =>
                                                            x.display(loggedIn.role) ? (
                                                                <TableCell key={uuid()} align="center">
                                                                    {x.getData(row, prices)}
                                                                </TableCell>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )}
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography>{t.noOrdersMsg}</Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}

const mapDispatchToProps = {
    getAllUsersActiveOrders,
    getPricesSettings
}

export const CrossBorderSchedules = connect(null, mapDispatchToProps)(CrossBorderSchedulesComponent)
