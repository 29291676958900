import { HomeStepsMobile } from './mobile/HomeStepsMobile'
import { HomeStepsDesktop } from './desktop/HomeStepsDesktop'
import { DeviceComponents, useResponsive } from '../../../../hooks/useResponsive'

export function HomeSteps(): JSX.Element {
    const components = {
        mobile: <HomeStepsMobile />,
        smallDesktop: <HomeStepsDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}
