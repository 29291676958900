import { AnyAction, createReducer } from '@reduxjs/toolkit'

import { ActionTypes } from '../actionTypes'

interface IEcontOfficeAddressCityCountry {
    code2: string
    code3: string
    id: number
    isEU: boolean
    name: string
    nameEn: string
}

interface IEcontOfficeAddressCity {
    country: IEcontOfficeAddressCityCountry
    name: string
    nameEn: string
    postCode: string
}

interface IEcontOfficeAddress {
    city: IEcontOfficeAddressCity
    fullAddress: string
    fullAddressEn: string
}

export interface IEcontOffice {
    address: IEcontOfficeAddress
    id: number
    name: string
    nameEn: string
    phones: string[]
}

export interface IEcontOfficeSimple {
    id: number
    fullAddress: string
    fullAddressEn: string
    phones: string[]
    name: string
    nameEn: string
}

export interface EcontState {
    offices: IEcontOffice[]
    simplified: IEcontOfficeSimple[]
}

const initialState: EcontState = {
    offices: [],
    simplified: []
}

export const econtReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(ActionTypes.econt.getOffices.fulfilled(), (state: EcontState, action: AnyAction) => {
            const simplified = simplify(action.payload)

            state.offices = action.payload
            state.simplified = simplified
        })
})

function simplify(offices: IEcontOffice[]): IEcontOfficeSimple[] {
    return offices.map((office) => {
        const { address, id, phones, name, nameEn } = office
        const { fullAddress, fullAddressEn } = address
        return { fullAddress, fullAddressEn, id, phones, name, nameEn }
    })
}
