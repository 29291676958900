import { useEffect } from 'react'
import { connect } from 'react-redux'

import { AdminPanelMobile } from './mobile/AdminPanelMobile'
import { AdminPanelDesktop } from './desktop/AdminPanelDesktop'
import { getEcontOffices } from '../../data/actions/econtActions'
import { getDefaultsSettings } from '../../data/actions/settingsActions'
import { DeviceComponents, useResponsive } from '../../hooks/useResponsive'

import './styles.css'

export const AdminTabs = {
	USERS: 0,
	ORDERS: 1,
	INTERNAL_SCHEDULES: 2,
	CROSS_BORDER_SCHEDULES: 3,
	SETTINGS: 4,
	STATISTICS: 5,
	ARCHIVE: 6,
}

export function AdminPanelPageComponent(props: any) {
	useEffect(() => {
		props.getEcontOffices()
		props.getDefaultsSettings()
	}, [props])

	const components = {
		mobile: <AdminPanelMobile />,
		smallDesktop: <AdminPanelDesktop />
	} as DeviceComponents

	return useResponsive(components)
}

const mapDispatchToProps = { getEcontOffices, getDefaultsSettings }

export const AdminPanelPage = connect(null, mapDispatchToProps)(AdminPanelPageComponent)
