export const i18nPricing = {
	title: {
		bg: 'Цени',
		en: 'Prices'
	},
	priceKg: {
		bg: 'Цена за доставяне от Италия до България на всеки започнат килограм',
		en: 'Delivery price from Italy to Bulgaria for each started kilogram'
	},
	priceProcess: {
		bg: 'Такса обработка за всяка отделна пратка',
		en: 'Process fee for each separate order'
	},
	priceDeliverySofia: {
		bg: 'Цена доставка на пратки с наш куриер (само за гр. София)',
		en: 'Our internal delivery price (only for Sofia)'
	},
	priceUnregisteredOrders: {
		bg: 'Глоба за нерегистрирани пратки',
		en: 'Unregistered orders fee'
	},
	vatText: {
		bg: 'Всички посочени цени са с включен ДДС',
		en: 'All prices are with included Bulgarian VAT'
	}
}
