export const i18nSchedules = {
	title: {
		bg: 'График транспорт',
		en: 'Transport schedules'
	},
	subText: {
		bg: 'В момента няма обявен график за транспорт',
		en: 'There are no announced schedules at this time'
	},
	// BEGIN: table heads
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	departsFrom: {
		bg: 'Тръгва от',
		en: 'Departs from'
	},
	arriveIn: {
		bg: 'Пристига в',
		en: 'Arrives in'
	},
	departDate: {
		bg: 'Дата тръгва',
		en: 'Depart date'
	},
	arriveDate: {
		bg: 'Дата пристига',
		en: 'Arrive date'
	}
	// END: table heads
}
