export const i18nProfile = {
	info: {
		bg: 'Инфо',
		en: 'Info'
	},
	activeOrders: {
		bg: 'Активни пратки',
		en: 'Active packages'
	},
	ordersHistory: {
		bg: 'История пратки',
		en: 'Packages history'
	},
	internalSchedules: {
		bg: 'Вътрешни графици',
		en: 'Internal schedules'
	},
	crossBorderSchedules: {
		bg: 'Международни графици',
		en: 'Cross border schedules'
	}
}
