import { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Paper, Typography } from '@mui/material'
import { connect, useSelector } from 'react-redux'

import { i18nPricing } from './i18n'
import { GlobalState } from '../../data/store'
import { texts } from '../../utils/i18n/texts'
import { easeTimes } from '../../utils/easeTimes'
import { getPricesSettings } from '../../data/actions/settingsActions'
import { useTranslate, useTranslateSingle } from '../../hooks/translate'

import './styles.css'
import { DeviceStrings, useResponsiveString } from '../../hooks/useResponsiveString'

export function PricingPageComponent(props: any): JSX.Element {
    const { getPricesSettings } = props

    const { prices } = useSelector((state: GlobalState) => state.settings)

    const t = useTranslate(i18nPricing)
    const denomination = useTranslateSingle(texts.currency)

    useEffect(() => {
        getPricesSettings()
    }, [getPricesSettings])

    const classNames = {
        smallDesktop: 'pricing-container',
        mobile: 'pricing-container-mobile'
    } as DeviceStrings

    const containerClassName = useResponsiveString(classNames)

    return (
        <div className={containerClassName}>
            <Typography className="pricing-header" variant="h4" align="center">
                {t.title}
            </Typography>
            <Paper elevation={5} className="prices-info">
                <Fade cascade damping={0.3} triggerOnce duration={easeTimes.slow} direction="up">
                    <Typography className='price-info'>
                        {t.priceKg}:{' '}
                        <strong>
                            {Number(prices?.pricePerKg ?? 0).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography className='price-info'>
                        {t.priceProcess}:{' '}
                        <strong>
                            {Number(prices?.processPricePerPackage ?? 0).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography className='price-info'>
                        {t.priceDeliverySofia}:{' '}
                        <strong>
                            {Number(prices?.priceDeliverySofia ?? 0).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography className='price-info'>
                        {t.priceUnregisteredOrders}:{' '}
                        <strong>
                            {Number(prices?.unregisteredOrdersPenaltyFee ?? 0).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography align="center" marginTop={3} sx={{ fontStyle: 'italic' }}>
                        {t.vatText}
                    </Typography>
                </Fade>
            </Paper>
        </div>
    )
}

const mapDispatchToProps = { getPricesSettings }

export const PricingPage = connect(null, mapDispatchToProps)(PricingPageComponent)
