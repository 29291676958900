import { useEffect, useState } from 'react'

export type Device = 'm' | 't' | 'sd' | 'md' | 'bd'

export interface Devices {
    mobile: number
    tablet: number
    smallDesktop: number
    mediumDesktop: number
    bigDesktop: number
}

const defaultDevices = {
    mobile: 800,
    tablet: 1024,
    smallDesktop: 1280,
    mediumDesktop: 1600,
    bigDesktop: 1920,
}

export function useDevice(devices: Devices = defaultDevices) {
    const [size, setSize] = useState(window.innerWidth)

    const handleResize = () => setSize(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return recognizeDevice(size, devices)
}

function recognizeDevice(size: number, sizes: Devices): Device {
    if (size < sizes.mobile) return 'm'
    if (size < sizes.tablet) return 't'
    if (size < sizes.smallDesktop) return 'sd'
    if (size < sizes.mediumDesktop) return 'md'
    if (size < sizes.bigDesktop) return 'bd'

    return 'bd' // bigger than bigDesktop size
}
