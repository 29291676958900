export const texts = {
	companyName: {
		bg: 'Туто Пост ООД',
		en: 'Tutto Post LTD'
	},
	phoneBG: '+359 879 633 966',
	// phoneBG2: '+359 877 369 666',
	phoneIT: '+39 324 777 5035',
	customsPhone: '+359 877 369 666',
	italyCustomsPhone: '+39 0671304014',
	email: 'info@tuttopost.com',
	itAddress: 'Via Cancelliera 55, c/o Fasalog Holding s.r.l. - TPT, 00072 Ariccia (RM)',
	itAddress1Part: 'Via Cancelliera 55',
	itAddress2Part: 'c/o Fasalog Holding s.r.l. - TPT',
	itAddress3Part: '00072 Ariccia (RM)',
	bgAddress: {
		bg: '1000 гр. София, р-н Триадица, ул. Княз Борис I, 145, вх. А',
		en: '1000 Sofia, Triaditsa district, Knyaz Boris I str. 145, ent. A'
	},
	currency: {
		bg: 'лв.',
		en: ' BGN'
	},
	kgs: {
		bg: 'кг',
		en: 'kg'
	},
	cm: {
		bg: 'см',
		en: 'cm'
	}
}
