export const i18nHIW = {
	title: {
		bg: 'Как работи',
		en: 'How it works'
	},
	yourIdNumber: {
		bg: 'Твоят уникален ID номер',
		en: 'Your uniqe ID number'
	},
	step1Title: {
		bg: 'Влез / Регистрирай се',
		en: 'Login / Register'
	},
	step1Text1: {
		bg: 'Направи си регистрация в нашия сайт. След това системата ще ти изпрати код за потвърждение на имейла. Ако до 1 минута не получиш имейл се свържи с нас.',
		en: 'Create your profile on our platform. The system will send you a confirmation code to the given email. If you dont receive it - contact us so we can make sure it is sent to you.'
	},
	step2Title: {
		bg: 'Пазарувай онлайн от Италия',
		en: 'Shop online in Italy'
	},
	step2Text1: {
		bg: 'Попълни по следния начин данни си в регистрационната форма на интернет магазина, от който желаеш да пазаруваш:',
		en: 'Fill your personal data in the forms of the online store where you want to shop from:'
	},
	step2Text2: {
		bg: 'Nome: Твоето име и фамилия на латиница!',
		en: 'Nome: Your name and surname'
	},
	step2Text3: {
		bg: 'Имената на латиница при регистрация в online магазини/сайтове и ЗАДЪЛЖИТЕЛНО изписвай ID номера си след "TPT:"',
		en: 'It is necessary to fill in your unique ID number at the end of the address, right after "TPT:"'
	},
	step3Title: {
		bg: 'Регистрирай пратка',
		en: 'Register package'
	},
	step3Text1: {
		bg: 'Ако имаш съществуваща регистрация в eBay (co.uk, fr, com и др.) или amazon (co.uk, fr, com и др.) не е нужно да се регистрираш повторно, добави италианския адрес в адресната си книга по следния начин:',
		en: 'If you have existing registration in eBay (co.uk, fr, com, etc.) or amazon (co.uk, fr, com, etc.) you just need to fill the Italian address in the list of your addresses like so:'
	},
	step3InputLabel1: {
		bg: 'Име и фамилия на латиница',
		en: 'Name and surname'
	},
	step3Warning: {
		bg: 'ВНИМАНИЕ: ТутоПост ООД НЕ НОСИ ОТГОВОРНОСТ за нерегистрирани изгубени пратки!',
		en: 'WARNING: TuttoPostLtd IS NOT RESPONSIBLE for unregistered lost packages!'
	},
	step4Title: {
		bg: 'Получаване на пратката',
		en: 'Receive the package'
	},
	step4Text1: {
		bg: 'Повечето търговци в Италия използват за вътрешната доставка до Рим куриерски фирми и може да изисквате номер на вътрешната товарителница, ако той не бъде предоставен веднага от изпращача. С този номер може да следите кога пратката ще бъде доставена в нашия склад.',
		en: 'Most of the retailers in Italy are using third party couriers for delivering their goods to Rome and you can request the tracking number of your delivery. You can use this number to track if your goods was delivered in our warehouse in Italy.'
	},
	step4Text2: {
		bg: 'Пратката отпътува със следващия възможен курс, който можеш да следиш на нашата страница с',
		en: 'Your order will be loaded on the next possible schedule to Bulgaria. Schedules can be tracked on our platform at'
	},
	step4ButtonLabel: {
		bg: 'График',
		en: 'Schedule'
	},
	step4Text3: {
		bg: 'Ако не получиш известие от нас, а си сигурен че пратката е доставена в Рим (това се случва ако пратката няма име или ID) моля изискай от продавача доказателство за доставка. Препрати го на имейла ни',
		en: 'If you dont receive a notification from us and you are sure your order was delivered in Rome (this may happen when the order doesnt have Names or ID) please request delivery evidence from the retailer and send it to us.'
	},
	step4Text4: {
		bg: 'В случай, че си изпратил пратка към нашият адрес, но не си я регистрирал, не можем да транспортираме пратката до България, тъй като камионите пътуват през Сърбия, която е държава извън ЕС и се изпълняват всички митнически формалности за транспорт.',
		en: 'In case you have sent an order to our address, but you have not registered it, we cant transport it to Bulgaria, since our trucks most oftenly travel through Serbia, which is a non-EU country, and we should apply to all customs transport regulations.'
	},
	step4Text5: {
		bg: 'За по-лесно разпознаване на пратките моля описвай съдаржанието им на български език.',
		en: 'For easier recognition of your order please provide relative description.'
	},
	step4Text6: {
		bg: 'Когато разбереш в последствие, че пратката ти е разделена на повече от една част от изпращача, трябва своевременно да регистрираш останалите части от пратката като нови в нашата платформа.',
		en: 'If you get notified by the retailer that your package was separated on more than one part, you should register the other parts as separate pacakges in our platform as well.'
	},
	step4Text7: {
		bg: 'Когато чакаш пратки от различни продавачи НЕ ГИ регистрирай като една пратка. Регистрирай всяка една поотделно.',
		en: 'If you place several orders from different retailers DO NOT register them as one order in our platform. Register each one separately.'
	},
	step4Text8: {
		bg: 'Адреса в Италия не може да се използва за нищо друго освен за онлайн пазаруване от магазини/сайтове на стоки които са 100% заплатени или за превоз на личен багаж.',
		en: 'Our Italian address can be used ONLY for online shopping from stores/sites of goods that are 100% paid beofre their arrival in our address.'
	},
	step: {
		bg: 'Стъпка',
		en: 'Step'
	}
}
