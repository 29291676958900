import { endpoints } from './endpoints'
import { apiService } from './apiService'

const domain = endpoints.users

export const usersService = {
	login: (userData: Object) => apiService.post(domain.login, userData),
	register: (userData: any) => apiService.post(domain.register, userData),
	registerSpecific: (userData: Object) => apiService.post(domain.registerSpecific, userData),
	updateUserRole: (userData: Object) => apiService.post(domain.updateRole, userData),
	updateUserProfile: (userData: Object) => apiService.post(domain.updateProfile, userData),
	sendForgetPasswordRequest: (email: string) => apiService.post(domain.forgetPasswordRequest, { email }),
	restorePassword: (userData: Object) => apiService.post(domain.restorePassword, userData),
	verifyRegistration: (userData: Object) => apiService.post(domain.verifyRegistration, userData),
	resendVerificationCode: (email: string) => apiService.post(domain.resendVerificationCode, { email }),
	sendVerificationSupportRequest: (email: string) => apiService.post(domain.sendVerificationSupportRequest, { email }),
	getAllUsers: () => apiService.get(domain.getAll),
	deleteUser: (data: any) => apiService.delete(domain.delete, data),
	sendEmailRequest: (data: any) => apiService.post(domain.emailRequest, data)
}
