import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Paper,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Typography,
    IconButton,
    Pagination,
    TableContainer,
} from '@mui/material'

import { i18nProfileCO } from '../i18n'
import { tableStruct } from './tableUtils'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import { orderPredicate } from '../../../../utils/functions'
import { getOrderByHeadIcon } from '../../../../components/Shared'
import { DeviceStrings, useResponsiveString } from '../../../../hooks/useResponsiveString'

import './styles.css'

export function CompletedOrdersTable(): JSX.Element {
    const [pageIndex, setPageIndex] = useState(1)
    // eslint-disable-next-line
    const [itemsPerPage, setItemsPerPage] = useState(25)

    const [orderBy, setOrderBy] = useState('')
    const [ascending, setAscending] = useState(true)

    const { orders } = useSelector((state: GlobalState) => state.orders)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const t = useTranslate(i18nProfileCO)
    const heads = Object.values(t).slice(1)

    const handleSetOrderBy = (propName: string) => {
        if (orderBy === propName) {
            setAscending((oldValue) => !oldValue)
        } else {
            setAscending(true)
            setOrderBy(propName)
        }
    }

    const handleChangePage = (ev: any, pageIndex: any) => setPageIndex(pageIndex)

    const tableStructure = tableStruct(heads, lang)

    const orderByPredicate = orderPredicate(orderBy, ascending)
    const orderedOrders = orders ? orders.slice().sort(orderByPredicate) : []

    const elementStart = (pageIndex - 1) * itemsPerPage
    const paginatedOrders = orderedOrders.slice(elementStart, elementStart + itemsPerPage)

    const pagesCount = Math.ceil(orderedOrders.length / itemsPerPage)

    const boundaryCountStrings = { mobile: '0', smallDesktop: '1' } as DeviceStrings
    const siblingsCountStrings = { mobile: '0', smallDesktop: '1' } as DeviceStrings

    const boundary = +useResponsiveString(boundaryCountStrings)
    const siblings = +useResponsiveString(siblingsCountStrings)

    return (
        !orderedOrders.length ?
            <Typography variant='h4'>{t.noCompletedOrders}</Typography>
            :
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableStructure.map((x: any, i: number) => {
                                    return (
                                        <TableCell key={i} align="center">
                                            <Typography variant="h6">
                                                {x.sortable ? (
                                                    <IconButton onClick={() => handleSetOrderBy(x.name)}>
                                                        {getOrderByHeadIcon(x, orderBy, ascending)}
                                                    </IconButton>
                                                ) : (
                                                    false
                                                )}{' '}
                                                {x.head}
                                            </Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedOrders.map((row, i: number) => (
                                <TableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    {tableStructure.map((x: any, ii: number) => (
                                        <TableCell key={ii} align="center">
                                            {x.getData(row)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='profile-orders-pagination-wrapper'>
                    <Pagination
                        size='large'
                        shape="rounded"
                        page={pageIndex}
                        count={pagesCount}
                        variant="outlined"
                        siblingCount={siblings}
                        boundaryCount={boundary}
                        onChange={handleChangePage}
                    />
                </div>
            </>
    )
}
