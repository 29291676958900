import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Tab, Tabs } from '@mui/material'

import { TABS } from '../TABS'
import { i18nProfile } from '../i18n'
import { ROLES } from '../../../utils/roles'
import { GlobalState } from '../../../data/store'
import { useTranslate } from '../../../hooks/translate'
import { ActiveOrders } from '../activeOrders/ActiveOrders'
import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { CompletedOrders } from '../completedOrders/CompletedOrders'
import { ProfileInformation } from '../information/ProfileInformation'
import { InternalSchedules } from '../internalSchedules/InternalSchedules'
import { CrossBorderSchedules } from '../crossBorderSchedules/CrossBorderSchedules'

export function ProfilePageDesktop(): JSX.Element {
    const [tab, setTab] = useState(TABS.information)

    const { loggedIn } = useSelector((state: GlobalState) => state.users)

    const isInternalDriver = [ROLES.LOCAL_DRIVER, ROLES.ADMIN, ROLES.MODERATOR].includes(loggedIn.role)
    const isCrossBorderDriver = [ROLES.CROSS_BORDER_DRIVER, ROLES.ADMIN, ROLES.MODERATOR].includes(loggedIn.role)

    const handleChange = (ev: any, value: any) => setTab(value)

    const t = useTranslate(i18nProfile)

    const getTabs = () => {
        if ([ROLES.ADMIN, ROLES.MODERATOR].includes(loggedIn.role)) {
            return (
                <>
                    <TabPanel value={tab} index={3}>
                        <InternalSchedules />
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <CrossBorderSchedules />
                    </TabPanel>
                </>
            )
        }

        if (isInternalDriver) {
            return (
                <TabPanel value={tab} index={3}>
                    <InternalSchedules />
                </TabPanel>
            )
        }

        if (isCrossBorderDriver) {
            return (
                <TabPanel value={tab} index={3}>
                    <CrossBorderSchedules />
                </TabPanel>
            )
        }
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label={t.info} id="profile-tab-0" />
                    <Tab label={t.activeOrders} id="profile-tab-1" />
                    <Tab label={t.ordersHistory} id="profile-tab-2" />
                    {isInternalDriver && <Tab label={t.internalSchedules} id="profile-tab-3" />}
                    {isCrossBorderDriver && <Tab label={t.crossBorderSchedules} id="profile-tab-4" />}
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <ProfileInformation />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ActiveOrders />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <CompletedOrders />
            </TabPanel>
            {getTabs()}
        </>
    )
}
