import { useSelector } from "react-redux"
import { CancelOutlined } from "@mui/icons-material"
import { Button, IconButton, Modal, Paper, Typography } from "@mui/material"

import { i18nISCardDetailsModal } from "./i18n"
import { GlobalState } from "../../../../../data/store"
import { useTranslate } from "../../../../../hooks/translate"
import { IPackage, Order } from "../../../../../data/reducers/ordersReducer"
import { calculatePackagesTotalWeight } from "../../../../../utils/functions"

import './styles.css'

export function InternalScheduleCardDetailsModal(props: any) {
    const { handleClose } = props
    const order = props.order as Order

    const t = useTranslate(i18nISCardDetailsModal)
    const { prices } = useSelector((state: GlobalState) => state.settings)
    const {
        pricePerKg,
        priceDeliverySofia,
        processPricePerPackage,
        unregisteredOrdersPenaltyFee
    } = prices

    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const kg = lang === 'en' ? 'kg' : 'кг'
    const count = lang === 'en' ? 'pcs' : 'бр'
    const currency = lang === 'en' ? 'BGN' : 'лв'

    const isSofiaDelivery = order.deliveryCompany === 'tuttopost'
    // @ts-ignore
    const priceDelivery = isSofiaDelivery ? priceDeliverySofia : 0
    const pricePenalty = order.unregistered ? unregisteredOrdersPenaltyFee : 0

    function getFormula(packages: Array<IPackage>): string {
        const hasMass = packages.some(pckg => {
            // @ts-ignore
            const [w, h, d] = pckg.sizes.split('x')
            return +w > 59 || +h > 59 || +d > 59
        })

        if (hasMass) {
            const massMsg = {
                bg: 'Съдържа масивни пратки',
                en: 'Contains massive packages'
            }

            // @ts-ignore
            return massMsg[lang]
        }

        const totalKgs = calculatePackagesTotalWeight(packages || [])

        const formula = `
            (${totalKgs}${kg}*${pricePerKg}${currency})
            +
            (${packages.length}${count}*${processPricePerPackage}${currency})
            ${priceDelivery > 0 ? `+ ${priceDelivery}${currency}` : ''}
            ${pricePenalty > 0 ? `+ ${pricePenalty}${currency}` : ''}
        `

        return formula
    }

    const formula = getFormula(order.packages)

    return (
        <Modal open={true} onClose={handleClose}>
            <div className='internal-schedule-order-details-modal-wrapper'>
                <Paper className='internal-schedule-order-details-modal-container' elevation={3}>
                    <div className='top-section'>
                        <Typography className='order-receiver' variant='h5'>{order.receiver}</Typography>
                        <IconButton className='close-modal-icon' size='large' onClick={handleClose}>
                            <CancelOutlined fontSize='large' />
                        </IconButton>
                    </div>
                    <div className='mid-section'>
                        <Typography>{t.registerDate}: <strong>{order.registerDate ?? 'N/A'}</strong></Typography>
                        <Typography>{t.siteOrigin}: <strong>{order.siteOrigin ?? 'N/A'}</strong></Typography>
                        <Typography>{t.description}: <strong>{order.description ?? 'N/A'}</strong></Typography>
                        <Typography>{t.valueEur}: <strong>{order.valueEur ?? 'N/A'}</strong></Typography>
                        <Typography>{t.itDeliveryCompany}: <strong>{order.itDeliveryCompany ?? 'N/A'}</strong></Typography>
                        <Typography>{t.itTrackingNumber}: <strong>{order.trackingNumber ?? 'N/A'}</strong></Typography>
                        <div className='price-details'>
                            <Typography>{t.priceDetails}:</Typography>
                            <Typography color={pricePenalty ? 'error' : ''}><strong>{formula}</strong></Typography>
                        </div>
                    </div>
                    <div className='bot-section'>
                        <Button color='error' variant='contained' fullWidth onClick={handleClose}>{t.closeBtn}</Button>
                    </div>
                </Paper>
            </div>
        </Modal >
    )
}

