import { Typography } from '@mui/material'

import { i18nLanding } from '../i18n'
import { useTranslate } from '../../../../../hooks/translate'

import './styles.css'

export function HomeLandingMobile(): JSX.Element {
    const t = useTranslate(i18nLanding)

    return (
        <section className="landing-mobile">
            <div className="text-content">
                <Typography variant="h6" className="top-label">
                    {t.topLabel}
                </Typography>
                <Typography variant="h4" className="middle-label">
                    {t.midLabel}
                </Typography>
                <Typography variant="h6" className="bottom-label">
                    {t.botLabel}
                </Typography>
            </div>
        </section>
    )
}
