import { Typography } from '@mui/material'

import { i18nForbiddenItems } from './i18n'
import { useTranslate } from '../../hooks/translate'

import './styles.css'

export function ForbiddenItemsPage(): JSX.Element {
    const t = useTranslate(i18nForbiddenItems)
    const texts = Object.values(t).slice(1)

    return (
        <div className="fobidden-items-container">
            <Typography className="fobidden-items-header" variant="h4" align="center">
                {t.title}
            </Typography>
            <ul className="forbidden-list">
                {texts.map((text: string, i: number) => {
                    return (
                        <li key={i} className="forbidden-list-item">
                            {text}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
