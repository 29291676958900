import { useSelector } from "react-redux"
import { Paper, Typography } from "@mui/material"

import { GlobalState } from "../../../../data/store"
import { getOrderStatusChip } from "../../../../components/Shared"
import { ORDER_STATUS, Order } from "../../../../data/reducers/ordersReducer"
import {
    calculatePackagesTotalWeight,
    calculateTransportationPrice,
    mapPackagesStringSizesToPackagesObjectsSizes
} from "../../../../utils/functions"

export function ActiveOrdersMobileCard(props: any) {
    const order = props.order as Order

    const priceSettings = useSelector((state: GlobalState) => state.settings.prices)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const isCompleted = order?.status === ORDER_STATUS.COMPLETED

    const kg = lang === 'en' ? 'kg' : 'кг'
    const count = lang === 'en' ? 'pcs' : 'бр'
    const currency = lang === 'en' ? 'BGN' : 'лв'

    const totalWeight = calculatePackagesTotalWeight(order?.packages || [])
    const isSofiaDelivery = order?.deliveryCompany === 'tuttopost'
    const pckgs = order ? mapPackagesStringSizesToPackagesObjectsSizes(order.packages ?? []) : []
    const price = calculateTransportationPrice(pckgs, priceSettings, isSofiaDelivery, order?.unregistered)

    return (
        <Paper className={`active-order-card ${isCompleted ? 'completed' : ''}`} elevation={3}>
            {getOrderStatusChip(order.status, lang)}
            <br />
            <br />
            <div className='base-info-wrapper'>
                <Typography variant='h5'>{order?.siteOrigin ?? 'N/A'}</Typography>
                <Typography variant='h6'>{order?.valueEur ?? '0'} EUR</Typography>
            </div>
            <br />
            <Typography variant='h5'>{order?.description}</Typography>
            <Typography variant='h6'>{order?.receiverPhone}</Typography>
            {
                <Typography className='delivery-address'>
                    {
                        order?.deliverTo === 'office'
                            // @ts-ignore
                            ? order?.econtOfficeDeliveryAddress?.[lang] || ''
                            : order?.deliveryAddress
                    }
                </Typography>
            }
            <br />
            <Typography>{order?.comment}</Typography>
            <Typography variant='h5'>{`${totalWeight} ${kg}. | ${order?.packages?.length} ${count}.`}</Typography>
            <Typography variant='h4'>{`${price} ${currency}.`}</Typography>
        </Paper>
    )
}