//@ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../../data/store'
import { User } from '../../../../data/reducers/usersReducer'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function RegisteredUsersGraphic() {
	const { users } = useSelector((state: GlobalState) => state.users)

	const usersData = mapUsers(users)
	const dataPoints = usersData.map(usersByMonth => ({
		x: usersByMonth.date,
		y: usersByMonth.users.length
	}))

	const options = {
		theme: "light2",
		animationEnabled: true,
		title: {
			text: "Registered Users"
		},
		data: [{
			type: "spline",
			name: "Count",
			showInLegend: true,
			xValueFormatString: "MMM YYYY",
			yValueFormatString: "#,##0",
			dataPoints
		}]
	}

	return <CanvasJSChart options={options} />
}

function mapUsers(users: User[]) {
	const yearAgo = new Date()
	yearAgo.setFullYear(yearAgo.getFullYear() - 1)
	yearAgo.setHours(0, 0, 0, 0)

	const currentDate = new Date()
	currentDate.setMonth(currentDate.getMonth() - 1)

	const registeredUsersByMonth = []
	while (
		yearAgo.getTime() <= currentDate.getTime()
	) {
		yearAgo.setMonth(yearAgo.getMonth() + 1)
		const fromDateEpoch = yearAgo.getTime()

		const toDate = new Date(yearAgo)
		toDate.setMonth(toDate.getMonth() + 1)
		const toDateEpoch = toDate.getTime()

		const usersForCurrentMonth = users.filter(user => (
			user.registerDateEpoch >= fromDateEpoch && 
			user.registerDateEpoch <= toDateEpoch
		))

		registeredUsersByMonth.push({
			date: new Date(yearAgo),
			month: yearAgo.getMonth(),
			users: usersForCurrentMonth
		})
	}

	registeredUsersByMonth.pop()

	return registeredUsersByMonth
}