import { apiService } from "./apiService";
import { endpoints } from "./endpoints";

const domain = endpoints.settings

export const settingsService = {
	getPrices: () => apiService.get(domain.prices),
	updatePrices: (data: Object) => apiService.post(domain.prices, data),
	getDefaults: () => apiService.get(domain.defaults),
	updateDefaults: (data: Object) => apiService.post(domain.defaults, data)
}
