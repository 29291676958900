import { Formik } from 'formik'
import { Typography } from '@mui/material'
import { connect, useSelector } from 'react-redux'

import { i18nRegOrder } from '../i18n'
import { GlobalState } from '../../../data/store'
import { useTranslateSingle } from '../../../hooks/translate'
import { registerOrder } from '../../../data/actions/ordersActions'
import { RegisterOrderFormDesktop } from './RegisterOrderFormDesktop'
import { getRegisterOrderFormInitialValues, handleSubmitForm, handleValidateForm } from '../formUtils'

import './styles.css'

function RegisterOrderDesktopComponent(props: any): JSX.Element {
    const user = useSelector((state: GlobalState) => state.users.loggedIn)
    const { _id, role } = user

    const { offices } = useSelector((state: GlobalState) => state.econt)

    const title = useTranslateSingle(i18nRegOrder.title)

    const initialValues = getRegisterOrderFormInitialValues(user, offices)

    return (
        <div className="register-order-container">
            <Typography variant="h4" className="register-order-header">
                {title}
            </Typography>
            <Formik
                initialValues={initialValues}
                validate={(values) => handleValidateForm(values)}
                onSubmit={async (values: any, { setSubmitting, resetForm }) => {
                    handleSubmitForm(values, setSubmitting, resetForm, props.registerOrder, _id, role)
                }}
            >
                {(props) => <RegisterOrderFormDesktop {...props} />}
            </Formik>
        </div>
    )
}

const mapDispatchToProps = { registerOrder }

export const RegisterOrderDesktop = connect(null, mapDispatchToProps)(RegisterOrderDesktopComponent)
