import { DeviceComponents, useResponsive } from '../../hooks/useResponsive'
import { RestorePasswordPageMobile } from './mobile/RestorePasswordPageMobile'
import { RestorePasswordPageDesktop } from './desktop/RestorePasswordPageDesktop'

export function RestorePasswordPage(): JSX.Element {
	const components = {
		mobile: <RestorePasswordPageMobile />,
		smallDesktop: <RestorePasswordPageDesktop />
	} as DeviceComponents

	return useResponsive(components)
}
