import { ContactsPageMobile } from './mobile/ContactsPageMobile'
import { ContactsPageDesktop } from './desktop/ContactsPageDesktop'
import { DeviceComponents, useResponsive } from '../../hooks/useResponsive'

export function ContactsPage() {
    const components = {
        mobile: <ContactsPageMobile />,
        smallDesktop: <ContactsPageDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}
