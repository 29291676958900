import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { i18nRegOrderBanner } from '../i18n'
import { useTranslate } from '../../../hooks/translate'

import './styles.css'

export function RegisterOrderBannerMobile(): JSX.Element {
    const t = useTranslate(i18nRegOrderBanner)

    return (
        <section className="register-order-banner-mobile">
            <div className="text-content">
                <Typography variant="h6" className="order-question-label">
                    {t.question}
                </Typography>
                <Typography variant="h5" className="register-order-label">
                    {t.action}
                </Typography>
                <Link to="/register-package">
                    <Button className="register-order-button" size="large" variant="contained" color="secondary">
                        {t.buttonLabel}
                    </Button>
                </Link>
            </div>
        </section>
    )
}
