import { Order } from '../../../data/reducers/ordersReducer'
import { getFormattedDate } from '../../../utils/functions'

export const tableStruct = (heads: string[], lang: string) => [
    {
        head: heads[0],
        getData: (obj: Order) => obj.receiver,
        sortable: true,
        name: 'receiver',
    },
    {
        head: heads[1],
        getData: (obj: Order) => obj.receiverPhone,
        sortable: false,
    },
    {
        head: heads[2],
        getData: (obj: Order) => obj.email,
        sortable: true,
        name: 'email'
    },
    {
        head: heads[3],
        getData: (obj: Order) => obj.deliveryAddress,
        sortable: false,
    },
	{
        head: heads[4],
        getData: (obj: Order) => obj.registerDate,
        sortable: true,
        name: 'registerDateEpoch'
    },
	{
        head: heads[5],
        getData: (obj: Order) => obj.orderId,
        sortable: true,
        name: 'orderId'
    },
    {
        head: heads[6],
        getData: (obj: Order) => obj.deliveryCompany,
        sortable: true,
        name: 'deliveryCompany'
    },
    {
        head: heads[7],
        getData: (obj: Order) => obj.siteOrigin,
        sortable: true,
        name: 'siteOrigin'
    },
    {
        head: heads[8],
        getData: (obj: Order) => obj.valueEur,
        sortable: true,
        name: 'valueEur'
    },
]

export const getCompletedOrdersDataGridColumns = (t: any, lang: string) => {
    return [
        {
            field: 'receiver',
            headerName: t.receiver,
            type: 'string',
            width: 250,
        },
        {
            field: 'receiverPhone',
            headerName: t.phone,
            type: 'string',
            width: 130,
        },
        {
            field: 'deliveryAddress',
            headerName: t.address,
            type: 'string',
            width: 300,
        },
        {
            field: 'packages',
            headerName: t.count,
            width: 80,
            align: 'center',
            sortable: false,
            valueGetter: (value: any, row: Order) => `${row.packages.length ?? 0}`,
        },
        {
            field: 'registerDateEpoch',
            headerName: t.registerDate,
            type: 'number',
            width: 150,
            renderCell: (params: any) => getFormattedDate(true, true, true, true, params.value)
        },
        {
            field: 'orderId',
            headerName: t.orderId,
            type: 'number',
            width: 110,
        },
        {
            field: 'deliveryCompany',
            headerName: t.deliveryCompany,
            type: 'string',
            width: 100,
            editable: false,
        },
        {
            field: 'siteOrigin',
            headerName: t.siteOrigin,
            type: 'string',
            width: 100,
            editable: false,
        },
        {
            field: 'valueEur',
            headerName: t.valueEur,
            type: 'string',
            width: 100,
            editable: false
        },
    ]
}
