import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { connect, useSelector } from 'react-redux'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

import { i18nSchedules } from './i18n'
import { tableStruct } from './tableUtils'
import { GlobalState } from '../../data/store'
import { useTranslate } from '../../hooks/translate'
import { getAllCrossBorderSchedules } from '../../data/actions/crossBorderSchedulesActions'

import './styles.css'

export function SchedulesPageComponent(props: any) {
    const { getAllCrossBorderSchedules } = props

    const { schedules } = useSelector((state: GlobalState) => state.crossBorderSchedules)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const t = useTranslate(i18nSchedules)
    const heads = Object.values(t).slice(2)

    useEffect(() => {
        getAllCrossBorderSchedules()
    }, [getAllCrossBorderSchedules])

    if (!schedules?.length) {
        return <div style={{ margin: '20px' }}>Loading...</div>
    }

    const tableStructure = tableStruct(heads, lang)

    const _2monthsAgoDate = new Date()
    _2monthsAgoDate.setMonth(_2monthsAgoDate.getMonth() - 2)

    const _2moAgo = schedules.filter(schedule => schedule.deliveryDate > _2monthsAgoDate.getTime())
    const sorted = _2moAgo?.slice().sort((a, b) => b.creationDate - a.creationDate) ?? []

    return (
        <div className="schedules-container">
            <Typography variant="h4" align="center" className="schedules-header">
                {t.title}
            </Typography>
            {sorted && sorted.length ? (
                <TableContainer className="schedules-table-container" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableStructure.map((x: any, i: number) => {
                                    return (
                                        <TableCell key={i} align="center">
                                            <Typography variant="h6">{x.head}</Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sorted.map((row: any, i: number) => (
                                <TableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    {tableStructure.map((x: any) => (
                                        <TableCell key={uuid()} align="center">
                                            {x.getData(row)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h5" align="center" className="schedules-header">
                    {t.subText}
                </Typography>
            )}
        </div>
    )
}

const mapDispatchToProps = { getAllCrossBorderSchedules }

export const SchedulesPage = connect(null, mapDispatchToProps)(SchedulesPageComponent)
