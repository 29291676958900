export const i18nLogin = {
	title: {
		bg: 'Вход',
		en: 'Login'
	},
	email: {
		bg: 'Имейл',
		en: 'Email'
	},
	password: {
		bg: 'Парола',
		en: 'Password'
	},
	rememberMe: {
		bg: 'Запомни ме',
		en: 'Remember me'
	},
	forgotPassword: {
		bg: 'Забравена парола?',
		en: 'Forgot password?'
	},
	buttonLabel: {
		bg: 'Вход',
		en: 'Login'
	},
	registerLink: {
		bg: 'Регистрация',
		en: 'Register'
	}
}
