import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { i18nPackageCalc } from "../i18n"
import { GlobalState } from "../../../../data/store"
import { texts } from "../../../../utils/i18n/texts"
import { sendReactGAEvent } from "../../../../utils/ReactGAEvent"
import { FormikError } from "../../../../utils/formik/FormikError"
import { useTranslate, useTranslateSingle } from "../../../../hooks/translate"
import { bgRegions, CountryConfig, itRegions, RegionConfig, transportCountries } from "../../../../utils/regionsAndCities"

import './styles.css'

function showResult(values: any, requestText: string, pricePrefix: string, priceSuffix: string) {
    if (values.showRequestButton) {
        return (
            <Link to='/contacts'>
                <Button
                    className="price-request-button"
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                >
                    {requestText}
                </Button>
            </Link>
        )
    }

    if (values.price > 0) {
        return (
            <Typography variant="h6">
                {pricePrefix}:{' '}
                <strong>
                    {values.price}
                    {priceSuffix}
                </strong>
            </Typography>
        )
    }

    return <></>
}

export function CalcPackagesMobileForm(props: any): JSX.Element {
    const BG = 'Bulgaria'
    const IT = 'Italy'
    const SF = 'Sofia'
    const RM = 'Rome'

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue } = props
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const handleSubmitClicked = () => {
        sendReactGAEvent('calculator', 'packages', 'mobile')
        handleSubmit()
    }

    const t = useTranslate(i18nPackageCalc)
    const denomination = useTranslateSingle(texts.currency)

    const regions = { bgRegions, itRegions }

    const handleChangeToCountry = (event: any, value: any) => {
        if (event.target.value === BG) {
            setFieldValue('toRegion', SF)
            if (values.fromCountry === BG) {
                setFieldValue('fromCountry', IT)
                setFieldValue('fromRegion', RM)
            }
        } else if (event.target.value === IT) {
            setFieldValue('toRegion', RM)
            if (values.fromCountry === IT) {
                setFieldValue('fromCountry', BG)
                setFieldValue('fromRegion', SF)
            }
        }

        hideResult()
        handleChange(event)
    }

    const handleChangeFromCountry = (event: any, value: any) => {
        if (event.target.value === BG) {
            setFieldValue('fromRegion', SF)
            if (values.toCountry === BG) {
                setFieldValue('toCountry', IT)
                setFieldValue('toRegion', RM)
            }
        } else if (event.target.value === IT) {
            setFieldValue('fromRegion', RM)
            if (values.toCountry === IT) {
                setFieldValue('toCountry', BG)
                setFieldValue('toRegion', SF)
            }
        }

        hideResult()
        handleChange(event)
    }

    const handleChangeRegion = (event: any) => {
        const { value } = event.target
        const shouldShowRequestButton = value !== SF && value !== RM
        setFieldValue('showRequestButton', shouldShowRequestButton)

        hideResult()
        handleChange(event)
    }

    const hideResult = () => {
        setFieldValue('price', 0)
    }

    const handleChangeRow = (event: any) => {
        const { name, value } = event.target
        const row = values.packages[0] as any
        row[name] = +value
        setFieldValue('packages', values.packages)
    }

    const fromCountryConfig = transportCountries.find((x) => x.name === values.fromCountry)
    const toCountryConfig = transportCountries.find((x) => x.name === values.toCountry)

    //@ts-ignore
    const fromRegions = regions[fromCountryConfig?.regionsSource]
    //@ts-ignore
    const toRegions = regions[toCountryConfig?.regionsSource]

    const pckg = values.packages[0]

    return (
        <form className="price-calc-form" onSubmit={handleSubmit}>
            <div className="price-calc-input-field">
                <FormControl fullWidth size="small">
                    <InputLabel id="from-country-select-label">{t.fromCountryLabel}</InputLabel>
                    <Select
                        labelId="from-country-select-label"
                        onChange={handleChangeFromCountry}
                        value={values.fromCountry}
                        label={t.fromCountryLabel}
                        onBlur={handleBlur}
                        name="fromCountry"
                    >
                        {transportCountries.map((countryData: CountryConfig, i: number) => {
                            return (
                                <MenuItem key={i} value={countryData.name}>
                                    {lang === 'bg' ? countryData.bgName : countryData.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormikError name="fromRegion" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-input-field">
                <FormControl fullWidth size="small">
                    <InputLabel id="from-region-select-label">{t.fromRegionLabel}</InputLabel>
                    <Select
                        labelId="from-region-select-label"
                        onChange={handleChangeRegion}
                        value={values.fromRegion}
                        label={t.fromRegionLabel}
                        onBlur={handleBlur}
                        name="fromRegion"
                    >
                        {fromRegions.map((regionData: RegionConfig, i: number) => {
                            return (
                                <MenuItem key={i} value={regionData.name}>
                                    {lang === 'bg' ? regionData.bgName : regionData.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormikError name="fromRegion" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-input-field">
                <FormControl fullWidth size="small">
                    <InputLabel id="to-country-select-label">{t.toCountryLabel}</InputLabel>
                    <Select
                        labelId="to-country-select-label"
                        onChange={handleChangeToCountry}
                        value={values.toCountry}
                        label={t.toCountryLabel}
                        onBlur={handleBlur}
                        name="toCountry"
                    >
                        {transportCountries.map((countryData: CountryConfig, i: number) => {
                            return (
                                <MenuItem key={i} value={countryData.name}>
                                    {lang === 'bg' ? countryData.bgName : countryData.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormikError name="toCountry" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-input-field">
                <FormControl fullWidth size="small">
                    <InputLabel id="to-region-select-label">{t.toRegionLabel}</InputLabel>
                    <Select
                        labelId="to-region-select-label"
                        onChange={handleChangeRegion}
                        value={values.toRegion}
                        label={t.toRegionLabel}
                        onBlur={handleBlur}
                        name="toRegion"
                    >
                        {toRegions.map((regionData: RegionConfig, i: number) => {
                            return (
                                <MenuItem key={i} value={regionData.name}>
                                    {lang === 'bg' ? regionData.bgName : regionData.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormikError name="toRegion" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-input-field">
                <TextField
                    fullWidth
                    size="small"
                    name="weight"
                    variant="outlined"
                    value={pckg.weight}
                    label={t.weightLabel}
                    onBlur={handleBlur}
                    onChange={handleChangeRow}
                />
                <FormikError name="weight" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-input-field">
                <TextField
                    fullWidth
                    name="width"
                    size="small"
                    value={pckg.width}
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.widthLabel}
                    onChange={handleChangeRow}
                />
                <FormikError name="width" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-input-field">
                <TextField
                    fullWidth
                    size="small"
                    name="height"
                    variant="outlined"
                    value={pckg.height}
                    onBlur={handleBlur}
                    label={t.heightLabel}
                    onChange={handleChangeRow}
                />
                <FormikError name="height" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-input-field">
                <TextField
                    fullWidth
                    size="small"
                    name="depth"
                    value={pckg.depth}
                    variant="outlined"
                    onBlur={handleBlur}
                    label={t.depthLabel}
                    onChange={handleChangeRow}
                />
                <FormikError name="depth" errors={errors} touched={touched} />
            </div>
            <div className="price-calc-field">
                <Button
                    className="price-calc-button"
                    onClick={handleSubmitClicked}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    size="large"
                    fullWidth
                >
                    {t.calcButtonLabel}
                </Button>
            </div>
            <div className="price-calc-field">
                {showResult(values, t.sendRequest, t.transportPrice, denomination)}
            </div>
        </form>
    )
}