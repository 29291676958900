import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Fade } from 'react-awesome-reveal'

import { i18nStepsBanner } from '../i18n'
import { easeTimes } from '../../../../../utils/easeTimes'
import { useTranslate } from '../../../../../hooks/translate'

import arrow from '../../../../../assets/home-page-arrow.png'
import Step1Image from '../../../../../assets/vectors/home-page_register-icon-image.svg'
import Step2Image from '../../../../../assets/vectors/home-page_register-order-icon-image.svg'
import Step3Image from '../../../../../assets/vectors/home-page_delivery-price-icon-image.svg'
import Step4Image from '../../../../../assets/vectors/home-page_delivery-schedule-icon-image.svg'

import './styles.css'

export function HomeStepsDesktop(): JSX.Element {
    const t = useTranslate(i18nStepsBanner)

    return (
        <section className="home-steps-wrapper">
            <div className="home-steps">
                <div className="subsection">
                    <div className="info-content">
                        <Fade cascade triggerOnce duration={easeTimes.medium} direction="up">
                            <Typography variant="body1">{t.step1}</Typography>
                            <Typography variant="h4">{t.step1Title}</Typography>
                            <Typography variant="body1">{t.step1Description}</Typography>
                            <div className="action-buttons">
                                <Link to="/login">
                                    <Button variant="outlined" color="secondary">
                                        {t.step1ButtonLabel1}
                                    </Button>
                                </Link>
                                <Link to="/register">
                                    <Button variant="contained" color="secondary">
                                        {t.step1ButtonLabel2}
                                    </Button>
                                </Link>
                            </div>
                        </Fade>
                    </div>
                    <div className="arrow-wrapper">
                        <img className="arrow-image rotated" src={arrow} alt="arrow" />
                    </div>
                    <div className="image-wrapper">
                        <img className="content-image" src={Step1Image} alt="login-register" />
                    </div>
                </div>
                <div className="subsection">
                    <div className="image-wrapper">
                        <img className="content-image" src={Step2Image} alt="register-order" />
                    </div>
                    <div className="arrow-wrapper">
                        <img className="arrow-image" src={arrow} alt="arrow" />
                    </div>
                    <div className="info-content">
                        <Fade cascade triggerOnce duration={easeTimes.medium} direction="up">
                            <Typography variant="body1">{t.step2}</Typography>
                            <Typography variant="h4">{t.step2Title}</Typography>
                            <Typography variant="body1">{t.step2Description}</Typography>
                            <div className="action-buttons">
                                <Link to="/register-package">
                                    <Button variant="contained" color="secondary">
                                        {t.step2ButtonLabel}
                                    </Button>
                                </Link>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="subsection">
                    <div className="info-content">
                        <Fade cascade triggerOnce duration={easeTimes.medium} direction="up">
                            <Typography variant="body1">{t.step3}</Typography>
                            <Typography variant="h4">{t.step3Title}</Typography>
                            <Typography variant="body1">{t.step3Description}</Typography>
                            <div className="action-buttons">
                                <Link to="/pricing">
                                    <Button variant="contained" color="secondary">
                                        {t.step3ButtonLabel}
                                    </Button>
                                </Link>
                            </div>
                        </Fade>
                    </div>
                    <div className="arrow-wrapper">
                        <img className="arrow-image rotated" src={arrow} alt="arrow" />
                    </div>
                    <div className="image-wrapper">
                        <img className="content-image" src={Step3Image} alt="delivery-prices" />
                    </div>
                </div>
                <div className="subsection">
                    <div className="image-wrapper">
                        <img className="content-image" src={Step4Image} alt="delivery-schedule" />
                    </div>
                    <div className="info-content">
                        <Fade cascade triggerOnce duration={easeTimes.medium} direction="up">
                            <Typography variant="body1">{t.step4}</Typography>
                            <Typography variant="h4">{t.step4Title}</Typography>
                            <Typography variant="body1">{t.step4Description}</Typography>
                            <div className="action-buttons">
                                <Link to="/schedules">
                                    <Button variant="contained" color="secondary">
                                        {t.step4ButtonLabel}
                                    </Button>
                                </Link>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    )
}
