import { FormikErrors } from 'formik'
import { store } from '../../data/store'

interface LangType { bg: string, en: string }

function getTErrorMsg(bg: string, en: string): string {
	const lang = store.getState().lang.currentSelection
	let obj: LangType = { bg, en }

	return obj[lang as keyof LangType] as string
}

export function validateEmail(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	for (const name of names) {
		const value = values[name]
		const isValidEmail = String(value)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

		if (!isValidEmail) {
			errors[name] = getTErrorMsg('Невалиден имейл адрес', 'Invalid email address')
		}
	}

	return errors
}

export function validatePassword(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	const minimumLength = 8
	for (const name of names) {
		if (values[name]?.length < minimumLength) {
			errors[name] = getTErrorMsg('Паролата трябва да е дълга поне 8 символа', "The password's length should be at least 8 symbols")
		}

		if (values[name]?.includes(' ')) {
			errors[name] = getTErrorMsg('Паролата не трябва да съдържа празни пространства', 'The password should not contain whitespaces')
		}

		if (!values[name]?.match(/[0-9]+/)) {
			errors[name] = getTErrorMsg('Паролата трябва да съдържа поне едно число', 'The password should contain at least one number')
		}

		if (!values[name]?.match(/[A-Z]+/)) {
			errors[name] = getTErrorMsg('Паролата трябва да съдържа поне една главна буква', 'The password should contain at least one capital letter')
		}

		if (!values[name]?.match(/[a-z]+/)) {
			errors[name] = getTErrorMsg('Паролата трябва да съдържа поне едно малка буква', 'The password should contain at least one small letter')
		}

		if (!values[name]?.match(/[$@#&!]+/)) {
			errors[name] = getTErrorMsg('Паролата трябва да съдържа поне един специален символ', 'The password should contain at least one symbol')
		}
	}

	return errors
}

export function matchPasswords(values: any, password1Name: string, password2Name: string): FormikErrors<Object> {
	const errors = {} as any

	if (values[password1Name] !== values[password2Name]) {
		const err = getTErrorMsg('Паролите не съвпадат', 'The passwords does not match')
		errors[password1Name] = err
		errors[password2Name] = err
	}

	return errors
}

export function validateNumbers(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	for (const name of names) {
		try {
			// eslint-disable-next-line
			const value = +values[name]
			continue
		} catch {
			errors[name] = getTErrorMsg('Невалидно число', 'Invalid number')
		}
	}

	return errors
}

export function validatePositiveNumers(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	for (const name of names) {
		const number = +values[name]
		if (number <= 0) {
			errors[name] = getTErrorMsg('Числото трябва да е позитивно', 'The number should be positive')
		}
	}

	return errors
}

export function validateNonEmpty(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	for (const name of names) {
		if (values[name]?.toString().length === 0) {
			errors[name] = getTErrorMsg('Задължително поле', 'Required field')
		}
	}

	return errors
}

export function validateSizeFormat(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	const regexFormat = /\d{2,}x\d{2,}x\d{2,}/gm
	const regexFormat2 = /\d{2,}х\d{2,}х\d{2,}/gm
	for (const name of names) {
		if (!values[name]?.match(regexFormat) && !values[name]?.match(regexFormat2)) {
			errors[name] = getTErrorMsg('Невалиден формат', 'Invalid format')
		}
	}

	return errors
}

export function validateDateFormat(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	const regexFormat = /\d{2}\.\d{2}\.\d{4}/gm
	for (const name of names) {
		if (!values[name]?.match(regexFormat)) {
			errors[name] = getTErrorMsg('Невалиден формат', 'Invalid format')
		}
	}

	return errors
}

export function validateNotOlderThanDate(values: any, notOlderThanEpoch: number, bgAmountText: string, enAmountText: string, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	const regexFormat = /\d{2}\.\d{2}\.\d{4}/gm
	for (const name of names) {
		const splitted = values[name]?.split('.')
		if (!values[name]?.match(regexFormat) || splitted.length !== 3) {
			errors[name] = getTErrorMsg('Невалиден формат', 'Invalid format')
		}

		const epochDate = new Date(splitted[2], splitted[1] - 1, splitted[0]).getTime()

		if (epochDate < notOlderThanEpoch) {
			errors[name] = getTErrorMsg(`Датата не може да бъде по-стара от ${bgAmountText} от сега`, `The date cant be older than ${enAmountText} from now`)
		}
	}

	return errors
}

export function validateNoFutureDate(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	const regexFormat = /\d{2}\.\d{2}\.\d{4}/gm
	for (const name of names) {
		const splitted = values[name]?.split('.')
		if (!values[name]?.match(regexFormat) || splitted.length !== 3) {
			errors[name] = getTErrorMsg('Невалиден формат', 'Invalid format')
		}

		const epochDate = new Date(splitted[2], splitted[1] - 1, splitted[0]).getTime()

		if (epochDate > Date.now()) {
			errors[name] = getTErrorMsg(`Датата не може да бъде в бъдещето`, `The date cant be in the future`)
		}
	}

	return errors
}

export function validateMaxLength(values: any, maxLength: number, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	for (const name of names) {
		if (values[name]?.length > maxLength) {
			errors[name] = getTErrorMsg(`Максимална дължина на данните: ${maxLength}`, `Maximum length of the input: ${maxLength}`)
		}
	}

	return errors
}

export function validatePackageItems(values: any, name: string): FormikErrors<Object> {
	const errors = {} as any

	const items = values[name]

	const regexFormat = /\d{2,}x\d{2,}x\d{2,}/gm
	const regexFormat2 = /\d{2,}х\d{2,}х\d{2,}/gm

	let hasError = false
	for (let i = 0; i < items.length; i++) {
		const item = items[i]

		if (item.weight.length === 0) {
			hasError = true
			break
		}

		if (item.sizes.length === 0) {
			hasError = true
			break
		}

		if (!item.sizes.match(regexFormat) && !item.sizes.match(regexFormat2)) {
			hasError = true
			break
		}
	}

	if (hasError) {
		errors[name] = getTErrorMsg('Невалиден формат', 'Invalid format')
	}

	return errors
}

export function validateCompanyId(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	const regexFormat = /(\d{13}|\d{9})/gm
	for (const name of names) {
		if (!values[name]?.match(regexFormat)) {
			errors[name] = getTErrorMsg('Невалиден формат', 'Invalid format')
		}
	}

	return errors
}

export function validateTwoNames(values: any, names: string[]): FormikErrors<Object> {
	const errors = {} as any

	const regexFormat = /(([a-zA-Z]{2,} [a-zA-Z]{2,})|([а-яА-Я]{2,} [а-яА-Я]{2,}))/gm
	for (const name of names) {
		if (!values[name]?.match(regexFormat)) {
			errors[name] = getTErrorMsg('Навалидно име', 'Invalid name')
		}
	}

	return errors
}
