export interface CountryConfig {
	name: string
	bgName: string
	regionsSource: string
}

export const transportCountries: CountryConfig[] = [
	{
		name: 'Bulgaria',
		bgName: 'България',
		regionsSource: 'bgRegions'
	},
	{
		name: 'Italy',
		bgName: 'Италия',
		regionsSource: 'itRegions'
	}
]

export interface RegionConfig {
	name: string
	postalCodeStarts: string[]
	postalCodeEnds: string[]
	bgName: string
}

export const bgRegions: RegionConfig[] = [
	{
		name: '',
		bgName: '',
		postalCodeEnds: [],
		postalCodeStarts: [],
	},
	{
		name: 'Blagoevgrad',
		postalCodeStarts: ['2700'],
		postalCodeEnds: ['2700'],
		bgName: 'Благоевград'
	},
	{
		name: 'Burgas',
		postalCodeStarts: ['8000'],
		postalCodeEnds: ['8000'],
		bgName: 'Бургас'
	},
	{
		name: 'Varna',
		postalCodeStarts: ['9000'],
		postalCodeEnds: ['9000'],
		bgName: 'Варна'
	},
	{
		name: 'Veliko Tarnovo',
		postalCodeStarts: ['5000'],
		postalCodeEnds: ['5000'],
		bgName: 'Велико Търново'
	},
	{
		name: 'Vidin',
		postalCodeStarts: ['3700'],
		postalCodeEnds: ['3700'],
		bgName: 'Видин'
	},
	{
		name: 'Vraca',
		postalCodeStarts: ['3000'],
		postalCodeEnds: ['3000'],
		bgName: 'Враца'
	},
	{
		name: 'Gabrovo',
		postalCodeStarts: ['5300'],
		postalCodeEnds: ['5300'],
		bgName: 'Габрово'
	},
	{
		name: 'Dobrich',
		postalCodeStarts: ['9300'],
		postalCodeEnds: ['9300'],
		bgName: 'Добрич'
	},
	{
		name: 'Kardzhali',
		postalCodeStarts: ['6600'],
		postalCodeEnds: ['6600'],
		bgName: 'Кърджали'
	},
	{
		name: 'Kiustendil',
		postalCodeStarts: ['2500'],
		postalCodeEnds: ['2500'],
		bgName: 'Кюстендил'
	},
	{
		name: 'Lovech',
		postalCodeStarts: ['5500'],
		postalCodeEnds: ['5500'],
		bgName: 'Ловеч'
	},
	{
		name: 'Montana',
		postalCodeStarts: ['3400'],
		postalCodeEnds: ['3400'],
		bgName: 'Монтана'
	},
	{
		name: 'Pazardzhik',
		postalCodeStarts: ['4400'],
		postalCodeEnds: ['4400'],
		bgName: 'Пазарджик'
	},
	{
		name: 'Pernik',
		postalCodeStarts: ['2300'],
		postalCodeEnds: ['2300'],
		bgName: 'Перник'
	},
	{
		name: 'Pleven',
		postalCodeStarts: ['5800'],
		postalCodeEnds: ['5800'],
		bgName: 'Плевен'
	},
	{
		name: 'Plovdiv',
		postalCodeStarts: ['4000'],
		postalCodeEnds: ['4000'],
		bgName: 'Пловдив'
	},
	{
		name: 'Razgrad',
		postalCodeStarts: ['7200'],
		postalCodeEnds: ['7200'],
		bgName: 'Разград'
	},
	{
		name: 'Ruse',
		postalCodeStarts: ['7000'],
		postalCodeEnds: ['7000'],
		bgName: 'Русе'
	},
	{
		name: 'Silistra',
		postalCodeStarts: ['7500'],
		postalCodeEnds: ['7500'],
		bgName: 'Силистра'
	},
	{
		name: 'Sliven',
		postalCodeStarts: ['8800'],
		postalCodeEnds: ['8800'],
		bgName: 'Сливен'
	},
	{
		name: 'Smolyan',
		postalCodeStarts: ['4700'],
		postalCodeEnds: ['4700'],
		bgName: 'Смолян'
	},
	{
		name: 'Sofia',
		postalCodeStarts: ['1000'],
		postalCodeEnds: ['1000'],
		bgName: 'София'
	},
	{
		name: 'Stara Zagora',
		postalCodeStarts: ['6000'],
		postalCodeEnds: ['6000'],
		bgName: 'Стара Загора'
	},
	{
		name: 'Targovishte',
		postalCodeStarts: ['7700'],
		postalCodeEnds: ['7700'],
		bgName: 'Търговище'
	},
	{
		name: 'Haskovo',
		postalCodeStarts: ['6300'],
		postalCodeEnds: ['6300'],
		bgName: 'Хасково'
	},
	{
		name: 'Shumen',
		postalCodeStarts: ['9700'],
		postalCodeEnds: ['9700'],
		bgName: 'Шумен'
	},
	{
		name: 'Yambol',
		postalCodeStarts: ['8600'],
		postalCodeEnds: ['8600'],
		bgName: 'Ямбол'
	},
]

export const itRegions: RegionConfig[] = [
	{
		name: '',
		bgName: '',
		postalCodeEnds: [],
		postalCodeStarts: [],
	},
	{
		name: 'Rome',
		postalCodeStarts: ['00100'],
		postalCodeEnds: ['00199'],
		bgName: 'Рим'
	},
	{
		name: 'Umbria',
		postalCodeStarts: ['05010'],
		postalCodeEnds: ['06135'],
		bgName: 'Умбрия'
	},
	{
		name: 'Sardinia',
		postalCodeStarts: ['07014'],
		postalCodeEnds: ['09170'],
		bgName: 'Сардиния'
	},
	{
		name: 'Piedmont',
		postalCodeStarts: ['10010', '28016'],
		postalCodeEnds: ['18025', '28925'],
		bgName: 'Пиемонт'
	},
	{
		name: 'Aosta Valley',
		postalCodeStarts: ['11013'],
		postalCodeEnds: ['11029'],
		bgName: 'Аоста'
	},
	{
		name: 'Liguria',
		postalCodeStarts: ['16013'],
		postalCodeEnds: ['19139'],
		bgName: 'Лигурия'
	},
	{
		name: 'Lombardy',
		postalCodeStarts: ['20010'],
		postalCodeEnds: ['28819'],
		bgName: 'Ломбардия'
	},
	{
		name: 'Veneto',
		postalCodeStarts: ['30010', '35010'],
		postalCodeEnds: ['32100', '37142'],
		bgName: 'Венето'
	},
	{
		name: 'Friuli-Venezia Giulia',
		postalCodeStarts: ['33010'],
		postalCodeEnds: ['34170'],
		bgName: 'Фриули-Венеция Джулия'
	},
	{
		name: 'Trentino-Alto Adige-Südtirol',
		postalCodeStarts: ['38019'],
		postalCodeEnds: ['39100'],
		bgName: 'Трентино-Алто'
	},
	{
		name: 'Emilia-Romagna',
		postalCodeStarts: ['40010'],
		postalCodeEnds: ['48125'],
		bgName: 'Емилия-Романя'
	},
	{
		name: 'Tuscany',
		postalCodeStarts: ['50010'],
		postalCodeEnds: ['59100'],
		bgName: 'Тускана'
	},
	{
		name: 'Marche',
		postalCodeStarts: ['60010'],
		postalCodeEnds: ['63900'],
		bgName: 'Марке'
	},
	{
		name: 'Abruzzo',
		postalCodeStarts: ['64010'],
		postalCodeEnds: ['67100'],
		bgName: 'Абруцо'
	},
	{
		name: 'Apulia',
		postalCodeStarts: ['70010'],
		postalCodeEnds: ['76125'],
		bgName: 'Пулия'
	},
	{
		name: 'Campania',
		postalCodeStarts: ['80010'],
		postalCodeEnds: ['84135'],
		bgName: 'Кампаня'
	},
	{
		name: 'Molise',
		postalCodeStarts: ['86014'],
		postalCodeEnds: ['86170'],
		bgName: 'Молизе'
	},
	{
		name: 'Calabria',
		postalCodeStarts: ['87010'],
		postalCodeEnds: ['89900'],
		bgName: 'Калабрия'
	},
	{
		name: 'Sicily',
		postalCodeStarts: ['90010'],
		postalCodeEnds: ['98168'],
		bgName: 'Сицилия'
	},
]
