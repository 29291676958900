import { useState } from 'react'
import { Link } from 'react-router-dom'

import {
    Button,
    Checkbox,
    FormGroup,
    TextField,
    IconButton,
    InputLabel,
    Typography,
    FormControl,
    OutlinedInput,
    InputAdornment,
    FormControlLabel,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { i18nLogin } from './i18n'
import { FormikError } from '../../utils/formik/FormikError'
import { useTranslate } from '../../hooks/translate'

export function LoginForm(props: any): JSX.Element {
    const { handleSubmit, handleChange, values, errors, touched, handleBlur } = props

	const t = useTranslate(i18nLogin)

    const [showPasswords, setShowPasswords] = useState(false)
    const toggleShowPasswords = () => setShowPasswords((oldState) => !oldState)

    return (
        <form onSubmit={handleSubmit} className="login-input-fields">
            <div className="login-input-field">
                <TextField
                    fullWidth
                    type="email"
                    name="email"
                    label={t.email}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                />
                <FormikError name="email" errors={errors} touched={touched} />
            </div>
            <div className="login-input-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="toggle-password-visibility">{t.password}</InputLabel>
                    <OutlinedInput
                        fullWidth
                        type={showPasswords ? 'text' : 'password'}
                        id="toggle-password-visibility"
                        onChange={handleChange}
                        value={values.password}
                        onBlur={handleBlur}
                        name="password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={toggleShowPasswords}
                                    aria-label="toggle password visibility"
                                >
                                    {showPasswords ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t.password}
                    />
                </FormControl>
                <FormikError name="password" errors={errors} touched={touched} />
            </div>
            <div className="helpers-section">
                <FormGroup>
                    <FormControlLabel
						name='rememberUser'
                        label={t.rememberMe}
						onChange={handleChange}
                        control={<Checkbox checked={values.rememberUser} color="primary" />}
                    />
                </FormGroup>
                <span className="forget-password-wrapper">
                    <Link to="/forget-password">
                        <Typography variant="body1" style={{ textDecoration: 'underline' }}>
                            {t.forgotPassword}
                        </Typography>
                    </Link>
                </span>
            </div>
            <div className="action-buttons">
                <Button
                    type="submit"
                    size="large"
                    className="action-button submit-login"
                    variant="contained"
                    color="secondary"
                >
                    {t.buttonLabel}
                </Button>
                <Link to="/register" className="action-button">
                    <Button size="large" variant="outlined" color="secondary">
                        {t.registerLink}
                    </Button>
                </Link>
            </div>
        </form>
    )
}
