import { createAsyncThunk } from '@reduxjs/toolkit'

import { isAuthRequest } from './utils'
import { ActionTypes } from '../actionTypes'
import { getTranslation } from './translation'
import { messages } from '../../utils/i18n/messages'
import { hideLoader, showLoader } from './loaderActions'
import { showNotification } from './notificationActions'
import { settingsService } from '../../services/settingsService'
import { IDefaultEcontDeliveryAddress } from '../reducers/usersReducer'

export const getPricesSettings = createAsyncThunk(
	ActionTypes.settings.getPrices.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await settingsService.getPrices()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdatePricesSettingsInterface {
	pricePerKg: number
	processPricePerPackage: number
	priceDeliverySofia: number
	userId: string
}
export const updatePricesSettings = createAsyncThunk(
	ActionTypes.settings.updatePrices.typeName,
	async (data: UpdatePricesSettingsInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await settingsService.updatePrices(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification('Успешно обновявне на ценови настройки', 'success'))
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const getDefaultsSettings = createAsyncThunk(
	ActionTypes.settings.getDefaults.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await settingsService.getDefaults()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdateDefaultsSettingsInterface {
	econtOfficeSenderAddress: IDefaultEcontDeliveryAddress
	shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab: boolean
	userId: string
}
export const updateDefaultsSettings = createAsyncThunk(
	ActionTypes.settings.updateDefaults.typeName,
	async (data: UpdateDefaultsSettingsInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await settingsService.updateDefaults(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification('Успешно обновявне на настройки по подразбиране', 'success'))
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)
