import { Formik } from 'formik'
import { connect, useSelector } from 'react-redux'

import { GlobalState } from '../../../data/store'
import { isEmployee, ROLES } from '../../../utils/roles'
import { sendReactGAEvent } from '../../../utils/ReactGAEvent'
import { validateEmail } from '../../../utils/formik/validations'
import { ProfileInformationForm } from './ProfileInformationForm'
import { updateProfile } from '../../../data/actions/usersActions'
import { __defaultEcontDeliveryAddress } from '../../../data/reducers/usersReducer'
import { DeviceStrings, useResponsiveString } from '../../../hooks/useResponsiveString'

import './styles.css'

function ProfileInformationComponent(props: any): JSX.Element {
    const { updateProfile } = props
    const loggedIn = useSelector((state: GlobalState) => state.users.loggedIn)

    const isBusinessClient = loggedIn.role === ROLES.BUSINESS_CLIENT

    const classNames = {
        mobile: 'profile-information-container-mobile',
        smallDesktop: 'profile-information-container',
    } as DeviceStrings

    const containerClassName = useResponsiveString(classNames)

    return (
        <div className={containerClassName}>
            <Formik
                initialValues={{
                    email: loggedIn.email || '',
                    firstName: loggedIn.firstName || '',
                    lastName: loggedIn.lastName || '',
                    country: loggedIn.country || '',
                    city: loggedIn.city || '',
                    address: loggedIn.address || '',
                    phoneNumber: loggedIn.phoneNumber || '',
                    companyName: loggedIn.companyName || '',
                    companyId: loggedIn.companyId || '',
                    companyOwner: loggedIn.companyOwner || '',
                    companyAddress: loggedIn.companyAddress || '',
                    uniqueId: loggedIn.uniqueId || '',
                    defaultEcontOfficeDeliveryAddress: loggedIn.defaultEcontOfficeDeliveryAddress || __defaultEcontDeliveryAddress
                }}
                validate={(values) => {
                    console.log(values)
                    return {
                        ...validateEmail(values, ['email']),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    if (isBusinessClient) {
                        const { email, firstName, lastName, ...rest } = values
                        sendReactGAEvent('users', 'profile_update', 'business')
                        updateProfile({ userId: loggedIn._id, ...rest })
                    } else {
                        const { email, companyId, companyName, companyOwner, companyAddress, ...rest } = values
                        sendReactGAEvent('users', 'profile_update', 'individual')
                        updateProfile({ userId: loggedIn._id, ...rest })
                    }
                }}
            >
                {(props) => (
                    <ProfileInformationForm
                        {...props}
                        isEmployee={isEmployee(loggedIn.role)}
                        isCompany={isBusinessClient}
                    />
                )}
            </Formik>
        </div>
    )
}

const mapDispatchToProps = { updateProfile }

export const ProfileInformation = connect(null, mapDispatchToProps)(ProfileInformationComponent)
