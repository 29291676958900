import { Link } from 'react-router-dom'
import { Fade } from 'react-awesome-reveal'
import { Button, Typography } from '@mui/material'

import { i18nRegBanner } from '../i18n'
import { easeTimes } from '../../../../../utils/easeTimes'
import { useTranslate } from '../../../../../hooks/translate'

import './styles.css'

export function HomeRegisterUserDesktop(): JSX.Element {
    const t = useTranslate(i18nRegBanner)

    return (
        <section className="home-register-user">
            <div className="text-content">
                <Fade triggerOnce cascade damping={0.3} direction="up" duration={easeTimes.slow}>
                    <Typography variant="h3" className="register-question-label">
                        {t.question}
                    </Typography>
                    <Typography variant="h3" className="register-now-label">
                        {t.action}
                    </Typography>
                    <Link to="/register">
                        <Button className="register-now-button" size="large" variant="contained" color="secondary">
                            {t.buttonLabel}
                        </Button>
                    </Link>
                </Fade>
            </div>
        </section>
    )
}
