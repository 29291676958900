export const messages = {
	domainName: {
		bg: 'tuttopost.com',
		en: 'tuttopost.com'
	},
    sessionExpired: {
		bg: 'Сесията ви изтече, моля влезте в профила си отново',
		en: 'Session expired, please login again'
	},
    unexpectedError: {
		bg: 'Възникна неочаквана грешка',
		en: 'Unexpected error occured'
	},
    loginSuccess: {
		bg: 'Успешен вход',
		en: 'Login success'
	},
    registerSuccess: {
		bg: 'Успешна регистрация, изпратихме ви имейл с код за потвърждение',
		en: "Registration success, we've sent a confirmation code to your email"
	},
	finishRegisterSuccess: {
		bg: 'Успешна верификация',
		en: "Verification success"
	},
	resendCodeSuccess: {
		bg: 'Успешно изпратихме нов код за потвърждение',
		en: "We've sent a new confirmation code to your email"
	},
	sendVerificationSupportRequest: {
		bg: 'Успешно изпращане на заявка за техническа помощ',
		en: "Successfully sent technical support request"
	},
	passwordResetRequest: {
		bg: 'Успешна заявка за възстановяване на парола, провери имейла си',
		en: 'Request password recovery success, check your email'
	},
	userDeleteSuccess: {
		bg: 'Успешно изтриване на потребител',
		en: 'Delete user success'
	},
	sendEmailRequestSuccess: {
		bg: 'Успешно изпращане на запитване',
		en: 'Send request success'
	},
    logoutSuccess: {
		bg: 'Успешен изход',
		en: 'Logout success'
	},
    updateRoleSuccess: {
		bg: 'Успешно обновяване на роля',
		en: 'Update role success'
	},
	updateProfileSuccess: {
		bg: 'Успешно обновяване на профил',
		en: 'Update profile success'
	},
	registerOrderSuccess: {
		bg: 'Успешно регистриране на пратка',
		en: 'Register package success'
	},
	updateOrderSuccess: {
		bg: 'Успешно обновяване на пратка',
		en: 'Update package success'
	},
	deleteOrderSuccess: {
		bg: 'Успешно изтриване на пратка',
		en: 'Delete package success'
	},
	createCrossBorderScheduleSuccess: {
		bg: 'Успешно създаване на международен график',
		en: 'Create cross border schedule success'
	},
	updateCrossBorderScheduleSuccess: {
		bg: 'Успешно обновяване на международен график',
		en: 'Update cross border schedule success'
	},
	deleteCrossBorderScheduleSuccess: {
		bg: 'Успешно изтриване на международен график',
		en: 'Delete cross border schedule success'
	},
	createInternalScheduleSuccess: {
		bg: 'Успешно създаване на вътрешен график',
		en: 'Create internal schedule success'
	},
	updateInternalScheduleSuccess: {
		bg: 'Успешно обновяване на вътрешен график',
		en: 'Update internal schedule success'
	},
	deleteInternalScheduleSuccess: {
		bg: 'Успешно изтриване на вътрешен график',
		en: 'Delete internal schedule success'
	},
	successfullyCopiedToClipboard: {
		bg: 'Успешно копирано в клипборда',
		en: 'Copied to clipboard'
	}
}
