export const i18nAdminOrdersArchive = {
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	phone: {
		bg: 'Телефон',
		en: 'Phone'
	},
    email: {
		bg: 'Имейл',
		en: 'Email'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	registerDate: {
		bg: 'Дата регистриране',
		en: 'Register date'
	},
	orderId: {
		bg: 'ТТП Номер',
		en: 'TTP Number'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
    siteOrigin: {
		bg: 'Поръчано от',
		en: 'Ordered from'
	},
    valueEur: {
		bg: 'Стойност EUR',
		en: 'Value EUR'
	},
}
