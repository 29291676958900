import { apiService } from './apiService'
import { endpoints } from './endpoints'

const domain = endpoints.crossBorderSchedules

export const crossBorderSchedulesService = {
	getAllActive: () => apiService.get(domain.getAllActive),
	getAllComplete: () => apiService.get(domain.getAllCompleted),
	create: (data: Object) => apiService.post(domain.create, data),
	update: (data: Object) => apiService.post(domain.update, data),
	delete: (id: string) => apiService.delete(domain.delete(id), {}),
	completeSchedule: (data: any) => apiService.post(domain.completeSchedule, data),
	getAll: () => apiService.get(domain.getAll),
	notifyOrdersPickedUpViaEmail: (ordersIds: string[]) => apiService.post(domain.notifyOrdersPickedUp, { ordersIds })
}
