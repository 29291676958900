//@ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../../data/store'
import { Order } from '../../../../data/reducers/ordersReducer'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function OrdersCountGraphic() {
	const { orders } = useSelector((state: GlobalState) => state.orders)

	const ordersData = mapOrders(orders)
	const totalDataPoints = ordersData.map(ordersByMonth => ({
		x: ordersByMonth.date,
		y: ordersByMonth.orders.length
	}))
	const econtDataPoints = ordersData.map(ordersByMonth => ({
		x: ordersByMonth.date,
		y: ordersByMonth.econtOrders.length
	}))
	const tuttopostDataPoints = ordersData.map(ordersByMonth => ({
		x: ordersByMonth.date,
		y: ordersByMonth.tuttopostOrders.length
	}))

	const options = {
		theme: "light2",
		animationEnabled: true,
		title: {
			text: "Orders count"
		},
		data: [
			{
				type: "spline",
				xValueFormatString: "MMM YYYY",
				yValueFormatString: "Total #,##0",
				dataPoints: totalDataPoints
			},
			{
				type: "spline",
				name: "econt",
				showInLegend: true,
				xValueFormatString: "MMM YYYY",
				yValueFormatString: "Econt #,##0",
				dataPoints: econtDataPoints
			},
			{
				type: "spline",
				name: "tuttopost",
				showInLegend: true,
				xValueFormatString: "MMM YYYY",
				yValueFormatString: "TuttoPost #,##0",
				dataPoints: tuttopostDataPoints
			}
		]
	}

	return <CanvasJSChart options={options} />
}

function mapOrders(orders: Order[]) {
	const yearAgo = new Date()
	yearAgo.setFullYear(yearAgo.getFullYear() - 1)
	yearAgo.setHours(0, 0, 0, 0)

	const currentDate = new Date()
	currentDate.setMonth(currentDate.getMonth() - 1)

	const registeredOrdersByMonth = []
	while (
		yearAgo.getTime() <= currentDate.getTime()
	) {
		yearAgo.setMonth(yearAgo.getMonth() + 1)
		const fromDateEpoch = yearAgo.getTime()

		const toDate = new Date(yearAgo)
		toDate.setMonth(toDate.getMonth() + 1)
		const toDateEpoch = toDate.getTime()

		const ordersForCurrentMonth = orders.filter(order => (
			order.registerDateEpoch >= fromDateEpoch && 
			order.registerDateEpoch <= toDateEpoch
		))

		const econtOrders = ordersForCurrentMonth.filter(order => order.deliveryCompany === 'econt')
		const tuttopostOrders = ordersForCurrentMonth.filter(order => order.deliveryCompany === 'tuttopost')

		registeredOrdersByMonth.push({
			date: new Date(yearAgo),
			month: yearAgo.getMonth(),
			orders: ordersForCurrentMonth,
			econtOrders,
			tuttopostOrders
		})
	}

	registeredOrdersByMonth.pop()

	return registeredOrdersByMonth
}