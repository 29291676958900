export type ROLE = 'GUEST' | 'CLIENT' | 'MODERATOR' | 'ADMIN' | 'BUSINESS_CLIENT' | 'LOCAL_DRIVER' | 'CROSS_BORDER_DRIVER'

export const ROLES = {
	GUEST: 'GUEST' as ROLE,
	CLIENT: 'CLIENT' as ROLE,
	BUSINESS_CLIENT: 'BUSINESS_CLIENT' as ROLE,
	LOCAL_DRIVER: 'LOCAL_DRIVER' as ROLE,
	CROSS_BORDER_DRIVER: 'CROSS_BORDER_DRIVER' as ROLE,
	MODERATOR: 'MODERATOR' as ROLE,
	ADMIN: 'ADMIN' as ROLE,
}

export function isEmployee(role: ROLE) {
	return role === ROLES.LOCAL_DRIVER || role === ROLES.CROSS_BORDER_DRIVER || role === ROLES.MODERATOR
}

export function isEqualOrHigherPermissions(userRole: ROLE, requiredRole: ROLE): boolean {
	const roles = Object.keys(ROLES)
	const userRoleIndex = roles.indexOf(userRole)
	const requiredRoleIndex = roles.indexOf(requiredRole)

	return userRoleIndex >= requiredRoleIndex
}
