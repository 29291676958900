import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import {
    Table,
    Paper,
    Modal,
    Button,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    TableContainer,
} from '@mui/material'

import { tableStruct } from './tableUtils'
import { i18nAdminCBSaddOrdersModal } from './i18n'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import { getFormattedDate } from '../../../../utils/functions'
import { Order, ORDER_STATUS } from '../../../../data/reducers/ordersReducer'

import './styles.css'

export function AddOrdersModal(props: any): JSX.Element {
    const { handleClose, data, handleSaveChanges } = props

    const [pickedOrders, setPickedOrders] = useState(data.orders || ([] as string[]))
    const { orders } = useSelector((state: GlobalState) => state.orders)
    const prices = useSelector((state: GlobalState) => state.settings.prices)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const t = useTranslate(i18nAdminCBSaddOrdersModal)
    const heads = Object.values(t).slice(1, 7)

    const unpickedOrders = orders.filter(
        (x) => x.status === ORDER_STATUS.REGISTERED || x.status === ORDER_STATUS.ARRIVED_ABROAD
    )

    const pickup = getFormattedDate(true, true, true, false, data.pickupDate)
    const delivery = getFormattedDate(true, true, true, false, data.deliveryDate)
    const scheduleInfo = `${data.fromCity} > ${data.toCity}, ${pickup} > ${delivery}`

    const handleClickOrder = (row: Order) => {
        if (isPicked(row)) {
            unpick(row)
        } else {
            pick(row)
        }
    }

    const pick = (row: Order) => setPickedOrders([...pickedOrders, row._id])

    const unpick = (row: Order) => {
        const index = pickedOrders.findIndex((id: string) => id === row._id)
        const copy = pickedOrders.slice()
        copy.splice(index, 1)
        setPickedOrders(copy)
    }

    const isPicked = (row: Order) => pickedOrders.findIndex((id: string) => id === row._id) !== -1

    const handleSave = () => handleSaveChanges({ ...data, orders: pickedOrders })

    const tableStructure = tableStruct(heads, lang)

    return (
        <Modal className='admin-add-orders-to-cb-modal' open={true} onClose={handleClose}>
            <div className="cross-border-schedules-orders-modal-container">
                <Typography className="modal-header" variant="h4" align="center">
                    {t.title}
                    <Typography variant="body1" align="center">
                        {scheduleInfo}
                    </Typography>
                </Typography>
                <TableContainer component={Paper} className="orders-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableStructure.map((x: any, i: number) => {
                                    return (
                                        <TableCell key={i} align="center">
                                            <Typography variant="h6">{x.head}</Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unpickedOrders.map((row, i: number) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    className={isPicked(row) ? 'picked-row' : 'unpicked-row'}
                                    onClick={() => handleClickOrder(row)}
                                    key={i}
                                >
                                    {tableStructure.map((x: any) => (
                                        <TableCell key={uuid()} align="center">
                                            {x.getData(row, prices)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="action-buttons">
                    <Button onClick={handleSave} variant="contained" color="secondary">
                        {t.buttonLabel1}
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="info">
                        {t.buttonLabel2}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
