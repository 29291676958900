export const i18nAdminIScreateUpdateModal = {
	createTitle: {
		bg: 'Създаване на вътрешен график',
		en: 'Create internal schedule'
	},
	updateTitle: {
		bg: 'Промяна на вътрешен график',
		en: 'Update internal schedule'
	},
	dateStart: {
		bg: 'Дата старт',
		en: 'Pickup date'
	},
	deadline: {
		bg: 'Краен срок',
		en: 'Pickup country'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	buttonLabel1: {
		bg: 'Създай',
		en: 'Create'
	},
	buttonLabel2: {
		bg: 'Обнови',
		en: 'Update'
	},
	buttonLabel3: {
		bg: 'Затвори',
		en: 'Close'
	}
}
