import { Button, TextField } from '@mui/material'

import { FormikError } from '../../utils/formik/FormikError'
import { useTranslate } from '../../hooks/translate'
import { i18nForgetPass } from './i18n'

export function ForgetPasswordForm(props: any): JSX.Element {
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = props

    const t = useTranslate(i18nForgetPass)

    return (
        <form className="forget-password-form" onSubmit={handleSubmit}>
            <div className="fp-input-field">
                <TextField
                    fullWidth
                    name="email"
                    label={t.email}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                />
                <FormikError name="email" errors={errors} touched={touched} />
            </div>
            <div className="register-input-field"></div>
            <Button
                fullWidth
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                className="fp-submit-btn"
            >
                {t.buttonLabel}
            </Button>
        </form>
    )
}
