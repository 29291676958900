import { createReducer } from '@reduxjs/toolkit'

import { ActionTypes } from '../actionTypes'

export interface LoaderState {
    isLoading: boolean
}

const initialState: LoaderState = {
    isLoading: false,
}

export const loaderReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(ActionTypes.loader.load.typeName, (state: LoaderState) => {
			state.isLoading = true
		})
		.addCase(ActionTypes.loader.unload.typeName, (state: LoaderState) => {
			state.isLoading = false
		})
})
