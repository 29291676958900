//@ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../../data/store'
import { Order } from '../../../../data/reducers/ordersReducer'
import { calculatePackagesTotalWeight, calculateTransportationPrice } from '../../../../utils/functions'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function IncomesGraphic() {
	const { orders } = useSelector((state: GlobalState) => state.orders)
	const { prices } = useSelector((state: GlobalState) => state.settings)

	const incomesData = mapIncomes(orders, prices)
	const incomesDataPoints = incomesData.map(incomesByMonth => ({
		x: incomesByMonth.date,
		y: incomesByMonth.incomes
	}))

	const weightsDataPoints = incomesData.map(incomesByMonth => ({
		x: incomesByMonth.date,
		y: incomesByMonth.weights
	}))

	const options = {
		theme: "light2",
		animationEnabled: true,
		title: {
			text: "Incomes"
		},
		data: [
			{
				type: "spline",
				name: 'Amount BGN',
				showInLegend: true,
				xValueFormatString: "MMM YYYY",
				yValueFormatString: "#,##0.00 BGN",
				dataPoints: incomesDataPoints
			},
			{
				type: "spline",
				name: 'Weight KG',
				showInLegend: true,
				xValueFormatString: "MMM YYYY",
				yValueFormatString: "#,##0.00 kg",
				dataPoints: weightsDataPoints
			},
		]
	}

	return <CanvasJSChart options={options} />
}

function mapIncomes(orders: Order[], prices: any) {
	const yearAgo = new Date()
	yearAgo.setFullYear(yearAgo.getFullYear() - 1)
	yearAgo.setHours(0, 0, 0, 0)

	const currentDate = new Date()
	currentDate.setMonth(currentDate.getMonth() - 1)

	const incomesByMonth = []
	while (
		yearAgo.getTime() <= currentDate.getTime()
	) {
		yearAgo.setMonth(yearAgo.getMonth() + 1)
		const fromDateEpoch = yearAgo.getTime()

		const toDate = new Date(yearAgo)
		toDate.setMonth(toDate.getMonth() + 1)
		const toDateEpoch = toDate.getTime()

		const ordersForCurrentMonth = orders.filter(order => (
			order.registerDateEpoch >= fromDateEpoch && 
			order.registerDateEpoch <= toDateEpoch
		))

		const mapped = ordersForCurrentMonth?.map(x => ({
            count: x.packages.length,
            // @ts-ignore
            weight: calculatePackagesTotalWeight(x.packages || []),
            income: calculateTransportationPrice(x.packages || [], prices),
            deliveryCompany: x.deliveryCompany
        }))

		const incomes = mapped.map((x: any) => +x.income).reduce((a: any, b: any) => Math.ceil(a + b), 0)
		const mappedWeights = mapped.map((x: any) => {
			if (isNaN(x.weight)) {
				return 0
			}

			return +x.weight
		})
		const weights = mappedWeights.reduce((a: any, b: any) => Math.ceil(a + b), 0)

		incomesByMonth.push({
			date: new Date(yearAgo),
			month: yearAgo.getMonth(),
			incomes,
			weights
		})
	}

	incomesByMonth.pop()

	return incomesByMonth
}