export const i18nProfileEditOrder = {
	title: {
		bg: 'Данни за пратка на',
		en: 'Details for package of '
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	delivery: {
		bg: 'Доставка',
		en: 'Delivery'
	},
	tracking: {
		bg: 'Проследяване',
		en: 'Tracking'
	},
	items: {
		bg: 'Пакети',
		en: 'Items'
	},
	itDeliveryCompany: {
		bg: 'Доставчик Италия',
		en: 'Italian delivery company'
	},
	trackingNumber: {
		bg: 'Номер за проследяване',
		en: 'Tracking number'
	},
	count: {
		bg: 'Брой пакети',
		en: 'Items count'
	},
	totalWeight: {
		bg: 'Общо тегло',
		en: 'Total weight'
	},
	origin: {
		bg: 'Поръчано от',
		en: 'Ordered from'
	},
	valueEur: {
		bg: 'Стойност в EUR',
		en: 'Value EUR'
	},
	description: {
		bg: 'Описание',
		en: 'Description'
	},
	reference: {
		bg: 'Референтен номер или фактура',
		en: 'Reference number of invoice'
	},
	transactionDate: {
		bg: 'Дата на транзакцията',
		en: 'Transaction date'
	},
	address: {
		bg: 'Адрес за доставка',
		en: 'Delivery address'
	},
	phone: {
		bg: 'Телефон на получателя',
		en: 'Receiver phone'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	deliveryPrice: {
		bg: 'Цена за доставка',
		en: 'Delivery price'
	},
	deleteItem: {
		bg: 'Изтрий пакет',
		en: 'Delete item'
	},
	addItem: {
		bg: 'Добави пакет',
		en: 'Add item'
	},
	buttonLabel1: {
		bg: 'Запази',
		en: 'Save'
	},
	buttonLabel2: {
		bg: 'Затвори',
		en: 'Close'
	}
}
