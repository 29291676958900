export const i18nDeliveryInformation = {
    deliveryCompanyAddressType: {
        bg: 'Доставка до',
		en: 'Delivery to'
    },
    deliveryCompany: {
        bg: 'Доставка с куриер (в БГ) *',
		en: 'Delivery courier (in BG) *'
    },
    deliveryCompanyAddressTypeOffice: {
        bg: 'Офис',
		en: 'Office'
    },
    deliveryCompanyAddressTypeAddress: {
		bg: 'Адрес',
		en: 'Address'
	},
    address: {
        bg: 'Адрес за доставка *',
		en: 'Delivery address *'
    },
}
