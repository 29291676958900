import { AnyAction, createReducer } from "@reduxjs/toolkit"
import { ActionTypes } from "../actionTypes"

export type CrossBorderScheduleStatus = 'EXPECT_TO_PICKUP' | 'PICKED_UP' | 'DELIVERED' | 'COMPLETED'

export const CROSS_BORDER_SCHEDULE_STATUS = {
	EXPECT_TO_PICKUP: 'EXPECT_TO_PICKUP' as CrossBorderScheduleStatus,
	PICKED_UP: 'PICKED_UP' as CrossBorderScheduleStatus,
	DELIVERED: 'DELIVERED' as CrossBorderScheduleStatus,
	COMPLETED: 'COMPLETED' as CrossBorderScheduleStatus
}

export interface CrossBorderSchedule {
	_id: string
	status: CrossBorderScheduleStatus
	fromCountry: string
	toCountry: string
	fromCity: string
	toCity: string
	pickupDate: number
	deliveryDate: number
	creationDate: number
	createdBy: string
	lastUpdated?: number
	lastUpdaterId?: string
	orders: string[]
}

export interface CrossBorderSchedulesState {
	schedules: CrossBorderSchedule[]
}

const initialState = {
	schedules: []
} as CrossBorderSchedulesState

export const crossBorderSchedulesReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(ActionTypes.crossBorderSchedules.all.fulfilled(), (state: CrossBorderSchedulesState, action: AnyAction) => {
			state.schedules = action.payload
		})
		.addCase(ActionTypes.crossBorderSchedules.allActive.fulfilled(), (state: CrossBorderSchedulesState, action: AnyAction) => {
			state.schedules = action.payload
		})
		.addCase(ActionTypes.crossBorderSchedules.create.fulfilled(), (state: CrossBorderSchedulesState, action: AnyAction) => {
			state.schedules = [
				...state.schedules,
				action.payload
			]
		})
		.addCase(ActionTypes.crossBorderSchedules.update.fulfilled(), (state: CrossBorderSchedulesState, action: AnyAction) => {
			const schedule = state.schedules.find(x => x._id === action.payload._id)
			if (schedule) {
				const index = state.schedules.indexOf(schedule)
				state.schedules.splice(index, 1, {
					...schedule,
					...action.payload
				})
			}
		})
		.addCase(ActionTypes.crossBorderSchedules.delete.fulfilled(), (state: CrossBorderSchedulesState, action: AnyAction) => {
			const index = state.schedules.findIndex(x => x._id === action.payload._id)
			state.schedules.splice(index, 1)
		})
		.addCase(ActionTypes.crossBorderSchedules.complete.fulfilled(), (state: CrossBorderSchedulesState, action: AnyAction) => {
			const schedule = state.schedules.find(x => x._id === action.payload.scheduleId)
			if (!schedule) {
				return
			}

			schedule.status = CROSS_BORDER_SCHEDULE_STATUS.COMPLETED
			const index = state.schedules.indexOf(schedule)
			state.schedules.splice(index, 1, schedule)
		})
})