import { useSelector } from 'react-redux'
import { Autocomplete, Button, TextField } from '@mui/material'

import { __defaultEcontDeliveryAddress } from '../../../data/reducers/usersReducer'
import { useEcontOffices } from '../../../hooks/useEcontOffices'
import { FormikError } from '../../../utils/formik/FormikError'
import { useTranslate } from '../../../hooks/translate'
import { GlobalState } from '../../../data/store'
import { i18nProfileInfo } from './i18n'

export function ProfileInformationForm(props: any) {
    const { values, touched, errors, handleChange, setFieldValue, handleSubmit, handleBlur, isCompany, isEmployee } = props

    const t = useTranslate(i18nProfileInfo)

    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const econtOffices = useEcontOffices()

    const handleChangeDefaultEcontOfficeDeliveryAddress = (e: any, args: any) => {
        setFieldValue('defaultEcontOfficeDeliveryAddress', args?.names || __defaultEcontDeliveryAddress)
    }

    return (
        <form onSubmit={handleSubmit} className="profile-information-form">
            <div className="profile-information-inputs-only">
                <div className="profile-information-input-field">
                    <TextField
                        disabled
                        fullWidth
                        name="uniqueId"
                        label={t.yourId}
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.uniqueId}
                        onChange={handleChange}
                    />
                    <FormikError name="uniqueId" errors={errors} touched={touched} />
                </div>
                <div className="profile-information-input-field">
                    <TextField
                        disabled
                        fullWidth
                        type="email"
                        name="email"
                        label={t.email}
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                    />
                    <FormikError name="email" errors={errors} touched={touched} />
                </div>
                {isCompany ? (
                    <>
                        <div className="profile-information-input-field">
                            <TextField
                                disabled
                                fullWidth
                                name="companyName"
                                variant="outlined"
                                onBlur={handleBlur}
                                label={t.companyName}
                                onChange={handleChange}
                                value={values.companyName}
                            />
                            <FormikError name="companyName" errors={errors} touched={touched} />
                        </div>
                        <div className="profile-information-input-field">
                            <TextField
                                disabled
                                fullWidth
                                name="companyId"
                                variant="outlined"
                                label={t.companyId}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyId}
                            />
                            <FormikError name="companyId" errors={errors} touched={touched} />
                        </div>
                        <div className="profile-information-input-field">
                            <TextField
                                disabled
                                fullWidth
                                variant="outlined"
                                name="companyOwner"
                                onBlur={handleBlur}
                                label={t.companyOwner}
                                onChange={handleChange}
                                value={values.companyOwner}
                            />
                            <FormikError name="companyOwner" errors={errors} touched={touched} />
                        </div>
                        <div className="profile-information-input-field">
                            <TextField
                                fullWidth
                                variant="outlined"
                                onBlur={handleBlur}
                                name="companyAddress"
                                onChange={handleChange}
                                label={t.companyAddress}
                                value={values.companyAddress}
                            />
                            <FormikError name="companyAddress" errors={errors} touched={touched} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="profile-information-input-field">
                            <TextField
                                fullWidth
                                label={t.name}
                                name="firstName"
                                variant="outlined"
                                onBlur={handleBlur}
                                disabled={isEmployee}
                                onChange={handleChange}
                                value={values.firstName}
                            />
                            <FormikError name="firstName" errors={errors} touched={touched} />
                        </div>
                        <div className="profile-information-input-field">
                            <TextField
                                fullWidth
                                name="lastName"
                                label={t.surname}
                                variant="outlined"
                                onBlur={handleBlur}
                                disabled={isEmployee}
                                value={values.lastName}
                                onChange={handleChange}
                            />
                            <FormikError name="lastName" errors={errors} touched={touched} />
                        </div>
                    </>
                )}
                <div className="profile-information-input-field">
                    <TextField
                        fullWidth
                        name="country"
                        label={t.country}
                        variant="outlined"
                        onBlur={handleBlur}
                        disabled={isEmployee}
                        value={values.country}
                        onChange={handleChange}
                    />
                    <FormikError name="country" errors={errors} touched={touched} />
                </div>
                <div className="profile-information-input-field">
                    <TextField
                        fullWidth
                        name="city"
                        label={t.city}
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.city}
                        disabled={isEmployee}
                        onChange={handleChange}
                    />
                    <FormikError name="city" errors={errors} touched={touched} />
                </div>
                <div className="profile-information-input-field">
                    <TextField
                        fullWidth
                        name="address"
                        label={t.address}
                        variant="outlined"
                        onBlur={handleBlur}
                        disabled={isEmployee}
                        value={values.address}
                        onChange={handleChange}
                    />
                    <FormikError name="address" errors={errors} touched={touched} />
                </div>
                <div className="profile-information-input-field">
                    <TextField
                        fullWidth
                        label={t.phone}
                        name="phoneNumber"
                        variant="outlined"
                        onBlur={handleBlur}
                        disabled={isEmployee}
                        onChange={handleChange}
                        value={values.phoneNumber}
                    />
                    <FormikError name="phoneNumber" errors={errors} touched={touched} />
                </div>
                <div className="profile-information-input-field">
                    <Autocomplete
                        fullWidth
                        autoHighlight
                        options={econtOffices}
                        onChange={handleChangeDefaultEcontOfficeDeliveryAddress}
                        value={values.defaultEcontOfficeDeliveryAddress?.[lang] || ''}
                        renderInput={(params) => <TextField label={t.defaultEcontOfficeDeliveryAddress} {...params} />}
                    />
                    <FormikError name="defaultEcontOfficeDeliveryAddress" errors={errors} touched={touched} />
                </div>
            </div>
            <Button
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                className="submit-profile-information"
            >
                {t.buttonLabel}
            </Button>
        </form>
    )
}
