import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Button, Paper, TextField, Typography } from '@mui/material'

import { i18nAdminSettings } from '../i18n'
import { ROLES } from '../../../../utils/roles'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import { getPricesSettings, updatePricesSettings } from '../../../../data/actions/settingsActions'

import './styles.css'

export function SettingsSectorMobileComponent(props: any): JSX.Element {
    const { getPricesSettings, updatePricesSettings } = props

    const { loggedIn } = useSelector((state: GlobalState) => state.users)
    const { prices } = useSelector((state: GlobalState) => state.settings)

    const [pricePerKg, setPricePerKg] = useState(prices.pricePerKg || 0)
    const [processPricePerPackage, setProcessPricePerPackage] = useState(prices.processPricePerPackage || 0)
    const [priceDeliverySofia, setPriceDeliverySofia] = useState(prices.priceDeliverySofia || 0)
    const [unregisteredOrdersPenaltyFee, setUnregisteredOrdersPenaltyFee] = useState(
        prices.unregisteredOrdersPenaltyFee || 0
    )

    const t = useTranslate(i18nAdminSettings)

    useEffect(() => {
        getPricesSettings()
    }, [getPricesSettings])

    const handleChangePricePerKg = (ev: any) => setPricePerKg(ev.target.value)
    const handleChangeProcessPricePerPackage = (ev: any) => setProcessPricePerPackage(ev.target.value)
    const handleChangePriceDeliverySofia = (ev: any) => setPriceDeliverySofia(ev.target.value)
    const handleChangeUnregisteredOrdersFee = (ev: any) => setUnregisteredOrdersPenaltyFee(ev.target.value)

    const handleSavePriceSettings = () => {
        if (isNaN(pricePerKg) || isNaN(processPricePerPackage)) {
            return
        }

        updatePricesSettings({
            userId: loggedIn._id,
            pricePerKg: +pricePerKg,
            priceDeliverySofia: +priceDeliverySofia,
            processPricePerPackage: +processPricePerPackage,
            unregisteredOrdersPenaltyFee: +unregisteredOrdersPenaltyFee,
        })
    }

    const isReadonly = loggedIn.role !== ROLES.ADMIN

    return (
        <div className="settings-container-mobile">
            <Paper className="prices-container-mobile" elevation={5}>
                <Typography variant="h5" align="center">
                    {t.pricesTitle}
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    label={t.kgLabel}
                    variant="outlined"
                    value={pricePerKg}
                    disabled={isReadonly}
                    className="price-input"
                    onChange={handleChangePricePerKg}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={isReadonly}
                    label={t.processLabel}
                    className="price-input"
                    value={processPricePerPackage}
                    onChange={handleChangeProcessPricePerPackage}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={isReadonly}
                    className="price-input"
                    value={priceDeliverySofia}
                    label={t.sofiaDeliveryLabel}
                    onChange={handleChangePriceDeliverySofia}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={isReadonly}
                    className="price-input"
                    label={t.unregisteredOrderLabel}
                    value={unregisteredOrdersPenaltyFee}
                    onChange={handleChangeUnregisteredOrdersFee}
                />
                {!isReadonly && (
                    <div className="submit-btn-container">
                        <Button variant="contained" size="large" color="secondary" onClick={handleSavePriceSettings}>
                            {t.buttonLabel}
                        </Button>
                    </div>
                )}
            </Paper>
        </div>
    )
}

const mapDispatchToProps = { getPricesSettings, updatePricesSettings }

export const SettingsSectorMobile = connect(null, mapDispatchToProps)(SettingsSectorMobileComponent)
