import { Formik, FormikProps } from "formik"
import { Paper, Typography } from "@mui/material"

import { i18nFreightCalc } from "./i18n"
import { useTranslateSingle } from "../../../hooks/translate"
import { CalcFreightsMobileForm } from "./mobile/CalcFreightsMobileForm"
import { CalcFreightsDesktopForm } from "./desktop/CalcFreightsDesktopForm"
import { DeviceComponents, useResponsive } from "../../../hooks/useResponsive"
import { DeviceStrings, useResponsiveString } from "../../../hooks/useResponsiveString"

export interface IFreightCalcFormValues {
    freightsCount: number
    fromCountry: string
    toCountry: string
    fromRegion: string
    toRegion: string
}

function FormComponent(props: FormikProps<IFreightCalcFormValues>): JSX.Element {
    const components = (props: FormikProps<IFreightCalcFormValues>) => ({
        mobile: <CalcFreightsMobileForm {...props} />,
        smallDesktop: <CalcFreightsDesktopForm {...props} />,
    }) as DeviceComponents

    const formComponent = components(props)

    return useResponsive(formComponent)
}

export function CalcFreightsForm(): JSX.Element {
    const title = useTranslateSingle(i18nFreightCalc.title)

    const classNames = {
        mobile: 'price-calc-freight-container-mobile',
        smallDesktop: 'price-calc-freight-container',
    } as DeviceStrings

    const className = useResponsiveString(classNames)

    return (
        <div className={className}>
            <Paper className="price-calc-form-paper" elevation={3}>
                <Typography variant="h5" className="price-calc-header">
                    {title}
                </Typography>
                <Formik
                    initialValues={{
                        freightsCount: 1,
                        showRequestButton: true,
                        fromCountry: 'Italy',
                        fromRegion: 'Rome',
                        toCountry: 'Bulgaria',
                        toRegion: 'Sofia',
                    } as IFreightCalcFormValues}
                    onSubmit={(values: any, { setSubmitting, setFieldValue }) => {
                        setSubmitting(true)

                        const { fromRegion, toRegion } = values
                        const noAutoPriceFromRegions = fromRegion !== 'Sofia' && fromRegion !== 'Rome'
                        const noAutoPriceToRegions = toRegion !== 'Sofia' && toRegion !== 'Rome'
                        if (noAutoPriceFromRegions || noAutoPriceToRegions) {
                            setFieldValue('price', 0)
                            return
                        }

                        const totalPrice = 0 // TODO: make freight calc automation 
                        setFieldValue('price', totalPrice)
                    }}
                >
                    {(props) => <FormComponent {...props} />}
                </Formik>
            </Paper>
        </div>
    )
}