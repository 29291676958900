import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { i18nStepsBanner } from '../i18n'
import { useTranslate } from '../../../../../hooks/translate'

import Step1Image from '../../../../../assets/vectors/home-page_register-icon-image.svg'
import Step2Image from '../../../../../assets/vectors/home-page_register-order-icon-image.svg'
import Step3Image from '../../../../../assets/vectors/home-page_delivery-price-icon-image.svg'
import Step4Image from '../../../../../assets/vectors/home-page_delivery-schedule-icon-image.svg'

import './styles.css'

export function HomeStepsMobile(): JSX.Element {
    const t = useTranslate(i18nStepsBanner)

    return (
        <section className="home-steps-mobile">
            <div className="subsection">
                <div className="info-content">
                    <div className="subsection-header">
                        <Typography variant="body1">{t.step1}</Typography>
                        <Typography variant="h6">{t.step1Title}</Typography>
                    </div>
                    <div className="image-wrapper">
                        <img className="content-image" src={Step1Image} alt="login-register" />
                    </div>
                    <Typography variant="body2" className="subsection-description">
                        {t.step1Description}
                    </Typography>
                    <div className="action-buttons">
                        <Link to="/login">
                            <Button variant="outlined" color="secondary">
                                {t.step1ButtonLabel1}
                            </Button>
                        </Link>
                        <Link to="/register">
                            <Button variant="contained" color="secondary">
                                {t.step1ButtonLabel2}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="subsection">
                <div className="info-content">
                    <div className="subsection-header">
                        <Typography variant="body1">{t.step2}</Typography>
                        <Typography variant="h6">{t.step2Title}</Typography>
                    </div>
                    <div className="image-wrapper">
                        <img className="content-image" src={Step2Image} alt="register-order" />
                    </div>
                    <Typography variant="body2" className="subsection-description">
                        {t.step2Description}
                    </Typography>
                    <div className="action-buttons">
                        <Link to="/register-package">
                            <Button variant="contained" color="secondary">
                                {t.step2ButtonLabel}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="subsection">
                <div className="info-content">
                    <div className="subsection-header">
                        <Typography variant="body1">{t.step3}</Typography>
                        <Typography variant="h6">{t.step3Title}</Typography>
                    </div>
                    <div className="image-wrapper">
                        <img className="content-image" src={Step3Image} alt="delivery-prices" />
                    </div>
                    <Typography variant="body2" className="subsection-description">
                        {t.step3Description}
                    </Typography>
                    <div className="action-buttons">
                        <Link to="/pricing">
                            <Button variant="contained" color="secondary">
                                {t.step3ButtonLabel}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="subsection">
                <div className="info-content">
                    <div className="subsection-header">
                        <Typography variant="body1">{t.step4}</Typography>
                        <Typography variant="h6">{t.step4Title}</Typography>
                    </div>
                    <div className="image-wrapper">
                        <img className="content-image" src={Step4Image} alt="delivery-schedule" />
                    </div>
                    <Typography variant="body2" className="subsection-description">
                        {t.step4Description}
                    </Typography>
                    <div className="action-buttons">
                        <Link to="/schedules">
                            <Button variant="contained" color="secondary">
                                {t.step4ButtonLabel}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
