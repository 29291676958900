import { TopNavBar } from './topNavBar/TopNavBar'
import { NavBarMobile } from './mainNavBar/mobile/NavBarMobile'
import { TopmostMessage } from './topmostMessage/TopmostMessage'
import { NavBarDesktop } from './mainNavBar/desktop/NavBarDesktop'
import { DeviceComponents, useResponsive } from '../../hooks/useResponsive'
import { DeviceStrings, useResponsiveString } from '../../hooks/useResponsiveString'

import './styles.css'

export default function NavBar() {
    const components = {
        mobile: <NavBarMobile />,
        smallDesktop: <NavBarDesktop />,
    } as DeviceComponents

    const classNames = {
        mobile: 'nav-bars-container-mobile',
        smallDesktop: 'nav-bars-container',
    } as DeviceStrings

    const containerClassName = useResponsiveString(classNames)

    return (
        <div className={containerClassName}>
            <TopmostMessage />
            <TopNavBar />
            {useResponsive(components)}
        </div>
    )
}
