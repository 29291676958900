import { Order } from "../../../../data/reducers/ordersReducer";
import { InternalScheduleDeliveryCard } from "./InternalScheduleDeliveryCard";

export function InternalScheduleCards(props: any) {
    const { orders, showNotification, toggleStatus } = props

    return orders?.map((order: Order, i: number) => (
        <InternalScheduleDeliveryCard
            key={i}
            order={order}
            toggleStatus={toggleStatus}
            showNotification={showNotification}
        />
    ))
}