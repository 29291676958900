import { Typography } from '@mui/material'

import { i18nViews } from './i18n'
import { useTranslateSingle } from '../../hooks/translate'

export function UnauthenticatedView(): JSX.Element {
    const warnText = useTranslateSingle(i18nViews.unuathenticatedLabel)
    return (
        <div className="unauthenticated-container">
            <Typography variant="h4">{warnText}</Typography>
        </div>
    )
}
