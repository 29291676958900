import { Box } from '@mui/material'

import './styles.css'

export function TabPanel(props: any) {
    const { children, boxClassName, value, index, ...other } = props

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box className={boxClassName} sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}
