export const i18nAdminCBS = {
	createSchedule: {
		bg: 'Създай график',
		en: 'Create schedule'
	},
	confirmDelete: {
		bg: 'Сигурни ли сте, че искате да изтриете избрания международен график?',
		en: 'Are your sure you want to delete selected cross border schedule?'
	},
	confirmComplete: {
		bg: 'Сигурни ли сте, че искате да маркирате избрания международен график като готов? Това също така ще маркира всички поръчки вътре в него като пристигнали в България.',
		en: 'Are you sure you want to complete the selected cross border schedule? This will also mark all the orders inside it as arrived in Bulgaria.'
	},
	confirmNotifyViaEmail: {
		bg: 'Сигурни ли сте, че искате да известите всички получатели, че пратките им са натоварени?',
		en: 'Are you sure you want to notify all receivers about their packages being picked up?'
	},
	actions: {
		bg: 'Действия',
		en: 'Actions'
	},
	warnNoOrders: {
		bg: 'Избрания график няма добавени пратки',
		en: 'Selected schedule does not have packages'
	},
	downloadFile: {
		bg: 'Изтегли .docx файл',
		en: 'Download .docx file'
	},
	addOrders: {
		bg: 'Добави пратки',
		en: 'Add packages'
	},
	notifyEmail: {
		bg: 'Изпрати имейл до получателите',
		en: 'Send emails to all receivers'
	},
	completeSchedule: {
		bg: 'Маркирай като готов',
		en: 'Mark as complete'
	},
	deleteSchedule: {
		bg: 'Изтрий график',
		en: 'Delete schedule'
	},

	// BEGIN table heads
	pickupLocation: {
		bg: 'Тръгва от',
		en: 'Pickup place'
	},
	deliveryLocation: {
		bg: 'Пристига в',
		en: 'Arrive place'
	},
	pickupDate: {
		bg: 'Дата тръгва',
		en: 'Pickup date'
	},
	arriveDate: {
		bg: 'Дата пристига',
		en: 'Arrive date'
	},
	dateCreated: {
		bg: 'Създаден',
		en: 'Date created'
	},
	ordersCount: {
		bg: 'Брой пратки',
		en: 'Packages count'
	}
	// END table heads
}
