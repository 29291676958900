import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Fade } from 'react-awesome-reveal'

import { i18nRegOrderBanner } from '../i18n'
import { easeTimes } from '../../../utils/easeTimes'
import { useTranslate } from '../../../hooks/translate'

import './styles.css'

export function RegisterOrderBannerDesktop(): JSX.Element {
    const t = useTranslate(i18nRegOrderBanner)

    return (
        <section className="register-order-banner">
            <div className="text-content">
                <Fade cascade damping={0.3} triggerOnce duration={easeTimes.slow} direction="left">
                    <Typography variant="h3" className="order-question-label">
                        {t.question}
                    </Typography>
                    <Typography variant="h3" className="register-order-label">
                        {t.action}
                    </Typography>
                    <Link to="/register-package">
                        <Button className="register-order-button" size="large" variant="contained" color="secondary">
                            {t.buttonLabel}
                        </Button>
                    </Link>
                </Fade>
            </div>
        </section>
    )
}
