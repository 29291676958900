import { getOrderStatusChip } from '../../../../components/Shared'

import { ORDER_STATUS, Order } from '../../../../data/reducers/ordersReducer'
import { calculatePackagesTotalWeight, calculateTransportationPrice, getFormattedDate, mapPackagesStringSizesToPackagesObjectsSizes } from '../../../../utils/functions'

export const tableStruct = (heads: string[], lang: string) => [
    {
        head: heads[0],
        getData: (obj: Order) => getFormattedDate(true, true, true, true, obj.registerDateEpoch),
        sortable: true,
        name: 'registerDateEpoch',
    },
    {
        head: heads[1],
        getData: (obj: Order) => getOrderStatusChip(obj.status, lang),
        sortable: true,
        name: 'status',
    },
    {
        head: heads[2],
        // @ts-ignore
        getData: (obj: Order) => obj.deliverTo === 'office' ? obj.econtOfficeDeliveryAddress?.[lang] || '' : obj.deliveryAddress,
        sortable: false,
    },
    {
        head: heads[3],
        getData: (obj: Order) => {
            const totalWeight = calculatePackagesTotalWeight(obj?.packages || [])
            const kg = lang === 'bg' ? 'кг.' : 'kg.'
            return `${Number(totalWeight).toFixed(2)} ${kg}`
        },
    },
    {
        head: heads[4],
        getData: (obj: Order) => obj.packages.length,
        sortable: true,
        name: 'count',
    },
    {
        head: heads[5],
        getData: (obj: Order) => obj.siteOrigin,
        sortable: false,
    },
    {
        head: heads[6],
        getData: (obj: Order) => obj.deliveryCompany,
        sortable: false,
    },
    {
        head: heads[7],
        getData: (obj: Order, priceSettings: any) => {
            if (obj.status !== ORDER_STATUS.ARRIVED_BG) {
                return lang === 'bg' ? 'неизмерена' : 'not measured'
            }

            const isTuttoDelivery = obj.deliveryCompany === 'tuttopost'
            const packagesInfo = mapPackagesStringSizesToPackagesObjectsSizes(obj.packages)
            const price = calculateTransportationPrice(packagesInfo, priceSettings, isTuttoDelivery, !!obj.unregistered)
            const denomination = lang === 'bg' ? 'лв' : 'BGN'
            return `${price} ${denomination}`
        },
    },
    {
        head: heads[8],
        getData: () => {

        }
    }
]
