export const i18nAdminOrders = {
	addUnregistered: {
		bg: 'Добави нерегистрирана пратка',
		en: 'Add unregistered package'
	},
	deleteOrder: {
		bg: 'Изтрий пратка',
		en: 'Delete package'
	},
	createLabel: {
		bg: 'Създай товарителница',
		en: 'Create label'
	},
	confirmDelete: {
		bg: 'Сигурни ли сте, че искате да изтриете избраната пратка?',
		en: 'Are your sure you want to delete selected package?'
	},
	confirmCombine: {
		bg: 'Сигурни ли сте, че искате да комбинирате избраните пратки?',
		en: 'Are you sure you want to combine selected packages?'
	},
	actions: {
		bg: 'Действия',
		en: 'Actions'
	},
	search: {
		bg: 'Търсене',
		en: 'Search'
	},
	typeTerm: {
		bg: 'Напиши част от',
		en: 'Type term'
	},
	searchBy: {
		bg: 'Търсене по',
		en: 'Search by'
	},
	combineOrders: {
		bg: 'Комбинирай',
		en: 'Combine'
	},
	// BEGIN table heads
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	phone: {
		bg: 'Телефон',
		en: 'Phone'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	count: {
		bg: 'Брой',
		en: 'Count'
	},
	registerDate: {
		bg: 'Дата регистриране',
		en: 'Register date'
	},
	orderId: {
		bg: 'ТТП Номер',
		en: 'TTP Number'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	// END table heads

	// BEGIN package manager translations
	packageLabel: {
		bg: 'Пакет',
		en: 'Item'
	},
	processedLabel: {
		bg: 'Обработена',
		en: 'Processed'
	},
	measuredWeight: {
		bg: 'Измерено тегло кг.',
		en: 'Measured weight kg.'
	},
	measuredSizes: {
		bg: 'Измерени размери см.',
		en: 'Measured sizes cm.'
	},
	addPackage: {
		bg: 'Добави пакет',
		en: 'Add item'
	},
	deliveryPrice: {
		bg: 'Цена за доставка',
		en: 'Delivery price'
	},
	// END package manager translations
}
