import { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

import { i18nEntryModal } from "./i18n"
import { texts } from "../../utils/i18n/texts"
import { useTranslate } from "../../hooks/translate"

import MainLogo from '../../assets/vectors/TUTTO-POST_MAIN-LOGO.svg'

import './styles.css'

export function EntryModal() {
    const isEntryModalClosed = localStorage.getItem('isEntryModalClosed')

    const [isOpen, setIsOpen] = useState(!isEntryModalClosed && isEntryModalClosed !== 'true')

    const t = useTranslate(i18nEntryModal)

    const handleAgree = () => {
        localStorage.setItem('isEntryModalClosed', 'true')
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen} className='entry-modal-container'>
            <DialogTitle>
                <div className='logo-wrapper'>
                    <img src={MainLogo} alt="main-logo" className="main-logo-img" />
                </div>
                <br />
                <Typography variant='h3' align='center'>
                    {t.title}
                </Typography>
            </DialogTitle>
            <DialogContent className='entry-modal-container-contents'>
                <DialogContentText>
                    <Typography variant='h5' className='entry-modal-message-1'>
                        {t.message1}
                    </Typography>
                </DialogContentText>
                <br />
                <DialogContentText>
                    <Typography variant='h5' className='entry-modal-message-2'>
                        {t.message2}
                    </Typography>
                    <div className='entry-modal-message-3'>
                        <Typography variant='h4'>
                            {texts.itAddress1Part}
                        </Typography>
                        <Typography variant='h4'>
                            {texts.itAddress2Part}
                        </Typography>
                        <Typography variant='h4'>
                            {texts.itAddress3Part}
                        </Typography>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    className='entry-modal-agree-button'
                    onClick={handleAgree}
                    variant="contained"
                    color='secondary'
                    size='large'
                >
                    {t.confirmButton}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
