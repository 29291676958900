import { connect } from 'react-redux'
import { Typography } from '@mui/material'
import { Fade } from 'react-awesome-reveal'

import { i18nLanding } from '../i18n'
import { easeTimes } from '../../../../../utils/easeTimes'
import { useTranslate } from '../../../../../hooks/translate'
import { changeLanguage } from '../../../../../data/actions/languageActions'

import './styles.css'

export function HomeLandingDesktopC(props: any): JSX.Element {
    const t = useTranslate(i18nLanding)

    const changeLang = () => {
        const newLang = 'en'
        props.changeLanguage(newLang)
    }

    return (
        <section className="landing">
            <div className="main-content">
                <Fade triggerOnce cascade damping={0.1} direction="up" duration={easeTimes.slow}>
                    <Typography variant="h4" className="top-label" onClick={changeLang}>
                        {t.topLabel}
                    </Typography>
                    <Typography variant="h2" className="middle-label">
                        {t.midLabel}
                    </Typography>
                    <Typography variant="h5" className="bottom-label">
                        {t.botLabel}
                    </Typography>
                </Fade>
            </div>
        </section>
    )
}

const mapDispatchToProps = { changeLanguage }

export const HomeLandingDesktop = connect(null, mapDispatchToProps)(HomeLandingDesktopC)
