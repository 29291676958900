import { createAsyncThunk } from "@reduxjs/toolkit"

import { isAuthRequest } from "./utils"
import { hideLoader } from "./loaderActions"
import { ActionTypes } from "../actionTypes"
import { getTranslation } from "./translation"
import { messages } from "../../utils/i18n/messages"
import { showNotification } from "./notificationActions"
import { ordersService } from "../../services/ordersService"
import { Order, OrderStatus } from "../reducers/ordersReducer"
import { internalSchedulesService } from "../../services/internalSchedules"
import { InternalSchedule, InternalScheduleStatus } from "../reducers/internalSchedulesReducer"

export const getActiveInternalSchedules = createAsyncThunk(
	ActionTypes.internalSchedules.allActive.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await internalSchedulesService.getAllActive()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const getCompletedInternalSchedules = createAsyncThunk(
	ActionTypes.internalSchedules.allCompleted.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await internalSchedulesService.getAllComplete()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const createInternalSchedule = createAsyncThunk(
	ActionTypes.internalSchedules.create.typeName,
	async (data: InternalSchedule, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await internalSchedulesService.create(data)
			if (!response.isSuccess) {
				dispatch(showNotification(response.message, 'error'))
				return
			}

			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.createInternalScheduleSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdateInternalScheduleInterface {
	status?: InternalScheduleStatus
	pickupDate?: number
	deliveryDate?: number
	userId: string
}
export const updateInternalSchedule = createAsyncThunk(
	ActionTypes.internalSchedules.update.typeName,
	async (data: UpdateInternalScheduleInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await internalSchedulesService.update(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.updateInternalScheduleSuccess, 'success'))

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface CompleteInternalScheduleInterface {
	scheduleId: string
	userId: string
	ordersIds: string[]
}
export const completeInternalSchedule = createAsyncThunk(
	ActionTypes.internalSchedules.complete.typeName,
	async (data: CompleteInternalScheduleInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await internalSchedulesService.completeSchedule(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface DeleteInternalScheduleInterface {
	_id: string
}
export const deleteInternalSchedule = createAsyncThunk(
	ActionTypes.internalSchedules.delete.typeName,
	async (data: DeleteInternalScheduleInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await internalSchedulesService.delete(data._id)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.deleteInternalScheduleSuccess, 'success'))

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdateDeliveriesOrderInterface {
	scheduleId: string
	deliveries: Order[]
}
export const changeDeliveriesOrder = createAsyncThunk(
	ActionTypes.internalSchedules.updateOrder.typeName,
	async (data: UpdateDeliveriesOrderInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const { scheduleId, deliveries } = data
			const response = await internalSchedulesService.updateOrder(scheduleId, deliveries.map(x => x._id))
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.updateInternalScheduleSuccess, 'success'))
			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdateOrderStatusInterface {
	userId: string
	orderId: string
	scheduleId: string
	newStatus: OrderStatus
}
export const updateInternalScheduleOrderStatus = createAsyncThunk(
	ActionTypes.internalSchedules.updateOrderStatus.typeName,
	async (data: UpdateOrderStatusInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const { userId, orderId, newStatus } = data
			const response = await ordersService.updateOrder(userId, { orderId, status: newStatus })
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.updateOrderSuccess, 'success'))
			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface NotifySofiaDeliveryViaEmailInterface {
	ordersIds: string[]
	startDate: string
	endDate: string
}
export const notifySofiaOrdersDeliveryViaEmail = createAsyncThunk(
	ActionTypes.internalSchedules.notifySofiaOrdersDelivery.typeName,
	async (data: NotifySofiaDeliveryViaEmailInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await internalSchedulesService.notifySofiaOrdersDelivery(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)
