export const i18nPriceCalc = {
    packageTabLabel: {
        bg: 'Пратки',
        en: 'Packages'
    },
    palletTabLabel: {
        bg: 'Палети',
        en: 'Pallets'
    },
    freightTabLabel: {
        bg: 'Камиони',
        en: 'Freights'
    },
    relocationTabLabel: {
        bg: 'Преместване',
        en: 'Relocation'
    }
}
