export const i18nProfileIS = {
	noInternalSchedules: {
		bg: 'В момента няма създадени вътрешни графици',
		en: 'Currently there are no active internal schedules'
	},
	noOrdersMsg: {
		bg: 'Няма пратки към този график',
		en: 'No packages for this schedule'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	weight: {
		bg: 'Тегло',
		en: 'Weight'
	},
	count: {
		bg: 'Брой',
		en: 'Count'
	},
	deliveryValue: {
		bg: 'За плащане',
		en: 'To pay'
	},
	actions: {
		bg: 'Действия',
		en: 'Actions'
	},

	tuttopostSchedules: {
		bg: 'ТутоПост',
		en: 'TuttoPost'
	},
	econtSchedules: {
		bg: 'Еконт',
		en: 'Econt'
	}
}
