import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'

import { i18nAdminOrdersArchive } from './i18n'
import { GlobalState } from '../../../data/store'
import { useTranslate } from '../../../hooks/translate'
import { Order } from '../../../data/reducers/ordersReducer'
import { getCompletedOrdersDataGridColumns } from './tableUtils'
import { getAllCompletedOrders } from '../../../data/actions/ordersActions'

export function CompletedOrdersSectorComponent(props: any) {
    const { orders } = useSelector((state: GlobalState) => state.orders)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    useEffect(() => {
        props.getAllCompletedOrders()
    }, [props])

    const t = useTranslate(i18nAdminOrdersArchive)

    const rows = orders.map((order: Order, i: number) => ({ ...order, id: i }))
    const columns = getCompletedOrdersDataGridColumns(t, lang)

    return (
        <DataGrid
            rows={rows}
            // @ts-ignore
            columns={columns}
            disableRowSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [{ field: 'registerDateEpoch', sort: 'asc' }],
                },
                pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                },
            }}
            pageSizeOptions={[25, 50, 100]}
        />
    )
}

const mapDispatchToProps = { getAllCompletedOrders }

export const CompletedOrdersSector = connect(null, mapDispatchToProps)(CompletedOrdersSectorComponent)