export const i18nMainNavBar = {
	howItWorks: {
		bg: 'Как работи',
		en: 'How it works'
	},
	schedule: {
		bg: 'График',
		en: 'Schedule'
	},
	prices: {
		bg: 'Цени',
		en: 'Prices'
	},
	contacts: {
		bg: 'Контакти',
		en: 'Contacts'
	},
	registerOrder: {
		bg: 'Регистрирай пратка',
		en: 'Register package'
	},
	profile: {
		bg: 'Профил',
		en: 'Profile',
	},
	logout: {
		bg: 'Изход',
		en: 'Logout'
	},
	login: {
		bg: 'Вход',
		en: 'Login'
	},
	register: {
		bg: 'Регистрация',
		en: 'Register'
	}
}
