import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { Button, Typography } from '@mui/material'

import { AccountCircleOutlined, AdminPanelSettings, Logout } from '@mui/icons-material'

import { i18nTopNavBar } from '../i18n'

import { ROLES } from '../../../../utils/roles'
import { texts } from '../../../../utils/i18n/texts'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import { changeLanguage } from '../../../../data/actions/languageActions'

import ukFlag from '../../../../assets/uk-flag.png'
import bgFlag from '../../../../assets/bulgaria-flag.png'

import './styles.css'

export function TopNavBarMobileComponent(props: any): JSX.Element {
    const loggedIn = useSelector((state: GlobalState) => state.users.loggedIn)

    const t = useTranslate(i18nTopNavBar)

    const bgLang = () => props.changeLanguage('bg')
    const enLang = () => props.changeLanguage('en')

    const isAdmin = loggedIn.role === ROLES.ADMIN || loggedIn.role === ROLES.MODERATOR

    return (
        <div className="top-nav-bar-mobile">
            <div className="top">
                <div className="left">
                    <a href={`tel:${texts.phoneBG}`} className="phone-link">
                        <div className="flag-wrapper">
                            <img src={bgFlag} alt="italy-flag" className="phone-flag" />
                        </div>
                        <Typography variant="caption">{texts.phoneBG}</Typography>
                    </a>
                </div>
                <div className="right">
                    <img src={bgFlag} alt="bg-flag" className="image-flag" onClick={bgLang} />
                    <img src={ukFlag} alt="uk-flag" className="image-flag" onClick={enLang} />
                </div>
            </div>
            <div className="bottom">
                {loggedIn.isLogged ? (
                    <span className="bottom-links">
                        <Link to="/profile">
                            <div className="top-nav-link-icon-wrapper">
                                <AccountCircleOutlined color="primary" />
                            </div>
                        </Link>
                        {isAdmin && (
                            <Link to="/admin">
                                <div className="top-nav-link-icon-wrapper">
                                    <AdminPanelSettings color="primary" />
                                </div>
                            </Link>
                        )}
                        <Link to="/logout">
                            <div className="top-nav-link-icon-wrapper">
                                <Logout color="primary" />
                            </div>
                        </Link>
                    </span>
                ) : (
                    <span className="bottom-links">
                        <Link to="/login" className="top-nav-link">
                            <Button variant="outlined" size="small">
                                <Typography variant="caption">{t.login}</Typography>
                            </Button>
                        </Link>
                        <Link to="/register" className="top-nav-link">
                            <Button variant="outlined" size="small" color="secondary">
                                <Typography variant="caption">{t.register}</Typography>
                            </Button>
                        </Link>
                    </span>
                )}
            </div>
        </div>
    )
}

const mapDispatchToProps = { changeLanguage }

export const TopNavBarMobile = connect(null, mapDispatchToProps)(TopNavBarMobileComponent)
