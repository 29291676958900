import { useSelector } from 'react-redux'

import { GlobalState } from '../data/store'
import { UnauthenticatedView } from '../components/views/UnauthenticatedView'

export function authenticate(component: JSX.Element): () => JSX.Element {
    function NewComponent(): JSX.Element {
        const { loggedIn } = useSelector((state: GlobalState) => state.users)
        return loggedIn._id && loggedIn.isLogged ? component : <UnauthenticatedView />
    }

    return NewComponent
}
