import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { logout } from '../../data/actions/usersActions'

function LogoutPageComponent(props: any): JSX.Element {
	const navigate = useNavigate()
    useEffect(() => {
        ;(async () => {
            await props.logout()
            navigate('/')
        })()
    })

    return <></>
}

const mapDispatchToProps = { logout }

export const LogoutPage = connect(null, mapDispatchToProps)(LogoutPageComponent)
