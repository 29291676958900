import { AnyAction, createReducer } from '@reduxjs/toolkit'

import { ROLE } from '../../utils/roles'
import { ActionTypes } from '../actionTypes'
import { IDefaultEcontDeliveryAddress } from './usersReducer'

export type OrderStatus = 'REGISTERED' | 'ARRIVED_ABROAD' | 'TRAVELING' | 'ARRIVED_BG' | 'COMPLETED' | 'CANCELLED' | 'LINKED'

export const ORDER_STATUS = {
	REGISTERED: 'REGISTERED' as OrderStatus,
	ARRIVED_ABROAD: 'ARRIVED_ABROAD' as OrderStatus,
	TRAVELING: 'TRAVELING' as OrderStatus,
	ARRIVED_BG: 'ARRIVED_BG' as OrderStatus,
	COMPLETED: 'COMPLETED' as OrderStatus,
	CANCELLED: 'CANCELLED' as OrderStatus,
	LINKED: 'LINKED' as OrderStatus
}

export interface IPackage {
	weight: number
	sizes?: string
	processed?: boolean
}

export interface Order {
	_id: string
	userId: string
	userRole: ROLE
	siteOrigin: string
	description: string
	comment: string
	unregistered?: boolean
	transactionDate: string
	registerDate: string
	orderId: string
	registerDateEpoch: number
	trackingNumber: string
	itDeliveryCompany: string
	lastUpdated?: number
	valueEur: string
	packages: IPackage[]
	deliveryCompany: string
	email: string
	totalWeight: number
	receiver: string
	deliveryAddress: string
	receiverPhone: string
	status: OrderStatus
	deliverTo: string
	econtOfficeDeliveryAddress?: IDefaultEcontDeliveryAddress
	referenceNumberOrInvoice: string
	econtLabelCreated?: boolean
}

export interface OrdersState {
	orders: Order[]
}

const initialState = {
	orders: [] as Order[],
}

export const ordersReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(ActionTypes.orders.allActiveOrders.fulfilled(), (state: OrdersState, action: AnyAction) => {
			state.orders = action.payload
		})
		.addCase(ActionTypes.orders.allCompletedOrders.fulfilled(), (state: OrdersState, action: AnyAction) => {
			state.orders = action.payload
		})
		.addCase(ActionTypes.orders.getSpecific.fulfilled(), (state: OrdersState, action: AnyAction) => {
			state.orders = action.payload
		})
		.addCase(ActionTypes.orders.combineOrders.fulfilled(), (state: OrdersState, action: AnyAction) => {
			const { masterOrder, linkedOrders } = action.payload

			const copy = state.orders.slice()

			for (const linkedOrder of linkedOrders) {
				const index = copy.findIndex(x => x._id === linkedOrder._id)
				copy.splice(index, 1)
			}

			copy.push(masterOrder)

			state.orders = copy
		})
		.addCase(ActionTypes.orders.uncombineOrders.fulfilled(), (state: OrdersState, action: AnyAction) => {
			const { masterOrder, unlinkedOrders } = action.payload

			const copy = state.orders.slice()

			const index = copy.findIndex(x => x._id === masterOrder._id)
			copy.splice(index, 1)

			copy.push(...unlinkedOrders)

			state.orders = copy
		})
		.addCase(ActionTypes.internalSchedules.updateOrderStatus.fulfilled(), (state: OrdersState, action: AnyAction) => {
			if (action.payload.orderId) {
				const order = state.orders.find(x => x._id === action.payload.orderId)
				if (order) {
					order.status = action.payload.newStatus
				}
			}
		})
		.addCase(ActionTypes.orders.update.fulfilled(), (state: OrdersState, action: AnyAction) => {
			let order = state.orders.find(x => x._id === action.payload.orderId)
			if (order) {
				const { userId, orderId, ...rest } = action.payload
				order = {
					...order,
					...rest
				}
				const index = state.orders.findIndex(x => x._id === orderId)
				state.orders.splice(index, 1, order as Order)
			}
		})
		.addCase(ActionTypes.orders.userActiveOrders.fulfilled(), (state: OrdersState, action: AnyAction) => {
			state.orders = action.payload
		})
		.addCase(ActionTypes.orders.userCompleteOrders.fulfilled(), (state: OrdersState, action: AnyAction) => {
			state.orders = action.payload
		})
		.addCase(ActionTypes.orders.delete.fulfilled(), (state: OrdersState, action: AnyAction) => {
			const index = state.orders.findIndex(x => x._id === action.payload.orderId)
			if (index >= 0) {
				state.orders.splice(index, 1)
			}
		})
		.addCase(ActionTypes.orders.registerOrder.fulfilled(), (state: OrdersState, action: AnyAction) => {
			if (action.payload._id) {
				state.orders.push(action.payload)
			}
		})
		.addCase(ActionTypes.econt.createLabel.fulfilled(), (state: OrdersState, action: AnyAction) => {
			if (action.payload.orderId) {
				const order = state.orders.find(x => x._id === action.payload.orderId)

				if (order) {
					order.econtLabelCreated = true
				}
			}
		})
}
)
