export const i18nRegister = {
	title: {
		bg: 'Нов клиент',
		en: 'New client'
	},
	email: {
		bg: 'Имейл',
		en: 'Email'
	},
	password: {
		bg: 'Парола',
		en: 'Password'
	},
	confirmPassword: {
		bg: 'Потвърди парола',
		en: 'Confirm password'
	},
	subscribe: {
		bg: 'Искам да получавам известия от ',
		en: 'I want to subscribe for the newsletter of '
	},
	subscribeTo: {
		bg: 'tuttopost.com',
		en: 'tuttopost.com'
	},
	agreeText: {
		bg: 'Съгласен съм с ',
		en: 'I agree with '
	},
	tcText: {
		bg: 'Общите условия',
		en: 'Terms and Conditions'
	},
	companyAccount: {
		bg: 'Фирмен акаунт',
		en: 'Company account'
	},
	companyName: {
		bg: 'Име на фирма',
		en: 'Company name'
	},
	companyId: {
		bg: 'ЕИК / Булстат',
		en: 'Company ID'
	},
	companyOwner: {
		bg: 'МОЛ',
		en: 'Company owner'
	},
	companyAddress: {
		bg: 'Адрес',
		en: 'Company address'
	},
	buttonLabel: {
		bg: 'Регистрация',
		en: 'Register'
	}
}
