import { AnyAction, createReducer } from '@reduxjs/toolkit'

import { ActionTypes } from '../actionTypes'
import { sendReactGAEvent } from '../../utils/ReactGAEvent'

export interface LanguageState {
	currentSelection: string
}

const initialState: LanguageState = {
	currentSelection: 'bg',
}

export const languageReducer = createReducer(initialState, (builder) => {
	builder.addCase(ActionTypes.language.change.typeName, (state: LanguageState, action: AnyAction) => {
		sendReactGAEvent('language', 'change', action.payload)
		state.currentSelection = action.payload
	})
})
