import { SettingsSectorMobile } from './mobile/SettingsSectorMobile'
import { SettingsSectorDesktop } from './desktop/SettingsSectorDesktop'
import { DeviceComponents, useResponsive } from '../../../hooks/useResponsive'

export function SettingsSector(): JSX.Element {
    const components = {
        mobile: <SettingsSectorMobile />,
        smallDesktop: <SettingsSectorDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}
