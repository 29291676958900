import { createTheme } from '@mui/material'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#004B23',
        },
        secondary: {
            main: '#de2323',
        },
        success: {
            main: '#2E7D32',
        },
        error: {
            main: '#B00020',
        },
        info: {
            main: '#ffffff',
        },
        warning: {
            main: '#C1C1C1',
        },
    },
    typography: {
        fontFamily: 'Roboto',
        h1: { fontSize: '96px', fontWeight: 200 },
        h2: { fontSize: '60px', fontWeight: 200 },
        h3: { fontSize: '48px', fontWeight: 400 },
        h4: { fontSize: '34px', fontWeight: 400 },
        h5: { fontSize: '24px', fontWeight: 400 },
        h6: { fontSize: '20px', fontWeight: 600 },
        subtitle1: { fontSize: '16px', fontWeight: 400 },
        subtitle2: { fontSize: '14px', fontWeight: 600 },
        body1: { fontSize: '16px', fontWeight: 400 },
        body2: { fontSize: '14px', fontWeight: 400 },
        button: { fontSize: '14px', fontWeight: 600 },
        caption: { fontSize: '12px', fontWeight: 400 },
        overline: { fontSize: '10px', fontWeight: 400 },
    },
})
