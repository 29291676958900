import { AnyAction, createReducer } from '@reduxjs/toolkit'

import { ActionTypes } from '../actionTypes'
import { IDefaultEcontDeliveryAddress, __defaultEcontDeliveryAddress } from './usersReducer'

export interface PricesSettings {
	pricePerKg: number
	processPricePerPackage: number
	priceDeliverySofia: number
	unregisteredOrdersPenaltyFee: number
}

export interface Defaults {
	econtOfficeSenderAddress: IDefaultEcontDeliveryAddress
	shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab: boolean
}

export interface SettingsState {
	prices: PricesSettings
	defaults: Defaults
}

const initialState: SettingsState = {
	prices: {
		pricePerKg: 0,
		processPricePerPackage: 0,
		priceDeliverySofia: 0,
		unregisteredOrdersPenaltyFee: 0
	},
	defaults: {
		econtOfficeSenderAddress: __defaultEcontDeliveryAddress,
		shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab: false
	}
}

export const settingsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(ActionTypes.settings.getPrices.fulfilled(), (state: SettingsState, action: AnyAction) => {
			state.prices = action.payload
		})
		.addCase(ActionTypes.settings.getDefaults.fulfilled(), (state: SettingsState, action: AnyAction) => {
			state.defaults = action.payload
		})
})
