import { v4 as uuid } from 'uuid'
import { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    Paper,
    Table,
    Button,
    Tooltip,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    IconButton,
    Typography,
    TableContainer,
} from '@mui/material'
import {
    ForwardToInbox,
    PostAddOutlined,
    FileDownloadOutlined,
    DeleteForeverOutlined,
    LibraryAddCheckOutlined,
} from '@mui/icons-material'

import { i18nAdminCBS } from './i18n'
import { tableStruct } from './tableUtils'
import { GlobalState } from '../../../data/store'
import { useTranslate } from '../../../hooks/translate'
import {
    createCrossBorderSchedule,
    updateCrossBorderSchedule,
    deleteCrossBorderSchedule,
    completeCrossBorderSchedule,
    notifyOrdersPickedUpViaEmail,
    getActiveCrossBorderSchedules,
} from '../../../data/actions/crossBorderSchedulesActions'
import { AddOrdersModal } from './AddOrdersModal/AddOrdersModal'
import { getPricesSettings } from '../../../data/actions/settingsActions'
import { showNotification } from '../../../data/actions/notificationActions'
import { getAllUsersActiveOrders } from '../../../data/actions/ordersActions'
import { downloadPrintFile, mapToDownloadPrintFileInputData } from './printFile'
import { ConfirmActionModal } from '../../../components/ConfirmActionModal/ConfirmActionModal'
import { CreateUpdateScheduleModal } from './CreateUpdateScheduleModal/CreateUpdateScheduleModal'
import { CrossBorderSchedule, CROSS_BORDER_SCHEDULE_STATUS } from '../../../data/reducers/crossBorderSchedulesReducer'

import './styles.css'

function CrossBorderSchedulesComponent(props: any): JSX.Element {
    const {
        getPricesSettings,
        getAllUsersActiveOrders,
        completeCrossBorderSchedule,
        notifyOrdersPickedUpViaEmail,
        getActiveCrossBorderSchedules,
    } = props

    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState({})

    const [checkedRow, setCheckedRow] = useState({ _id: undefined })
    const [openOrdersModal, setOpenOrdersModal] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalId, setDeleteModalId] = useState('')

    const [openCompleteModal, setOpenCompleteModal] = useState(false)
    const [completeModalData, setCompleteModalData] = useState({})

    const [openNotifyModal, setOpenConfirmNotifyModal] = useState(false)
    const [confirmNotifyId, setConfirmNotifyId] = useState('')

    const { orders } = useSelector((state: GlobalState) => state.orders)
    const { loggedIn } = useSelector((state: GlobalState) => state.users)
    const { schedules } = useSelector((state: GlobalState) => state.crossBorderSchedules)
    const dispatch = useDispatch()

    const t = useTranslate(i18nAdminCBS)
    const heads = Object.values(t).slice(11)

    useEffect(() => {
        getActiveCrossBorderSchedules()
        getAllUsersActiveOrders()
        getPricesSettings()
    }, [getAllUsersActiveOrders, getPricesSettings, getActiveCrossBorderSchedules])

    const handleOpenCreateUpdateModal = (data: any) => {
        if (!data._id) {
            data = {}
        }

        setOpenModal(true)
        setModalData(data)
    }

    const handleCloseCreateUpdateModal = () => {
        setOpenModal(false)
        setModalData({})
    }

    const handleCreateUpdateSchedule = (data: any) => {
        if (data._id) {
            data.userId = loggedIn._id
            props.updateCrossBorderSchedule(data)
        } else {
            data.createdBy = loggedIn._id
            data.creationDate = Date.now()
            props.createCrossBorderSchedule(data)
        }

        setOpenModal(false)
    }

    const handleDeleteSchedule = () => props.deleteCrossBorderSchedule({ _id: deleteModalId })
    const handleOpenDeleteModal = (ev: any, _id: string) => {
        ev.stopPropagation()
        setDeleteModalId(_id)
        setOpenDeleteModal(true)
    }
    const handleCloseDeleteModal = () => {
        setDeleteModalId('')
        setOpenDeleteModal(false)
    }

    const handleCompleteSchedule = () => {
        const obj = {
            userId: loggedIn._id,
            scheduleId: (completeModalData as any)._id,
            ordersIds: (completeModalData as any).orders,
        }
        completeCrossBorderSchedule(obj)
    }
    const handleOpenCompleteModal = (ev: any, schedule: CrossBorderSchedule) => {
        if (!schedule.orders?.length) {
            ev.stopPropagation()
            return
        }

        ev.stopPropagation()
        setCompleteModalData(schedule)
        setOpenCompleteModal(true)
    }
    const handleCloseCompleteModal = () => {
        setCompleteModalData('')
        setOpenCompleteModal(false)
    }

    const handleOpenOrdersModal = (ev: any, row: any) => {
        ev.stopPropagation()

        setCheckedRow(row)
        setOpenOrdersModal(true)
    }
    const handleCloseOrdersModal = () => setOpenOrdersModal(false)

    const handleOpenConfirmNotifyModal = (ev: any, row: any) => {
        ev.stopPropagation()

        setOpenConfirmNotifyModal(true)
        setConfirmNotifyId(row._id)
    }
    const handleCloseConfirmNotifyModal = () => setOpenConfirmNotifyModal(false)
    const handleConfirmNotifyViaEmail = () => {
        const schedule = schedules.find((x) => x._id === confirmNotifyId)
        if (schedule) {
            notifyOrdersPickedUpViaEmail({ ordersIds: schedule.orders })
        }
    }

    const handleAddOrders = (data: any) => {
        data.userId = loggedIn._id
        props.updateCrossBorderSchedule(data)

        setCheckedRow({ _id: undefined })
        setOpenOrdersModal(false)
    }

    const isButtonsDisabled = (schedule: CrossBorderSchedule) => {
        return schedule.status === CROSS_BORDER_SCHEDULE_STATUS.COMPLETED
    }

    const handleDownloadDocxFile = (ev: any, schedule: CrossBorderSchedule) => {
        ev.stopPropagation()

        if (schedule.orders && schedule.orders.length) {
            const fileInputData = mapToDownloadPrintFileInputData(schedule, orders)
            downloadPrintFile(fileInputData)
        } else {
            dispatch(showNotification(t.warnNoOrders, 'error'))
        }
    }

    const tableStructure = tableStruct(heads)

    return (
        <div className="cross-border-schedules-container">
            <div className="top-row">
                <Button
                    onClick={handleOpenCreateUpdateModal}
                    className="top-action-button"
                    variant="contained"
                    color="secondary"
                    size="large"
                >
                    {t.createSchedule}
                </Button>
            </div>
            {schedules && schedules.length > 0 && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableStructure.map((x: any, i: number) => {
                                    return (
                                        <TableCell key={i} align="center">
                                            <Typography variant="h6">{x.head}</Typography>
                                        </TableCell>
                                    )
                                })}
                                <TableCell align="center">
                                    <Typography variant="h6">{t.actions}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {schedules.map((row: CrossBorderSchedule, i: number) => (
                                <TableRow
                                    hover
                                    key={i}
                                    onClick={() => handleOpenCreateUpdateModal(row)}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {tableStructure.map((x: any) => (
                                        <TableCell key={uuid()} align="center">
                                            {x.getData(row)}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        {isButtonsDisabled(row) ? (
                                            'X'
                                        ) : (
                                            <>
                                                <Tooltip title={t.downloadFile}>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={(ev: any) => handleDownloadDocxFile(ev, row)}
                                                    >
                                                        <FileDownloadOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t.addOrders}>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={(ev: any) => handleOpenOrdersModal(ev, row)}
                                                    >
                                                        <PostAddOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t.notifyEmail}>
                                                    <IconButton
                                                        color="success"
                                                        onClick={(ev: any) => handleOpenConfirmNotifyModal(ev, row)}
                                                    >
                                                        <ForwardToInbox />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t.completeSchedule}>
                                                    <IconButton
                                                        color={row.orders?.length ? 'success' : 'warning'}
                                                        onClick={(ev: any) => handleOpenCompleteModal(ev, row)}
                                                    >
                                                        <LibraryAddCheckOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t.deleteSchedule}>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={(ev: any) => handleOpenDeleteModal(ev, row._id)}
                                                    >
                                                        <DeleteForeverOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {openModal && (
                <CreateUpdateScheduleModal
                    handleSaveChanges={handleCreateUpdateSchedule}
                    handleClose={handleCloseCreateUpdateModal}
                    data={modalData}
                />
            )}
            {openOrdersModal && (
                <AddOrdersModal
                    handleClose={handleCloseOrdersModal}
                    handleSaveChanges={handleAddOrders}
                    data={checkedRow}
                />
            )}
            <ConfirmActionModal
                type="delete"
                text={t.confirmDelete}
                isOpen={openDeleteModal}
                handleClose={handleCloseDeleteModal}
                confirmCallback={handleDeleteSchedule}
            />
            <ConfirmActionModal
                type="confirm"
                text={t.confirmComplete}
                isOpen={openCompleteModal}
                handleClose={handleCloseCompleteModal}
                confirmCallback={handleCompleteSchedule}
            />
            <ConfirmActionModal
                type="confirm"
                isOpen={openNotifyModal}
                text={t.confirmNotifyViaEmail}
                handleClose={handleCloseConfirmNotifyModal}
                confirmCallback={handleConfirmNotifyViaEmail}
            />
        </div>
    )
}

const mapDispatchToProps = {
    getPricesSettings,
    getAllUsersActiveOrders,
    createCrossBorderSchedule,
    updateCrossBorderSchedule,
    deleteCrossBorderSchedule,
    completeCrossBorderSchedule,
    notifyOrdersPickedUpViaEmail,
    getActiveCrossBorderSchedules,
}

export const CrossBorderSchedules = connect(null, mapDispatchToProps)(CrossBorderSchedulesComponent)
