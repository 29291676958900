import { AnyAction } from '@reduxjs/toolkit'

import { ActionTypes } from '../actionTypes'
import { ROLE, ROLES } from '../../utils/roles'
import { createReducer } from "@reduxjs/toolkit"

export const __defaultEcontDeliveryAddress: IDefaultEcontDeliveryAddress = { bg: '', en: '', id: -1 }

export interface IDefaultEcontDeliveryAddress {
	bg: string
	en: string
	id: number
}

export interface User {
	_id: string
	sessionExpired: boolean
	isLogged: boolean
	role: ROLE
	email: string
	firstName: string
	lastName: string
	address: string
	country: string
	city: string
	phoneNumber: string
	emailVerified: boolean
	companyId: string
	companyName: string
	companyOwner: string
	companyAddress: string
	registerDate: string
	registerDateEpoch: number
	passwordRestored: boolean
	uniqueId: number
	defaultEcontOfficeDeliveryAddress: IDefaultEcontDeliveryAddress
}

export interface UsersState {
	users: User[]
	pendingVerificationEmail: string
	loggedIn: User
}

const initialState: UsersState = {
	users: [],
	pendingVerificationEmail: '',
	loggedIn: {
		_id: '',
		sessionExpired: false,
		isLogged: false,
		role: ROLES.GUEST,
		country: '',
		city: '',
		email: '',
		address: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		emailVerified: false,
		companyAddress: '',
		companyId: '',
		companyName: '',
		companyOwner: '',
		registerDate: '',
		registerDateEpoch: 0,
		passwordRestored: false,
		uniqueId: 0,
		defaultEcontOfficeDeliveryAddress: __defaultEcontDeliveryAddress
	},
}

export const usersReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(ActionTypes.users.login.fulfilled(), (state: UsersState, action: AnyAction) => {
			if (action.payload === 204) {
				// user email not verified
				return
			}

			state.pendingVerificationEmail = ''

			state.loggedIn = {
				isLogged: true,
				sessionExpired: false,
				passwordRestored: false,
				...action.payload
			}
		})
		.addCase(ActionTypes.users.sessionExpired.fulfilled(), (state: UsersState) => {
			state.users.length = 0
			state.loggedIn = {
				...initialState.loggedIn,
				sessionExpired: true
			}
		})
		.addCase(ActionTypes.users.logout.fulfilled(), (state: UsersState) => {
			if (state.users) {
				state.users.length = 0
			}
			state.pendingVerificationEmail = ''
			state.loggedIn = { ...initialState.loggedIn }
		})
		.addCase(ActionTypes.users.register.fulfilled(), (state: UsersState, action: AnyAction) => {
			state.users.length = 0
			state.loggedIn = { ...initialState.loggedIn }
			state.pendingVerificationEmail = action.payload.email
		})
		.addCase(ActionTypes.users.registerSpecific.fulfilled(), (state: UsersState) => {
			state.users.length = 0
			state.pendingVerificationEmail = ''
			state.loggedIn = { ...initialState.loggedIn }
		})
		.addCase(ActionTypes.users.updateRole.fulfilled(), (state: UsersState) => {
			state.users.length = 0
			state.pendingVerificationEmail = ''
			state.loggedIn = { ...initialState.loggedIn }
		})
		.addCase(ActionTypes.users.updateProfile.fulfilled(), (state: UsersState, action: AnyAction) => {
			state.pendingVerificationEmail = ''
			state.loggedIn = {
				...state.loggedIn,
				...action.payload
			}
		})
		.addCase(ActionTypes.users.forgetPassword.fulfilled(), (state: UsersState) => {
			state.pendingVerificationEmail = ''
			state.loggedIn.passwordRestored = false
		})
		.addCase(ActionTypes.users.restorePassword.fulfilled(), (state: UsersState) => {
			state.pendingVerificationEmail = ''
			state.loggedIn.passwordRestored = true
		})
		.addCase(ActionTypes.users.getAllUsers.fulfilled(), (state: UsersState, action: AnyAction) => {
			state.users = action.payload
		})
		.addCase(ActionTypes.users.delete.fulfilled(), (state: UsersState, action: AnyAction) => {
			const { deleteUserId } = action.payload
			const index = state.users.findIndex(x => x._id === deleteUserId)
			state.users.splice(index, 1)
		})
})
