import { Button, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Fade } from 'react-awesome-reveal'
import { Link } from 'react-router-dom'

import { i18nHIW } from './i18n'
import { texts } from '../../utils/i18n/texts'
import { GlobalState } from '../../data/store'
import { easeTimes } from '../../utils/easeTimes'
import { messages } from '../../utils/i18n/messages'
import { HowItWorksStep } from './HowItWorksStep/HowItWorksStep'
import { useTranslate, useTranslateSingle } from '../../hooks/translate'
import { showNotification } from '../../data/actions/notificationActions'

import Image1 from '../../assets/vectors/how-it-works-img-1.svg'
import Image2 from '../../assets/vectors/how-it-works-img-2.svg'
import Image3 from '../../assets/vectors/how-it-works-img-3.svg'
import Image4 from '../../assets/vectors/how-it-works-img-4.svg'

import './styles.css'

export function HowItWorksPage() {
    const dispatch = useDispatch()
    const { loggedIn } = useSelector((state: GlobalState) => state.users)
    const successCopy = useTranslateSingle(messages.successfullyCopiedToClipboard)
    const t = useTranslate(i18nHIW)

    const idText = loggedIn.isLogged ? loggedIn.uniqueId : t.yourIdNumber

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(texts.itAddress)
        dispatch(showNotification(successCopy, 'success'))
    }

    return (
        <div className="how-it-works-wrapper">
            <div className="how-it-works-container">
                <Typography className="how-it-works-header" variant="h4" align="center">
                    {t.title}
                </Typography>
                <div className="how-it-works-steps">
                    <HowItWorksStep stepCount={1} stepHeader={t.step1Title} image={Image1}>
                        <Fade cascade triggerOnce duration={easeTimes.slow} direction="up">
                            <div>{t.step1Text1}</div>
                        </Fade>
                    </HowItWorksStep>
                    <HowItWorksStep stepCount={2} stepHeader={t.step2Title} image={Image2}>
                        <Fade cascade triggerOnce damping={0.15} duration={easeTimes.slow} direction="up">
                            <div>{t.step2Text1}</div>
                            <br />
                            <div>{t.step2Text2}</div>
                            <div
                                onClick={handleCopyToClipboard}
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                                {texts.itAddress1Part}, {texts.itAddress2Part}: {idText}, {texts.itAddress3Part}
                            </div>
                            <br />
                            <div>{t.step2Text3}</div>
                            <br />
                            <div>Indirizzo: {texts.itAddress1Part}</div>
                            <div>Indirizzo (seconda riga): {texts.itAddress2Part}: {idText}</div>
                            <div>Codice postale: {texts.itAddress3Part}</div>
                        </Fade>
                    </HowItWorksStep>
                    <HowItWorksStep stepCount={3} stepHeader={t.step3Title} image={Image3}>
                        <Fade cascade triggerOnce damping={0.15} duration={easeTimes.slow} direction="up">
                            <div>{t.step3Text1}</div>
                            <br />
                            <div>
                                <TextField
                                    fullWidth
                                    label="Via"
                                    size="small"
                                    margin="dense"
                                    variant="outlined"
                                    onChange={() => {}}
                                    value={t.step3InputLabel1}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    variant="outlined"
                                    onChange={() => {}}
                                    value={texts.itAddress1Part}
                                    label="Paese o provincial Nome"
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    variant="outlined"
                                    onChange={() => {}}
                                    label="Indirizzo (seconda riga)"
                                    value={`${texts.itAddress2Part}: ${idText}`}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    variant="outlined"
                                    onChange={() => {}}
                                    label="Codice postale"
                                    value={texts.itAddress3Part}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    label="Telefono"
                                    variant="outlined"
                                    onChange={() => {}}
                                    value="Твоят телефон"
                                />
                            </div>
                            <br />
                            <hr />
                            <Typography variant='h5'>{t.step3Warning}</Typography>
                        </Fade>
                    </HowItWorksStep>
                    <HowItWorksStep stepCount={4} stepHeader={t.step4Title} image={Image4}>
                        <Fade cascade triggerOnce damping={0.15} duration={easeTimes.slow} direction="up">
                            <div>{t.step4text1}</div>
                            <br />
                            <div>
                                {t.step4Text2}{' '}
                                <Link to="/schedules">
                                    <Button size="small" variant="outlined" color="secondary">
                                        {t.step4ButtonLabel}
                                    </Button>
                                </Link>
                            </div>
                            <br />
                            {/* <div>След като пратката пристигне в България и бъде обработена ви уведомяваме /e-mail/</div> */}
                            <br />
                            <div>
                                {t.step4Text3}{' '}
                                <Button size="small" variant="outlined" color="secondary">
                                    <a href={`mailto:${texts.email}`}>{texts.email}</a>
                                </Button>
                            </div>
                            <br />
                            <div>{t.step4Text4}</div>
                            <br />
                            <div>{t.step4Text5}</div>
                            <br />
                            <div>{t.step4Text6}</div>
                            <br />
                            <div>{t.step4Text7}</div>
                            <br />
                            <div>{t.step4Text8}</div>
                        </Fade>
                    </HowItWorksStep>
                </div>
            </div>
        </div>
    )
}
