import { useSelector } from "react-redux"
import { Paper, Typography } from "@mui/material"

import { GlobalState } from "../../../../data/store"
import { Order } from "../../../../data/reducers/ordersReducer"
import { getOrderStatusChip } from "../../../../components/Shared"

export function CompletedOrdersMobileCard(props: any) {
    const order = props.order as Order

    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    return (
        <Paper className='completed-order-card' elevation={3}>
            {getOrderStatusChip(order.status, lang)}
            <br />
            <br />
            <div className='base-info-wrapper'>
                <Typography variant='h5'>{order?.siteOrigin ?? 'N/A'}</Typography>
                <Typography variant='h6'>{order?.valueEur ?? '0'} EUR</Typography>
            </div>
            <br />
            <Typography variant='h5'>{order?.description}</Typography>
            <Typography variant='h6'>{order?.receiverPhone}</Typography>
            {
                <Typography className='delivery-address'>
                    {
                        order?.deliverTo === 'office'
                            // @ts-ignore
                            ? order?.econtOfficeDeliveryAddress?.[lang] || ''
                            : order?.deliveryAddress
                    }
                </Typography>
            }
            <br />
            <Typography>{order?.comment}</Typography>
        </Paper>
    )
}