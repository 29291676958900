import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { counterReducer } from './reducers/counterReducer'
import { usersReducer, UsersState } from './reducers/usersReducer'
import { econtReducer, EcontState } from './reducers/econtReducer'
import { ordersReducer, OrdersState } from './reducers/ordersReducer'
import { loaderReducer, LoaderState } from './reducers/loaderReducer'
import { languageReducer, LanguageState } from './reducers/languageReducer'
import { settingsReducer, SettingsState } from './reducers/settingsReducer'
import { notificationReducer, NotificationState } from './reducers/notificationReducer'
import { internalSchedulesReducer, InternalSchedulesState } from './reducers/internalSchedulesReducer'
import { crossBorderSchedulesReducer, CrossBorderSchedulesState } from './reducers/crossBorderSchedulesReducer'

export interface GlobalState {
	users: UsersState
	orders: OrdersState
	loader: LoaderState
	lang: LanguageState
	econt: EcontState
	settings: SettingsState
	notification: NotificationState
	internalSchedules: InternalSchedulesState
	crossBorderSchedules: CrossBorderSchedulesState
}

const reducers = combineReducers({
	users: usersReducer,
	orders: ordersReducer,
	loader: loaderReducer,
	lang: languageReducer,
	econt: econtReducer,
	settings: settingsReducer,
	notification: notificationReducer,
	internalSchedules: internalSchedulesReducer,
	crossBorderSchedules: crossBorderSchedulesReducer,
	counter: counterReducer
})

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['notification', 'loader', 'econt'] // parts of state that we dont want to persist in storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production'
})
