import { useState } from 'react'
import { useSelector } from 'react-redux'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'

import { GlobalState } from '../../../../data/store'
import { i18nAdminIScreateUpdateModal } from './i18n'
import { useTranslate } from '../../../../hooks/translate'
import { deliveryCompanies } from '../../../../utils/deliveryCompanies'
import { mapToFriendlyInternalSchedulesStatus } from '../../../../utils/functions'
import { INTERNAL_SCHEDULE_STATUS } from '../../../../data/reducers/internalSchedulesReducer'

import './styles.css'

export function CreateUpdateScheduleModal(props: any): JSX.Element {
    const { handleClose, data, handleSaveChanges } = props

    const initialStartDate = data.startDate ? new Date(data.startDate) : new Date()
    const initialEndDate = data.endDate ? new Date(data.endDate) : new Date()

    const [status, setStatus] = useState(data.status || INTERNAL_SCHEDULE_STATUS.EXPECT_TO_PICKUP)
    const [startDate, setStartDate] = useState(initialStartDate)
    const [endDate, setEndDate] = useState(initialEndDate)
    const [deliveryCompany, setDeliveryCompany] = useState(data.deliveryCompany || deliveryCompanies[0].value)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const t = useTranslate(i18nAdminIScreateUpdateModal)

    const handleSave = () => {
        const fixedStartDate = typeof startDate === 'number' ? startDate : startDate.getTime()
        const fixedEndDate = typeof endDate === 'number' ? endDate : endDate.getTime()
        const saveData = {
            _id: data._id,
            status,
            deliveryCompany,
            endDate: fixedEndDate,
            startDate: fixedStartDate,
        }
        handleSaveChanges(saveData)
    }

    const handleChangeStatus = (ev: any) => setStatus(ev.target.value)

    const handleChangeStartDate = (ev: any) => {
        const epoch = ev._d.getTime()
        if (endDate && epoch > endDate) {
            alert('Delivery date cannot be before pickup date')
            return
        }

        setStartDate(epoch)
    }
    const handleChangeEndDate = (ev: any) => {
        const epoch = ev._d.getTime()
        if (startDate && epoch < startDate) {
            alert('Delivery date cannot be before pickup date')
            return
        }

        setEndDate(epoch)
    }
    const handleChangeDeliveryCompany = (ev: any) => setDeliveryCompany(ev.target.value)

    const statuses = Object.values(INTERNAL_SCHEDULE_STATUS)
    const friendlyStatuses = statuses.map((status) => ({
        value: status,
        friendly: mapToFriendlyInternalSchedulesStatus(status, lang),
    }))

    return (
        <Modal open={true} onClose={handleClose}>
            <div className="internal-schedules-modal-container">
                <Typography className="modal-header" variant="h4" align="center">
                    {data._id ? t.updateTitle : t.createTitle}
                </Typography>
                <div className="timespan-inputs-wrapper">
                    <DesktopDatePicker
                        value={startDate}
                        label={t.dateStart}
                        inputFormat="DD.MM.YYYY"
                        onChange={handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DesktopDatePicker
                        label={t.deadline}
                        value={endDate}
                        inputFormat="DD.MM.YYYY"
                        onChange={handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </div>
                <FormControl margin="dense" fullWidth size="small">
                    <InputLabel id="status-select-label">{t.status}</InputLabel>
                    <Select
                        defaultValue={INTERNAL_SCHEDULE_STATUS.EXPECT_TO_PICKUP}
                        labelId="status-select-label"
                        onChange={handleChangeStatus}
                        label={t.status}
                        value={status}
                    >
                        {friendlyStatuses.map((orderStatus, i) => {
                            return (
                                <MenuItem key={i} value={orderStatus.value}>
                                    {orderStatus.friendly}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormControl margin="dense" fullWidth disabled={!!data._id} size="small">
                    <InputLabel id="delivery-company-select-label">{t.deliveryCompany}</InputLabel>
                    <Select
                        labelId="delivery-company-select-label"
                        onChange={handleChangeDeliveryCompany}
                        defaultValue={deliveryCompany}
                        label={t.deliveryCompany}
                        value={deliveryCompany}
                    >
                        {deliveryCompanies.map((deliveryCompany, i) => {
                            return (
                                <MenuItem key={i} value={deliveryCompany.value}>
                                    {deliveryCompany.displayName}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div className="action-buttons">
                    <Button onClick={handleSave} variant="contained" color="secondary">
                        {data._id ? t.buttonLabel2 : t.buttonLabel1}
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="info">
                        {t.buttonLabel3}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
