import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

import { i18nConfirmModal } from './i18n'
import { useTranslate } from '../../hooks/translate'

type ModalType = 'delete' | 'confirm'

interface Props {
    isOpen: boolean
    text: string
    type: ModalType
    handleClose: () => void
    confirmCallback: () => void
}

export function ConfirmActionModal(props: Props): JSX.Element {
    const { isOpen, type, text, handleClose, confirmCallback } = props

    const handleConfirm = () => {
        confirmCallback()
        handleClose()
    }

    const t = useTranslate(i18nConfirmModal)

    const modalTitle = type === 'confirm' ? t.confirmTitle : t.deleteTitle
    const agreeButtonText = type === 'confirm' ? t.confirm : t.deleteText
    const buttonColor = type === 'confirm' ? 'primary' : 'secondary'

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{modalTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} variant="contained" color={buttonColor}>
                    {agreeButtonText}
                </Button>
                <Button onClick={handleClose} autoFocus variant="contained" color="info">
                    {t.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
