export interface DeliveryCompany {
	value: string
	displayName: string
}

export const deliveryCompanies: DeliveryCompany[] = [
	{
		value: 'tuttopost',
		displayName: 'TuttoPost (само за София)'
	},
	{
		value: 'econt',
		displayName: 'Econt'
	},
	// {
	// 	value: 'speedy',
	// 	displayName: 'Speedy'
	// }
]
