export const i18nISCardDetailsModal = {
    registerDate: {
        bg: 'Дата регистрация',
        en: 'Register date'
    },
    siteOrigin: {
        bg: 'Произход',
        en: 'Site origin'
    },
    description: {
        bg: 'Описание',
        en: 'Description'
    },
    valueEur: {
        bg: 'Цена EUR',
        en: 'Price EUR'
    },
    itDeliveryCompany: {
        bg: 'Доставчик Италия',
        en: 'Italian Courier'
    },
    itTrackingNumber: {
        bg: 'Номер за проследяване',
        en: 'Tracking number'
    },
    priceDetails: {
        bg: 'Разбивка цена',
        en: 'Price details'
    },
    closeBtn: {
        bg: 'Затвори',
        en: 'Close'
    },
}
