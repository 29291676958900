export const i18nContacts = {
	leftTitle: {
		bg: 'Контакти',
		en: 'Contacts'
	},
	rightTitle: {
		bg: 'Изпрати запитване',
		en: 'Send a request'
	},
	bgAddressLabel: {
		bg: 'Адрес България',
		en: 'Address Bulgaria'
	},
	itAddressLabel: {
		bg: 'Адрес Италия',
		en: 'Address Italy'
	},
	bgPhoneLabel: {
		bg: 'Телефон България',
		en: 'Phone Bulgaria'
	},
	itPhoneLabel: {
		bg: 'Телефон Италия',
		en: 'Phone Italy'
	},
	emailLabel: {
		bg: 'Имейл',
		en: 'Email'
	},
	names: {
		bg: 'Твоите имена',
		en: 'Your names'
	},
	email: {
		bg: 'Твоят имейл',
		en: 'Your email'
	},
	phone: {
		bg: 'Телефон',
		en: 'Phone'
	},
	message: {
		bg: 'Съобщение',
		en: 'Request message'
	},
	buttonLabel: {
		bg: 'Изпрати',
		en: 'Send'
	}
}
