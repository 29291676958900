import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Paper, TextField, Typography } from '@mui/material'

import { i18nAdminSettings } from '../i18n'
import { ROLES } from '../../../../utils/roles'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import {
    getPricesSettings,
    getDefaultsSettings,
    updatePricesSettings,
    updateDefaultsSettings,
} from '../../../../data/actions/settingsActions'
import { useEcontOffices } from '../../../../hooks/useEcontOffices'
import { __defaultEcontDeliveryAddress } from '../../../../data/reducers/usersReducer'

import './styles.css'

export function SettingsSectorDesktopComponent(props: any): JSX.Element {
    const { getPricesSettings, updatePricesSettings, getDefaultsSettings, updateDefaultsSettings } = props

    const { loggedIn } = useSelector((state: GlobalState) => state.users)
    const { prices, defaults } = useSelector((state: GlobalState) => state.settings)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)
    const econtOffices = useEcontOffices()

    const [shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab, setShouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab] = useState(!!defaults.shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab)
    const [econtOfficeSenderAddress, setEcontOfficeSenderAddress] = useState(defaults.econtOfficeSenderAddress || __defaultEcontDeliveryAddress)
    const [pricePerKg, setPricePerKg] = useState(prices.pricePerKg || 0)
    const [processPricePerPackage, setProcessPricePerPackage] = useState(prices.processPricePerPackage || 0)
    const [priceDeliverySofia, setPriceDeliverySofia] = useState(prices.priceDeliverySofia || 0)
    const [unregisteredOrdersPenaltyFee, setUnregisteredOrdersPenaltyFee] = useState(
        prices.unregisteredOrdersPenaltyFee || 0
    )

    const t = useTranslate(i18nAdminSettings)

    useEffect(() => {
        getPricesSettings()
        getDefaultsSettings()
    }, [getPricesSettings, getDefaultsSettings])

    const handleChangePricePerKg = (ev: any) => setPricePerKg(ev.target.value)
    const handleChangeProcessPricePerPackage = (ev: any) => setProcessPricePerPackage(ev.target.value)
    const handleChangePriceDeliverySofia = (ev: any) => setPriceDeliverySofia(ev.target.value)
    const handleChangeUnregisteredOrdersFee = (ev: any) => setUnregisteredOrdersPenaltyFee(ev.target.value)

    const handleSavePriceSettings = () => {
        if (isNaN(pricePerKg) || isNaN(processPricePerPackage)) {
            return
        }

        updatePricesSettings({
            userId: loggedIn._id,
            pricePerKg: +pricePerKg,
            priceDeliverySofia: +priceDeliverySofia,
            processPricePerPackage: +processPricePerPackage,
            unregisteredOrdersPenaltyFee: +unregisteredOrdersPenaltyFee,
        })
    }

    const handleChangeSenderEcontOfficeAddress = (e: any, args: any) => {
        setEcontOfficeSenderAddress(args?.names || __defaultEcontDeliveryAddress)
    }

    const handleChangeShouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab = (e: any, checked: boolean) => {
        setShouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab(checked)
    }

    const handleSaveDefaultsSettings = () => {
        updateDefaultsSettings({
            userId: loggedIn._id,
            econtOfficeSenderAddress,
            shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab
        })
    }

    const isReadonly = loggedIn.role !== ROLES.ADMIN

    return (
        <div className="settings-container">
            <Paper className="prices-container" elevation={5}>
                <Typography variant="h5" align="center">
                    {t.pricesTitle}
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    label={t.kgLabel}
                    variant="outlined"
                    value={pricePerKg}
                    disabled={isReadonly}
                    className="price-input"
                    onChange={handleChangePricePerKg}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={isReadonly}
                    label={t.processLabel}
                    className="price-input"
                    value={processPricePerPackage}
                    onChange={handleChangeProcessPricePerPackage}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={isReadonly}
                    className="price-input"
                    value={priceDeliverySofia}
                    label={t.sofiaDeliveryLabel}
                    onChange={handleChangePriceDeliverySofia}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={isReadonly}
                    className="price-input"
                    label={t.unregisteredOrderLabel}
                    value={unregisteredOrdersPenaltyFee}
                    onChange={handleChangeUnregisteredOrdersFee}
                />
                {!isReadonly && (
                    <div className="submit-btn-container">
                        <Button variant="contained" size='large' color="secondary" onClick={handleSavePriceSettings}>
                            {t.buttonLabel}
                        </Button>
                    </div>
                )}
            </Paper>
            <Paper className="defaults-container" elevation={5}>
                <Typography variant="h5" align="center">
                    {t.defaultsTitle}
                </Typography>
                <div className='econt-office-autocomplete'>
                    <Autocomplete
                        fullWidth
                        size='small'
                        autoHighlight
                        disabled={isReadonly}
                        options={econtOffices}
                        // @ts-ignore
                        value={econtOfficeSenderAddress?.[lang] || ''}
                        onChange={handleChangeSenderEcontOfficeAddress}
                        renderInput={(params) => <TextField label={t.senderEcontOfficeAddress} {...params} />}
                    />
                </div>
                <div>

                </div>
                <div className="auto-open-econt-labels-checkbox-group">
                    <FormGroup>
                        <FormControlLabel
                            disabled={isReadonly}
                            onChange={handleChangeShouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab}
                            label={<Typography>{t.shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab}</Typography>}
                            control={<Checkbox color="primary" checked={!!shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab} />}
                        />
                    </FormGroup>
                </div>
                {!isReadonly && (
                    <div className="submit-btn-container">
                        <Button variant="contained" size='large' color="secondary" onClick={handleSaveDefaultsSettings}>
                            {t.buttonLabel}
                        </Button>
                    </div>
                )}
            </Paper>
        </div>
    )
}

const mapDispatchToProps = { getPricesSettings, updatePricesSettings, getDefaultsSettings, updateDefaultsSettings }

export const SettingsSectorDesktop = connect(null, mapDispatchToProps)(SettingsSectorDesktopComponent)
