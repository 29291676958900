import { useSelector } from "react-redux"

import { GlobalState } from "../data/store"

export function useEcontOffices() {
    const { simplified } = useSelector((state: GlobalState) => state.econt)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const econtOffices = simplified.map(office => {
        const { fullAddress, fullAddressEn, id, phones } = office
        const names = { en: fullAddressEn, bg: fullAddress, id }

        if (lang === 'en') {
            return { label: fullAddressEn, names, phones }
        } else {
            return { label: fullAddress, names, phones }
        }
    })

    return econtOffices
}
