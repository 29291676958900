export const i18nProfileAO = {
	noActiveOrders: {
		bg: 'В момента нямате активни пратки',
		en: 'Currently there are no active orders'
	},
	// BEGIN: table heads
	dateCreated: {
		bg: 'Създадена',
		en: 'Created'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	weight: {
		bg: 'Тегло',
		en: 'Weight'
	},
	count: {
		bg: 'Брой',
		en: 'Count'
	},
	origin: {
		bg: 'Произход',
		en: 'Origin'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	deliveryValue: {
		bg: 'Дължима сума',
		en: 'To pay'
	}
	// END: table heads
}
