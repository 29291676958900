export const i18nVerifyRegistration = {
    title: {
        bg: 'Верифициране на регистрация',
        en: 'Verify registration'
    },
    subTitle: {
        bg: 'Изпратихме ви имейл с код за потвърждение',
        en: "We've send you a emila with verification code"
    },
    code: {
        bg: 'Верификационен код',
        en: 'Verification code'
    },
    firstName: {
        bg: 'Първо име',
        en: 'First name'
    },
    lastName: {
        bg: 'Последно име',
        en: 'Last name'
    },
    phoneNumber: {
        bg: 'Телефонен номер',
        en: 'Phone number'
    },
    confirm: {
        bg: 'Потвърди',
        en: 'Confirm'
    },
    didntReceiveCodeLabel: {
        bg: 'Не получихте верификационен код?',
        en: "Didn't receive a verification code?"
    },
    requestNewCode: {
        bg: 'Заяви нов код',
        en: 'Request new code'
    },
    didntReceiveCodeX2Label: {
        bg: 'Отново не получихте верификационен код?',
        en: "Didn't receive a verification code again?"
    },
    requestSupportCheck: {
        bg: 'Заяви проверка',
        en: 'Request support check'
    },
}