import { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
    Paper,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Typography,
    IconButton,
    TableContainer,
} from '@mui/material'

import { i18nProfileAO } from '../i18n'
import { tableStruct } from './tableUtils'
import { GlobalState } from '../../../../data/store'
import { useTranslate } from '../../../../hooks/translate'
import { orderPredicate } from '../../../../utils/functions'
import { EditOrderModal } from './EditOrderModal/EditOrderModal'
import { getOrderByHeadIcon } from '../../../../components/Shared'
import { updateOrder } from '../../../../data/actions/ordersActions'

export function ActiveOrdersTableComponent(props: any): JSX.Element {
    const [orderBy, setOrderBy] = useState('')
    const [ascending, setAscending] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)

    const { orders } = useSelector((state: GlobalState) => state.orders)
    const { _id } = useSelector((state: GlobalState) => state.users.loggedIn)
    const prices = useSelector((state: GlobalState) => state.settings.prices)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const t = useTranslate(i18nProfileAO)
    const heads = Object.values(t).slice(1)

    const openEditModal = (modalData: any) => {
        setModalData(modalData)
        setOpenModal(true)
    }
    const closeEditModal = () => {
        setOpenModal(false)
        setModalData(null)
    }

    const handleEditOrder = (orderId: string, values: any) => {
        closeEditModal()
        props.updateOrder({ userId: _id, orderId, ...values })
    }

    const handleSetOrderBy = (propName: string) => {
        if (orderBy === propName) {
            setAscending((oldValue) => !oldValue)
        } else {
            setAscending(true)
            setOrderBy(propName)
        }
    }

    const tableStructure = tableStruct(heads, lang)

    const orderByPredicate = orderPredicate(orderBy, ascending)
    const orderedOrders = orders ? orders.slice().sort(orderByPredicate) : []

    return (
        !orderedOrders.length ?
            <Typography variant='h4'>{t.noActiveOrders}</Typography>
            :
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableStructure.map((x: any, i: number) => {
                                return (
                                    <TableCell key={i} align="center">
                                        <Typography variant="h6">
                                            {x.sortable ? (
                                                <IconButton onClick={() => handleSetOrderBy(x.name)}>
                                                    {getOrderByHeadIcon(x, orderBy, ascending)}
                                                </IconButton>
                                            ) : (
                                                false
                                            )}{' '}
                                            {x.head}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderedOrders.map((row, i: number) => (
                            <TableRow
                                hover
                                key={i}
                                onClick={() => openEditModal(row)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {tableStructure.map((x: any, ii: number) => (
                                    <TableCell key={ii} align="center">
                                        {x.getData(row, prices)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {openModal && (
                    <EditOrderModal handleSaveChanges={handleEditOrder} handleClose={closeEditModal} data={modalData} />
                )}
            </TableContainer>
    )
}

const mapDispatchToProps = {
    updateOrder,
}

export const ActiveOrdersTable = connect(null, mapDispatchToProps)(ActiveOrdersTableComponent)
