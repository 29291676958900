//@ts-ignore
import CanvasJSReact from '@canvasjs/react-charts'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../../data/store'
import { Order } from '../../../../data/reducers/ordersReducer'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

export function HourOfOrderGraphic() {
    const { orders } = useSelector((state: GlobalState) => state.orders)

    const ordersData = mapOrders(orders)

    const options = {
        theme: "light2",
        animationEnabled: true,
        title: {
            text: "Hour of order registration"
        },
        data: [
            {
                type: "column",
                dataPoints: [
                    { label: "00:00 to 02:00", y: ordersData.from0to2.length },
                    { label: "02:00 to 04:00", y: ordersData.from2to4.length },
                    { label: "04:00 to 06:00", y: ordersData.from4to6.length },
                    { label: "06:00 to 08:00", y: ordersData.from6to8.length },
                    { label: "08:00 to 10:00", y: ordersData.from8to10.length },
                    { label: "10:00 to 12:00", y: ordersData.from10to12.length },
                    { label: "12:00 to 14:00", y: ordersData.from12to14.length },
                    { label: "14:00 to 16:00", y: ordersData.from14to16.length },
                    { label: "16:00 to 18:00", y: ordersData.from16to18.length },
                    { label: "18:00 to 20:00", y: ordersData.from18to20.length },
                    { label: "20:00 to 22:00", y: ordersData.from20to22.length },
                    { label: "22:00 to 24:00", y: ordersData.from22to24.length },
                ]
            }
        ]
    }

    return <CanvasJSChart options={options} />
}

function mapOrders(orders: Order[]) {
    const _0_2 = isBetweenHours(0, 2)
    const _2_4 = isBetweenHours(2, 4)
    const _4_6 = isBetweenHours(4, 6)
    const _6_8 = isBetweenHours(6, 8)
    const _8_10 = isBetweenHours(8, 10)
    const _10_12 = isBetweenHours(10, 12)
    const _12_14 = isBetweenHours(12, 14)
    const _14_16 = isBetweenHours(14, 16)
    const _16_18 = isBetweenHours(16, 18)
    const _18_20 = isBetweenHours(18, 20)
    const _20_22 = isBetweenHours(20, 22)
    const _22_24 = isBetweenHours(22, 24)

    const from0to2 = orders.filter(_0_2)
    const from2to4 = orders.filter(_2_4)
    const from4to6 = orders.filter(_4_6)
    const from6to8 = orders.filter(_6_8)
    const from8to10 = orders.filter(_8_10)
    const from10to12 = orders.filter(_10_12)
    const from12to14 = orders.filter(_12_14)
    const from14to16 = orders.filter(_14_16)
    const from16to18 = orders.filter(_16_18)
    const from18to20 = orders.filter(_18_20)
    const from20to22 = orders.filter(_20_22)
    const from22to24 = orders.filter(_22_24)

    return {
        from0to2,
        from2to4,
        from4to6,
        from6to8,
        from8to10,
        from10to12,
        from12to14,
        from14to16,
        from16to18,
        from18to20,
        from20to22,
        from22to24,
    }
}

function isBetweenHours(startHour: number, endHour: number) {
    return (order: Order) => {
        const exactDate = new Date(order.registerDateEpoch)
        const hour = exactDate.getHours()

        return hour >= startHour && hour < endHour
    }
}