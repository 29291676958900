import { v4 as uuid } from 'uuid'
import { Paper, Typography } from '@mui/material'
import { termsAndConditions } from './termsAndConditions'

import './styles.css'

export function TermsAndConditionsPage() {
    return (
        <div className="tac-container">
            <Typography className="tac-header" variant="h4" align="center">
                Общи условия за ползване
            </Typography>
            <div className='general-terms-container'>
                {termsAndConditions.map((t, i) => {
                    return (
                        <Paper className='general-term-container' key={i} elevation={5}>
                            <Typography className="general-term-header" variant="h5" align="center">
                                {t.number}. {t.generalTermText}
                            </Typography>
                            <div>
                                {t.terms.map((term) => {
                                    return (
                                        <div key={uuid()}>
                                            <Typography className="term-text">
                                                {term.number}. {term.termText}
                                            </Typography>
                                            <div>
                                                {term.subTerms.map((subTerm) => {
                                                    return (
                                                        <Typography className='subterm-text' key={uuid()}>
                                                            {`${term.number}.${subTerm.number}.`} {subTerm.subTermText}
                                                        </Typography>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Paper>
                    )
                })}
            </div>
        </div>
    )
}
