import { createAsyncThunk } from '@reduxjs/toolkit'

import { isAuthRequest } from './utils'
import { ActionTypes } from '../actionTypes'
import { getTranslation } from './translation'
import { messages } from '../../utils/i18n/messages'
import { hideLoader, showLoader } from './loaderActions'
import { showNotification } from './notificationActions'
import { ordersService } from '../../services/ordersService'
import { CreateUnregisteredOrderFormikValues } from '../../pages/adminPanel/activeOrdersSector/ActiveOrdersList/AddUnregisteredOrderModal/AddUnregisteredOrderModal'
import { Order } from '../reducers/ordersReducer'
import { IDefaultEcontDeliveryAddress } from '../reducers/usersReducer'

interface UserBaseInterface {
	userId: string
}

export const getUserActiveOrders = createAsyncThunk(
	ActionTypes.orders.userActiveOrders.typeName,
	async (userData: UserBaseInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.getUserActiveOrders(userData.userId)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const getUserCompletedOrders = createAsyncThunk(
	ActionTypes.orders.userCompleteOrders.typeName,
	async (userData: UserBaseInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.getUserCompletedOrders(userData.userId)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const getAllUsersActiveOrders = createAsyncThunk(
	ActionTypes.orders.allActiveOrders.typeName,
	async (userData: UserBaseInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.getAllActiveOrders()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const getAllCompletedOrders = createAsyncThunk(
	ActionTypes.orders.allCompletedOrders.typeName,
	async (userData: UserBaseInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.getAllCompletedOrders()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface GetSpecificOrdersInterface {
	ordersIds: string[]
}
export const getSpecificOrders = createAsyncThunk(
	ActionTypes.orders.getSpecific.typeName,
	async (data: GetSpecificOrdersInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await ordersService.getSpecific(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface RegisterOrderInterface {
	userId: string
	receiver: string
	econtOfficeDeliveryAddress?: IDefaultEcontDeliveryAddress
	deliverTo: string
	officeDeliveryAddress: string
	siteOrigin: string
	transactionDate: string
	description: string
	referenceNumberOrInvoice: string
	valueEur: string
	count: number
	deliveryCompany: string
	receiverPhone: string
}
export const registerOrder = createAsyncThunk(
	ActionTypes.orders.registerOrder.typeName,
	async (orderData: RegisterOrderInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.registerOrder(orderData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.registerOrderSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface CreateUnregisterOrderInterface extends CreateUnregisteredOrderFormikValues {
	userId: string
	userRole: string
}
export const createUnregisteredOrder = createAsyncThunk(
	ActionTypes.orders.registerOrder.typeName,
	async (orderData: CreateUnregisterOrderInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.createUnregisteredOrder(orderData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.registerOrderSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface OrderData {
	userId: string
	orderId: string
}
export const updateOrder = createAsyncThunk(
	ActionTypes.orders.update.typeName,
	async (orderData: OrderData, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const { userId, ...rest } = orderData
			const response = await ordersService.updateOrder(userId, rest)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.updateOrderSuccess, 'success'))

			return orderData
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface DeleteOrderInterface {
	userId: string
	orderId: string
}
export const deleteOrder = createAsyncThunk(
	ActionTypes.orders.delete.typeName,
	async (orderData: DeleteOrderInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.deleteOrder(orderData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.deleteOrderSuccess, 'success'))

			return orderData
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface CombineOrdersInterface {
	orders: Order[]
	userId: string
}
export const combineOrders = createAsyncThunk(
	ActionTypes.orders.combineOrders.typeName,
	async (ordersData: CombineOrdersInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.combineOrders(ordersData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return {
				masterOrder: response.data,
				linkedOrders: ordersData.orders
			}
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UncombineOrdersInterface {
	masterOrder: Order
	userId: string
}
export const uncombineOrders = createAsyncThunk(
	ActionTypes.orders.uncombineOrders.typeName,
	async (ordersData: UncombineOrdersInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await ordersService.uncombineOrders(ordersData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return {
				masterOrder: ordersData.masterOrder,
				unlinkedOrders: response.data
			}
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)
