import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import ReactGA from 'react-ga4'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { store } from './data/store'
import { AppRouter } from './AppRouter'
import { theme } from './utils/mui/theme'
import reportWebVitals from './reportWebVitals'

import './index.css'

// PROD Google Analytics
const TRACKING_ID = "G-E79THZY7CB"
// TEST Google analytics
// const TRACKING_ID = "G-2P6T1R5THW"
ReactGA.initialize(TRACKING_ID)

let persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <PersistGate loading={null} persistor={persistor}>
                        <AppRouter />
                    </PersistGate>
                </LocalizationProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
)

const sendAnalytics = () => ReactGA.send({ hitType: 'pageview', page: window.location.pathname })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendAnalytics)
