import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

import { i18nFreightCalc } from '../i18n'
import { useTranslateSingle } from '../../../../hooks/translate'
import { sendReactGAEvent } from '../../../../utils/ReactGAEvent'

import './styles.css'

export function CalcFreightsMobileForm(props: any): JSX.Element {
    const requestText = useTranslateSingle(i18nFreightCalc.sendRequest)

    const handleClick = () => sendReactGAEvent('calculator', 'freights', 'mobile')

    return (
        <>
            <Link to='/contacts'>
                <Button
                    className="price-request-button"
                    onClick={handleClick}
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                >
                    {requestText}
                </Button>
            </Link>
        </>
    )
}
