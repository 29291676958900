import { saveAs } from 'file-saver'
import {
	Table,
	Packer,
	TextRun,
	TableRow,
	Document,
	TableCell,
	Paragraph,
	WidthType,
	AlignmentType,
	VerticalAlign,
} from 'docx'

import { texts } from '../../../utils/i18n/texts'
import { getFormattedDate } from '../../../utils/functions'
import { Order } from '../../../data/reducers/ordersReducer'
import { CrossBorderSchedule } from '../../../data/reducers/crossBorderSchedulesReducer'

export interface SimplifiedPackage {
	weight: number
	width: number
	depth: number
	height: number
}

export interface SimplifiedOrder {
	packagesCount: number
	description: string
	referenceNumberOrInvoice: string
	totalWeight: number
}

export interface DownloadPrintFileInputInterface {
	orders: SimplifiedOrder[]
	startDate: string
	endDate: string
}

const headTableHeaderSize = 22
const orderstableHeaderSize = 26

const generateDocxHeadTable = (mainInfo: DownloadPrintFileInputInterface): Table => {
	return new Table({
		width: {
			size: 100,
			type: WidthType.PERCENTAGE
		},
		rows: [
			new TableRow({
				children: [
					new TableCell({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										size: headTableHeaderSize,
										text: 'Start Date',
										bold: true,
									})
								],
								alignment: AlignmentType.CENTER
							})
						]
					}),
					new TableCell({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										size: headTableHeaderSize,
										text: 'Order Number',
										bold: true,
									})
								],
								alignment: AlignmentType.CENTER
							})
						]
					}),
					new TableCell({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										size: headTableHeaderSize,
										text: 'Shipping Company',
										bold: true,
									})
								],
								alignment: AlignmentType.CENTER
							})
						]
					}),
					new TableCell({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										size: headTableHeaderSize,
										text: 'End Date',
										bold: true,
									})
								],
								alignment: AlignmentType.CENTER
							})
						]
					}),
				]
			}),
			new TableRow({
				children: [
					new TableCell({
						children: [
							new Paragraph({ children: [new TextRun(mainInfo.startDate)], alignment: AlignmentType.CENTER })
						]
					}),
					new TableCell({
						children: [
							new Paragraph({ children: [new TextRun('03690123')], alignment: AlignmentType.CENTER })
						]
					}),
					new TableCell({
						children: [
							new Paragraph({ children: [new TextRun('TuttoPost Ltd. Sofia-Center Bulgaria')], alignment: AlignmentType.CENTER })
						]
					}),
					new TableCell({
						children: [
							new Paragraph({ children: [new TextRun(mainInfo.endDate)], alignment: AlignmentType.CENTER })
						]
					}),
				]
			})
		]
	})
}

const generateDocxTotalsTable = (orders: SimplifiedOrder[]): Table => {
	let totalPackages = 0
	let totalWeight = 0
	orders.forEach(order => {
		totalPackages += order.packagesCount
		totalWeight += order.totalWeight
	})
	return new Table({
		width: {
			size: 100,
			type: WidthType.PERCENTAGE
		},
		rows: [
			new TableRow({
				children: [
					new TableCell({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										size: headTableHeaderSize,
										text: 'Total shipped',
										bold: true,
									})
								],
								alignment: AlignmentType.CENTER
							})
						]
					}),
					new TableCell({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										size: headTableHeaderSize,
										text: 'Total weight kg.',
										bold: true,
									})
								],
								alignment: AlignmentType.CENTER
							})
						]
					}),
					new TableCell({
						children: [
							new Paragraph({
								children: [
									new TextRun({
										size: headTableHeaderSize,
										text: 'Shipping notes',
										bold: true,
									})
								],
								alignment: AlignmentType.CENTER
							})
						]
					}),
				]
			}),
			new TableRow({
				children: [
					new TableCell({
						children: [
							new Paragraph({ children: [new TextRun(totalPackages.toString())], alignment: AlignmentType.CENTER })
						]
					}),
					new TableCell({
						children: [
							new Paragraph({ children: [new TextRun(totalWeight.toString())], alignment: AlignmentType.CENTER })
						]
					}),
					new TableCell({
						children: [
							new Paragraph({ children: [new TextRun('N/A')], alignment: AlignmentType.CENTER })
						]
					}),
				]
			})
		]
	})
}

const generateDocxOrdersTable = (orders: SimplifiedOrder[]): Table => {
	const rows = orders.map((order: SimplifiedOrder, i: number) => {
		return new TableRow({
			children: [
				new TableCell({
					verticalAlign: VerticalAlign.CENTER,
					children: [new Paragraph({
						children: [
							new TextRun({
								size: 24,
								text: order.packagesCount.toString(),
							})
						],
						alignment: AlignmentType.CENTER,
					})]
				}),
				new TableCell({
					verticalAlign: VerticalAlign.CENTER,
					children: [new Paragraph({
						children: [
							new TextRun({
								size: 24,
								text: order.totalWeight.toString(),
							})
						],
						alignment: AlignmentType.CENTER,
					})]
				}),
				new TableCell({
					verticalAlign: VerticalAlign.CENTER,
					children: [new Paragraph({
						children: [
							new TextRun({
								size: 24,
								text: order.description
							})
						],
						alignment: AlignmentType.CENTER,
					})]
				}),
				new TableCell({
					verticalAlign: VerticalAlign.CENTER,
					children: [new Paragraph({
						children: [
							new TextRun({
								size: 24,
								text: order.referenceNumberOrInvoice,
							})
						],
						alignment: AlignmentType.CENTER,
					})]
				}),
			]
		})
	})

	return new Table({
		width: {
			size: 100,
			type: WidthType.PERCENTAGE
		},
		rows: [
			new TableRow({
				children: [
					new TableCell({
						verticalAlign: VerticalAlign.BOTTOM,
						children: [new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									size: orderstableHeaderSize,
									text: 'Quantity',
									bold: true
								})
							]
						})]
					}),
					new TableCell({
						verticalAlign: VerticalAlign.BOTTOM,
						children: [new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									size: orderstableHeaderSize,
									text: 'Total weight kg.',
									bold: true
								})
							]
						})]
					}),
					new TableCell({
						verticalAlign: VerticalAlign.BOTTOM,
						children: [new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									size: orderstableHeaderSize,
									text: 'Description',
									bold: true
								})
							]
						})]
					}),
					new TableCell({
						verticalAlign: VerticalAlign.BOTTOM,
						children: [new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									size: orderstableHeaderSize,
									text: 'Reference ID',
									bold: true
								})
							]
						})]
					}),
				]
			}),
			...rows
		]
	})
}

export function downloadPrintFile(inputData: DownloadPrintFileInputInterface) {
	const { orders, startDate, endDate } = inputData

	const header = `it-bg trans details ${startDate} - ${endDate}`

	const appendSameLineNextText = (currentText: string, nextText: string): string => {
		const symbolsBeforeSameLineNextText = 80
		const iterations = (symbolsBeforeSameLineNextText - currentText.length) / 2
		let result = currentText
		for (let i = 0; i < iterations; i++) {
			result += '  '
		}
		result += nextText
		return result
	}

	const headTable = generateDocxHeadTable(inputData)
	const ordersTable = generateDocxOrdersTable(orders)
	const totalsTable = generateDocxTotalsTable(orders)
	const docxObj = new Document({
		sections: [
			{
				children: [
					new Paragraph({
						children: [
							new TextRun({
								text: 'IT-BG Transportation Details',
								allCaps: true,
								size: 30
							}),
							new TextRun({
								break: 2
							})
						],
						alignment: AlignmentType.CENTER,
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: appendSameLineNextText('Company: Newline Company S.r.l.', 'Customer: TuttoPost Ltd.'),
							})
						],
						alignment: AlignmentType.LEFT,
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: appendSameLineNextText('Address: Via degli Agrostemmi, 241', ' Address: 145A, "Knyaz Boris I" Str.'),
							})
						],
						alignment: AlignmentType.LEFT,
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: appendSameLineNextText('City, State, Zip: 00134 Roma (RM) Italia', 'City, State, Zip: 1000 Sofia-Center Bulgaria'),
							})
						],
						alignment: AlignmentType.LEFT,
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: appendSameLineNextText(`Phone: ${texts.italyCustomsPhone}`, `       Phone: ${texts.customsPhone}`),
							}),
							new TextRun({
								break: 2
							})
						],
						alignment: AlignmentType.LEFT,
					}),
				]
			},
			{
				children: [headTable, ordersTable, totalsTable]
			}
		]
	})

	saveDocumentToFile(docxObj, `${header}.docx`)
}

export function mapToDownloadPrintFileInputData(schedule: CrossBorderSchedule, allOrders: Order[]): DownloadPrintFileInputInterface {
	const ordersObjs = allOrders.filter(x => schedule.orders.includes(x._id))

	const getFormattedPackages = (order: Order) => {
		return order.packages.map((pckg: any) => {
			const [width, height, depth] = pckg.sizes.split('x').map((x: string) => +x)
			return { width, height, depth, weight: pckg.weight }
		})
	}

	const getOrderTotalWeight = (packages: Object[]): number => {
		let total = 0
		packages.forEach((pckg: any) => {
			total += pckg.weight
		})
		return +Number(total).toFixed(2)
	}

	const simplifiedOrders = ordersObjs.map((order: Order, i: number) => {
		const packages = getFormattedPackages(order)
		return {
			referenceNumberOrInvoice: order.referenceNumberOrInvoice,
			packagesCount: packages.length,
			description: order.description,
			totalWeight: getOrderTotalWeight(packages)
		} as SimplifiedOrder
	})

	return {
		startDate: getFormattedDate(true, true, true, false, schedule.pickupDate),
		endDate: getFormattedDate(true, true, true, false, schedule.deliveryDate),
		orders: simplifiedOrders
	} as DownloadPrintFileInputInterface
}

function saveDocumentToFile(doc: any, fileName: string) {
	const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	Packer.toBlob(doc).then((blob) => {
		const docblob = blob.slice(0, blob.size, mimeType)
		saveAs(docblob, fileName)
	})
}