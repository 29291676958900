import { createAction } from '@reduxjs/toolkit'

import { ActionTypes } from '../actionTypes'
import { NotificationType } from '../reducers/notificationReducer'
import { store } from '../store'

export const showNotification = (message: string | { bg: string, en: string }, type: NotificationType) => {
	let msg = message
	if (typeof message !== 'string') {
		const lang = store.getState().lang.currentSelection
		msg = (message as any)[lang]
	}

	return (createAction(ActionTypes.notification.show.typeName, () => {
		return {
			payload: {
				message: msg,
				type
			}
		}
	}))()
}

export const hideNotification = createAction(ActionTypes.notification.hide.typeName)
