import { getFormattedDate } from "../../utils/functions";
import { getCrossBorderScheduleStatusChip } from "../../components/Shared";
import { CrossBorderSchedule } from "../../data/reducers/crossBorderSchedulesReducer";

export const tableStruct = (heads: string[], lang: string) => [
	{
		head: heads[0],
		getData: (obj: CrossBorderSchedule) => getCrossBorderScheduleStatusChip(obj.status, lang),
	},
	{
		head: heads[1],
		getData: (obj: CrossBorderSchedule) => `${obj.fromCity}, ${obj.fromCountry}`,
	},
	{
		head: heads[2],
		getData: (obj: CrossBorderSchedule) => `${obj.toCity}, ${obj.toCountry}`,
	},
	{
		head: heads[3],
		getData: (obj: CrossBorderSchedule) => getFormattedDate(true, true, true, false, obj.pickupDate),
	},
	{
		head: heads[4],
		getData: (obj: CrossBorderSchedule) => getFormattedDate(true, true, true, false, obj.deliveryDate),
	},
]
