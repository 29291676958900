export const i18nProfileCBS = {
	noOrdersMsg: {
		bg: 'Няма пратки към този график',
		en: 'No packages for this schedule'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	weight: {
		bg: 'Тегло',
		en: 'Weight'
	},
	count: {
		bg: 'Брой',
		en: 'Count'
	},
	deliveryValue: {
		bg: 'За плащане',
		en: 'To pay'
	}
}
