export class DomainAction {
	typeName: string = ''

	constructor(typeName: string) {
		this.typeName = typeName
	}

	pending(): string {
		return this.typeName + '/pending'
	}
	fulfilled(): string {
		return this.typeName + '/fulfilled'
	}
	rejected(): string {
		return this.typeName + '/rejected'
	}

	toString(): string {
		return this.typeName
	}
}

export class ActionTypes {
	static users = {
		login: new DomainAction('users/login'),
		delete: new DomainAction('users/delete'),
		logout: new DomainAction('users/logout'),
		register: new DomainAction('users/register'),
		getAllUsers: new DomainAction('users/getAll'),
		updateRole: new DomainAction('users/updateRole'),
		updateProfile: new DomainAction('users/updateProfile'),
		sessionExpired: new DomainAction('users/sessionExpired'),
		forgetPassword: new DomainAction('users/forgetPassword'),
		restorePassword: new DomainAction('users/restorePassword'),
		registerSpecific: new DomainAction('users/registerSpecific'),
		sendEmailRequest: new DomainAction('users/sendEmailRequest'),
		verifyRegistration: new DomainAction('users/verifyRegistration'),
	}

	static orders = {
		update: new DomainAction('orders/update'),
		delete: new DomainAction('orders/delete'),
		getSpecific: new DomainAction('internalSchedules/getSpecific'),
		registerOrder: new DomainAction('orders/register'),
		allActiveOrders: new DomainAction('orders/allActive'),
		combineOrders: new DomainAction('orders/combine'),
		uncombineOrders: new DomainAction('orders/uncombine'),
		allCompletedOrders: new DomainAction('orders/allCompleted'),
		userActiveOrders: new DomainAction('orders/userActive'),
		userCompleteOrders: new DomainAction('orders/userComplete'),
	}

	static internalSchedules = {
		allActive: new DomainAction('internalSchedules/allActive'),
		allCompleted: new DomainAction('internalSchedules/allCompleted'),
		create: new DomainAction('internalSchedules/create'),
		update: new DomainAction('internalSchedules/update'),
		updateOrder: new DomainAction('internalSchedules/updateOrder'),
		delete: new DomainAction('internalSchedules/delete'),
		complete: new DomainAction('internalSchedules/complete'),
		updateOrderStatus: new DomainAction('internalSchedules/orderStatus'),
		notifySofiaOrdersDelivery: new DomainAction('internalSchedules/notifySofiaOrdersDelivery')
	}

	static crossBorderSchedules = {
		all: new DomainAction('crossBorderSchedules/all'),
		allActive: new DomainAction('crossBorderSchedules/allActive'),
		allCompleted: new DomainAction('crossBorderSchedules/allCompleted'),
		create: new DomainAction('crossBorderSchedules/create'),
		update: new DomainAction('crossBorderSchedules/update'),
		delete: new DomainAction('crossBorderSchedules/delete'),
		complete: new DomainAction('crossBorderSchedules/complete'),
		notifyOrdersPickedUp: new DomainAction('crossBorderSchedules/notifyOrdersPickedUp'),
	}

	static settings = {
		getPrices: new DomainAction('settings/getPrices'),
		updatePrices: new DomainAction('settings/updatePrices'),
		getDefaults: new DomainAction('settings/getDefaults'),
		updateDefaults: new DomainAction('settings/updateDefaults')
	}

	static econt = {
		getOffices: new DomainAction('econt/getOffices'),
		validateLabel: new DomainAction('econt/validateLabel'),
		createLabel: new DomainAction('econt/createLabel'),
	}

	static loader = {
		load: new DomainAction('loader/load'),
		unload: new DomainAction('loader/unload')
	}

	static language = {
		change: new DomainAction('language/change')
	}

	static notification = {
		show: new DomainAction('notification/show'),
		hide: new DomainAction('notification/hide')
	}
}
