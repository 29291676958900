import { Formik } from 'formik'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Typography } from '@mui/material'
import { Fade } from 'react-awesome-reveal'
import { useSelector, connect } from 'react-redux'

import { i18nLogin } from './i18n'
import { LoginForm } from './LoginForm'
import { GlobalState } from '../../data/store'
import { easeTimes } from '../../utils/easeTimes'
import { login } from '../../data/actions/usersActions'
import { useTranslateSingle } from '../../hooks/translate'
import { validateEmail } from '../../utils/formik/validations'
import { DeviceStrings, useResponsiveString } from '../../hooks/useResponsiveString'

import './styles.css'

export function LoginPageComponent(props: any): JSX.Element {
    const navigate = useNavigate()
    const { isLogged } = useSelector((state: GlobalState) => state.users.loggedIn)

    useEffect(() => {
        if (isLogged) {
            navigate('/')
        }
    }, [isLogged, navigate])

    const classNames = {
        mobile: 'login-container-mobile',
        smallDesktop: 'login-container',
    } as DeviceStrings

    const containerClassName = useResponsiveString(classNames)

    const title = useTranslateSingle(i18nLogin.title)

    return (
        <div className={containerClassName}>
            <div className="login-form-wrapper">
                <Fade cascade triggerOnce damping={0.3} direction="up" duration={easeTimes.slow}>
                    <Typography variant="h4" className="header">
                        {title}
                    </Typography>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            rememberUser: false,
                        }}
                        validate={(values) => validateEmail(values, ['email'])}
                        onSubmit={(values: any, { setSubmitting }) => {
                            setSubmitting(true)
                            props.login(values)
                        }}
                    >
                        {(props: any) => <LoginForm {...props} />}
                    </Formik>
                </Fade>
            </div>
        </div>
    )
}

const mapDispatchToProps = { login }

export const LoginPage = connect(null, mapDispatchToProps)(LoginPageComponent)
