export const i18nPerks = {
	stores: {
		bg: 'Магазини',
		en: 'Stores'
	},
	storesSub: {
		bg: 'Списъкът ще бъде редовно допълван и обновяван.',
		en: 'The list will be updated regularly.'
	},
	newsAndSchedule: {
		bg: 'Новини и график',
		en: 'News and schedule'
	},
	newsAndScheduleSub: {
		bg: 'Разберете кога заминава от Италия и пристига в България вашата пратка, както и нейния статус.',
		en: 'Learn when your order will be trasported as well as its status.'
	},
	promotions: {
		bg: 'Промоции',
		en: 'Promotions'
	},
	promotionsSub: {
		bg: '0% за асистирано пазаруване и още промоции, от които можете да се възползвате.',
		en: '0% for online shopping assistance and more promotions you can benefit from.'
	}
}
