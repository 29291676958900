import { Link } from 'react-router-dom'
import { Chip } from '@mui/material'
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Adjust } from '@mui/icons-material'

import {
    mapToFriendlyOrderStatus,
    mapToFriendlyInternalSchedulesStatus,
    mapToFriendlyCrossBorderScheduleStatus,
} from '../utils/functions'
import { OrderStatus, ORDER_STATUS } from '../data/reducers/ordersReducer'
import { InternalScheduleStatus, INTERNAL_SCHEDULE_STATUS } from '../data/reducers/internalSchedulesReducer'
import { CrossBorderScheduleStatus, CROSS_BORDER_SCHEDULE_STATUS } from '../data/reducers/crossBorderSchedulesReducer'

export function getOrderByHeadIcon(headObj: any, orderBy: string, ascending: boolean) {
    if (orderBy !== headObj.name) {
        return <Adjust color="primary" />
    } else {
        return ascending ? <KeyboardDoubleArrowDown color="secondary" /> : <KeyboardDoubleArrowUp color="secondary" />
    }
}

export function getOrderStatusChip(status: OrderStatus, lang: string = 'bg'): JSX.Element {
    const friendlyStatus = mapToFriendlyOrderStatus(status, lang)
    switch (status) {
        case ORDER_STATUS.CANCELLED: {
            return <Chip size="small" variant="filled" color="secondary" label={friendlyStatus} />
        }
        case ORDER_STATUS.ARRIVED_ABROAD: {
            return <Chip size="small" variant="filled" color="warning" label={friendlyStatus} />
        }
		case ORDER_STATUS.TRAVELING: {
            return <Chip size="small" variant="filled" color="secondary" label={friendlyStatus} />
        }
        case ORDER_STATUS.ARRIVED_BG: {
            return <Chip size="small" variant="filled" color="success" label={friendlyStatus} />
        }
        case ORDER_STATUS.REGISTERED: {
            return <Chip size="small" variant="filled" color="info" label={friendlyStatus} />
        }
        case ORDER_STATUS.COMPLETED: {
            return <Chip size="small" variant="filled" color="primary" label={friendlyStatus} />
        }
        default: {
            return <Chip size="small" variant="filled" color="info" label={friendlyStatus} />
        }
    }
}

export function getInternalScheduleStatusChip(status: InternalScheduleStatus, lang: string = 'bg'): JSX.Element {
    const friendlyName = mapToFriendlyInternalSchedulesStatus(status, lang)
    switch (status) {
        case INTERNAL_SCHEDULE_STATUS.EXPECT_TO_PICKUP: {
            return <Chip size="small" variant="filled" color="secondary" label={friendlyName} />
        }
        case INTERNAL_SCHEDULE_STATUS.PICKED_UP: {
            return <Chip size="small" variant="filled" color="warning" label={friendlyName} />
        }
        case INTERNAL_SCHEDULE_STATUS.DELIVERED: {
            return <Chip size="small" variant="filled" color="primary" label={friendlyName} />
        }
        default: {
            return <Chip size="small" variant="filled" color="info" label={friendlyName} />
        }
    }
}

export function getCrossBorderScheduleStatusChip(status: CrossBorderScheduleStatus, lang: string): JSX.Element {
    const friendlyName = mapToFriendlyCrossBorderScheduleStatus(status, lang)
    switch (status) {
        case CROSS_BORDER_SCHEDULE_STATUS.EXPECT_TO_PICKUP: {
            return <Chip size="small" variant="filled" color="warning" label={friendlyName} />
        }
        case CROSS_BORDER_SCHEDULE_STATUS.PICKED_UP: {
            return <Chip size="small" variant="filled" color="secondary" label={friendlyName} />
        }
        case CROSS_BORDER_SCHEDULE_STATUS.DELIVERED: {
            return <Chip size="small" variant="filled" color="error" label={friendlyName} />
        }
        case CROSS_BORDER_SCHEDULE_STATUS.COMPLETED: {
            return <Chip size="small" variant="filled" color="primary" label={friendlyName} />
        }
        default: {
            return <Chip size="small" variant="filled" color="info" label={friendlyName} />
        }
    }
}

export function AgreeWithTermsAndConditions(props: any): JSX.Element {
    const { label, main } = props
    return (
        <>
            {label}
            <Link to="/terms-and-conditions" style={{ textDecoration: 'underline' }}>
                {main}
            </Link>
        </>
    )
}
