import { useSelector } from "react-redux"

import { GlobalState } from "../data/store"
import { TranslatePairs, TranslatedObject } from "../utils/i18n/TranslateObject"

export function useTranslate(translateObject: Object): TranslatedObject {
	const lang = useSelector((state: GlobalState) => state.lang)

	const keys = Object.keys(translateObject)
	const translatedObject = {} as TranslatedObject
	for (const key of keys) {
		const pairs = translateObject[key as keyof Object] as unknown as TranslatePairs
		const translated = pairs[lang.currentSelection as keyof TranslatePairs]
		// @ts-ignore
		translatedObject[key as keyof TranslatedObject] = translated
	}
	return translatedObject
}

export function useTranslateSingle(translatePairs: TranslatePairs): string {
	const lang = useSelector((state: GlobalState) => state.lang)
	return translatePairs[lang.currentSelection as keyof TranslatePairs]
}
