import { DeviceComponents, useResponsive } from '../../../hooks/useResponsive'
import { InternalSchedulesMobile } from './mobile/InternalSchedulesMobile'
import { InternalSchedulesDesktop } from './desktop/InternalSchedulesDesktop'

export function InternalSchedules() {
    const components = {
        mobile: <InternalSchedulesMobile />,
        smallDesktop: <InternalSchedulesDesktop />
    } as DeviceComponents

    return useResponsive(components)
}
