import { useSelector } from "react-redux"
import { AddOutlined, DeleteForeverOutlined } from "@mui/icons-material"
import { FormControlLabel, FormGroup, IconButton, Switch, TextField, Tooltip, Typography } from "@mui/material"

import { i18nAdminOrders } from "./i18n"
import { GlobalState } from "../../../../data/store"
import { texts } from "../../../../utils/i18n/texts"
import { useTranslate, useTranslateSingle } from "../../../../hooks/translate"
import { IPackage, ORDER_STATUS } from "../../../../data/reducers/ordersReducer"
import { calculateTransportationPrice, mapPackagesStringSizesToPackagesObjectsSizes } from "../../../../utils/functions"

interface IProps {
    status: string
    packages: IPackage[]
    unregistered: boolean
    deliveryCompany: string
    handleAddPackage: () => void
    handleDeletePackage: (index: number) => void
    handleChangePackageSizes: (index: number, value: string) => void
    handleChangePackageWeight: (index: number, value: string) => void
    handleChangePackageProcessed: (index: number, isProcessed: boolean) => void
}

export function PackagesManager(props: IProps) {
    const {
        status,
        packages,
        unregistered,
        deliveryCompany,
        handleAddPackage,
        handleDeletePackage,
        handleChangePackageSizes,
        handleChangePackageWeight,
        handleChangePackageProcessed,
    } = props

    const { prices } = useSelector((state: GlobalState) => state.settings)

    const t = useTranslate(i18nAdminOrders)
    const currency = useTranslateSingle(texts.currency)

    const isTuttoDelivery = deliveryCompany === 'tuttopost'

    const packagesInfo = mapPackagesStringSizesToPackagesObjectsSizes(packages)
    const price = calculateTransportationPrice(packagesInfo, prices, isTuttoDelivery, !!unregistered)

    return (
        <>
            {status === ORDER_STATUS.ARRIVED_BG &&
                packages.length &&
                packages.map((packageObj: any, i: number) => {
                    return (
                        <div key={i} className={`package-measurements ${packageObj.processed ? 'processed' : ''}`}>
                            <div className="package-measurements-top-wrapper">
                                <Typography variant="h6">
                                    {t.packageLabel} {i + 1}
                                </Typography>
                                <FormGroup onChange={() => handleChangePackageProcessed(i, !packageObj.processed)}>
                                    <FormControlLabel
                                        control={<Switch checked={!!packageObj.processed} />}
                                        label={t.processedLabel}
                                    />
                                </FormGroup>
                                <IconButton color="secondary" onClick={() => handleDeletePackage(i)}>
                                    <DeleteForeverOutlined />
                                </IconButton>
                            </div>
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                margin="dense"
                                variant="outlined"
                                label={t.measuredWeight}
                                value={packages[i].weight}
                                disabled={packageObj.processed}
                                onChange={(ev: any) => handleChangePackageWeight(i, ev.target.value)}
                            />
                            <TextField
                                fullWidth
                                size="small"
                                margin="dense"
                                variant="outlined"
                                placeholder="40x50x60"
                                label={t.measuredSizes}
                                value={packages[i].sizes}
                                disabled={packageObj.processed}
                                onChange={(ev: any) => handleChangePackageSizes(i, ev.target.value)}
                            />
                            <hr />
                        </div>
                    )
                })}
            {status === ORDER_STATUS.ARRIVED_BG && (
                <Tooltip title={t.addPackage}>
                    <IconButton color="primary" onClick={handleAddPackage}>
                        <AddOutlined />
                    </IconButton>
                </Tooltip>
            )}
            {status === ORDER_STATUS.ARRIVED_BG && price > 0 && (
                <Typography className="order-price" variant="h6">
                    {t.deliveryPrice}: {price} {currency}
                </Typography>
            )}</>
    )
}