import { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { Box } from '@mui/system'
import { Tab, Tabs } from '@mui/material'

import { i18nStatsTabs } from './i18n'
import { useTranslate } from '../../../hooks/translate'
import { IncomesGraphic } from './graphics/IncomesGraphic'
import { OrdersCountGraphic } from './graphics/OrdersCount'
import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { getAllUsers } from '../../../data/actions/usersActions'
import { HourOfOrderGraphic } from './graphics/HourOfOrderGraphic'
import { RegisteredUsersGraphic } from './graphics/RegisteredUsersGraphic'
import { getAllCompletedOrders } from "../../../data/actions/ordersActions"
import { OrdersDestinationTotalGraphic } from './graphics/OrdersDestinationTotal'
import { OrdersDestinationOvertimeGraphic } from './graphics/OrdersDestinationOvertime'
import { getCompletedInternalSchedules } from '../../../data/actions/internalSchedulesActions'
import { getCompletedCrossBorderSchedules } from '../../../data/actions/crossBorderSchedulesActions'

const StatsTabs = {
    REGISTERED_USERS: 0,
    ORDERS_COUNT: 1,
	INCOMES: 2,
    DESTINATIONS_TOTAL: 3,
    DESTINATIONS_OVERTIME: 4,
    HOUR_OF_ORDER: 5,
}

function StatisticsSectorComponent(props: any) {
    const [tab, setTab] = useState(StatsTabs.REGISTERED_USERS)

    const t = useTranslate(i18nStatsTabs)

    const handleChange = (ev: any, value: any) => setTab(value)

    useEffect(() => {
		props.getAllUsers()
        props.getAllCompletedOrders()
        props.getCompletedInternalSchedules()
        props.getCompletedCrossBorderSchedules()
    }, [props])

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label={t.registeredUsers} id="statistics-tab-0" />
                    <Tab label={t.ordersCount} id="statistics-tab-1" />
					<Tab label={t.incomes} id="statistics-tab-2" />
                    <Tab label={t.destinationTotal} id="statistics-tab-3" />
                    <Tab label={t.destinationOvertime} id="statistics-tab-4" />
                    <Tab label={t.hourOfOrder} id="statistics-tab-5" />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={StatsTabs.REGISTERED_USERS}>
				<RegisteredUsersGraphic />
            </TabPanel>
            <TabPanel value={tab} index={StatsTabs.ORDERS_COUNT}>
				<OrdersCountGraphic />
            </TabPanel>
			<TabPanel value={tab} index={StatsTabs.INCOMES}>
				<IncomesGraphic />
            </TabPanel>
            <TabPanel value={tab} index={StatsTabs.DESTINATIONS_TOTAL}>
				<OrdersDestinationTotalGraphic />
            </TabPanel>
            <TabPanel value={tab} index={StatsTabs.DESTINATIONS_OVERTIME}>
				<OrdersDestinationOvertimeGraphic />
            </TabPanel>
            <TabPanel value={tab} index={StatsTabs.HOUR_OF_ORDER}>
				<HourOfOrderGraphic />
            </TabPanel>
        </>
    )
}

const mapDispatchToProps = {
	getAllUsers,
    getAllCompletedOrders,
    getCompletedInternalSchedules,
    getCompletedCrossBorderSchedules
}

export const StatisticsSector = connect(null, mapDispatchToProps)(StatisticsSectorComponent)
