import { useState } from "react"
import { useSelector } from "react-redux"
import { Pagination, Typography } from "@mui/material"

import { i18nProfileCO } from "../i18n"
import { GlobalState } from "../../../../data/store"
import { useTranslate } from "../../../../hooks/translate"
import { Order } from "../../../../data/reducers/ordersReducer"
import { CompletedOrdersMobileCard } from "./CompletedOrdersMobileCard"
import { DeviceStrings, useResponsiveString } from "../../../../hooks/useResponsiveString"

import './styles.css'

export function CompletedOrdersMobileList() {
    const { orders } = useSelector((state: GlobalState) => state.orders)

    const [pageIndex, setPageIndex] = useState(1)
    // eslint-disable-next-line
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const handleChangePage = (ev: any, pageIndex: any) => setPageIndex(pageIndex)

    const t = useTranslate(i18nProfileCO)

    const elementStart = (pageIndex - 1) * itemsPerPage
    const paginatedOrders = orders.slice(elementStart, elementStart + itemsPerPage)

    const pagesCount = Math.ceil(orders.length / itemsPerPage)

    const boundaryCountStrings = { mobile: '0', smallDesktop: '1' } as DeviceStrings
    const siblingsCountStrings = { mobile: '0', smallDesktop: '1' } as DeviceStrings

    const boundary = +useResponsiveString(boundaryCountStrings)
    const siblings = +useResponsiveString(siblingsCountStrings)

    return (
        <>
            {

                !orders.length ?
                    <div className='profile-mobile-no-completed-orders'>
                        <Typography align="center" variant='h4'>{t.noCompletedOrders}</Typography>
                    </div>
                    :
                    <>
                        {
                            paginatedOrders?.map((order: Order, i: number) => {
                                return <CompletedOrdersMobileCard key={i} order={order} />
                            })
                        }
                        <div className='profile-orders-pagination-wrapper-mobile'>
                            <Pagination
                                size='large'
                                shape="rounded"
                                page={pageIndex}
                                count={pagesCount}
                                variant="outlined"
                                siblingCount={siblings}
                                boundaryCount={boundary}
                                onChange={handleChangePage}
                            />
                        </div>
                    </>
            }
        </>
    )
}
