export const i18nForbiddenItems = {
	title: {
		bg: 'Забранени стоки',
		en: 'Forbidden items'
	},
	text1: {
		bg: 'Стоки с особена стойност - благородни метали, оригинална бижутерия, скъпоценни камъни, естествени перли, антики, предмети на изкуството.',
		en: 'Items with unusual value - precious metals, original jewelary, precious stones, natural pearls, antiques, objets of art.'
	},
	text2: {
		bg: 'Пари, удостоверения, дългови книжа, документи, гаранции и други вещи с парична стойност (кредитни, чекови, телефонни карти или подобни на тях), полици и записи на заповед, акции, документи за дружествени дялове, както и каквито и да са документи на хартиен носител или други средства, даващи право на плащане.',
		en: 'Money, certificates, debentures, documents, guarantees and other items of monetary value (credit, cheque, phone cards or similar), policies and promissory notes, shares, documents for company shares, as well as any documents of paper or other means of payment.'
	},
	text3: {
		bg: 'Ваучери и талони на стойност над 520 евро за един пакет.',
		en: 'Vouchers and coupons worth more than 520 euros for one package.'
	},
	text4: {
		bg: 'Кожи, килими, часовници и други бижутерийни артикули и стоки от кожа с единична стойност повече от 520 евро за един пакет.',
		en: 'Leather, carpets, watches and other jewelery and leather goods with a unit value of more than 520 euros for one package.'
	},
	text5: {
		bg: 'Всякакви стоки на стойност повече от 13 000 евро за един пакет.',
		en: 'Any goods worth more than 13,000 euros for one package.'
	},
	text6: {
		bg: 'Товари, чието съдържание, външен вид или изпращане представлява нарушение на закона.',
		en: 'Goods whose content, appearance or shipment constitute a violation of the law.'
	},
	text7: {
		bg: 'Оръжия, взривни вещества, противозаконни наркотични вещества, амуниции, газ, леснозапалими предмети, радиоактивни обекти, заразни или корозивни вещества, психотропни вещества или обекти, силнодействащи и отровни вещества.',
		en: 'Weapons, explosives, illegal narcotics, ammunition, gas, flammable objects, radioactive objects, infectious or corrosive substances, psychotropic substances or objects, powerful and poisonous substances.'
	},
	text8: {
		bg: 'Товари, които по своята същност или начин на опаковане биха могли да представляват опасност за персонала, който ги превозва и пренася или за околната среда, или да застрашат сигурността на превозните средства, използвани за транспортирането им, или да причинят вреди на други пратки, които се превозват или на трети лица.',
		en: 'Cargoes which, by their nature or method of packaging, could pose a danger to the personnel who transport them or to the environment, or to endanger the security of the vehicles used to transport them, or to cause damage to other consignments which are transported or on third parties.'
	},
	text9: {
		bg: 'Стоки, подлежащи на бързо разваляне, живи или мъртви животни.',
		en: 'Perishable goods, live or dead animals.'
	},
	text10: {
		bg: 'Животински кожи, материал за медицински или биологични изследвания, медицински отпадъци, човешки останки, части от тялото или органи.',
		en: 'Animal skins, material for medical or biological research, medical waste, human remains, body parts or organs.'
	},
	text11: {
		bg: 'Стоки, чиито внос или износ е забранен или изисква специално разрешение в съответствие с разпоредбите на законодателството на съответната страна на изпращане, преминаване или доставка.',
		en: 'Goods, the import or export of which is prohibited or requires special authorization in accordance with the provisions of the legislation of the relevant country of dispatch, transit or delivery.'
	},
	text12: {
		bg: 'Всякакви вещи, класифицирани като опасни от национални и международни разпоредби (например тези на IATA).',
		en: 'Any items classified as dangerous by national and international regulations (eg those of IATA).'
	},
	text13: {
		bg: 'Алкохолни съединения, които не са годни за консумация, растения и стоки, подлежащи на бързо разваляне, както и всякакви вещи, чиито внос е забранен в страната по направление.',
		en: 'Alcoholic compounds that are not suitable for consumption, plants and goods subject to rapid spoilage, as well as any items, the import of which is prohibited in the country of destination.'
	},
	text14: {
		bg: 'Неприлични или противоречащи на нравствените норми предмети, както и религиозни материали на забранени или нерегистрирани секти и организации.',
		en: 'Indecent or morally objectionable objects, as well as religious materials of prohibited or unregistered sects and organizations.'
	},
}
