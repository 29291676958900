export const i18nAdminCreateUnregOrder = {
	title: {
		bg: 'Добавяне на нерегистрирана пратка',
		en: 'Add unregistered package'
	},
	noPackMsg: {
		bg: 'Трябва да има поне един пакет',
		en: 'It should contain at least one item'
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	phone: {
		bg: 'Телефон',
		en: 'Phone'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	email: {
		bg: 'Имайл',
		en: 'Email'
	},
	origin: {
		bg: 'Произход',
		en: 'Site origin'
	},
	description: {
		bg: 'Описание',
		en: 'Description'
	},
	packagesLabel: {
		bg: 'Пакети',
		en: 'Items'
	},
	packageLabel: {
		bg: 'Пакети',
		en: 'Item'
	},
	measuredWeight: {
		bg: 'Измерено тегло кг.',
		en: 'Measured weight kg.'
	},
	measuredSizes: {
		bg: 'Измерени размери см.',
		en: 'Measured sizes cm.'
	},
	addPackage: {
		bg: 'Добави пакет',
		en: 'Add item'
	},
	buttonLabel1: {
		bg: 'Създай',
		en: 'Create'
	},
	buttonLabel2: {
		bg: 'Затвори',
		en: 'Close'
	}
}
