import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'

import { FormikError } from '../../../utils/formik/FormikError'
import { useTranslate } from '../../../hooks/translate'
import { i18nRestorePass } from '../i18n'

export function RestorePasswordFormMobile(props: any): JSX.Element {
    const { values, handleChange, handleBlur, errors, touched, handleSubmit } = props

    const [showPasswords, setShowPasswords] = useState(false)
    const toggleShowPasswords = () => setShowPasswords((oldState) => !oldState)

    const t = useTranslate(i18nRestorePass)

    return (
        <form className="restore-password-form" onSubmit={handleSubmit}>
            <div className="rp-input-field">
                <TextField
                    fullWidth
                    type="email"
                    name="email"
                    label={t.email}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                />
                <FormikError name="email" errors={errors} touched={touched} />
            </div>
            <div className="rp-input-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="toggle-password-visibility">{t.newPassword}</InputLabel>
                    <OutlinedInput
                        fullWidth
                        type={showPasswords ? 'text' : 'password'}
                        id="toggle-password-visibility"
                        value={values.newPassword}
                        onChange={handleChange}
                        label={t.newPassword}
                        onBlur={handleBlur}
                        name="newPassword"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={toggleShowPasswords}
                                    aria-label="toggle password visibility"
                                >
                                    {showPasswords ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormikError name="newPassword" errors={errors} touched={touched} />
            </div>
            <Button
                fullWidth
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                className="rp-submit-btn"
            >
                {t.buttonLabel}
            </Button>
        </form>
    )
}
