import { HomePerksMobile } from './mobile/HomePerksMobile'
import { HomePerksDesktop } from './desktop/HomePerksDesktop'
import { DeviceComponents, useResponsive } from '../../../../hooks/useResponsive'

export function HomePerks(): JSX.Element {
    const components = {
        mobile: <HomePerksMobile />,
        smallDesktop: <HomePerksDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}
