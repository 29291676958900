import { connect, useDispatch, useSelector } from "react-redux"
import { decrement, increment, incrementAsync } from "../data/actions/counterActions"

export function CounterBase(props: any): JSX.Element {
	const counter = useSelector(state => (state as any).counter)
	const dispatch = useDispatch()
	const handleIncrement = () => dispatch(increment())
	const handleDecrement = () => dispatch(decrement())
	const handleIncrementAsync = () => props.incrementAsync('')
	return (
		<div>
			<button onClick={handleIncrement}>Increment</button>
			<h6>Value: {counter.value}</h6>
			<button onClick={handleDecrement}>Decrement</button>
			<button onClick={handleIncrementAsync}>Increment Async</button>
		</div>
	)
}

const mapDispatchToProps = { incrementAsync }

export const Counter = connect(null, mapDispatchToProps)(CounterBase)
