export const i18nConfirmModal = {
	confirmTitle: {
		bg: 'Потвърждаване на действие',
		en: 'Confirm action'
	},
	deleteTitle: {
		bg: 'Изтриване завинаги',
		en: 'Delete forever'
	},
	confirm: {
		bg: 'Потвърди',
		en: 'Confirm'
	},
	deleteText: {
		bg: 'Изтрий',
		en: 'Delete'
	},
	close: {
		bg: 'Затвори',
		en: 'Close'
	}
}
