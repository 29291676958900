import { createAsyncThunk } from "@reduxjs/toolkit"

import { hideLoader } from "./loaderActions"
import { ActionTypes } from "../actionTypes"
import { getTranslation } from "./translation"
import { messages } from "../../utils/i18n/messages"
import { showNotification } from "./notificationActions"
import { logFailApiRequest } from "../../utils/functions"
import { crossBorderSchedulesService } from "../../services/crossBorderSchedules"
import { CrossBorderSchedule, CrossBorderScheduleStatus } from "../reducers/crossBorderSchedulesReducer"
import { isAuthRequest } from "./utils"

export const getAllCrossBorderSchedules = createAsyncThunk(
	ActionTypes.crossBorderSchedules.all.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.getAll()
			if (!response.isSuccess) {
				logFailApiRequest(response)
				dispatch(showNotification(response.message, 'error'))
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
) as any

export const getActiveCrossBorderSchedules = createAsyncThunk(
	ActionTypes.crossBorderSchedules.allActive.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.getAllActive()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const getCompletedCrossBorderSchedules = createAsyncThunk(
	ActionTypes.crossBorderSchedules.allCompleted.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.getAllComplete()
			if (!response.isSuccess) {
				logFailApiRequest(response)
				dispatch(showNotification(response.message, 'error'))
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const createCrossBorderSchedule = createAsyncThunk(
	ActionTypes.crossBorderSchedules.create.typeName,
	async (data: CrossBorderSchedule, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.create(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.createCrossBorderScheduleSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdateCrossBorderScheduleInterface {
	_id: string
	status?: CrossBorderScheduleStatus
	pickupDate?: number
	deliveryDate?: number
	userId: string
}
export const updateCrossBorderSchedule = createAsyncThunk(
	ActionTypes.crossBorderSchedules.update.typeName,
	async (data: UpdateCrossBorderScheduleInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.update(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.updateCrossBorderScheduleSuccess, 'success'))

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface DeleteCrossBorderScheduleInterface {
	_id: string
}
export const deleteCrossBorderSchedule = createAsyncThunk(
	ActionTypes.crossBorderSchedules.delete.typeName,
	async (data: DeleteCrossBorderScheduleInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.delete(data._id)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.deleteCrossBorderScheduleSuccess, 'success'))

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface CompleteCrossBorderScheduleInterface {
	scheduleId: string
	userId: string
	ordersIds: string[]
}
export const completeCrossBorderSchedule = createAsyncThunk(
	ActionTypes.crossBorderSchedules.complete.typeName,
	async (data: CompleteCrossBorderScheduleInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.completeSchedule(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface NotifyOrdersPickedUpViaEmailInterface {
	ordersIds: string[]
}
export const notifyOrdersPickedUpViaEmail = createAsyncThunk(
	ActionTypes.crossBorderSchedules.notifyOrdersPickedUp.typeName,
	async (data: NotifyOrdersPickedUpViaEmailInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			const response = await crossBorderSchedulesService.notifyOrdersPickedUpViaEmail(data.ordersIds)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)
