import { Order } from '../../../../data/reducers/ordersReducer'

export const tableStruct = (heads: string[], lang: string) => [
	{
        head: heads[0],
        getData: (obj: Order) => obj.registerDate,
		sortable: true,
        name: 'registerDateEpoch',
    },
	{
        head: heads[1],
        // @ts-ignore
        getData: (obj: Order) => obj.deliverTo === 'office' ? obj.econtOfficeDeliveryAddress?.[lang] || '' : obj.deliveryAddress,
        sortable: false,
    },
    {
        head: heads[2],
        getData: (obj: Order) => {
            let totalWeight = 0
			const kg = lang === 'bg' ? 'кг.' : 'kg.'
            obj.packages.forEach((pckg: any) => (totalWeight += +pckg.weight))
            return `${Number(totalWeight).toFixed(2)} ${kg}`
        },
        sortable: true,
        name: 'weight',
    },
    {
        head: heads[3],
        getData: (obj: Order) => obj.packages.length,
    },
    {
        head: heads[4],
        getData: (obj: Order) => obj.siteOrigin,
        sortable: false,
    },
    {
        head: heads[5],
        getData: (obj: Order) => `${obj.valueEur} EUR`,
        sortable: true,
        name: 'valueEur',
    },
    {
        head: heads[6],
        getData: (obj: Order) => obj.deliveryCompany,
        sortable: false,
    },
]
