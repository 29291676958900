import { Link } from 'react-router-dom'
import { Box } from '@mui/system'
import {
    // IconButton,
    Typography
} from '@mui/material'
// import { Facebook, Google, LinkedIn } from '@mui/icons-material'

import { i18nFooter } from '../i18n'
import { texts } from '../../../utils/i18n/texts'
import { getFormattedDate } from '../../../utils/functions'
import { useTranslate, useTranslateSingle } from '../../../hooks/translate'

import Project12Logo from '../../../assets/vectors/project-12-logo.svg'
import MainLogo from '../../../assets/vectors/TUTTO-POST_MAIN-LOGO.svg'

import './styles.css'

export function FooterDesktop(): JSX.Element {
    const t = useTranslate(i18nFooter)

    const bgAddress = useTranslateSingle(texts.bgAddress)

    return (
        <Box sx={{ backgroundColor: 'primary.main' }} className="footer">
            <div className="top-footer">
                <div className="footer-section logo-wrapper">
                    <img src={MainLogo} alt="main-logo" className='footer-logo-main' />
                </div>
                <div className="footer-section">
                    <Typography variant="h6" className="section-header">
                        {t.menu}
                    </Typography>
                    <div className="section-links">
                        <Link to="/">
                            <Typography>{t.home}</Typography>
                        </Link>
                        <Link to="/how-it-works">
                            <Typography>{t.howItWorks}</Typography>
                        </Link>
                        <Link to="/schedules">
                            <Typography>{t.schedule}</Typography>
                        </Link>
                        <Link to="/pricing">
                            <Typography>{t.prices}</Typography>
                        </Link>
                        <Link to="/contacts">
                            <Typography>{t.contacts}</Typography>
                        </Link>
                    </div>
                </div>
                <div className="footer-section">
                    <Typography variant="h6" className="section-header">
                        {t.info}
                    </Typography>
                    <div className="section-links">
                        <Link to="/contacts">
                            <Typography>{t.itAddress}</Typography>
                        </Link>
                        {/* <Link to="/unregistered-packages">
                            <Typography>Нерегистрирани пратки</Typography>
                        </Link> */}
                        <Link to="/weight-and-sizes">
                            <Typography>{t.weightAndSizes}</Typography>
                        </Link>
                        <Link to="/faq">
                            <Typography>{t.faq}</Typography>
                        </Link>
                        <Link to="/delivery-time">
                            <Typography>{t.deliveryTime}</Typography>
                        </Link>
                        <Link to="/forbidden-items">
                            <Typography>{t.forbiddenStocks}</Typography>
                        </Link>
                        <Link to="/terms-and-conditions">
                            <Typography>{t.termsAndConditions}</Typography>
                        </Link>
                    </div>
                </div>
                <div className="footer-section">
                    <Typography variant="h6" className="section-header">
                        {t.myAccount}
                    </Typography>
                    <div className="section-links">
                        <Link to="/login">
                            <Typography>{t.login}</Typography>
                        </Link>
                        <Link to="/register">
                            <Typography>{t.register}</Typography>
                        </Link>
                        <Link to="/profile">
                            <Typography>{t.profile}</Typography>
                        </Link>
                    </div>
                </div>
                <div className="footer-section">
                    <Typography variant="h6" className="section-header">
                        {t.contacts}
                    </Typography>
                    <div className="section-contacts">
                        <Typography variant="body1">{bgAddress}</Typography>
                        <Typography variant="body1">
                            {t.phone}:{' '}
                            <a href={`tel:${texts.phoneBG}`} style={{ textDecoration: 'underline' }}>
                                {texts.phoneBG}
                            </a>
                        </Typography>
                        <Typography variant="body1">
                            {t.email}:{' '}
                            <a href={`mailto: ${texts.email}`} style={{ textDecoration: 'underline' }}>
                                {texts.email}
                            </a>
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="copyright-info-wrapper">
                    <Typography variant="body1">
                        © Copyright {getFormattedDate(true, true, true, false)} | Developed and maintained by Project 12{' /'}
                        <img src={Project12Logo} alt="project-12-logo" className="project-12-logo" />
                    </Typography>
                </div>
                {/* <div className="social-networks-wrapper">
                    <a href="https://www.facebook.com/what-page" target="_blank" rel="noopener" title='facebook-link'>
                        <IconButton size="large" color="info">
                            <Facebook fontSize="large" />
                        </IconButton>
                    </a>
                    <a href="https://www.google.com/what-page" target="_blank" rel="noopener" title='google-link'>
                        <IconButton size="large" color="info">
                            <Google fontSize="large" />
                        </IconButton>
                    </a>
                    <a href="https://www.linkedin.com/what-page" target="_blank" rel="noopener" title='linkedin-link'>
                        <IconButton size="large" color="info">
                            <LinkedIn fontSize="large" />
                        </IconButton>
                    </a>
                </div> */}
            </div>
        </Box>
    )
}
