export const i18nFooter = {
	menu: {
		bg: 'Меню',
		en: 'Menu'
	},
	home: {
		bg: 'Начало',
		en: 'Home'
	},
	howItWorks: {
		bg: 'Как работи',
		en: 'How it works'
	},
	schedule: {
		bg: 'График',
		en: 'Schedule'
	},
	prices: {
		bg: 'Цени',
		en: 'Prices'
	},
	contacts: {
		bg: 'Контакти',
		en: 'Contacts'
	},
	info: {
		bg: 'Информация',
		en: 'Information'
	},
	itAddress: {
		bg: 'Адрес в Италия',
		en: 'Address in Italy'
	},
	weightAndSizes: {
		bg: 'Тегло и размери',
		en: 'Weight and sizes'
	},
	faq: {
		bg: 'Често задавани въпроси',
		en: 'FAQ'
	},
	deliveryTime: {
		bg: 'Време за доставка',
		en: 'Delivery time'
	},
	forbiddenStocks: {
		bg: 'Забранени стоки',
		en: 'Forbidden items'
	},
	termsAndConditions: {
		bg: 'Общи условия',
		en: 'Terms and conditions'
	},
	myAccount: {
		bg: 'Моят акаунт',
		en: 'My account'
	},
	login: {
		bg: 'Влез в профила си',
		en: 'Login'
	},
	register: {
		bg: 'Регистрирай се',
		en: 'Registration'
	},
	profile: {
		bg: 'Посети профила си',
		en: 'Visit your profile'
	},
	phone: {
		bg: 'Тел.',
		en: 'Phone'
	},
	email: {
		bg: 'Имейл',
		en: 'Email'
	}
}