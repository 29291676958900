import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { GlobalState } from '../../../data/store'
import { ActiveOrdersTable } from './table/ActiveOrdersTable'
import { getUserActiveOrders } from '../../../data/actions/ordersActions'
import { getPricesSettings } from '../../../data/actions/settingsActions'
import { DeviceComponents, useResponsive } from '../../../hooks/useResponsive'
import { ActiveOrdersMobileList } from './mobileList/ActiveOrdersMobileList'

export function ActiveOrdersComponent(props: any): JSX.Element {
    const { getUserActiveOrders, getPricesSettings } = props

    const { _id } = useSelector((state: GlobalState) => state.users.loggedIn)

    useEffect(() => {
        getUserActiveOrders({ userId: _id })
        getPricesSettings()
    }, [getUserActiveOrders, getPricesSettings, _id])

    const components = {
        smallDesktop: <ActiveOrdersTable />,
        mobile: <ActiveOrdersMobileList />
    } as DeviceComponents

    return useResponsive(components)
}

const mapDispatchToProps = { getUserActiveOrders, getPricesSettings }

export const ActiveOrders = connect(null, mapDispatchToProps)(ActiveOrdersComponent)
