export const i18nAdminOrdersTabs = {
    list: {
        bg: 'Списък',
        en: 'List'
    },
    statistics: {
        bg: 'Графики',
        en: 'Graphics'
    }
}
