import { connect } from 'react-redux'
import { Typography } from '@mui/material'
import { Formik } from 'formik'

import { i18nForgetPass } from './i18n'
import { useTranslate } from '../../hooks/translate'
import { ForgetPasswordForm } from './ForgetPasswordForm'
import { validateEmail } from '../../utils/formik/validations'
import { forgetPasswordRequest } from '../../data/actions/usersActions'
import { DeviceStrings, useResponsiveString } from '../../hooks/useResponsiveString'

import './styles.css'

export function ForgetPasswordPageComponent(props: any): JSX.Element {
    const classNames = {
        mobile: 'forget-password-container-mobile',
        smallDesktop: 'forget-password-container',
    } as DeviceStrings

    const containerClassName = useResponsiveString(classNames)
    const t = useTranslate(i18nForgetPass)

    return (
        <div className={containerClassName}>
            <div className="forget-password-form-wrapper">
                <Typography variant="h4" className="fp-header">
                    {t.title}
                </Typography>
                <Typography variant="body1" className="fp-hint">
                    {t.subTitle}
                </Typography>
                <Formik
                    validate={(values) => validateEmail(values, ['email'])}
                    initialValues={{
                        email: '',
                    }}
                    onSubmit={(values: any, { setSubmitting }) => {
                        setSubmitting(true)
                        props.forgetPasswordRequest(values)
                    }}
                >
                    {(props) => <ForgetPasswordForm {...props} />}
                </Formik>
            </div>
        </div>
    )
}

const mapDispatchToProps = { forgetPasswordRequest }

export const ForgetPasswordPage = connect(null, mapDispatchToProps)(ForgetPasswordPageComponent)
