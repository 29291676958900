import { Typography } from '@mui/material'

import { i18nViews } from './i18n'
import { useTranslateSingle } from '../../hooks/translate'

export function UnauthorizedView(): JSX.Element {
    const warnText = useTranslateSingle(i18nViews.unauthorizedLabel)
    return (
        <div className="unauthorized-container">
            <Typography variant="h4">{warnText}</Typography>
        </div>
    )
}
