import { createReducer } from "@reduxjs/toolkit"
import { decrement, increment, incrementByAmount } from "../actions/counterActions"

interface CounterState {
	value: number
}

const initialState = { value: 0 } as CounterState

export const counterReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(increment, (state) => {
			state.value++
		})
		.addCase(`${increment}/fulfilled`, (state) => {
			state.value++
		})
		.addCase(decrement, (state) => {
			state.value--
		})
		.addCase(incrementByAmount, (state, action) => {
			state.value += (action as any).payload
		})
})