export const i18nFunctions = {
	expectsPickup: {
		bg: 'Очаква натоварване',
		en: 'Expecting pickup'
	},
	pickedUp: {
		bg: 'Натоварен, пътува',
		en: 'Picked up, traveling'
	},
	delivered: {
		bg: 'Завършен',
		en: 'Completed'
	},
	deliveredCrossSchedule: {
		bg: 'Доставен, обработва се',
		en: 'Delivered, processing'
	},
	completed: {
		bg: 'Завършен',
		en: 'Completed'
	},
	wrongStatus: {
		bg: 'Грешен статус',
		en: 'Wrong status'
	},
	registered: {
		bg: 'Регистрирана',
		en: 'Registered'
	},
	arrivedIt: {
		bg: 'Пристигнала в Италия',
		en: 'Arrived in Italy'
	},
	traveling: {
		bg: 'Пътува',
		en: 'Traveling'
	},
	arrivedBg: {
		bg: 'Пристигнала в България',
		en: 'Arrived in Bulgaria'
	},
	cancelled: {
		bg: 'Отказана',
		en: 'Cancelled'
	}
}
