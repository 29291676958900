export const i18nAdminCBSaddOrdersModal = {
	title: {
		bg: 'Добавяне на пратки към международен график',
		en: 'Add packages to cross border schedule'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	weight: {
		bg: 'Тегло',
		en: 'Weight'
	},
	count: {
		bg: 'Брой',
		en: 'Count'
	},
	value: {
		bg: 'Стойност',
		en: 'Value'
	},
	buttonLabel1: {
		bg: 'Запази',
		en: 'Save'
	},
	buttonLabel2: {
		bg: 'Затвори',
		en: 'Close'
	}
}
