import { useSelector } from 'react-redux'
import { Backdrop, CircularProgress } from '@mui/material'

import { GlobalState } from '../../data/store'

function BackdropProgress(): JSX.Element {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export function ApplicationLoader(): JSX.Element {
    const loader = useSelector((state: GlobalState) => state.loader)
    return loader.isLoading ? <BackdropProgress /> : <></>
}
