export const i18nAdminSettings = {
	pricesTitle: {
		bg: 'Ценови настройки',
		en: 'Price settings'
	},
	kgLabel: {
		bg: 'Цена на кг.',
		en: 'Price per kg.'
	},
	processLabel: {
		bg: 'Цена обработка на пратка',
		en: 'Process price per package'
	},
	sofiaDeliveryLabel: {
		bg: 'Цена доставка в София',
		en: 'Delivery price for Sofia'
	},
	unregisteredOrderLabel: {
		bg: 'Глоба нерегистрирани пратки',
		en: 'Unregistered packages fee'
	},
	buttonLabel: {
		bg: 'Обнови',
		en: 'Update'
	},

	defaultsTitle: {
		bg: 'Настройки по подразбиране',
		en: 'Defaults settings'
	},
	senderEcontOfficeAddress: {
		bg: 'Еконт офис за изпращане',
		en: 'Econt office sender address'
	},
	shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab: {
		bg: 'Автоматично отваряне на успешно създадени товарителници в нов прозорец',
		en: 'Auto-open successfully created econt labels PDFs in new tab'
	}
}
