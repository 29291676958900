import { Typography } from '@mui/material'

import { i18nDeliveryTime } from './i18n'
import { useTranslate } from '../../hooks/translate'

import './styles.css'

export function DeliveryTimePage(): JSX.Element {
    const t = useTranslate(i18nDeliveryTime)

    return (
        <div className="delivery-time-container">
            <Typography variant="h4" align="center" className="delivery-time-header">
                {t.title}
            </Typography>
            <div className="delivery-time-info-wrapper">
                <Typography className="delivery-time-description" align="center">
                    {t.text1}
                    <strong>{t.text1Bold}</strong>
                </Typography>
                <Typography className="delivery-time-description" align="center">
                    {t.text2}
                    <strong>{t.text2Bold}</strong>
                </Typography>
                <Typography className="delivery-time-description" align="center">
                    {t.text3}
                    <strong>{t.text3Bold}</strong>
                </Typography>
                <Typography className="delivery-time-description" align="center">
                    {t.text4}
                    <strong>{t.text4Bold}</strong>
                </Typography>
            </div>
        </div>
    )
}
