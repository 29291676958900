import { useState } from 'react'
import { Box } from '@mui/system'
import { Tab, Tabs } from '@mui/material'

import { i18nAdmin } from '../i18n'
import { AdminTabs } from '../AdminPanel'
import { useTranslate } from '../../../hooks/translate'
import { UsersSector } from '../usersSector/UsersSector'
import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { SettingsSector } from '../settingsSector/SettingsSector'
import { StatisticsSector } from '../statisticsSector/StatisticsSector'
import { InternalSchedules } from '../internalSchedules/InternalSchedules'
import { ActiveOrdersSector } from '../activeOrdersSector/ActiveOrdersSector'
import { CrossBorderSchedules } from '../crossBorderSchedules/CrossBorderSchedules'
import { CompletedOrdersSector } from '../completedOrdersSector/CompletedOrdersSector'

import './styles.css'

export function AdminPanelDesktop(): JSX.Element {
    const [tab, setTab] = useState(AdminTabs.USERS)

    const handleChange = (ev: any, value: any) => setTab(value)

    const t = useTranslate(i18nAdmin)

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label={t.users} id="admin-tab-0" />
                    <Tab label={t.orders} id="admin-tab-1" />
                    <Tab label={t.internalSchedules} id="admin-tab-2" />
                    <Tab label={t.crossBorderSchedules} id="admin-tab-3" />
                    <Tab label={t.settings} id="admin-tab-4" />
                    <Tab label={t.statistics} id="admin-tab-5" />
                    <Tab label={t.archive} id="admin-tab-6" />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={AdminTabs.USERS}>
                <UsersSector />
            </TabPanel>
            <TabPanel value={tab} index={AdminTabs.ORDERS}>
                <ActiveOrdersSector />
            </TabPanel>
            <TabPanel value={tab} index={AdminTabs.INTERNAL_SCHEDULES}>
                <InternalSchedules />
            </TabPanel>
            <TabPanel value={tab} index={AdminTabs.CROSS_BORDER_SCHEDULES}>
                <CrossBorderSchedules />
            </TabPanel>
            <TabPanel value={tab} index={AdminTabs.SETTINGS}>
                <SettingsSector />
            </TabPanel>
            <TabPanel value={tab} index={AdminTabs.STATISTICS}>
                <StatisticsSector />
            </TabPanel>
            <TabPanel value={tab} index={AdminTabs.ARCHIVE}>
                <CompletedOrdersSector />
            </TabPanel>
        </>
    )
}
