import { TopNavBarMobile } from './mobile/TopNavBarMobile'
import { TopNavBarDesktop } from './desktop/TopNavBarDesktop'
import { DeviceComponents, useResponsive } from '../../../hooks/useResponsive'

export function TopNavBar(): JSX.Element {
    const components = {
		mobile: <TopNavBarMobile />,
		smallDesktop: <TopNavBarDesktop />
	} as DeviceComponents

	return useResponsive(components)
}
