export const i18nRegOrder = {
	title: {
		bg: 'Регистрация на пратка',
		en: 'Register package'
	},
	origin: {
		bg: 'Произход / Сайт *',
		en: 'Origin / Website *'
	},
	description: {
		bg: 'Кратко описание *',
		en: 'Short description *'
	},
	weight: {
		bg: 'Тегло кг. *',
		en: 'Weight kg. *'
	},
	sizes: {
		bg: 'Размери см. *',
		en: 'Sizes cm. *'
	},
	deleteItem: {
		bg: 'Изтрий пакет',
		en: 'Delete item'
	},
	addItem: {
		bg: 'Добави пакет',
		en: 'Add item'
	},
	reference: {
		bg: 'Референтен номер или фактура *',
		en: 'Reference number or invoice *'
	},
	transDate: {
		bg: 'Дата на транзакцията *',
		en: 'Transaction date *'
	},
	valueEur: {
		bg: 'Стойност в EUR *',
		en: 'Value in EUR *'
	},
	packCount: {
		bg: 'Брой пратки *',
		en: 'Packages count *'
	},
	trackingNumber: {
		bg: 'Номер за проследяване *',
		en: 'Tracking number *'
	},
	itDeliveryCompany: {
		bg: 'Доставчик Италия *',
		en: 'Italian delivery company *'
	},
	comment: {
		bg: 'Коментар',
		en: 'Comment'
	},
	email: {
		bg: 'Имейл *',
		en: 'Email *'
	},
	name: {
		bg: 'Име на получател *',
		en: 'Receiver name *'
	},
	phone: {
		bg: 'Телефон на получател *',
		en: 'Receiver phone *'
	},
	agreeText: {
		bg: 'Съгласен съм с ',
		en: 'I agree with '
	},
	tcText: {
		bg: 'Общите условия',
		en: 'Terms and Conditions'
	},
	buttonLabel: {
		bg: 'Регистрирай пратка',
		en: 'Register order'
	},
}
