import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Paper, Typography } from '@mui/material'

import { i18nWeightAndSizes } from './i18n'
import { texts } from '../../utils/i18n/texts'
import { GlobalState } from '../../data/store'
import { useTranslate, useTranslateSingle } from '../../hooks/translate'
import { getPricesSettings } from '../../data/actions/settingsActions'

import './styles.css'

export function WeightAndSizesPageComponent(props: any): JSX.Element {
    const { getPricesSettings } = props

    const { prices } = useSelector((state: GlobalState) => state.settings)

    const t = useTranslate(i18nWeightAndSizes)
    const denomination = useTranslateSingle(texts.currency)

    useEffect(() => {
        getPricesSettings()
    }, [getPricesSettings])

    return (
        <div className="weight-and-sizes-wrapper">
            <div className="weight-and-sizes-container">
                <Typography className="was-header" variant="h4" align="center">
                    {t.title}
                </Typography>
                <Typography className="was-request" variant="body1" align="center">
                    {t.text1}
                    <strong>{texts.email}</strong>
                </Typography>
                <Typography className="was-info" variant="body1" align="center">
                    {t.text2}
                </Typography>
                <Typography className="was-formula" variant="body1" align="center">
                    <strong>{t.text3}</strong>
                </Typography>
                <div className="was-summary">
                    <Typography variant="body1" align="center">
                        {t.text4}
                    </Typography>
                    <Typography variant="body1" align="center">
                        <em>{t.text5Em}</em>
                        {t.text5}
                    </Typography>
                </div>
                <Paper elevation={5} className="prices-info">
                    <Typography variant="body1" align="center">
                        {t.text6}
                        <strong>
                            {Number(prices.pricePerKg).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t.text7}
                        <strong>
                            {Number(prices.processPricePerPackage).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t.text8}
                        <strong>
                            {Number(prices.priceDeliverySofia).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography variant="body1" align="center">
                        {t.text9}
                        <strong>
                            {Number(prices.unregisteredOrdersPenaltyFee).toFixed(2)}
                            {denomination}
                        </strong>
                    </Typography>
                    <Typography align="center" marginTop={3} sx={{ fontStyle: 'italic' }}>
                        {t.text10}
                    </Typography>
                </Paper>
            </div>
        </div>
    )
}

const mapDispatchToProps = { getPricesSettings }

export const WeightAndSizesPage = connect(null, mapDispatchToProps)(WeightAndSizesPageComponent)
