// export const faqTexts = [
// 	['Трябва ли да ви известя за очаквана пратка на адреса в Италия?', 'Задължително регистрирайте в личния си профил пратките, които очаквате на адреса в Италия.'],
// 	['Защо е необходим адрес в Италия?', 'Защото повечето сайтове или магазини в Италия не предлагат доставка до България или е много скъпа. На адресите ни в Италия можете да изпращате вашите поръчки.'],
// 	['Колко време ще чакам докато получа пратката си от момента на закупуването от онлайн магазина?', 'Обикновено времето за получаване на пратка от сайт или магазин в склад Италия е от 2 до 5 работни дни. След получаване на пратка в склад Италия, ще бъде натоварена и препратена към България с първия възможен курс, от 1 до 7 дни. Пътя от склад Италия до склад България е от 3 до 5 дни. След пристигане на пратка в България следва разтоварване и обработка от 1 до 3 дни.'],
// 	['Как плащам за услугите?', 'Плащате при получаване на пратката в България.'],
// 	['Колко ще ми струва услугата за доставката и регистрацията на адреса?', 'Адреса се предоставя безплатно, клиента плаща само сумата за доставка на пратката от Италия до България според ценовата листа на фирмата плюс цената на куриерската услуга на територията на България.'],
// 	['Има ли право фирмата да отваря пратките?', 'Да, по причини посочени в общите условия и при нерегистрирани пратки или такива без имена.'],
// 	['Какво не мога да пращам?', 'Вижте "Забранени стоки".'],
// 	['Какво е максималното тегло и обем, което мога да изпратя?', 'Няма лимит за тегло и обем. При по-голямо тегло или размери изпратете запитване за цена.'],
// 	['Ако купувам от името на фирма?', 'Процедира се по същия начин, както и за физически лица.'],
// 	['Издавате ли фактури?', 'Да.'],
// 	['При пазаруване от онлайн магазин съм задължен да предоставя телефонен номер. Кой номер да предоставя?', 'Вашият личен телефонен номер.'],
// 	['Мога ли да изпращам до адреса в Италия стоки от друга държава, като Китай или САЩ?', 'Да, но трябва предварително да заплатите митото и ДДС-то, което се дължи. ТутоПост не се ангажира с освобождаването на пратки от депата на куриерите.'],
// 	['Можете ли да заплатите/закупите вместо мен стоки от Италия?', 'Да, но при 100% авансово заплатена сума от ваша страна.'],
// 	['Предлагате ли вземене на пратка от адрес в Италия?', 'Не.'],
// 	['Пратката ми се забави повече от очакваното. Каква е причината?', 'Свържете се с нас за да направим справка и да ви дадем повече информация.'],
// ]

export const faqTexts = [
	[
		{
			bg: 'Трябва ли да ви известя за очаквана пратка на адреса в Италия?',
			en: 'Should I notify you about a package expected to arrive in your Italian address?'
		},
		{
			bg: 'Винаги регистрирайте пратките, които очаквате на адреса ни в Италия.',
			en: 'Always register the orders you exect to arrive in our Italian address.'
		}
	],
	[
		{
			bg: 'Защо е необходим адрес в Италия?',
			en: 'Why an Italian address is necessary?'
		},
		{
			bg: 'Защото повечето сайтове или магазини в Италия не предлагат доставка до България или е много скъпа. На адресите ни в Италия можете да изпращате вашите поръчки.',
			en: 'Because most retailers do not deliver to Bulgaria, or it will be too expensive via other courier companies. You can send your orders in our Italian address.'
		}
	],
	[
		{
			bg: 'Колко време ще чакам докато получа пратката си от момента на закупуването от онлайн магазина?',
			en: 'How much time it takes the order to be delivered to me in Bulgaria?'
		},
		{
			bg: 'Обикновено времето за получаване на пратка от сайт или магазин в склад Италия е от 2 до 5 работни дни. След получаване на пратка в склад Италия, ще бъде натоварена и препратена към България с първия възможен курс, от 1 до 7 дни. Пътя от склад Италия до склад България е от 3 до 5 дни. След пристигане на пратка в България следва разтоварване и обработка от 1 до 2 дни преди финално доставяне.',
			en: 'Time to receive an order from Italian retailer in our storage in Italy is from 3 to 5 working days. After we receive it in Italy it will be placed and trasported to Bulgaria with first possible schedule, from 1 to 7 days. Road time from our Italian storage to our Bulgarian storage is from 3 to 5 days. After arriving in Bulgaria we need to process all orders from 1 to 2 days before final delivery.'
		}
	],
	[
		{
			bg: 'Как плащам за услугите на TuttoPost?',
			en: 'How do I pay for TuttoPost services?'
		},
		{
			bg: 'Плащате при получаване на поръчката си в България.',
			en: 'When you receive your order in Bulgaria.'
		}
	],
	[
		{
			bg: 'Колко ще ми струва услугата?',
			en: 'How much the service costs?'
		},
		{
			bg: 'Посетете страницата "Цени" за повече информация относно ценообразуването на услугите ни.',
			en: 'Visit our "Prices" page for more information about how we calculate the prices of our services.'
		}
	],
	[
		{
			bg: 'Има ли право фирмата да отваря пратките?',
			en: 'Does TuttoPost has the right to open my packages?'
		},
		{
			bg: 'Да, по причини посочени в Общите ни условия, Забранени стоки, както и при нерегистрирани пратки или такива без имена.',
			en: 'Yes, the reasons are pointed in our Terms and Conditions, Forbidden Orders, and if the order is unregistered or without receiver names.'
		}
	],
	[
		{
			bg: 'Какво не мога да пращам?',
			en: 'What I am not allowed to send in Italian address?'
		},
		{
			bg: 'Вижте "Забранени стоки".',
			en: 'Visit our "Forbidden orders" page.'
		}],
	[
		{
			bg: 'Какво е максималното тегло и обем, което мога да изпратя?',
			en: 'What is the maximum allowed weight or sizes I can send to Italian address?'
		},
		{
			bg: 'Няма лимит за тегло и обем. При по-голямо тегло и/или размери изпратете запитване за цена.',
			en: 'There is no limit. For bigger weights or/and sizes send a price request.'
		}
	],
	[
		{
			bg: 'Мога ли да купувам от името на фирма?',
			en: 'Can I place orders from a company/business (B2B)?'
		},
		{
			bg: 'Процедира се по същия начин, както и за физически лица, с изключение, че при нужда можем да издадем фактура.',
			en: 'The procedure is the same as with non-businesses, except we can create an invoice if needed.'
		}
	],
	[
		{
			bg: 'Издавате ли фактури?',
			en: 'Can you create invoices?'
		},
		{
			bg: 'Да.',
			en: 'Yes.'
		}
	],
	[
		{
			bg: 'При пазаруване от онлайн магазин съм задължен да предоставя телефонен номер. Кой номер да предоставя?',
			en: 'When I shop online I am required to provide a phone number. Which number should I provide?'
		},
		{
			bg: 'Вашият личен телефонен номер.',
			en: 'Your personal phone number.'
		}
	],
	[
		{
			bg: 'Мога ли да изпращам до адреса в Италия стоки от друга държава, като Китай или САЩ?',
			en: 'Can I send orders to the Italian address from other countrier, such as Chine or US?'
		},
		{
			bg: 'Да, но трябва предварително да заплатите митото и ДДС-то, което се дължи. ТутоПост не се ангажира с освобождаването на пратки от депата на куриерите.',
			en: 'Yes, but you should have paid all customs fees and VATs before we receive it. We at TuttoPost cannot and does not engage for doing the customs work for your orders.'
		}
	],
	[
		{
			bg: 'Можете ли да заплатите/закупите вместо мен стоки от Италия?',
			en: 'Can TuttoPost pay/buy an Italian product instead of me?'
		},
		{
			bg: 'Да, но при 100% авансово заплатена сума от ваша страна.',
			en: 'Yes, but with 100% product prepayment from your side.'
		}
	],
	[
		{
			bg: 'Предлагате ли вземене на пратка от адрес в Италия?',
			en: 'Can you pickup an order from user given Italian address?'
		},
		{
			bg: 'Не.',
			en: 'No.'
		}
	],
	[
		{
			bg: 'Пратката ми се забави повече от очакваното. Каква е причината?',
			en: 'My order is delayed more than expected. What is the reason?'
		},
		{
			bg: 'Свържете се с нас за да направим справка и да ви дадем повече информация.',
			en: 'Contact us so we can check and give you more information about it.'
		}
	],
]