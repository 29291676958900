import { useDispatch, useSelector } from 'react-redux'
import { Alert, Snackbar } from '@mui/material'
import Slide from '@mui/material/Slide'

import { GlobalState } from '../../data/store'
import { hideNotification } from '../../data/actions/notificationActions'

function SlideTransition(props: any): JSX.Element {
    return <Slide {...props} direction="up" />
}

const autoHideDuration = 5000

export function Notification() {
    const notification = useSelector((state: GlobalState) => state.notification)
    const dispatch = useDispatch()
    const handleHideNotification = () => dispatch(hideNotification())

    if (!notification.message) {
        return <></>
    }

    return (
        <Snackbar
            autoHideDuration={autoHideDuration}
            open={notification.visible}
			onClose={handleHideNotification}
            TransitionComponent={SlideTransition}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert variant='filled' onClose={handleHideNotification} severity={notification.type}>
                {notification.message}
            </Alert>
        </Snackbar>
    )
}
