export const i18nAdminIS = {
	createSchedule: {
		bg: 'Създай график',
		en: 'Create schedule'
	},
	confirmComplete: {
		bg: 'Сигурни ли сте, че искате да маркирате избрания вътрешен график като готов? Това също така ще маркира всички поръчки вътре в него като завършени.',
		en: 'Are you sure you want to complete the selected internal schedule? This will also mark all the orders inside it as completed.'
	},
	confirmDelete: {
		bg: 'Сигурни ли сте, че искате да изтриете избрания вътрешен график?',
		en: 'Are your sure you want to delete selected internal schedule?'
	},
	confirmNotifyViaEmail: {
		bg: 'Сигурни ли сте, че искате да известите всички получатели, че пратките им ще бъдат доставени в София?',
		en: 'Are you sure you want to notify all receivers about their packages being delivered in Sofia?'
	},
	actions: {
		bg: 'Действия',
		en: 'Actions'
	},
	warnNoOrders: {
		bg: 'Избрания график няма добавени пратки',
		en: 'Selected schedule does not have packages'
	},
	downloadFile: {
		bg: 'Изтегли .docx файл',
		en: 'Download .docx file'
	},
	addOrders: {
		bg: 'Добави пратки',
		en: 'Add packages'
	},
	notifyEmail: {
		bg: 'Изпрати имейл до получателите',
		en: 'Send emails to all receivers'
	},
	completeSchedule: {
		bg: 'Маркирай като готов',
		en: 'Mark as complete'
	},
	deleteSchedule: {
		bg: 'Изтрий график',
		en: 'Delete schedule'
	},

	// BEGIN table heads
	startDate: {
		bg: 'Дата старт',
		en: 'Start date'
	},
	endDate: {
		bg: 'Краен срок',
		en: 'Deadline date'
	},
	createDate: {
		bg: 'Създаден',
		en: 'Created'
	},
	packagesCount: {
		bg: 'Брой пратки',
		en: 'Packages count'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	// END table heads
}
