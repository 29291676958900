import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { GlobalState } from '../../data/store'
import { getEcontOffices } from '../../data/actions/econtActions'
import { RegisterOrderMobile } from './mobile/RegisterOrderMobile'
import { RegisterOrderDesktop } from './desktop/RegisterOrderDesktop'
import { DeviceComponents, useResponsive } from '../../hooks/useResponsive'

export function RegisterOrderPageComponent(props: any): JSX.Element {
    const { getEcontOffices } = props
    const { loggedIn } = useSelector((state: GlobalState) => state.users)
    const navigate = useNavigate()

	const components = {
		mobile: <RegisterOrderMobile />,
		smallDesktop: <RegisterOrderDesktop />
	} as DeviceComponents

    useEffect(() => {
        if (!loggedIn.isLogged) {
            navigate('/login')
        } else {   
            getEcontOffices()
        }
    }, [loggedIn.isLogged, navigate, getEcontOffices])

	return useResponsive(components)
}

const mapDispatchToProps = { getEcontOffices }

export const RegisterOrderPage = connect(null, mapDispatchToProps)(RegisterOrderPageComponent)
