import { Formik, FormikProps } from "formik"
import { Paper, Typography } from "@mui/material"

import { i18nRelocationCalc } from "./i18n"
import { useTranslateSingle } from "../../../hooks/translate"
import { CalcRelocationMobileForm } from "./mobile/CalcRelocationMobileForm"
import { CalcRelocationDesktopForm } from "./desktop/CalcRelocationDesktopForm"
import { DeviceComponents, useResponsive } from "../../../hooks/useResponsive"
import { DeviceStrings, useResponsiveString } from "../../../hooks/useResponsiveString"

export interface IPalletCalcFormValues {
    fromCountry: string
    toCountry: string
    fromRegion: string
    toRegion: string
}

function FormComponent(props: FormikProps<IPalletCalcFormValues>): JSX.Element {
    const components = (props: FormikProps<IPalletCalcFormValues>) => ({
        mobile: <CalcRelocationMobileForm {...props} />,
        smallDesktop: <CalcRelocationDesktopForm {...props} />,
    }) as DeviceComponents

    const formComponent = components(props)

    return useResponsive(formComponent)
}

export function CalcRelocationForm(): JSX.Element {
    const title = useTranslateSingle(i18nRelocationCalc.title)

    const classNames = {
        mobile: 'price-calc-relocation-container-mobile',
        smallDesktop: 'price-calc-relocation-container',
    } as DeviceStrings

    const className = useResponsiveString(classNames)

    return (
        <div className={className}>
            <Paper className="price-calc-form-paper" elevation={3}>
                <Typography variant="h5" className="price-calc-header">
                    {title}
                </Typography>
                <Formik
                    initialValues={{
                        showRequestButton: true,
                        fromCountry: 'Italy',
                        fromRegion: 'Rome',
                        toCountry: 'Bulgaria',
                        toRegion: 'Sofia',
                    } as IPalletCalcFormValues}
                    onSubmit={(values: any, { setSubmitting, setFieldValue }) => {
                        setSubmitting(true)

                        const { fromRegion, toRegion } = values
                        const noAutoPriceFromRegions = fromRegion !== 'Sofia' && fromRegion !== 'Rome'
                        const noAutoPriceToRegions = toRegion !== 'Sofia' && toRegion !== 'Rome'
                        if (noAutoPriceFromRegions || noAutoPriceToRegions) {
                            setFieldValue('price', 0)
                            return
                        }

                        // const totalPrice = calculateTransportationPrice(values.packages, prices)
                        const totalPrice = 0 // TODO: make pallet calc automation 
                        setFieldValue('price', totalPrice)
                    }}
                >
                    {(props) => <FormComponent {...props} />}
                </Formik>
            </Paper>
        </div>
    )
}