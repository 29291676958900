export const i18nPackageCalc = {
    title: {
        bg: 'Изчисляване цена пратки',
        en: 'Calculate packages price',
    },
    fromCountryLabel: {
        bg: 'От държава',
        en: 'From country',
    },
    fromRegionLabel: {
        bg: 'От област',
        en: 'From region',
    },
    toCountryLabel: {
        bg: 'До държава',
        en: 'To country'
    },
    toRegionLabel: {
        bg: 'До област',
        en: 'To Region',
    },
    weightLabel: {
        bg: 'Тегло кг.',
        en: 'Weight kg.'
    },
    widthLabel: {
        bg: 'Дължина см.',
        en: 'Width cm.'
    },
    heightLabel: {
        bg: 'Височина см.',
        en: 'Height cm.'
    },
    depthLabel: {
        bg: 'Широчина см.',
        en: 'Depth cm.',
    },
    namesLabel: {
        bg: 'Имена',
        en: 'Names',
    },
    emailLabel: {
        bg: 'Имейл',
        en: 'Email'
    },
    phoneLabel: {
        bg: 'Телефон',
        en: 'Phone',
    },
    descriptionLabel: {
        bg: 'Описание',
        en: 'Description',
    },
    calcButtonLabel: {
        bg: 'Изчисли',
        en: 'Calculate'
    },
    transportPrice: {
        bg: 'Цена транспорт',
        en: 'Transport price'
    },
    sendRequest: {
        bg: 'Изпрати запитване',
        en: 'Send request'
    }
}
