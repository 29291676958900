export const termsAndConditions = [
	{
		number: 'I',
		generalTermText: 'Общи положения',
		terms: [
			{
				number: '1',
				termText: 'С настоящите Общи условия на договора с потребителите на услуги, включени в обхвата на неуниверсалната пощенскa услугa , предоставяни от „Туто Пост” ООД и наричани за краткост "Общи условия", се уреждат реда и условията за предоставяне на услуги, включени в обхвата на неуниверсалната пощенскa услугa и се определят правата, задълженията и отговорностите на страните по договора при извършването и ползването им.',
				subTerms: []
			},
			{
				number: '2',
				termText: 'Потребители на услуги по т.1 от ОУ, предоставяни от „Туто Пост” ООД, могат да бъдат всички физически лица, юридически лица и земеделски производители.',
				subTerms: []
			},
			{
				number: '3',
				termText: 'Потребители на услуги по т.1 от ОУ, предоставяни от „Туто Пост” ООД, могат да се запознаят с настоящите Общи условия:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Във стационарен офис на „Туто Пост” ООД, където ОУ са поставени на видно и достъпно място.',
					},
					{
						number: '2',
						subTermText: 'Във всеки мобилен офис на „Туто Пост” ООД, където се предоставят при поискване от потребителите.',
					},
					{
						number: '3',
						subTermText: 'На електроннaта страница на „Туто Пост” ООД в интернет с адрес www.tuttopost.com, където са публикувани в електронен вариант.',
					}
				]
			},
			{
				number: '4',
				termText: 'Страните по договора по т.1 могат да договарят и други условия за предоставяне на услугите, за което сключват индивидуален договор, неразделна част от който са настоящите ОУ. Ако страните договорят условия за предоставяне на УСЛУГИТЕ, които са различни от условията, записани в настоящите ОУ, имат сила договорените условия в индивидуалния договор.',
				subTerms: []
			},
			{
				number: '5',
				termText: '„Туто Пост” ООД наричан за краткост УПЕРАТОР предоставя услугите по т.1 при действието на настоящите ОУ като предоставя екземпляр от ОУ на потребителя и получава изричното му съгласие с тях.',
				subTerms: []
			},
			{
				number: '6',
				termText: 'За международните писма и пратки се прилагат освен тези ОУ и разпоредбите на актовете на Всемирния пощенски съюз, които са ратифицирани и обнародвани по установения ред.',
				subTerms: []
			},
			{
				number: '7',
				termText: 'За неуговорените в тези ОУ условия, както и при тълкуването на включени в тези ОУ понятия, се прилага Законът за пощенските услуги (ЗПУ) и законодателството на Република България.',
				subTerms: []
			},
		]
	},
	{
		number: 'II',
		generalTermText: 'Обхват и характеристика на услугите',
		terms: [
			{
				number: '8',
				termText: 'Настоящите ОУ се прилагат за вътрешни и международни пощенски услуги от обхвата на Неуниверсалните пощенски услуги, както и за допълнителните услуги към тях извън обхвата на Неуниверсалните пощенски услуги, предоставяни от „Туто Пост” ООД на територията на Република България и други страни членки на ЕС включваща стационарни пощенски офиси, мобилни пощенски офиси, пощенски агентства, наричани за кратко офиси, пощенски кутии и съоръжения, разменни сортировъчни центрове и възли, транспортни и технически средства.',
				subTerms: []
			},
			{
				number: '9',
				termText: 'Пощенските услуги от обхвата на Неуниверсалните пощенски услуги , които „Туто Пост” ООД  предоставя, са:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Приемане, пренасяне и доставяне на вътрешни и международни пощенски кореспондентски пратки (писма) до 2 кг, които съдържат съобщение от текущ или личен характер, вложено в плик, формата и размерът на който не надвишава ширина 260мм, дължина 360мм, височина 20мм.',
					},
					{
						number: '2',
						subTermText: 'Приемане, пренасяне и доставяне на вътрешни и международни пощенски пратки, които може да бъдат от един или няколко пакета, размерът на които не надвишава ширина 60см, дължина 60см, височина 60см. Пратки са: малки пакети до 2 кг; печатни произведения до 5 кг; пощенски колети до 25 кг.',
					}
				]
			},
			{
				number: '10',
				termText: 'Допълнителните услуги от обхвата на Неуниверсалните пощенски услуги , които „Туто Пост” ООД предоставя са:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Препоръка - договорено осигуряване срещу рискове от загуба, кражба или повреда, за което на подателя се издава документ за приемане на пратката, а предаването й се извършва срещу собственоръчен или електронен подпис на получателя. Допълнителната услуга се прилага към писма по т.9.1 от настоящите ОУ.',
					},
					{
						number: '2',
						subTermText: 'Обявена стойност - договорена отговорност на „Туто Пост” ООД в случай на загуба, кражба или повреда на пратка до размера на обявената от подателя стойност на съдържанието, но не повече от 10 000 лв. Обявената стойност трябва да съответства на действителната стойност на съдържанието на пратката. Допълнителната услуга се прилага към всички писма и пратки по т.9.1 и т.9.2 от настоящите ОУ.',
					}
				]
			},
			{
				number: '11',
				termText: 'Допълнителните услуги извън обхвата на Неуниверсалните пощенски услуги, които „Туто Пост” ООД  предоставя са:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Обратна разписка - уведомяване на подателя с документ, удостоверяващ: доставката до получател на препоръчано писмо или пратка, потвърдена с ръчно вписани от получателя имена и поставен негов подпис върху документа за получаване; отсъствие на получател или отказа му да приеме препоръчано писмо или пратка, удостоверено със запис от „Туто Пост” ООД върху документа. „Туто Пост” ООД  доставя обратната разписка на подателя. Допълнителната услуга се прилага към препоръчани писма по т.9.1 и пратки по т.9.2 от настоящите ОУ.',
					},
					{
						number: '2',
						subTermText: 'Обратни документи - придружаващ документ, изготвен и изпратен от подател към получател, който „Туто Пост” ООД  доставя обратно на подателя с препоръчано писмо. Допълнителната услуга се прилага към пратки по т.9.2 от настоящите ОУ.',
					},
					{
						number: '3',
						subTermText: 'Магазинаж - престой на писмо или пратка в офис на „Туто Пост” ООД след изтичане на 7 работни дни от датата на пристигане на пратката в съответния офис за доставка.  Магазинаж се начислява за всеки следващ ден на престой на писмо/пратка. Допълнителната услуга се прилага към всички писма и пратки по т.9.1 и т.9.2  от настоящите ОУ.',
					}
				]
			}
		]
	},
	{
		number: 'III',
		generalTermText: 'Условия за достъп',
		terms: [
			{
				number: '12',
				termText: '„Туто Пост” ООД гарантира равнопоставен достъп на потребителите до предоставяните чрез пощенската си мрежа пощенски услуги.',
				subTerms: []
			},
			{
				number: '13',
				termText: 'Договорът за извършване на Неуниверсалните пощенски услуги се счита за сключен както следва:',
				subTerms: [
					{
						number: '1',
						subTermText: 'За препоръчани писма - с предаването им в офис на „Туто Пост” ООД или на упълномощено от „Туто Пост” ООД лице.',
					},
					{
						number: '2',
						subTermText: 'За пратки - с предаването им в офис на „Туто Пост” ООД или на упълномощено от „Туто Пост” ООД лице, или с изпращането им чрез друга куриерска фирма до „Туто Пост” ООД.',
					}
				]
			},
			{
				number: '14',
				termText: 'Договорът за извършване на Неуниверсалните пощенски услуги се счита за изпълнен:',
				subTerms: [
					{
						number: '1',
						subTermText: 'С доставката на писмото или пратката, удостоверена с подписа на получателя.',
					},
					{
						number: '2',
						subTermText: 'С връщането към подателя на недоставено писмо или пратка.',
					},
					{
						number: '3',
						subTermText: 'С унищожаването на писмото или пратката по реда на Раздел VI, т.35 от настоящите ОУ.',
					}
				]
			}
		]
	},
	{
		number: 'IV',
		generalTermText: 'Опазване тайната на кореспонденцията',
		terms: [
			{
				number: '15',
				termText: '„Туто Пост” ООД гарантира опазване на тайната на кореспонденцията на потребителите и изискванията за пощенска сигурност, както по време на извършване на пощенската услуга, така и след това, като:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Няма право да присвоява пощенски пратки.',
					},
					{
						number: '2',
						subTermText: 'Няма право да отваря пощенски пратки, освен с писмено съгласие на подателя или получателя, при основателно съмнение, че пощенската пратка съдържа предмети или вещества, които са забранени или на специален режим за пренасяне чрез пощенската мрежа по смисъла и реда на Раздел Х от настоящите ОУ, както и по разпореждане на компетентните органи по установения в закона ред.',
					},
					{
						number: '3',
						subTermText: 'Няма право да взема от съдържанието на отворени пощенски пратки (пратки с нарушена опаковка).',
					},
					{
						number: '4',
						subTermText: 'Няма право да предава пратките на трети лица с цел да узнаят тяхното съдържание, освен при основателно съмнение, че пощенската пратка съдържа предмети или вещества, които са забранени или на специален режим за пренасяне чрез пощенската мрежа по смисъла и реда на Раздел Х от настоящите ОУ, както и по разпореждане на компетентните органи по установения в закона ред.',
					},
					{
						number: '5',
						subTermText: 'няма право да предоставя справки и информация за пощенските пратки и съдържанието им, освен на подателя и получателя или на упълномощени от тях лица, както и на компетентните органи в случаите и по реда, предвидени със закон, с изключение на информация за движението на пратките (проследяване) в електронната страница на „Туто Пост” ООД.',
					},
					{
						number: '6',
						subTermText: 'Няма право да предоставя информация за пощенския трафик между отделни лица, освен на компетентните органи в случаите и по реда, предвидени със закон.',
					},
					{
						number: '7',
						subTermText: 'Няма право да предоставя информация за лични данни на потребителите на трети лица, освен на компетентните органи в случаите и по реда, предвидени със закон.',
					},
					{
						number: '8',
						subTermText: 'Предприема всички мерки и действия за охрана и опазване на пощенските пратки, паричните средства, имуществото на „Туто Пост” ООД, живота и здравето на служителите и потребителите; гарантира защитата на личните данни и съдейства за разкриване на нарушения и престъпления, извършени срещу или чрез пощенската мрежа.',
					}
				]
			}
		]
	},
	{
		number: 'V',
		generalTermText: 'Цени и начини на плащане',
		terms: [
			{
				number: '16',
				termText: '„Туто Пост” ООД образува цените на пощенските услуги, които предоставя в съответствие с търсенето и предлагането, като ги предоставя на Комисията за регулиране на съобщенията за сведение в срок от 10 дни преди влизането им в сила.',
				subTerms: []
			},
			{
				number: '17',
				termText: 'Всички цени на пощенски услуги, предоставяни от „Туто Пост” ООД, се включват в Тарифа за пощенски услуги, която се обявява на потребителите чрез:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Публикация на електронната страница на „Туто Пост” ООД в интернет, с адрес www.tuttopost.com.',
					},
					{
						number: '2',
						subTermText: 'Поставяне на видно и достъпно място във всеки стационарен офис на „Туто Пост” ООД.',
					},
					{
						number: '3',
						subTermText: 'Предоставяне при поискване в мобилните офиси на „Туто Пост” ООД.',
					}
				]
			},
			{
				number: '18',
				termText: 'Цените на пощенските услуги се предплащат от потребителите, както следва:',
				subTerms: [
					{
						number: '1',
						subTermText: 'От подател преди изпращане на пратката.',
					},
					{
						number: '2',
						subTermText: 'От получател преди получаване на пратката.',
					},
					{
						number: '3',
						subTermText: 'От трета страна преди получаване на пратката.',
					}
				]
			},
			{
				number: '19',
				termText: 'Заплащането се извършва в брой, по банков път или по друг начин, в зависимост от определения от „Туто Пост” ООД ред за заплащане на съответната услуга.',
				subTerms: []
			},
			{
				number: '20',
				termText: 'При използване на пощенски продукти – пощенски карти, пощенски пликове и други, с отпечатан върху тях специален знак за цената, заплащането на цената се извършва при закупуване на пощенските продукти.',
				subTerms: []
			},
			{
				number: '21',
				termText: 'В индивидуалния договор с потребител „Туто Пост” ООД може да договаря заплащането на услугата да се извършва при прилагането на различни срокове и определени други условия и цени.',
				subTerms: []
			},
			{
				number: '22',
				termText: 'Писма/пратки, пуснати в пощенски кутии или обявени съоръжения на „Туто Пост” ООД, за които не са използвани пощенски продукти на „Туто Пост” ООД с отпечатан върху тях специален знак за цената, се доставят на получателя след като заплати цената. Ако получателят откаже да заплати сумата, писмото се връща на подателя, който дължи заплащане на услугата в двоен размер. Ако подателят откаже да заплати сумата, писмото се унищожава, съгласно Раздел VI, т.35.',
				subTerms: []
			}
		]
	},
	{
		number: 'VI',
		generalTermText: 'Условия за приемане и доставяне на пощенски пратки',
		terms: [
			{
				number: '23',
				termText: 'Пощенски пратки се приемат в офис на „Туто Пост” ООД , в рамките на определено от „Туто Пост” ООД  работно време.',
				subTerms: []
			},
			{
				number: '24',
				termText: 'Работното време на офисите включва всички работни дни и се определя от „Туто Пост” ООД като се обявява на електронната страница на „Туто Пост” ООД в интернет, с адрес www.tuttopost.com. В стационарните офиси работното време се поставя на видно и достъпно за всички потребители място, а в мобилните офиси се предоставя при поискване от потребителите.',
				subTerms: []
			},
			{
				number: '25',
				termText: '„Туто Пост” ООД определя задължителните изисквания относно формата, минималните и максималните размери, тарифното тегло и адресирането на пощенските пратки:',
				subTerms: [
					{
						number: '1',
						subTermText: 'ИЗИСКВАНИЯ: А. Към размера: За писмата - размерите да не надвишават ширина 260 мм, дължина 360 мм, височина 20 мм.; За пратките - размерите да не надвишават ширина 60 см, дължина 60 см, височина 60 см.; Б. Към адресирането: В горен ляв ъгъл се записват данните за подателя – име и фамилия, адрес, телефон, пощенски код на населеното място; В долен десен ъгъл се записват данните за получателя – име и фамилия, адрес, телефон, пощенски код на населеното място.',
					},
					{
						number: '2',
						subTermText: 'ИЗКЛЮЧЕНИЯ: А. При доставка на писма и пратки до офис на „Туто Пост” ООД, адресите на подателя и получателя не са задължителен реквизит. Данните се диктуват от подателя и се записват от оператор в електронен вид. Подателят получава разписка с адресните данни, които сверява за точност.; Б. При международни пратки данните на подателя и получателя се изписват с четливи печатни букви на латиница, като се добавят страна на подаване и получаване на пратката.',
					},
					{
						number: '3',
						subTermText: '„Туто Пост” ООД може да определя конкретни изисквания за точно място и форма на адресиране и съдържание на пратките с цел да се използват механизирани и автоматизирани начини на сортиране, за които по подходящ начин уведомява потребителите.',
					}
				]
			},
			{
				number: '26',
				termText: 'Опаковката и затварянето на пратките трябва да бъдат съобразени с обема, теглото и съдържанието им, така че да осигуряват тяхното запазване от повреди. Осигуряването на подходяща опаковка и затваряне на пратките е отговорност на потребителите.',
				subTerms: []
			},
			{
				number: '27',
				termText: 'Пощенските писма и пратки се доставят на лицето, посочено като получател, на негов родител или законен настойник, ако е малолетно, или на изрично упълномощено от получателя или законния му представител лице.',
				subTerms: [
					{
						number: '1',
						subTermText: '„Туто Пост” ООД НЕ НОСИ ОТГОВОРНОСТ за нерегистрирани изгубени пратки!',
					}
				]
			},
			{
				number: '28',
				termText: 'Доставянето на писма и пратки се извършва:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Пратките се доставят в офис на „Туто Пост” ООД срещу подпис на хартиен или друг носител.',
					}
				]
			},
			{
				number: '29',
				termText: 'В случаите, когато „Туто Пост” ООД трябва да изпрати известие до получател или подател относно доставката на писмо или пратка, това може да бъде направено по един от следните начини:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Чрез служебно известие, доставено до пощенската кутия на подателя или получателя. За този вид известяване е задължително при изпращането на писмото/пратката да са посочени адресите на подател/получател, където да бъде доставено известието.',
					},
					{
						number: '2',
						subTermText: 'Известяване за доставка със SMS на мобилен телефон, с имейл, с телефонно обаждане или друг начин за контакт с подател/получател, посочен при изпращане на пратката или заявен от него като начин за комуникация.',
					},
					{
						number: '3',
						subTermText: 'С публикуване на информацията в информационната система на „Туто Пост” ООД, за която получателите/подателите имат предоставен достъп чрез уникално потребителско име и парола.',
					}
				]
			},
			{
				number: '30',
				termText: 'При доставяне на писма и пратки с обявена стойност с повредена опаковка, за които има основания да се счита, че е налице посегателство или разлика в теглото на пратката с вписаното тегло върху пратката при приемането й, задължително се съставя констативен протокол по образец, който се подписва от съставителя и от получателя на пратката. В протокола се вписват данни относно датата и часа на доставката, вида на пратката с означено тегло, обявената стойност, мястото и времето на подаване, адресите на подателя и получателя, констатираното увреждане и евентуалните причини за него, ако има данни за това. Ако получателят откаже да подпише протокола, съставителят осигурява един свидетел, който може да бъде и служител на „Туто Пост” ООД и който заедно със съставителя подписва документа. Копие от протокола се връчва на получателя, който може да направи рекламация, ако счита, че „Туто Пост” ООД  има вина за констатираните нередности по пратката.',
				subTerms: []
			},
			{
				number: '31',
				termText: 'Получателите на писма или пратки могат да упълномощават други лица да ги получават с писмено пълномощно. При получаването на писмо или пратка упълномощеното лице представя оригинал от пълномощното и документ за самоличност. „Туто Пост” ООД може да записва данни за упълномощаването и упълномощеното лице, както и да снема копие от пълномощното, като същите се съхраняват и ползват единствено с цел доказване на самоличността на получателя във връзка с доставянето на писмото или пратката.',
				subTerms: []
			},
			{
				number: '32',
				termText: 'Писма и пратки, които не могат да бъдат доставени поради това, че: не са потърсени в срок, определен в служебното известие; получателите отказват да ги получат или да заплатят сумата на наложения платеж; заминали са на неизвестен адрес; непознати са на посочения адрес или са починали; посоченият адрес е недостатъчен или не съществува, се връщат на подателя за негова сметка. На подателя се изпраща служебно известие с покана да се яви за получаване на пратката в офис на „Туто Пост” ООД в срок до 20 дни от датата на доставяне на известието. Ако не се яви в този срок, на подателя се изпраща второ известие за получаване, със срок 10 дни. За съхранение на писмо или пратка в офис на „Туто Пост” ООД след изтичане на 7 работни дни от датата на пристигане на пратката в съответния офис за доставка се начислява магазинаж.',
				subTerms: []
			},
			{
				number: '33',
				termText: 'Пратки с бързо развалящо се съдържание, при издаване признак на разваляне, се връщат незабавно на подателя за негова сметка.',
				subTerms: []
			},
			{
				number: '34',
				termText: 'Недоставени писма и пратки, които не могат да бъдат върнати на подателите поради това, че те не са посочили адрес или са посочили непълен, неточен или нечетлив адрес, отказват да ги получат обратно, заминали са на неизвестен адрес, починали са или не са се явили да ги получат в определения им срок след писменото им известяване от „Туто Пост” ООД, стават собственост на „Туто Пост” ООД. „Туто Пост” ООД има правото, в качеството си на собственик, да ги продаде на търг или унищожи.',
				subTerms: []
			},
			{
				number: '35',
				termText: 'Унищожаването на пощенските пратки по т.34 се извършва с протокол от комисия, съставена от трима служители на „Туто Пост” ООД, по следния начин:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Писмата се нарязват ръчно или чрез специализирани машини за нарязване на хартия.',
					},
					{
						number: '2',
						subTermText: 'Предмети в пратки, които могат да се продадат, се продават на търг, като приходите след приспадане на разходите по продажбата остават в полза на „Туто Пост” ООД.',
					},
					{
						number: '3',
						subTermText: 'Предмети, непродадени след търга по т.35.2, се унищожават по подходящ начин.',
					}
				]
			},
		]
	},
	{
		number: 'VII',
		generalTermText: 'Срокове за доставяне на писма и пратки',
		terms: [
			{
				number: '36',
				termText: 'Писма и пратки, адресирани до получател, се доставят съгласно график на обслужване обявен на електронната страница на „Туто Пост” ООД в интернет с адрес www.tuttopost.com',
				subTerms: []
			},
			{
				number: '37',
				termText: 'В случай, че подателят на писмо/пратка до адрес на получател е посочил непълен/неточен адрес, имейл или телефон за връзка с получателя, „Туто Пост” ООД не е обвързан с предварително обявения срок на доставка на пратката и известяването за доставка.',
				subTerms: []
			}
		]
	},
	{
		number: 'VIII',
		generalTermText: 'Права и задължения на „Туто Пост” ООД',
		terms: [
			{
				number: '38',
				termText: '„Туто Пост” ООД има следните права и задължения:',
				subTerms: [
					{
						number: '1',
						subTermText: '„Туто Пост” ООД е длъжен да предоставя на всички потребители достъп до услугата при еднакви условия.',
					},
					{
						number: '2',
						subTermText: '„Туто Пост” ООД е длъжен да постави на видно и достъпно за потребителите място тези ОУ и ценовата тарифа в офисите си и на интернет страницата си.',
					},
					{
						number: '3',
						subTermText: '„Туто Пост” ООД има право да организира и изпълни по свое усмотрение приемането, обработката, транспортирането и доставката на пратките, използвайки маршрути, транспортни средства или подизпълнители, които намери за оптимално и целесъобразно.',
					},
					{
						number: '4',
						subTermText: '„Туто Пост” ООД има право да получи обявеното в актуалната ценова тарифа или договорено в индивидуален клиентски договор възнаграждение за предоставяне на съответната услуга.',
					},
					{
						number: '5',
						subTermText: '„Туто Пост” ООД има право да получи обезщетение от потребителите за причинени от техните пратки  щети на имущество или служители на „Туто Пост” ООД , на други пратки, както и на трети лица или тяхно имущество.',
					},
					{
						number: '6',
						subTermText: '„Туто Пост” ООД, в качеството си на администратор на лични данни, е длъжно да спазва изискванията за защита на личните данни съгласно Закона за защита на личните данни.',
					}
				]
			}
		]
	},
	{
		number: 'IX',
		generalTermText: 'Права и задължения на Потребителите',
		terms: [
			{
				number: '39',
				termText: 'Потребителите които са надлежно регистрирани на сайта на „Туто Пост” ООД  www.tuttopost.com имат следните права и задължения:',
				subTerms: [
					{
						number: '1',
						subTermText: 'Потребителите имат право да получават информация по достъпен начин относно действащите ОУ, ценовата тарифа на „Туто Пост” ООД, изискванията на „Туто Пост” ООД относно формата, минималните и максималните размери, теглото и начина на опаковане и адресиране на пощенските пратки, работното време на офиса, забранените за поставяне в пощенските пратки предмети и вещества, сроковете за приемане на рекламации и размерите на обезщетенията.',
					},
					{
						number: '2',
						subTermText: 'Потребителите имат право да правят рекламации в случаи на повредени, загубени, унищожени или ограбени пратки, при неспазване сроковете на доставка, както и да получат обезщетение в размерите, определени в ОУ на „Туто Пост” ООД.',
					},
					{
						number: '3',
						subTermText: 'Потребителите са длъжни да подготвят пратката във вид, отговарящ на изискванията на тези ОУ.',
					},
					{
						number: '4',
						subTermText: 'Потребителите са длъжни да предоставят максимално пълна и вярна информация за получателя и пратката, съгласно изискванията на тези ОУ.',
					},
					{
						number: '5',
						subTermText: 'Потребителите са длъжни да заплатят обявеното в актуалната ценова тарифа или договорено в индивидуалния клиентски договор възнаграждение за предоставяне на съответната услуга.',
					},
					{
						number: '6',
						subTermText: 'Подателите на писма и пратки са длъжни: - да се съобразяват с изискванията за форма, тегло, размери и начин на адресиране; - да не поставят забранени предмети и вещества в писмата и пратките.',
					},
					{
						number: '7',
						subTermText: 'Подателите носят отговорност за щетите, причинени от подадени от тях пратки, на имуществото/служителите на „Туто Пост” ООД, или на други писма/пратки или лица. Отговорността е в размер на понесените от „Туто Пост” ООД щети, а при нанесени щети на други пратки/писма или лица – в размера на изплатените от „Туто Пост” ООД обезщетения.',
					},
					{
						number: '8',
						subTermText: 'Потребител, който е направил регистрация в интернет страницата на „Туто Пост” ООД за ползване на модула за самообслужване, е информиран и приема, че „Туто Пост” ООД има право да изпраща съобщения и електронна поща на своите клиенти за целите на директния маркетинг и за реклама на собствени услуги. Потребителят има право по всяко време да изрази несъгласие с бъдещо получаване на подобни съобщения, като изпрати имейл на contacts@tuttopost.com',
					}
				]
			}
		]
	},
	{
		number: 'X',
		generalTermText: 'Забранени за пренасяне предмети и вещества',
		terms: [
			{
				number: '40',
				termText: 'Забранено е поставянето във вътрешни и международни пощенски пратки на следните вещества и предмети:',
				subTerms: [
					{
						number: '1',
						subTermText: 'наркотични, упойващи, психотропни и отровни вещества.',
					},
					{
						number: '2',
						subTermText: 'оръжие, боеприпаси, пиротехнически изделия, взривни, запалителни или други опасни вещества и предмети.',
					},
					{
						number: '3',
						subTermText: 'противоречащи на нравствените норми предмети.',
					},
					{
						number: '4',
						subTermText: 'предмети и вещества, които поради своето естество или опаковка представляват опасност за живота или здравето на пощенските служители или на други лица или могат да замърсят или повредят други пратки и съоръжения.',
					},
					{
						number: '5',
						subTermText: 'религиозни материали на забранени или нерегистрирани в страната секти и организации.',
					},
					{
						number: '6',
						subTermText: 'движими паметници на културата, за които няма издадено разрешение или сертификат.',
					},
					{
						number: '7',
						subTermText: 'в пощенските пратки, с изключение на пратките с обявена стойност, не се допуска поставянето на монети, банкноти, парични знаци, чекове за пътуване, предмети, представляващи ценност за подателя, платина, злато, сребро, обработени или необработени скъпоценни камъни и други ценни предмети.',
					},
					{
						number: '8',
						subTermText: 'в международните пощенски пратки, включително тези с обявена стойност, освен веществата и предметите по т.40.1.1 до 40.1.7 не могат да се поставят и други вещества и предмети, посочени в международни договори, както и предмети, внасянето или разпространяването на които е забранено в страната на местоназначението.',
					},
					{
						number: '9',
						subTermText: 'Не се приемат пощенски пратки, които съдържат животни или нетрайни биологични вещества, включително диагностични образци, ако опаковката им не отговаря  на изискванията, предвидени в нормативната уредба на РБ, и приложимите международни актове.',
					},
					{
						number: '10',
						subTermText: 'Не се приемат пощенски пратки, чието съдържание е предмет на специален режим и може да се държи, превозва или пренася само при изпълнение на определени изисквания на нормативната уредба на Република България, освен ако подателят не удостовери спазването на тези изисквания и не снабди „Туто Пост” ООД с необходимите за изпълнение на услугата документи.',
					},
					{
						number: '11',
						subTermText: 'При съмнение за поставени в пощенски пратки забранени предмети или вещества, или на такива, чието съдържание, превозване или пренасяне е предмет на специален режим, „Туто Пост” ООД изисква от подателя съгласие за извършване на проверка чрез отваряне на пратката и преглед на съдържанието. В случай на отказ пратката не се приема.',
					},
					{
						number: '12',
						subTermText: 'Когато има основание да се счита, че приета пощенска пратка съдържа забранени предмети или вещества, или такива, чието съдържание, превозване или пренасяне е предмет на специален режим, „Туто Пост” ООД има право да задържи пратката и да спре доставянето и да изиска от подателя или получателя писмено съгласие за извършване на проверка на съдържанието чрез отварянето и. При отказ или липса на отговор „Туто Пост” ООД уведомява компетентните власти за получаване на разпореждане за следващи действия.',
					},
				]
			}
		]
	},
	{
		number: 'XI',
		generalTermText: 'Процедури за рекламации и обезщетения за вътрешни и международни писма и пратки',
		terms: [
			{
				number: '41',
				termText: 'Потребителите имат право на рекламация в случаи на повредени, загубени, ограбени или унищожени препоръчани писма/пратки, при неспазване сроковете за доставка на писма/пратки и при неизпълнение от страна на „Туто Пост” ООД на договорените задължения.',
				subTerms: []
			},
			{
				number: '42',
				termText: 'Рекламация към „Туто Пост” ООД може да бъде подадена в срок от 9 дни, считано от датата на приемане на пратката.',
				subTerms: []
			},
			{
				number: '43',
				termText: 'При получаване на пратката потребителят трябва да провери съдържанието й в момента на получаването и в присъствието на куриера. В случай, че потребителят установи увреждане на съдържанието на пратката, се съставя констативен протокол по реда на т.30 от настоящите ОУ. В този случай рекламацията за увредена пратка се подава от потребителя в срок от 9 дни от датата на съставяне на констативния протокол.',
				subTerms: []
			},
			{
				number: '44',
				termText: 'Рекламацията се счита за редовно предявена единствено тогава, когато потребителят е  заплатил всички дължими на „Туто Пост” ООД суми за предоставени услуги (с изключение на тези по т.47.4) без отлагане или прихващане.',
				subTerms: []
			},
			{
				number: '45',
				termText: '„Туто Пост” ООД е длъжен в срок до 30 дни от предявяване на рекламацията за вътрешните пощенски пратки и 30 дни за международните пощенски пратки да проучи същата и да уведоми писмено рекламанта за резултата. В случай на подадени непълни данни в рекламацията, срокът се удължава с времето, необходимо за уточняване, но не по-дълго от 30 дни.',
				subTerms: []
			},
			{
				number: '46',
				termText: 'Ако предявена от потребител рекламация за вътрешни и международни пощенски пратки бъде приета за основателна, „Туто Пост” ООД дължи обезщетение в следните размери:',
				subTerms: [
					{
						number: '1',
						subTermText: 'По рекламация за повредена, унищожена или загубена пратка, която е без Обявена стойност, дължимото обезщетение е в размер на 15.00 лв.',
					},
					{
						number: '2',
						subTermText: 'По рекламация за липса, повреда на част от съдържанието, загуба или унищожение на пратка с Обявена стойност, дължимото обезщетение е в размер на действителната стойност на нанесената щета, но не повече от Обявената стойност, и се определя от „Туто Пост” ООД. За удостоверяване на действителната стойност на щетата потребителят предоставя необходимите документи - фактури, митнически декларации и др. подобни според случая.',
					},
					{
						number: '3',
						subTermText: 'По рекламации за неизпълнение на договорените срокове за доставка на пратки, транспортирани на територията на Република България дължимото обезщетение е в размер на стойността на услугата по съответната пратка, а за международни пратки  - в размер на 0,1% от стойността на услугата за всеки ден забава, но не повече от 10.00 лв. (за срок на доставка от 1 работен ден се счита края на следващия работен ден).',
					}
				]
			},
			{
				number: '47',
				termText: 'Срокът за изплащане на обезщетения по приети за основателни рекламации е 15 работни дни, считано от датата на изготвяне на отговора по рекламацията.',
				subTerms: []
			},
			{
				number: '48',
				termText: '„Туто Пост” ООД не носи отговорност:',
				subTerms: [
					{
						number: '1',
						subTermText: 'за непрепоръчани пощенски писма.',
					},
					{
						number: '2',
						subTermText: 'за повреждане или унищожаване на препоръчани или с Обявена стойност пощенски писма и пратки, настъпило в резултат на непреодолима сила.',
					},
					{
						number: '3',
						subTermText: 'когато препоръчаните пощенски пратки не е възможно да бъдат издирени поради унищожаване на служебните документи за тях от непреодолима сила.',
					},
					{
						number: '4',
						subTermText: 'когато повреждането или унищожаването на пощенските пратки е настъпило по вина на подателя или произтича от естеството на съдържанието на пощенските пратки.',
					},
					{
						number: '5',
						subTermText: 'за пощенски пратки, съдържащи предмети или вещества, които са забранени или тяхното съдържание или транспортирането е предмет на специален режим, които по тази причина са били конфискувани или унищожени от компетентните органи по установения ред.',
					},
					{
						number: '6',
						subTermText: 'когато поради непълен или погрешен адрес пощенската пратка е доставена на друго лице.',
					},
					{
						number: '7',
						subTermText: 'за забавени или недоставени пратки поради непълен или погрешен адрес.',
					},
					{
						number: '8',
						subTermText: 'когато потребителят не е предявил писмена рекламация в предвидения за това срок.',
					},
					{
						number: '9',
						subTermText: '„Туто Пост” ООД не отговаря за претърпени непреки щети и нереализирани печалби в резултат на загубени, повредени, ограбени, предадени на неправоимащи лица или забавени доставки на вътрешни и международни пратки.',
					},
					{
						number: '10',
						subTermText: '„Туто Пост” ООД не носи отговорност по договори за застраховка на пощенски пратки, сключени от подателите на пратки със застрахователи, когато последните встъпват в правата на застрахованите, съгласно чл. 213 от Кодекса за застраховането, над размерите на обезщетенията, на които подателят или получателят има право съгласно настоящите ОУ. Обезщетенията се изплащат на застрахователите, ако не са изплатени на подателите или получателите на пратките.',
					}
				]
			},
			{
				number: '49',
				termText: 'При изплатено обезщетение от страна на „Туто Пост” ООД по рекламация, което е в размер на стойността на пратката, „Туто Пост” ООД  става собственик на пратката. Рекламантът се задължава да я предаде на „Туто Пост” ООД преди изплащане на обезщетението.',
				subTerms: []
			},
		]
	},
	{
		number: 'XII',
		generalTermText: 'Ред за решаване на спорове',
		terms: [
			{
				number: '50',
				termText: 'Споровете между потребителите и „Туто Пост” ООД се решават на база добро партньорско сътрудничество, чрез преговори и допълнителни споразумения. При непостигане на съгласие, всяка от страните може да поиска съдействие от КРС, други компетентни органи или да отнесе спора за решаване пред компетентния български съд.',
				subTerms: []
			}
		]
	},
]
