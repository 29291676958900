import { Paper, Typography } from '@mui/material'

import { i18nHIW } from '../../i18n'
import { useTranslateSingle } from '../../../../hooks/translate'

import arrow from '../../../../assets/home-page-arrow.png'

import './styles.css'

interface Props {
    stepCount: number
    stepHeader: string
    image: string | undefined
    children: React.ReactNode
}

export function HowItWorksStepMobile(props: Props): JSX.Element {
    const { stepCount, stepHeader, image, children } = props

    const step = useTranslateSingle(i18nHIW.step)

    return (
        <div className="how-it-works-step-container-mobile">
            <div className="hiw-contents-wrapper">
                <Typography className="hiw-sub-header">
                    {step} {stepCount}
                </Typography>
                <Typography variant="h5" className="hiw-header">
                    {stepHeader}
                </Typography>
                <div className="hiw-image-wrapper">
                    <img src={image} alt={`step-show-${stepCount}`} />
                </div>
                <Paper elevation={5} className="hiw-contents-description">
                    {children}
                </Paper>
                {stepCount !== 4 && (
                    <div className={`hiw-arrow-wrapper ${stepCount % 2 === 0 ? 'rotated' : ''}`}>
                        <img src={arrow} alt={`stop-show-arrow-${stepCount}`} />
                    </div>
                )}
            </div>
        </div>
    )
}
