import { DeviceComponents, useResponsive } from "../../hooks/useResponsive"
import { RegisterOrderBannerMobile } from "./mobile/RegisterOrderBannerMobile"
import { RegisterOrderBannerDesktop } from "./desktop/RegisterOrderBannerDesktop"

export function RegisterOrderBanner(): JSX.Element {
	const components = {
		mobile: <RegisterOrderBannerMobile />,
		smallDesktop: <RegisterOrderBannerDesktop />
	} as DeviceComponents

	return useResponsive(components)
}
