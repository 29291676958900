import { useSelector } from 'react-redux'
import { Formik, FormikProps } from 'formik'
import {
    Button,
    Dialog,
    Select,
    Tooltip,
    MenuItem,
    TextField,
    InputLabel,
    IconButton,
    Typography,
    FormControl,
    DialogActions,
} from '@mui/material'
import { AddOutlined, DeleteForeverOutlined } from '@mui/icons-material'

import { i18nAdminCreateUnregOrder } from './i18n'
import { GlobalState } from '../../../../../data/store'
import { useTranslate } from '../../../../../hooks/translate'
import { FormikError } from '../../../../../utils/formik/FormikError'
import { getOrderStatusChip } from '../../../../../components/Shared'
import { OrderStatus, ORDER_STATUS } from '../../../../../data/reducers/ordersReducer'
import { deliveryCompanies, DeliveryCompany } from '../../../../../utils/deliveryCompanies'
import { IDefaultEcontDeliveryAddress, __defaultEcontDeliveryAddress } from '../../../../../data/reducers/usersReducer'

import './styles.css'

interface Props {
    handleSave: (data: Object) => void
    handleClose: () => void
}

interface Package {
    weight: string
    sizes: string
    processed: boolean
}

export interface CreateUnregisteredOrderFormikValues {
    receiver: string
    receiverPhone: string
    siteOrigin: string
    description: string
    referenceNumberOrInvoice: string
    transactionDate: string
    valueEur: string
    count: string
    deliveryCompany: string
    email: string
    deliveryAddress: string
    deliverTo: string
    econtOfficeDeliveryAddress: IDefaultEcontDeliveryAddress
    status: string
    packages: Package[]
}

export function AddUnregisteredOrderModal(props: Props): JSX.Element {
    const { handleClose, handleSave } = props

    const t = useTranslate(i18nAdminCreateUnregOrder)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const statuses = Object.values(ORDER_STATUS) as OrderStatus[]
    const validStatuses = statuses.filter(
        (status) => status === ORDER_STATUS.ARRIVED_ABROAD || status === ORDER_STATUS.ARRIVED_BG
    )

    return (
        <Dialog open={true} onClose={handleClose} scroll="body">
            <Typography variant="h5" className="add-unreg-order-form-title" align="center">
                {t.title}
            </Typography>
            <Formik
                validate={(values: CreateUnregisteredOrderFormikValues) => {
                    return values.packages.length === 0 ? { packages: t.noPackMsg } : {}
                }}
                initialValues={
                    {
                        receiver: '',
                        receiverPhone: '',
                        deliveryAddress: '',
                        status: ORDER_STATUS.ARRIVED_ABROAD,
                        referenceNumberOrInvoice: 'unknown',
                        unregistered: true,
                        packages: [{ weight: '1', sizes: '20x25x30', processed: true }],
                        count: '',
                        siteOrigin: '',
                        description: '',
                        deliverTo: 'address',
                        econtOfficeDeliveryAddress: __defaultEcontDeliveryAddress,
                        transactionDate: 'unknown',
                        valueEur: 'unknown',
                        deliveryCompany: deliveryCompanies[0].value,
                        email: '',
                    } as CreateUnregisteredOrderFormikValues
                }
                onSubmit={(values: CreateUnregisteredOrderFormikValues, { setSubmitting }) => {
                    setSubmitting(true)
                    handleSave(values)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    validateField,
                    setFieldValue,
                }: FormikProps<CreateUnregisteredOrderFormikValues>) => {
                    const handleAddPackage = () => {
                        const newPackage = {
                            weight: '1',
                            sizes: '22x23x24',
                            processed: true,
                        } as Package
                        const copy = values.packages.slice()
                        copy.push(newPackage)
                        values.packages = copy
                        setFieldValue('packages', copy)
                        setFieldValue('count', copy.length.toString())
                        validateField('packages')
                    }
                    const handleDeletePackage = (index: number) => {
                        const copy = values.packages.slice()
                        copy.splice(index, 1)
                        values.packages = copy
                        setFieldValue('packages', copy)
                        setFieldValue('count', copy.length.toString())
                        validateField('packages')
                    }
                    const handleChangePackageWeight = (index: number, value: string) =>
                        changePackageProp(index, 'weight', value)
                    const handleChangePackageSizes = (index: number, value: string) =>
                        changePackageProp(index, 'sizes', value)
                    const changePackageProp = (index: number, propName: string, value: any) => {
                        const pckg = { ...values.packages[index] } as any
                        pckg[propName] = value
                        const copy = values.packages.slice()
                        copy.splice(index, 1, pckg)
                        setFieldValue('packages', copy)
                    }
                    return (
                        <form className="add-unreg-order-form" onSubmit={handleSubmit}>
                            <div className="add-unreg-form-input-field">
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="receiver"
                                    label={t.receiver}
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.receiver}
                                    onChange={handleChange}
                                />
                                <FormikError name="receiver" errors={errors} touched={touched} />
                            </div>
                            <div className="add-unreg-form-input-field">
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={t.phone}
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    name="receiverPhone"
                                    onChange={handleChange}
                                    value={values.receiverPhone}
                                />
                                <FormikError name="receiverPhone" errors={errors} touched={touched} />
                            </div>
                            <div className="add-unreg-form-input-field">
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={t.address}
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    name="deliveryAddress"
                                    onChange={handleChange}
                                    value={values.deliveryAddress}
                                />
                                <FormikError name="deliveryAddress" errors={errors} touched={touched} />
                            </div>
                            <div className="add-unreg-form-input-field">
                                <FormControl margin="dense" fullWidth size="small">
                                    <InputLabel id="status-select-label">{t.status}</InputLabel>
                                    <Select
                                        labelId="status-select-label"
                                        onChange={handleChange}
                                        value={values.status}
                                        label={t.status}
                                        name="status"
                                    >
                                        {validStatuses.map((orderStatus, i) => {
                                            if (orderStatus === 'LINKED') {
                                                return <></>
                                            }
                                            
                                            return (
                                                <MenuItem key={i} value={orderStatus}>
                                                    {getOrderStatusChip(orderStatus, lang)}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormikError name="status" errors={errors} touched={touched} />
                            </div>
                            <div className="add-unreg-form-input-field">
                                <FormControl margin="dense" fullWidth size="small">
                                    <InputLabel id="delivery-company-select-label">{t.deliveryCompany}</InputLabel>
                                    <Select
                                        labelId="delivery-company-select-label"
                                        value={values.deliveryCompany}
                                        label={t.deliveryCompany}
                                        onChange={handleChange}
                                        name="deliveryCompany"
                                    >
                                        {deliveryCompanies.map((x: DeliveryCompany, i: number) => (
                                            <MenuItem key={i} value={x.value}>
                                                {x.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormikError name="deliveryCompany" errors={errors} touched={touched} />
                            </div>
                            <div className="add-unreg-form-input-field">
                                <TextField
                                    fullWidth
                                    name="email"
                                    size="small"
                                    label={t.email}
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.email}
                                    onChange={handleChange}
                                />
                                <FormikError name="email" errors={errors} touched={touched} />
                            </div>
                            <div className="add-unreg-form-input-field">
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={t.origin}
                                    name="siteOrigin"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.siteOrigin}
                                />
                                <FormikError name="siteOrigin" errors={errors} touched={touched} />
                            </div>
                            <div className="add-unreg-form-input-field">
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="description"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    label={t.description}
                                    onChange={handleChange}
                                    value={values.description}
                                />
                                <FormikError name="description" errors={errors} touched={touched} />
                            </div>
                            <div>
                                <hr />
                                <Typography variant="h6" align="center">
                                    {t.packagesLabel}
                                </Typography>
                                {values.packages.length > 0 &&
                                    values.packages.map((packageObj: any, i: number) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`package-measurements ${packageObj.processed ? 'processed' : ''
                                                    }`}
                                            >
                                                <div className="package-measurements-top-wrapper">
                                                    <Typography variant="h6">
                                                        {t.packageLabel} {i + 1}
                                                    </Typography>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleDeletePackage(i)}
                                                    >
                                                        <DeleteForeverOutlined />
                                                    </IconButton>
                                                </div>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    margin="dense"
                                                    variant="outlined"
                                                    label={t.measuredWeight}
                                                    value={values.packages[i].weight}
                                                    onChange={(ev: any) =>
                                                        handleChangePackageWeight(i, ev.target.value)
                                                    }
                                                />
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    margin="dense"
                                                    variant="outlined"
                                                    placeholder="40x50x60"
                                                    label={t.measuredSizes}
                                                    value={values.packages[i].sizes}
                                                    onChange={(ev: any) => handleChangePackageSizes(i, ev.target.value)}
                                                />
                                                <hr />
                                            </div>
                                        )
                                    })}
                                <Tooltip title={t.addPackage}>
                                    <IconButton color="primary" onClick={handleAddPackage}>
                                        <AddOutlined />
                                    </IconButton>
                                </Tooltip>
                                <FormikError name="packages" errors={errors} touched={touched} />
                            </div>
                            <DialogActions>
                                <Button variant="contained" color="secondary" type="submit">
                                    {t.buttonLabel1}
                                </Button>
                                <Button variant="contained" color="info" onClick={handleClose}>
                                    {t.buttonLabel2}
                                </Button>
                            </DialogActions>
                        </form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}
