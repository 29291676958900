export const i18nLanding = {
	topLabel: {
		bg: 'Доставка на пратки и колети от Италия!',
		en: 'Delivering packages from Italy'
	},
	midLabel: {
		bg: 'Онлайн пазаруване.',
		en: 'Online shopping.'
	},
	botLabel: {
		bg: 'Бързо и достъпно!',
		en: 'Fast and reliable!'
	}
}
