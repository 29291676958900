export const i18nStepsBanner = {
	step1: {
		bg: 'Стъпка 1',
		en: 'Step 1',
	},
	step1Title: {
		bg: 'Влез / Регистрирай се',
		en: 'Login / Register'
	},
	step1Description: {
		bg: 'Направи си регистрация на нашия сайт за да регистрираш пратки много по-бързо, да следиш твоите пратки, история на поръчки, промоции и много други',
		en: 'Create a profile to register orders much faster, track your orders, view orders history and more'
	},
	step1ButtonLabel1: {
		bg: 'Влез',
		en: 'Login'
	},
	step1ButtonLabel2: {
		bg: 'Регистрирай се',
		en: 'Register'
	},
	step2: {
		bg: 'Стъпка 2',
		en: 'Step 2'
	},
	step2Title: {
		bg: 'Регистрирай пратка',
		en: 'Reigster package'
	},
	step2Description: {
		bg: 'Пратката ти ще пристигне в нашия италиански склад, след което ние ще я натоварим и доставим в България',
		en: 'Your package will arrive in our italian storage, then we will deliver it to Bulgaria'
	},
	step2ButtonLabel: {
		bg: 'Регистрирай пратка',
		en: 'Reigster package'
	},
	step3: {
		bg: 'Стъпка 3',
		en: 'Step 3'
	},
	step3Title: {
		bg: 'Цени за доставка',
		en: 'Delivery prices'
	},
	step3Description: {
		bg: 'Пратките се доставят от български куриерски фирми, като има възможност ние да доставим пратките ако адреса за доставка е в гр. София',
		en: 'We are working with bulgarian delivery companies or directly we can deliver you packages if the delivery address is in Sofia'
	},
	step3ButtonLabel: {
		bg: 'Цени',
		en: 'Prices'
	},
	step4: {
		bg: 'Стъпка 4',
		en: 'Step 4'
	},
	step4Title: {
		bg: 'График доставки',
		en: 'Deliveries schedule'
	},
	step4Description: {
		bg: 'Правим доставки България-Италия-България всяка седмица, посети страницата ни с транспортния график за повече информация',
		en: 'We are making deliveries Bulgaria-Italy-Bulgaria every single week. Visit our schedule page for more information'
	},
	step4ButtonLabel: {
		bg: 'График транспорт',
		en: 'Schedule'
	}
}