import { createAsyncThunk } from '@reduxjs/toolkit'

import { ROLE } from '../../utils/roles'
import { isAuthRequest } from './utils'
import { ActionTypes } from '../actionTypes'
import { getTranslation } from './translation'
import { messages } from '../../utils/i18n/messages'
import { showNotification } from './notificationActions'
import { hideLoader, showLoader } from './loaderActions'
import { logFailApiRequest } from '../../utils/functions'
import { usersService } from '../../services/usersService'
import { sendReactGAEvent } from '../../utils/ReactGAEvent'

const saveToken = (token: string): void => {
	if (token) {
		localStorage.setItem('authToken', token)
	}
}

interface LoginUserData {
	email: string
	password: string
	rememberUser: boolean
}
export const login = createAsyncThunk(
	ActionTypes.users.login.typeName,
	async (userData: LoginUserData, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.login(userData)
			if (!response.isSuccess) {
				logFailApiRequest(response)
				dispatch(showNotification(response.message, 'error'))
				return response.status
			}

			const { token, ...rest } = response.data as any
			saveToken(token as string)

			dispatch(showNotification(translatedMsgs.loginSuccess, 'success'))

			return rest
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const logout = createAsyncThunk(
	ActionTypes.users.logout.typeName,
	(prop: any, thunkAPI) => {
		const translatedMsgs = getTranslation(messages)
		const { dispatch } = thunkAPI
		localStorage.setItem('authToken', '')
		dispatch(showNotification(translatedMsgs.logoutSuccess, 'success'))

		return prop
	}
)

interface RegisterUserData {
	email: string
	password: string
	agreeWithTC: boolean
	subscribeNewsletter: boolean
	isCompany: boolean
	companyId: string | null
	companyOwner: string | null
	companyAddress: string | null
}
export const register = createAsyncThunk(
	ActionTypes.users.register.typeName,
	async (userData: RegisterUserData, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.register(userData)
			if (!response.isSuccess) {
				if (response.status === 302) { // email exists
					dispatch(showNotification(response.message, 'error'))
				} else {
					logFailApiRequest(response)
					dispatch(showNotification(response.message, 'error'))
				}
				return
			}

			dispatch(showNotification(translatedMsgs.registerSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface VerifyRegistrationInterface {
	code: string
	firstName: string
	lastName: string
	phoneNumber: string
}
export const verifyRegistration = createAsyncThunk(
	ActionTypes.users.verifyRegistration.typeName,
	async (userData: VerifyRegistrationInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.verifyRegistration(userData)
			if (!response.isSuccess) {
				logFailApiRequest(response)
				dispatch(showNotification(response.message, 'error'))
				return false
			}

			dispatch(showNotification(translatedMsgs.finishRegisterSuccess, 'success'))
			return response
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
			return false
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface ResendVerificationCodeInterface {
	email: string
}
export const resendVerificationCode = createAsyncThunk(
	ActionTypes.users.verifyRegistration.typeName,
	async (userData: ResendVerificationCodeInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.resendVerificationCode(userData.email)
			if (!response.isSuccess) {
				logFailApiRequest(response)
				dispatch(showNotification(response.message, 'error'))
				return false
			}

			dispatch(showNotification(translatedMsgs.resendCodeSuccess, 'success'))
			return true
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
			return false
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface SendVerificationSupportRequestInterface {
	email: string
}
export const sendVerificationSupportRequest = createAsyncThunk(
	ActionTypes.users.verifyRegistration.typeName,
	async (userData: SendVerificationSupportRequestInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.sendVerificationSupportRequest(userData.email)
			if (!response.isSuccess) {
				logFailApiRequest(response)
				dispatch(showNotification(response.message, 'error'))
				return false
			}

			dispatch(showNotification(translatedMsgs.sendVerificationSupportRequest, 'success'))
			return true
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
			return false
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface RegisterSpecificInterface {
	email: string
	password: string
	role: ROLE
}
export const registerSpecific = createAsyncThunk(
	ActionTypes.users.registerSpecific.typeName,
	async (userData: RegisterSpecificInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.registerSpecific(userData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.registerSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdateRoleInterface {
	updaterId: string
	userId: string
	newRole: ROLE
}
export const updateRole = createAsyncThunk(
	ActionTypes.users.updateRole.typeName,
	async (userData: UpdateRoleInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.updateUserRole(userData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.updateRoleSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface UpdateProfileInterface {
	userId: string
	country?: string
	city?: string
	address?: string
	firstName?: string
	lastName?: string
	phoneNumber?: string
}
export const updateProfile = createAsyncThunk(
	ActionTypes.users.updateProfile.typeName,
	async (userData: UpdateProfileInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.updateUserProfile(userData)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.updateProfileSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface ForgetPasswordInterface {
	email: string
}
export const forgetPasswordRequest = createAsyncThunk(
	ActionTypes.users.forgetPassword.typeName,
	async (userData: ForgetPasswordInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.sendForgetPasswordRequest(userData.email)
			if (!response.isSuccess) {
				if (response.status === 404) {
					dispatch(showNotification(response.message, 'success')) // email not found
					return
				}

				logFailApiRequest(response)
				return
			}

			dispatch(showNotification(translatedMsgs.passwordResetRequest, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface RestorePasswordInterface {
	email: string
	newPassword: string
	token: string
}
export const restorePassword = createAsyncThunk(
	ActionTypes.users.restorePassword.typeName,
	async (userData: RestorePasswordInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.restorePassword(userData)
			if (!response.isSuccess) {
				dispatch(showNotification(response.message, 'error'))
				logFailApiRequest(response)
				return
			}

			dispatch(showNotification(translatedMsgs.updateProfileSuccess, 'success'))

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

export const getAllUsers = createAsyncThunk(
	ActionTypes.users.getAllUsers.typeName,
	async (data: any, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.getAllUsers()
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			return response.data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface DeleteUserInterface {
	adminId: string
	deleteUserId: string
}
export const deleteUser = createAsyncThunk(
	ActionTypes.users.delete.typeName,
	async (data: DeleteUserInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.deleteUser(data)
			if (!isAuthRequest(response, dispatch)) {
				return
			}

			dispatch(showNotification(translatedMsgs.userDeleteSuccess, 'success'))

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)

interface SendEmailRequestInterface {
	name: string
	email: string
	phone: string
	text: string
}
export const sendEmailRequest = createAsyncThunk(
	ActionTypes.users.sendEmailRequest.typeName,
	async (data: SendEmailRequestInterface, thunkAPI) => {
		const { dispatch } = thunkAPI
		const translatedMsgs = getTranslation(messages)
		try {
			dispatch(showLoader())
			const response = await usersService.sendEmailRequest(data)
			if (!response.isSuccess) {
				dispatch(showNotification(response.message, 'error'))
				logFailApiRequest(response)
				return
			}

			sendReactGAEvent('users', 'sendRequest')

			dispatch(showNotification(translatedMsgs.sendEmailRequestSuccess, 'success'))

			return data
		} catch (err) {
			console.log(err)
			dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
		} finally {
			dispatch(hideLoader())
		}
	}
)
