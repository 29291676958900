
import { Tabs, Tab, Box, Paper } from '@mui/material'
import { useState } from 'react'

import { i18nPriceCalc } from './i18n'
import { useTranslate } from '../../hooks/translate'
import { CalcPalletsForm } from './pallets/CalcPalletsForm'
import { CalcPackageForm } from './packages/CalcPackagesForm'
import { CalcFreightsForm } from './freights/CalcFreightForm'
import { CalcRelocationForm } from './relocation/CalcRelocationForm'
import { useDevice } from '../../hooks/useDevice'
import { DeviceStrings, useResponsiveString } from '../../hooks/useResponsiveString'

import './styles.css'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export function PriceCalculator(): JSX.Element {
    const [value, setValue] = useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const t = useTranslate(i18nPriceCalc)

    const classNames = {
        mobile: 'price-calc-top-box-mobile',
        smallDesktop: 'price-calc-top-box'
    } as DeviceStrings
    const className = useResponsiveString(classNames)

    const device = useDevice()
    const isNotMobile = device !== 'm'

    return (
        <Box className={className}>
            <Paper elevation={5} className='price-calc-top-paper'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={t.packageTabLabel} />
                        {
                            isNotMobile &&
                            [
                                <Tab key={0} label={t.palletTabLabel} />,
                                <Tab key={1} label={t.freightTabLabel} />,
                                <Tab key={2} label={t.relocationTabLabel} />
                            ]
                        }
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <CalcPackageForm />
                </CustomTabPanel>
                {
                    isNotMobile &&
                    [
                        <CustomTabPanel key={0} value={value} index={1}>
                            <CalcPalletsForm />
                        </CustomTabPanel>,
                        <CustomTabPanel key={1} value={value} index={2}>
                            <CalcFreightsForm />
                        </CustomTabPanel>,
                        <CustomTabPanel key={2} value={value} index={3}>
                            <CalcRelocationForm />
                        </CustomTabPanel>
                    ]
                }
            </Paper>
        </Box>
    );
}
