import { DeviceComponents, useResponsive } from "../../../hooks/useResponsive"

import { HowItWorksStepMobile } from './mobile/HowItWorksStepMobile'
import { HowItWorksStepDesktop } from './desktop/HowItWorksStepDesktop'

interface Props {
    stepCount: number
    stepHeader: string
    image: string | undefined
    children: React.ReactNode
}

export function HowItWorksStep(props: Props): JSX.Element {
	const components = {
		mobile: <HowItWorksStepMobile {...props} />,
		smallDesktop: <HowItWorksStepDesktop {...props} />
	} as DeviceComponents

	return useResponsive(components)
}
