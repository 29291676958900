import { DeviceComponents, useResponsive } from '../../hooks/useResponsive'
import { FooterDesktop } from './desktop/FooterDesktop'
import { FooterMobile } from './mobile/FooterMobile'

export function Footer(): JSX.Element {
    const components = {
        mobile: <FooterMobile />,
        smallDesktop: <FooterDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}
