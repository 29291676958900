export const i18nAdminISaddOrdersModal = {
	title: {
		bg: 'Добавяне на пратки към вътрешен график за ',
		en: 'Add packages to internal schedule for '
	},
	totalValue: {
		bg: 'Обща стойност',
		en: 'Total value'
	},
	status: {
		bg: 'Статус',
		en: 'Status'
	},
	receiver: {
		bg: 'Получател',
		en: 'Receiver'
	},
	address: {
		bg: 'Адрес',
		en: 'Address'
	},
	weight: {
		bg: 'Тегло',
		en: 'Weight'
	},
	count: {
		bg: 'Брой',
		en: 'Count'
	},
	deliveryCompany: {
		bg: 'Доставчик',
		en: 'Delivery company'
	},
	value: {
		bg: 'Стойност',
		en: 'Value'
	},
	buttonLabel1: {
		bg: 'Запази',
		en: 'Save'
	},
	buttonLabel2: {
		bg: 'Затвори',
		en: 'Close'
	}
}
