import { useEffect } from 'react'
import { connect } from 'react-redux'
import { ProfilePageMobile } from './mobile/ProfilePageMobile'
import { ProfilePageDesktop } from './desktop/ProfilePageDesktop'
import { getEcontOffices } from '../../data/actions/econtActions'
import { DeviceComponents, useResponsive } from '../../hooks/useResponsive'

export function ProfilePageComponent(props: any): JSX.Element {
    useEffect(() => {
        props.getEcontOffices()
    }, [props])

    const components = {
        mobile: <ProfilePageMobile />,
        smallDesktop: <ProfilePageDesktop />,
    } as DeviceComponents

    return useResponsive(components)
}

const mapDispatchToProps = { getEcontOffices }

export const ProfilePage = connect(null, mapDispatchToProps)(ProfilePageComponent)
