export const i18nTopNavBar = {
	profile: {
		bg: 'Профил',
		en: 'Profile',
	},
	admin: {
		bg: 'Админ',
		en: 'Admin',
	},
	logout: {
		bg: 'Изход',
		en: 'Logout'
	},
	login: {
		bg: 'Вход',
		en: 'Login'
	},
	register: {
		bg: 'Регистрация',
		en: 'Register'
	}
}
